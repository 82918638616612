import { injectable } from "inversify";
import RoleEntity from "../../entities/RoleEntity";
import ExceptionEntity from "../../entities/ExceptionEntity";
import { Either } from "fp-ts/lib/Either";
import RoleRepository from "../../repositories/RoleRepository";

interface props { roleRepository: RoleRepository }

@injectable()
export default class GetRoleInfoByIdUseCase {
    _roleRepository: RoleRepository;

    constructor(_: props) {
        this._roleRepository = _.roleRepository;
    }

    public call = async (id: string) => new Promise<Either<ExceptionEntity, RoleEntity>>(async (resolve, reject) => {
        try {
            const response = await this._roleRepository.getRoleById(id);
            
            return resolve(response);
        } catch (error) {
            return reject();
        }
    });
};

export const GetRoleInfoByIdUseCaseName = "GetRoleInfoByIdUseCaseName";