import { Either, left, right } from "fp-ts/lib/Either";
import ExceptionEntity from "../../../../domain/entities/ExceptionEntity";
import { Auth } from "aws-amplify";
import callAPI from "../../fetchCallImplementation";
import Constants, { ConstantsName } from "../../../../constants/Constants";
import di from "../../../../di/DependencyInjection";

function extractEntityKeySubmodules(operations: any[]) {
    const uniqueKeywords = new Set();
    const policies = ['View', 'Edit', 'Delete', 'Create', 'Assign', 'Update'];

    operations.forEach((operation: string) => {
        const words = operation.match(/[A-Z][a-z]*/g);
        if (words) {
        words.forEach((word: string) => !policies.includes(word) ? uniqueKeywords.add(word) : null);
        }
    });

    return Array.from(uniqueKeywords);
}

const GetAllPermissionsApiImpl  = async (): Promise<Either<ExceptionEntity, any[]>> => {

    try {
        const baseUrl = di.get<Constants>(ConstantsName).HOST_API_1_URL;
        const response = await callAPI(`${baseUrl}/permissions`, 'GET', {});

        if (!response.ok) {
            const error = await response.json()
            throw new Error(error.message);
        }
        
        const result = await response.json();

        const resources = result.map((permission: any) => {
            return permission.resource_name;
        }).filter((item: any) => item !== null);
        

        let uniqueArray = resources.reduce((accumulator: any[], currentValue: any) => {
            if (!accumulator.includes(currentValue)) {
                accumulator.push(currentValue);
            }
            return accumulator;
        }, []);
        

        const resourcesWithPermissions = uniqueArray.map((resource: any) => {
            
            let newPermissions: any[] = [];
            result.map((permission: any) => {
                
                if (permission.resource_name === resource) {
                    newPermissions.push(permission.permission_name)
                }
            });
            return {
                [resource?.replaceAll(" ", "_")]: newPermissions
            }
        })

        let resultObject = resourcesWithPermissions.reduce((acc: any, obj: any) => {
            let key = Object.keys(obj)[0];
            let value = obj[key];     
            
            acc[key] = {
                permissions: value,
                resources: extractEntityKeySubmodules(value),
            }

            return acc;
        }, {});
                
        return right(resultObject);
        
    } catch (error: any) {
        
        const exception: ExceptionEntity = {message: error.message};
        
        return left(exception);
    }
}

export default GetAllPermissionsApiImpl;