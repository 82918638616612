import { injectable } from "inversify";
import ShiftRepository, { GetUsersWithShiftResponse } from "../../repositories/ShiftRepository";
import SearchResultEntity from "../../entities/SearchResultEntity";
import UserEntity from "../../entities/UserEntity";

interface props {
  shiftRepository: ShiftRepository;
}
@injectable()
export default class SearchUsersOfShiftUseCase {
  private shiftRepository: ShiftRepository;

  public constructor(_: props) {
    this.shiftRepository = _.shiftRepository;
  }

  public async call(shiftId: string, word: string, page: number, itemsPerPage: number): Promise<SearchResultEntity<UserEntity>> {
    return this.shiftRepository.searchUsersOfShift(shiftId, word, page, itemsPerPage);
  }
}

export const SearchUsersOfShiftUseCaseName = "SearchUsersOfShiftUseCaseName";