import { FC } from "react";
import DateFormComponentProps from "./DateRangeFormComponentProps";
import { ErrorMessage } from "@hookform/error-message";
import Validators from "../../../utils/Validators";

const DateRangeFormComponent: FC<DateFormComponentProps> = ({ label, keyName, disabled, register, onChange, watch, errors, validatorProps }) => {
    //add validator that has to be >= to the other date
    const _validatorLast = {
        ...validatorProps,
        greaterThan: watch(keyName + '.first'),
    }

    return <div className="w-100">
        <div className="form-group">
            <label>{label}</label>
            <div className="w-100">
                <div className="row">
                    <div className="col-6">
                        <input type="date" placeholder="Min" className="form-control" {...register(keyName + ".first", Validators(validatorProps!))} />
                    </div>
                    <div className="col-6">
                        <input type="date" placeholder="Max" className="form-control" {...register(keyName + ".last", Validators(_validatorLast))} />
                    </div>
                </div>
                <ErrorMessage errors={errors} name={keyName + '.first'} />
                <ErrorMessage errors={errors} name={keyName + '.last'} />
            </div>
        </div>
    </div>
}

DateRangeFormComponent.defaultProps = {
    validatorProps: {}
};

export default DateRangeFormComponent;