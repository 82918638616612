import { injectable } from "inversify";
import Constants, { ConstantsName } from "../../constants/Constants";
import di from "../../di/DependencyInjection";
import RestApi from "./RestApi";

@injectable()
class HostApi extends RestApi {
    public url = di.get<Constants>(ConstantsName).HOST_API_1_URL;
}

export default HostApi;

export const HostApiName = "HostApi";