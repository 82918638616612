import { injectable } from "inversify";
import EventScheduleEntity from "../../entities/EventScheduleEntity";
import { Either, isRight, left } from "fp-ts/lib/Either";
import ExceptionEntity from "../../entities/ExceptionEntity";
import CalendarRepository from "../../repositories/CalendarRepository";
import TrackingTimeProvider from "../../providers/trackingTime/TrackingTimeProvider";
import di from "../../../di/DependencyInjection";
import GetTodayTrackingCalendarUseCase, { GetTodayTrackingCalendarUseCaseName } from "../trackingTime/GetTodayTrackingCalendarUseCase";
import UserProvider from "../../providers/user/UserProvider";
import KeyWordLocalization from "../../../ui/providers/localization/dictionaries/KeyWordLocalization";

interface props {
    calendarRepository: CalendarRepository;
    trackingTimeProvider: TrackingTimeProvider;
    userProvider: UserProvider;
}

@injectable()
export default class CreateEventInCalendarUseCase {
    _calendarRepository: CalendarRepository;
    _trackingTimeProvider: TrackingTimeProvider;
    _userProvider: UserProvider;

    constructor(_: props) {
        this._calendarRepository = _.calendarRepository;
        this._trackingTimeProvider = _.trackingTimeProvider;
        this._userProvider = _.userProvider;
    }

    public call = async (event: EventScheduleEntity, userId: string): Promise<Either<ExceptionEntity, EventScheduleEntity>> => {
        const myUser = this._userProvider.Actions.user;
        const havePermision = (myUser?.id == userId && myUser?.permissions?.calendar.my_calendar.create) || (myUser?.id != userId && myUser?.permissions?.calendar.others_calendar.create);
        if (!havePermision) return left({ code: KeyWordLocalization.UserNotAuthorized});

        //now create
        const response = await this._calendarRepository.createEventForUser(event, userId);
        const eventIsToday = event.dateStart.toDateString() === new Date().toDateString();
        if (isRight(response) && eventIsToday && myUser?.id == userId)
            di.get<GetTodayTrackingCalendarUseCase>(GetTodayTrackingCalendarUseCaseName).call();
        return response;
    }
}

export const CreateEventInCalendarUseCaseName = "CreateEventInCalendarUseCaseName";
