import { injectable } from "inversify";
import FilterOptionEntity from "../../entities/FilterOptionEntity";
import RolesEntity from "../../entities/RolesEntity";
import RoleRepository from "../../repositories/RoleRepository";
import ModuleEntity from "../../entities/ModuleEntity";
import RoleEntity from "../../entities/RoleEntity";
import ExceptionEntity from "../../entities/ExceptionEntity";
import { Either } from "fp-ts/lib/Either";

interface props { roleRepository: RoleRepository }

@injectable()
export default class GetAllPermissionsUseCase {
    _roleRepository: RoleRepository;

    constructor(_: props) {
        this._roleRepository = _.roleRepository;
    }

    public call = async () => new Promise<Either<ExceptionEntity, any[]>>(async (resolve, reject) => {
        try {
            const response = await this._roleRepository.getAllPermissions();
            
            return resolve(response);
        } catch (error) {
            return reject();
        }
    });
}

export const GetAllPermissionsUseCaseName = "GetAllPermissionsUseCaseName";