import EventScheduleEntity from "../../../domain/entities/EventScheduleEntity";
import DateParse from "../../../ui/utils/DateParse";
import TypeEventScheduleEntityHostDto from "./TypeEventScheduleEntityHostDto";

//TODO DTO check
const fromJson = (json: any):EventScheduleEntity => {
    const type = TypeEventScheduleEntityHostDto.fromJson(json.type ?? json);
    const temp:EventScheduleEntity = {
        dateEnd: DateParse.stringToDate(json.dateEnd ?? json.end_time),
        dateStart: DateParse.stringToDate(json.dateStart ?? json.start_time),
        description: json.description,
        id: json.shift_modifier_id?.toString() ?? json.id?.toString(),
        name: type.name,
        status: json.status, //TODO adapt
        type: type,
        endedAt : json.endedAt != undefined ? DateParse.stringToDate(json.endedAt) : undefined,
        startedAt: json.startedAt != undefined ? DateParse.stringToDate(json.startedAt) : undefined,
    }
    return temp;
}

const toJson = (eventSchedule: EventScheduleEntity): any => {
    return {
        dateEnd: DateParse.formatDate(eventSchedule.dateEnd),
        dateStart: DateParse.formatDate(eventSchedule.dateStart),
        description: eventSchedule.description,
        id: eventSchedule.id,
        name: eventSchedule.name,
        status: eventSchedule.status, //TODO adapt
        type: TypeEventScheduleEntityHostDto.toJson(eventSchedule.type),
        endedAt : eventSchedule.endedAt != undefined ? DateParse.formatDate(eventSchedule.endedAt) : undefined,
        startedAt: eventSchedule.startedAt != undefined ? DateParse.formatDate(eventSchedule.startedAt) : undefined,
    }
}

export default {
    fromJson,
    toJson,
}