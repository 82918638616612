import './UserNameWithPhotoComponentStyles.scss';
import { FC } from "react";
import UserNameWithPhotoComponentProps from './UserNameWithPhotoComponentProps';

const UserNameWithPhotoComponent: FC<UserNameWithPhotoComponentProps> = ({ image, title, subtitle, description }) => {
    return <div className="user_component">
        <div className="user-circle-image-container">
            <img src={image ?? '/assets/no_photo_profile.svg'} alt="" className="img_circle" />
        </div>
        <div className="flex-grow-1 d-flex flex-column ms-2">
            <div className='user_component_title'>{title}</div>
            {subtitle && <span className='user_component_subtitle'>{subtitle}</span>}
            {description && <span className='user_component_subtitle'>{description}</span>}
        </div>
    </div>
}

export default UserNameWithPhotoComponent;