/********************************************************************************
 * File Header - AWS Create User API Implementation
 * Created By: Marlon Alejandro Mendez Castañeda
 * Contact: +57 3234686680
 * Date: August 9, 2023
 *
 * Description:
 * Create a user in AWS Cognito, from the user entity and the password.
 ********************************************************************************/

import { Auth } from "aws-amplify";
import { Either, left, right } from 'fp-ts/Either';
import ExceptionEntity from "../../../../../domain/entities/ExceptionEntity";
import KeyWordLocalization from "../../../../../ui/providers/localization/dictionaries/KeyWordLocalization";
import UserEntity from "../../../../../domain/entities/UserEntity";

const CreateUserAWSApiImpl = async (user: UserEntity, password: string): Promise<Either<ExceptionEntity, void>> => {
  const attributes = {
    email: user.email,
  };
  try {
    const response = await Auth.signUp({
      username: user.email,
      password: password,
      attributes: attributes,
    });
    if(!response.userConfirmed) {
      return left({
        code: KeyWordLocalization.AuthRepositoryErrorUserNotConfirmed
      });
    }else{
      return right(undefined);
    }
  } catch (error) {
    return left({});
  }
}

export default CreateUserAWSApiImpl;