/********************************************************************************
 * File Header - Get Current User API Implementation
 * Created By: Marlon Alejandro Mendez Castañeda
 * Contact: +57 3234686680
 * Date: August 9, 2023
 *
 * Description:
 * Get the current user from AWS User Pool.
 * 
 * Comments:
 * Ended and tested
 ********************************************************************************/

import { Either, left, right } from "fp-ts/lib/Either";
import UserEntity, { UserEntityStatus } from "../../../../../domain/entities/UserEntity";
import { Auth } from "aws-amplify";
import ExceptionEntity from "../../../../../domain/entities/ExceptionEntity";
import KeyWordLocalization from "../../../../../ui/providers/localization/dictionaries/KeyWordLocalization";
import HostApi, { HostApiName } from "../../../../settings/HostApi";
import UserHostDto from "../../../../dto/impl/UserHostDto";
import di from "../../../../../di/DependencyInjection";
import SetPermissionOfUserApiImpl from "./SetPermissionOfUserApiImpl";

const GetCurrentUserApiImpl = async (): Promise<Either<ExceptionEntity, UserEntity>> => {
    try {
        const response = await Auth.currentAuthenticatedUser();
        di.get<HostApi>(HostApiName).setToken(response.signInUserSession.idToken.jwtToken);
        const relativeUrl = "/currentuser";
        const userTemp = await di.get<HostApi>(HostApiName).get(relativeUrl);
        window.localStorage.setItem('timeZoneId', userTemp.timezone);

        const userParsed = UserHostDto.fromJson(userTemp);
        if (userParsed?.status != UserEntityStatus.active) return left({
            code: KeyWordLocalization.AuthRepositoryErrorUserDisabled
        });


        const permissionsResult = await SetPermissionOfUserApiImpl(userParsed);

        const userPermissions = {
            ...userParsed,
            permissions: permissionsResult
        } as UserEntity;

        return right(userPermissions);
    }
    catch (error: any) {
        return left({});
    }
}

export default GetCurrentUserApiImpl;
