/********************************************************************************
 * File Header - Search Groups Api Impl
 * Created By: Marlon Alejandro Mendez Castañeda
 * Contact: +57 3234686680
 * Date: January 25, 2024
 *
 * Description:
 * Search groups and get the info
 * 
 * Comments:
 * 
 ********************************************************************************/

import di from "../../../../../di/DependencyInjection";
import GroupEntity from "../../../../../domain/entities/GroupEntity";
import SearchResultEntity from "../../../../../domain/entities/SearchResultEntity";
import GroupHostDto from "../../../../dto/impl/GroupHostDto";
import HostApi, { HostApiName } from "../../../../settings/HostApi";
import UserHostApi, { UserHostApiName } from "../../../../settings/UserHostApi";

const SearchGroupApiImpl = async (word: string, page: number, itemsPerPage: number): Promise<SearchResultEntity<GroupEntity>> => {
    try {
        const relativeUrl = "/groups";
        const params = `?keyword=${word}&page=${page}&per_page=${itemsPerPage} `
        const groupsList = await di.get<UserHostApi>(UserHostApiName).get(relativeUrl + params);
        const groups: GroupEntity[] = groupsList.data?.map((group: any) => GroupHostDto.fromJson(group));
        return {
            items: groups,
            total: groups.length,
            pages: 1,
        }
    }
    catch (error: any) {
        return {
            items: [],
            pages: 0,
            total: 0,
        };
    }
}

export default SearchGroupApiImpl;