import { Either, isLeft } from "fp-ts/lib/Either";
import ExceptionEntity from "../../../../../domain/entities/ExceptionEntity";
import TrackingClockInteractionEntity from "../../../../../domain/entities/TrackingClockInteractionEntity";
import CreateTimeTrackingApiImpl from "./CreateTimeTrackingApiImpl";
import CreateTimeTrackingSwapApiImpl from "./CreateTimeTrackingSwapApiImpl";

//TODO implement
const EditTrackingInteractionAsSupervisorApiImpl = async (trackingTime: TrackingClockInteractionEntity, commentContent: string, supervisorId: string): Promise<Either<ExceptionEntity, void>> => {
    //first create a new one
    const createTrackingResponse = await CreateTimeTrackingApiImpl(trackingTime.activityTypeId, trackingTime.clockInTime, trackingTime.clockOutTime, trackingTime.details, trackingTime.shiftId, trackingTime.tagTypeId);
    if (isLeft(createTrackingResponse)) return createTrackingResponse;
    const newTracking = createTrackingResponse.right;
    //then swap with id
    const responseSwap = await CreateTimeTrackingSwapApiImpl(commentContent, trackingTime.id, supervisorId, supervisorId, newTracking.id);
    return responseSwap;
}

export default EditTrackingInteractionAsSupervisorApiImpl;