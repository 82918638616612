import { injectable } from "inversify";
import UserEntity from "../../entities/UserEntity";
import UserRepository from "../../repositories/UserRepository";
import { Either } from "fp-ts/lib/Either";
import ExceptionEntity from "../../entities/ExceptionEntity";

interface props { updateUsersRepository: UserRepository }

@injectable()
export default class UpdateUserUseCase {
    _updateUsersRepository: UserRepository;

    constructor(_: props) {
        this._updateUsersRepository = _.updateUsersRepository;
    }

    public call = async (data: any[]) => new Promise<Either<ExceptionEntity, string>>(async (resolve, reject) => {
        try {
            const response = await this._updateUsersRepository.updateUsers(data);
            return resolve(response);
        } catch (error) {
            return reject();
        }
    });
}

export const UpdateUserUseCaseName = "UpdateUserUseCaseName";