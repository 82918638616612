import { Either, left, right } from "fp-ts/lib/Either";
import di from "../../di/DependencyInjection";
import ExceptionEntity from "../../domain/entities/ExceptionEntity";
import AuthRepository, { AuthRepositoryName } from "../../domain/repositories/AuthRepository";
import KeyWordLocalization from "../../ui/providers/localization/dictionaries/KeyWordLocalization";
import Constants, { ConstantsName } from "../../constants/Constants";

const url = () => {
    try {
        return di.get<Constants>(ConstantsName).GRAPH_API_URL;
    } catch (error) {
        return "https://obw26pjgcfg57ijmwweqanejfu.appsync-api.us-east-1.amazonaws.com/graphql";
    }
}

// axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
const AWS_COGNITO_TOKEN_KEY = "aws_cognito_token";
const AWS_COGNITO_EMAIL_KEY = "aws_cognito_email";

const getToken = () => window.localStorage.getItem(AWS_COGNITO_TOKEN_KEY) ?? "";
const getEmail = () => window.localStorage.getItem(AWS_COGNITO_EMAIL_KEY) ?? "";

const setToken = (token: string) => {
    window.localStorage.setItem(AWS_COGNITO_TOKEN_KEY, token);
};
const setEmail = (email: string) => window.localStorage.setItem(AWS_COGNITO_EMAIL_KEY, email);

const removeToken = () => window.localStorage.removeItem(AWS_COGNITO_TOKEN_KEY);
const removeEmail = () => window.localStorage.removeItem(AWS_COGNITO_EMAIL_KEY);

const _getHeaders = () => {
    return {
        'Content-Type': 'application/json',
        'Authorization': getToken(),
    };
}

const _parseError = async (response: Response | string): Promise<ExceptionEntity> => {
    if (response instanceof Response) {
        try {
            const error = await response.json();
            console.error('error message', error);
            return {
                message: error.message,
                code: 'ErrorHost' + error.key,
            }
        } catch (error) {
            console.error('error unkown message', error);
            const errorAsText = response;
            const key: string = 'ErrorHost' + errorAsText;
            return {
                message: key,
                code: key in KeyWordLocalization ? (KeyWordLocalization as { [key: string]: string })[key] : KeyWordLocalization.UnknownError,
            }
        }
    } else {
        if (response.toLocaleLowerCase() == "Invalid role.".toLocaleLowerCase()) {
            return {
                message: KeyWordLocalization.ErrorHostInvalidRole,
                code: KeyWordLocalization.ErrorHostInvalidRole,
            }
        }
        const errorAsText = response;
        const key: string = 'ErrorHost' + errorAsText;
        return {
            message: errorAsText,
            code: key in KeyWordLocalization ? (KeyWordLocalization as { [key: string]: string })[key] : KeyWordLocalization.UnknownError,
        }
    }
}


const refreshToken = async () => await di.get<AuthRepository>(AuthRepositoryName).refreshToken();

const checkToken = async (callback: Function): Promise<any> => {
    try {
        const response = await callback();
        return response;
    } catch (error: any) {
        if (error.message == "The incoming token has expired") {
            await refreshToken();
            return await callback();
        }
    }
}

const query = async (name: string, params: any, results: string[]): Promise<Either<ExceptionEntity, any>> => multipleQuery([{ name, params, results }]);

const multipleQuery = async (queries: { name: string, params: any, results: string[], }[], ignoreLeft?: boolean): Promise<Either<ExceptionEntity, any>> => {
    const _petition = async (): Promise<Either<ExceptionEntity, any>> => {
        const queriesAsStringList = queries.map((query) => {
            const paramsAsString = JSON.stringify(query.params).replace(/"([^"]+)":/g, '$1:');
            //remove first and last {}
            const paramsNotBrackets = paramsAsString.substring(1, paramsAsString.length - 1);

            return `${query.name} ${paramsNotBrackets.length > 0 ? `(${paramsNotBrackets})` : ''}{
                ${query.results.join('\n')}
              }`;
        });
        const joined = queriesAsStringList.join('\n');
        return await fetch(url(), {
            method: 'POST',
            headers: _getHeaders(),
            body: JSON.stringify({
                query: `query MyQuery {
                    ${joined}
                  }`,
            })
        }).then(async response => {
            if (ignoreLeft != true && (!response.ok || response.status >= 400)) {
                const errorParsed = await _parseError(response);
                return left(errorParsed);
            }
            try {
                const js = await response.json();
                if (ignoreLeft != true && js?.errors?.length > 0) {
                    const parsedErrorr = await _parseError(js.errors[0].message);
                    return left(parsedErrorr);
                }
                return right(js);
            } catch (error) {
                const ts = await response.text();
                return right(ts);
            }
        }).catch((error) => error);
    };
    return await checkToken(_petition).then((response) => (response)).catch((error) => left(error));
}

const mutation = async (name: string, params: any, results: string[]): Promise<Either<ExceptionEntity, any>> => multipleMutation([{ name, params, results }]);

const multipleMutation = async (mutations: { name: string, params: any, results: string[] }[]): Promise<Either<ExceptionEntity, any>> => {
    const _petition = async (): Promise<Either<ExceptionEntity, any>> => {
        //mutation schema name (params,...) {results}
        const mutationsAsStringList = mutations.map((mutation) => {
            // const paramsAsStringList = Object.keys(mutation.params).map((key) => {
            //     return `${key}: ${typeof mutation.params[key] == 'string' ? `"${mutation.params[key]}"` : mutation.params[key]}`;
            // });
            const paramsAsString = JSON.stringify(mutation.params).replace(/"([^"]+)":/g, '$1:');
            //remove first and last {}
            const paramsNotBrackets = paramsAsString.substring(1, paramsAsString.length - 1);
            return `${mutation.name} (${paramsNotBrackets}) {
                ${mutation.results.join('\n')}
              }`;
        });
        const joined = mutationsAsStringList.join(',\n');
        return await fetch(url(), {
            method: 'POST',
            headers: _getHeaders(),
            body: JSON.stringify({
                query: `mutation MyMutation {
                    ${joined}
                  }`,
            })
        }).then(async response => {
            if (!response.ok || response.status >= 400) {
                const errorParsed = await _parseError(response);
                return left(errorParsed);
            }
            try {
                const js = await response.json();
                if (js?.errors?.length > 0) {
                    const parsedErrorr = await _parseError(js.errors[0].message);
                    return left(parsedErrorr);
                }
                return right(js);
            } catch (error) {
                const ts = await response.text();
                return right(ts);
            }
        }).catch((error) => error);
    };
    return await checkToken(_petition).then((response) => (response)).catch((error) => left(error));
}

export default {
    mutation,
    query,
    getToken,
    setToken,
    removeToken,
    getEmail,
    setEmail,
    removeEmail,
    multipleMutation,
    multipleQuery,
}

export const GraphApiName = "GrapApi";