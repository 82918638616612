export enum FilterOptionType {
    DATE, //-done
    RANGE_DATE, //-done
    TEXT, //-done
    NUMBER, //-done
    BOOLEAN, //-done
    NUMBER_RANGE, 
    SELECT, //-done
    MULTI_SELECT, //-done
}
export default interface FilterOptionEntity {
    label: string;
    keyName: string;
    defaultValue?: any;
    minValue?: number | undefined | Date;
    maxValue?: number | undefined | Date;
    options?: {
        label: string;
        id: any;
    }[] | undefined;
    type?: FilterOptionType | undefined;
}