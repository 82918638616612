import BusinessUnitEntity from "../../../domain/entities/BusinessUnitEntity"

const fromJson = (json:any): BusinessUnitEntity => {
    return {
        id: json.name,
        name: json.name,
        logo: json.logo ?? '', // logo is missing
    }
}

const toJson = (businessUnit: BusinessUnitEntity): any => {
    return {
        bu_id: businessUnit.name,
        name: businessUnit.name,
        logo: businessUnit.logo, // logo is missing
    }
}

export default {
    toJson,
    fromJson,
}