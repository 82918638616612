/********************************************************************************
 * File Header - AWS Sign In With Active Directory API Implementation
 * Created By: Marlon Alejandro Mendez Castañeda
 * Contact: +57 3234686680
 * Date: August 9, 2023
 *
 * Description:
 * This file contains the implementation of the AWS Sign In With Active Directory of Microsoft 365 API.
 ********************************************************************************/

import { Either, left } from "fp-ts/lib/Either";
import UserEntity from "../../../../../domain/entities/UserEntity";
import { Auth } from "aws-amplify";
import ExceptionEntity from "../../../../../domain/entities/ExceptionEntity";
import KeyWordLocalization from "../../../../../ui/providers/localization/dictionaries/KeyWordLocalization";
import GetCurrentUserApiImpl from "./GetCurrentUserApiImpl";

const SignInWithActiveDirectoryApiImpl = async (): Promise<Either<ExceptionEntity, UserEntity>> => {
  try {
    const isTried = window.localStorage.getItem('isTried365') == 'true';
    if(isTried){
      window.localStorage.removeItem('isTried365');
    }else {
      window.localStorage.setItem('isTried365', 'true');
    }
    await Auth.federatedSignIn({ customProvider: 'itel365pool' });
    const response = await GetCurrentUserApiImpl();
    Auth.currentSession().then(res=>{
      let accessToken = res.getIdToken()
      let jwt = accessToken.getJwtToken()
      localStorage.setItem('jwt', jwt)

    }).catch((errors) => {
    });
    return response;
  }
  catch (error: any) {
    if (error?.code == 'UserNotConfirmedException') {
      return left({
        code: KeyWordLocalization.AuthRepositoryErrorUserNotConfirmed,
      });
    }
    return left({});
  }
}

export default SignInWithActiveDirectoryApiImpl;
