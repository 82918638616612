/********************************************************************************
 * File Header - AWS Delete User API Implementation
 * Created By: Marlon Alejandro Mendez Castañeda
 * Contact: +57 3234686680
 * Date: August 9, 2023
 *
 * Description:
 * Delete the current user from AWS Cognito.
 ********************************************************************************/

import { Auth } from "aws-amplify";
import { Either, left, right } from "fp-ts/lib/Either";
import ExceptionEntity from "../../../../../domain/entities/ExceptionEntity";

const DeleteUserApiImpl = async (): Promise<Either<ExceptionEntity, void>> => {
    try {
        //delete current user
        await Auth.currentAuthenticatedUser().then((_) => {
            return Auth.deleteUser();
        });
        return right(undefined);
    }
    catch (error: any) {
        return left({});
    }
}

export default DeleteUserApiImpl;