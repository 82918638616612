import { injectable } from "inversify";
import AIRepository from "../../repositories/AIRepository";

interface props {
    aiRepository: AIRepository;
}

@injectable()
export class SearchAISummariesUseCase {
    _aiRepository: AIRepository;

    constructor(_: props) {
        this._aiRepository = _.aiRepository;
    }

    public call = async (query: any): Promise<any> => {
        return await this._aiRepository.searchAISummaries(query);
    }
}

export const SearchAISummariesUseCaseName = "SearchAISummariesUseCaseName";