import { ChangeEvent, FC, useContext, useEffect, useRef, useState } from "react";
import ModalsContext from "../../../../../domain/providers/modal/ModalsContext";
import ModalsContextType from "../../../../../domain/providers/modal/ModalsContextType";
import './CreateModalComponentStyles.scss';
import Icons from "../../../../assets/Icons";
import CreateModalComponentProps from "./CreateModalComponentProps";

const CreateModalComponent:FC<CreateModalComponentProps> = ({}) => {
    const { isOpenModalCreate, closeModalCreate, createModal, changeContent } = useContext(ModalsContext) as ModalsContextType;


    const modalContainerRef = useRef<HTMLDivElement>(null);

    const handleSave = () => {
            createModal.onConfirm(createModal);
            closeModalCreate();
    }

    const handleCancel = () => {
        closeModalCreate();
    }

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (modalContainerRef.current && !modalContainerRef.current.contains(event.target as Node)) {
            closeModalCreate();
            }
        };

        if (isOpenModalCreate) {
            window.addEventListener("mousedown", handleClickOutside);
        }

        return () => {
            window.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isOpenModalCreate, closeModalCreate]);

    if (createModal === undefined) return <></>
    
    return <section className="create-back-container">
        <div className="create_modal_component" ref={modalContainerRef}>
                <div className="create_modal_component_header">
                    <div>{createModal.title}</div>
                    <div onClick={() => closeModalCreate()}>
                        <Icons.CloseX className="create_modal_component_header_icon"/>
                    </div>
                    
                </div>
                <div className="create_modal_component_content">
                    <p className="create_modal_component_content_titles">Name</p>
                    <textarea
                        className="create_modal_component_content_input_name"
                        name=""
                        id=""
                        spellCheck="false"
                        onChange={(e) => changeContent(createModal.title, e.target.value, createModal.content, createModal.onConfirm)}
                        value={createModal.name}
                    >
                        {createModal.name}
                    </textarea>
                    <p className="create_modal_component_content_titles">Description</p>
                    <textarea 
                        className="create_modal_component_content_input_description"
                        spellCheck="false"
                        onChange={(e) => changeContent(createModal.title, createModal.name, e.target.value, createModal.onConfirm)}
                        value={createModal.content}
                    >
                    </textarea>
                </div>
                <div className="create_modal_component_footer">
                    <button className="create_modal_component_cancel" onClick={() => handleCancel()}>Cancel</button>
                    <button className="create_modal_component_delete" onClick={() => handleSave()}>Update</button>
                </div>
        </div>
    </section>
}

export default CreateModalComponent;
