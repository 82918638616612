import './CardTrackingInteractionComponentStyles.scss';
import { FC, useContext } from "react";
import Icons from '../../../../../assets/Icons';
import LocalizationContext from '../../../../../../domain/providers/localization/LocalizationContext';
import LocalizationContextType from '../../../../../../domain/providers/localization/LocalizationContextType';
import DateParse from '../../../../../utils/DateParse';
import CardTrackingInteractionComponentProps from './CardTrackingInteractionComponentProps';

const CardTrackingInteractionComponent: FC<CardTrackingInteractionComponentProps> = ({ interaction, onClick }) => {
    const { i18n } = useContext(LocalizationContext) as LocalizationContextType;
    return <div className={`card_tracking_interaction_component ${onClick && 'hover'}`} onClick={()=>onClick?.(interaction!)}>
        <div className="content">
            <div className="line_event" style={{ background: interaction.tagType?.color }}> </div>
            <div className="">
                <div className="w-100 d-flex justify-content-between">
                    <div className="flex-grow-1 me-2 text_ellipsis"><strong>{interaction.tagType?.name}</strong></div>
                    <span className='text-end'>{DateParse.timeWithPmAm(interaction.clockInTime as Date)} - {interaction.clockOutTime != null ? DateParse.timeWithPmAm(interaction.clockOutTime as Date) : 'Not ended'}</span>
                </div>
                {/* {event?.description && <div className="mt-2">{event?.description}</div>} */}
                <div className="d-flex mt-1 billable_container">
                    <div className="me-2 d-flex align-items-center">{interaction.tagType?.billable ? <Icons.Money /> : <Icons.MoneyOff />} <span className='mx-2'>{i18n(interaction.tagType?.billable ? 'Billable' : 'No Billable')}</span> </div>
                    <div className="me-2 d-flex align-items-center">{interaction.tagType?.payable ? <Icons.Money /> : <Icons.MoneyOff />} <span className='mx-2'>{i18n(interaction.tagType?.payable ? 'Payable' : 'No Payable')}</span> </div>
                </div>
            </div>
        </div>
    </div >
}

export default CardTrackingInteractionComponent;