import { Either, isLeft, right } from "fp-ts/lib/Either";
import TrackingClockInteractionEntity from "../../../../../domain/entities/TrackingClockInteractionEntity";
import ExceptionEntity from "../../../../../domain/entities/ExceptionEntity";
import GraphApi from "../../../../settings/GraphApi";
import TrackingClockInteractionHostDto from "../../../../dto/impl/TrackingClockInteractionHostDto";
import DateParse from "../../../../../ui/utils/DateParse";

const CreateTimeTrackingApiImpl = async (activity_type_id: number, clock_in_time: Date, clock_out_time: Date | null, details: string, shift_id: number, tag_type_id: number): Promise<Either<ExceptionEntity, TrackingClockInteractionEntity>> => {
    const response = await GraphApi.mutation('createTime_tracking_unrestricted', {
        createTime_trackingInput: { activity_type_id, clock_in_time: DateParse.formatDate(clock_in_time), clock_out_time: clock_out_time != null ? DateParse.formatDate(clock_out_time): null, details, shift_id, tag_type_id }
    }, ['activity_type_id', 'clock_in_time', 'clock_out_time', 'details', 'is_active', 'shift_id', 'tag_type_id', 'time_tracking_id']);

    if (isLeft(response)) return response;

    const parsedItem = TrackingClockInteractionHostDto.fromJson(response.right.data.createTime_tracking_unrestricted);
    return right(parsedItem);
}

export default CreateTimeTrackingApiImpl;