import { FC, useContext, useEffect, useRef, useState } from 'react';
import './HomePageStyles.scss';
import { modules, routes, iRouteModule, iRoute } from '../../routes/RoutesComponent';
import LocalizationContext from '../../../domain/providers/localization/LocalizationContext';
import LocalizationContextType from '../../../domain/providers/localization/LocalizationContextType';
import KeyWordLocalization from '../../providers/localization/dictionaries/KeyWordLocalization';
import { useNavigate } from 'react-router-dom';
import di from '../../../di/DependencyInjection';
import SignOutUseCase, { SignOutUseCaseName } from '../../../domain/use_cases/auth/SignOutUseCase';
import { MdOutlineLogout } from 'react-icons/md';
import UserEntity from "../../../domain/entities/UserEntity";
import UserContextType from '../../../domain/providers/user/UserContextType';
import UserContext from '../../../domain/providers/user/UserContext';

const HomePage: React.FC = () => {
    const { user } = useContext(UserContext) as UserContextType;
    const { i18n } = useContext(LocalizationContext) as LocalizationContextType;

    const [moduleItems, setModuleItems] = useState<iRouteModule | null>(null);
    const [openModal, setOpenModal] = useState(false);
    const modalRef = useRef<HTMLDivElement>(null);
    const gridContainerRef = useRef<HTMLDivElement>(null);

    const navigate = useNavigate();

    const _userCanAccessToModule = (module: iRouteModule) => 
        (module.page?.auth(user) || module.pages.some(page => page.auth(user)));

    const _handleOpenOptions = (module: iRouteModule) => {
        if (module.pages.length > 0) {
            setModuleItems(module);
            setOpenModal(true);
        } else if (module.page) {
            navigate(module.page.path);
        }
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
            setOpenModal(false);
        }
    };

    const _handleSignOut = async () => {
        await di.get<SignOutUseCase>(SignOutUseCaseName).call();
        navigate(routes.signin.path);
    };

    const scrollLeft = () => {
        if (gridContainerRef.current) {
            gridContainerRef.current.scrollBy({ left: -200, behavior: 'smooth' });
        }
    };

    const scrollRight = () => {
        if (gridContainerRef.current) {
            gridContainerRef.current.scrollBy({ left: 200, behavior: 'smooth' });
        }
    };

    useEffect(() => {
        if (openModal) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [openModal]);

    return (
        <div>
            <div className={openModal ? 'back_home_open' : 'back_home'}>
                <div className="home_header">
                    <img src="/assets/logos/logo.png" className="logo" alt="App Logo" />
                    <div className="title_app">
                        <span className="text_bold">{i18n(KeyWordLocalization.TitleApp)}</span>
                        <span>{i18n(KeyWordLocalization.TitleApp2)}</span> 
                    </div>
                </div>
                <div className="grid-navigation">
                    <div className="scroll-container">
                        <div className="grid-container" ref={gridContainerRef}>
                            {modules.map((module, index) => 
                                _userCanAccessToModule(module) ? (
                                    <div key={index} className="grid-item" onClick={() => _handleOpenOptions(module)}>
                                        <module.icon width={40} height={40} className="icon" />
                                        <div className="item_title">{i18n(module.name)}</div>
                                    </div>
                                ) : null
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {openModal && (
                <div className="modules_modal_container">
                    <div className="modules_modal" ref={modalRef}>
                        {moduleItems?.pages.map((item, index) => {
                            if (item.auth(user)) {
                                return (
                                    <div
                                    key={index}
                                    className="modules_modal_item"
                                    onClick={() => {
                                            navigate(item.path);
                                    }}
                                    >
                                        {i18n(item.name)}
                                    </div>
                                )
                            }
                        })}
                    </div>
                </div>
            )}
            <div onClick={_handleSignOut} className="home_signout">
                <MdOutlineLogout size={32} className="icon" />
                <span>{i18n(KeyWordLocalization.SignOut)}</span>
            </div>
        </div>
    );
};

export default HomePage;
