const KeyWordLocalization = {
    // ----------------------General---------------------- //
    TitleApp: 'TitleApp',
    TitleApp2: 'TitleApp2',
    UnknownError: 'UnknownError',
    Search: 'Search',
    Save: 'Save',
    Yes: 'Yes',
    No: 'No',
    Create: 'Create',
    Edit: 'Edit',
    Delete: 'Delete',
    Password: 'Password',
    ConfirmNotMatch: 'ConfirmNotMatch',
    Confirm: 'Confirm',
    Cancel: 'Cancel',
    SavedSuccessfully: 'SavedSuccessfully',
    ErrorSaving: 'ErrorSaving',
    PasswordConfirm: 'PasswordConfirm',
    PasswordsNotMatch: 'PasswordsNotMatch',
    Loading: 'Loading',
    SignOut: 'SignOut',
    Clear: 'Clear',

    // ----------------------Routes---------------------- //
    MeModule: 'MeModule',
    PersonModule: 'PersonModule',
    ShiftsModule: 'ShiftsModule',
    TrackingModule: 'TrackingModule',
    RolesModule: 'RolesModule',
    AttendanceModule: 'AttendanceModule',
    AIModule: 'AIModule',
    RouteGoHome: 'RouteGoHome',

    RoutePageSignIn: 'RoutePageSignIn',
    RoutePageSignIn365: 'RoutePageSignIn365',
    RoutePageSignUp: 'RoutePageSignUp',
    RoutePageHome: 'RoutePageHome',
    RoutePageUsers: 'RoutePageUsers',
    RoutePageRoles: 'RoutePageRoles',
    RoutePagePermissions: 'RoutePagePermissions',
    RoutePageRoleDetailed: 'RoutePageRoleDetailed',
    RoutePageUserDetailed: 'RoutePageUserDetailed',
    RoutePageMyCalendar: 'RoutePageMyCalendar',
    RoutePageEmployeeCalendar: 'RoutePageEmployeeCalendar',
    RoutePageSendCode: 'RoutePageSendCode',
    RoutePageUpdatePassword: 'RoutePageUpdatePassword',
    RoutePageResetPassword: 'RoutePageResetPassword',
    RoutePageConfirmAccount: 'RoutePageConfirmAccount',
    RoutePageShiftCalendar: 'RoutePageShiftCalendar',
    RoutePageCreatingShiftCalendar: 'RoutePageCreatingShiftCalendar',
    RoutePageShifts: 'RoutePageShifts',
    RoutePageShiftPerSupervisor: 'RoutePageShiftPerSupervisor',
    RoutePageStaffPerShift: 'RoutePageStaffPerShift',
    RoutePageEditAttendanceTracking: 'RoutePageEditAttendanceTracking',
    RoutePageAttendanceDashboard: 'RoutePageAttendanceDashboard',
    RoutePageEditAttendanceAdjustments: 'RoutePageEditAttendanceAdjustments',

    // ----------------------Validators---------------------- //
    ValidatorRequired: 'ValidatorRequired',
    ValidatorEmail: 'ValidatorEmail',
    ValidatorMinLength: 'ValidatorMinLength',
    ValidatorMaxLength: 'ValidatorMaxLength',
    ValidatorMinValue: 'ValidatorMinValue',
    ValidatorMaxValue: 'ValidatorMaxValue',
    ValidatorMustBeNumber: 'ValidatorMustBeNumber',
    ValidatorSpecialCharactersIsRequired: 'ValidatorSpecialCharactersIsRequired',
    ValidatorUpperCaseIsRequired: 'ValidatorUpperCaseIsRequired',
    ValidatorLowerCaseIsRequired: 'ValidatorLowerCaseIsRequired',
    ValidatorNumberIsRequired: 'ValidatorNumberIsRequired',
    ValidatorMustBeEqual: 'ValidatorMustBeEqual',
    ValidatorSpecialCharacterIsNotAllowed: 'ValidatorSpecialCharacterIsNotAllowed',
    ValidatorUpperCaseIsNotAllowed: 'ValidatorUpperCaseIsNotAllowed',
    ValidatorLowerCaseIsNotAllowed: 'ValidatorLowerCaseIsNotAllowed',
    ValidatorNumberIsNotAllowed: 'ValidatorNumberIsNotAllowed',
    ValidatorIsNotName: 'ValidatorIsNotName',
    ValidatorIsNotPhone: 'ValidatorIsNotPhone',
    ValidatorGreaterThan: 'ValidatorGreaterThan', //value
    ValidatorGreaterOrEqualThan: 'ValidatorGreaterOrEqualThan', //value
    ValidatorLessThan: 'ValidatorLessThan', //value
    ValidatorMustHavePartBeforeAt: 'ValidatorMustHavePartBeforeAt',
    ValidatorMinTime: "ValidatorMinTime", //value
    ValidatorMaxTime : "ValidatorMaxTime", //value

    // ----------------------Entities---------------------- //
    UserEntityId: 'UserEntityId',
    UserEntityName: 'UserEntityName',
    UserEntityPhone: 'UserEntityPhone',
    UserEntityEmail: 'UserEntityEmail',
    UserEntityEnabled: 'UserEntityEnabled',
    UserEntityPermissions: 'UserEntityPermissions',
    UserEntityStatus: 'UserEntityStatus',
    UserEntityRole: 'UserEntityRole',
    UserEntityBUAssociated: 'UserEntityBUAssociated',
    UserEntitySupervisor: 'UserEntitySupervisor',

    EventScheduleEntityId: 'EventScheduleEntityId',
    EventScheduleEntityName: 'EventScheduleEntityName',
    EventScheduleEntityDescription: 'EventScheduleEntityDescription',
    EventScheduleEntityDateStart: 'EventScheduleEntityDateStart',
    EventScheduleEntityDate: "EventScheduleEntityDate",
    EventScheduleEntityDateStartHour: 'EventScheduleEntityDateStartHour',
    EventScheduleEntityDateEnd: 'EventScheduleEntityDateEnd',
    EventScheduleEntityType: 'EventScheduleEntityType',
    EventScheduleEntityStartedAt: 'EventScheduleEntityStartedAt',
    EventScheduleEntityEndedAt: 'EventScheduleEntityEndedAt',
    EventScheduleEntityStatus: 'EventScheduleEntityStatus',

    ShiftEntity: "ShiftEntity",
    ShiftEntityId: "ShiftEntityId",
    ShiftEntityName: "ShiftEntityName",
    ShiftEntityUsers: "ShiftEntityUsers",
    ShiftEntityRating: "ShiftEntityRating",
    ShiftEntityDays: "ShiftEntityDays",
    ShiftEntityDaysDay: "ShiftEntityDaysDay",
    ShiftEntityDaysInitHour: "ShiftEntityDaysInitHour",
    ShiftEntityDaysEndHour: "ShiftEntityDaysEndHour",
    ShiftEntityDaysUsers: "ShiftEntityDaysUsers",
    ShiftEntityDaysRating: "ShiftEntityDaysRating",
    ShiftEntityDaysRatingUsersGroupsStatus: "ShiftEntityDaysRatingUsersGroupsStatus",
    ShiftEntityBusinessUnitId: "ShiftEntityBusinessUnitId",
    ShiftEntityInitHour: 'ShiftEntityInitHour',
    ShiftEntityEndHour: 'ShiftEntityEndHour',

    TypeEventScheduleEntityBillable: 'TypeEventScheduleEntityBillable',
    TypeEventScheduleEntityPayable: 'TypeEventScheduleEntityPayable',
    TypeEventScheduleEntityName: 'TypeEventScheduleEntityName',

    GroupEntityName: 'GroupEntityName',
    GroupEntityUsers: 'GroupEntityUsers',
    GroupEntityDescription: 'GroupEntityDescription',

    TrackingClockInteractionEntityClockInTime: 'TrackingClockInteractionEntityClockInTime',
    TrackingClockInteractionEntityClockOutTime: 'TrackingClockInteractionEntityClockOutTime',
    TrackingClockInteractionEntityType: 'TrackingClockInteractionEntityType',
    TrackingClockInteractionEntityComment: 'TrackingClockInteractionEntityComment',

    // ----------------------Pages---------------------- //
    SignInPageTitle: 'SignInPageTitle',
    SignInPageEmail: 'SignInPageEmail',
    SignInPagePassword: 'SignInPagePassword',
    SignInPageDoYouForgetPassword: 'SignInPageDoYouForgetPassword',
    SignInPageSubmit: 'SignInPageSubmit',
    SingInPageWrongCredentials: 'SingInPageWrongCredentials',
    SignInPageSignUp: 'SignInPageSignUp',

    SignUpPageTitle: 'SignUpPageTitle',
    SignUpPageEmail: 'SignUpPageEmail',
    SignUpPagePassword: 'SignUpPagePassword',
    SignUpPagePasswordConfirm: 'SignUpPagePasswordConfirm',
    SignUpPageSubmit: 'SignUpPageSubmit',
    SignUpPageSignIn: 'SignUpPageSignIn',
    SignUpPagePasswordNotMatch: 'SignUpPagePasswordNotMatch',
    SignUpPageErrorCreating: 'SignUpPageErrorCreating',

    SendCodeRecoveryPageTitle: 'SendCodeRecoveryPageTitle',
    SendCodeRecoveryPageEmail: 'SendCodeRecoveryPageEmail',
    SendCodeRecoveryPageSubmit: 'SendCodeRecoveryPageSubmit',
    SendCodeRecoveryPageEmailNotFound: 'SendCodeRecoveryPageEmailNotFound',
    SendCodeRecoveryPageCodeSent: 'SendCodeRecoveryPageCodeSent',
    SendCodeRecoveryPageIRememberMyPassword: 'SendCodeRecoveryPageIRememberMyPassword',

    UpdatePasswordRecoveryPageTitle: 'UpdatePasswordRecoveryPageTitle',
    UpdatePasswordRecoveryPageCode: 'UpdatePasswordRecoveryPageCode',
    UpdatePasswordRecoveryPagePassword: 'UpdatePasswordRecoveryPagePassword',
    UpdatePasswordRecoveryPagePasswordConfirm: 'UpdatePasswordRecoveryPagePasswordConfirm',
    UpdatePasswordRecoveryPagePasswordNotMatch: 'UpdatePasswordRecoveryPagePasswordNotMatch',
    UpdatePasswordRecoveryPageSubmit: 'UpdatePasswordRecoveryPageSubmit',
    UpdatePasswordRecoveryPageResendCode: 'UpdatePasswordRecoveryPageResendCode',
    UpdatePasswordRecoveryPageUpdatedPassword: 'UpdatePasswordRecoveryPageUpdatedPassword',
    UpdatePasswordRecoveryPageErrorUpdatingPassword: 'UpdatePasswordRecoveryPageErrorUpdatingPassword',

    ConfirmAccountPageTitle: 'ConfirmAccountPageTitle',
    ConfirmAccountPageCode: 'ConfirmAccountPageCode',
    ConfirmAccountPageConfirmedAccount: 'ConfirmAccountPageConfirmedAccount',
    ConfirmAccountPageErrorConfirmingAccount: 'ConfirmAccountPageErrorConfirmingAccount',
    ConfirmAccountPageResentCode: 'ConfirmAccountPageResentCode',
    ConfirmAccountPageResendCode: 'ConfirmAccountPageResendCode',
    ConfirmAccountPageSubmit: 'ConfirmAccountPageSubmit',

    CalendarPersonPageCreateEvent: 'CalendarPersonPageCreateEvent',
    CalendarPersonPageEditEvent: 'CalendarPersonPageEditEvent',
    
    CalendarShiftPageSearchUsersTitle: 'CalendarShiftPageSearchUsersTitle',
    CalendarShiftPageCreateEvent: 'CalendarShiftPageCreateEvent',
    CalendarShiftPageEditEvent: 'CalendarShiftPageEditEvent',
    CalendarShiftPageDeleteEvent: 'CalendarShiftPageDeleteEvent',
    CalendarShiftPageCreateShift: 'CalendarShiftPageCreateShift',
    CalendarShiftPageEditShift: 'CalendarShiftPageEditShift',
    CalendarShiftPageDeleteShift: 'CalendarShiftPageDeleteShift',
    CalendarShiftPageSeeAll: 'CalendarShiftPageSeeAll',
    CalendarShiftPageMultipleEventsAdded: 'CalendarShiftPageMultipleEventsAdded',
    CalendarShiftPageEventsOutShift: 'CalendarShiftPageEventsOutShift',
    CalendarShiftPageShiftsOverlap: 'CalendarShiftPageShiftsOverlap',
    CalendarShiftPageEdit: 'CalendarShiftPageEdit',
    CalendarShiftPageSaveEditing: 'CalendarShiftPageSaveEditing',
    CalendarShiftPageDeleteShiftTitle: "CalendarShiftPageDeleteShiftTitle",
    CalendarShiftPageDeleteShiftDescription: "CalendarShiftPageDeleteShiftDescription",
    CalendarShiftPageDeleteEventTitle: "CalendarShiftPageDeleteEventTitle",
    CalendarShiftPageDeleteEventDescription: "CalendarShiftPageDeleteEventDescription",
    CalendarShiftPageChangesSaved: "CalendarShiftPageChangesSaved",

    ShiftListPageAssignShift: 'ShiftListPageAssignShift',
    ShiftListPageSomeEmployeesAlreadyHaveShifts: "ShiftListPageSomeEmployeesAlreadyHaveShifts",
    ShiftListPageNoEmployeesSelected: "ShiftListPageNoEmployeesSelected",
    
    DataLoaderPagePreviewCalendar: 'DataLoaderPagePreviewCalendar',

    EditAttendancePageEmployeeField: 'EditAttendancePageEmployeeField',
    EditAttendancePageDateField: 'EditAttendancePageDateField',
    EditAttendancePageSearchFirstTime: 'EditAttendancePageSearchFirstTime',
    EditAttendancePageEditionNotAllowedBefore: 'EditAttendancePageEditionNotAllowedBefore', //date
    EditAttendancePageEditionNotAllowed: 'EditAttendancePageEditionNotAllowed',

    TrackingDashboardPageBusinessesIds: 'TrackingDashboardPageBusinessesIds',
    TrackingDashboardPageSupervisor: 'TrackingDashboardPageSupervisor',
    TrackingDashboardPageEmployees: 'TrackingDashboardPageEmployees',
    TrackingDashboardPagePage: 'TrackingDashboardPagePage',
    TrackingDashboardPageItemsHasMore: 'TrackingDashboardPageItemsHasMore',
    TrackingDashboardPageDateInit: 'TrackingDashboardPageDateInit',
    TrackingDashboardPageDateEnd: 'TrackingDashboardPageDateEnd',

    TerminationStartPageEmployeeInformation: "TerminationStartPageEmployeeInformation",
    TerminationStartPagePositionInformation: "TerminationStartPagePositionInformation",
    TerminationStartPageTerminationInformation: "TerminationStartPageTerminationInformation",
    TerminationStartPageCompanyPropertyInformation: "TerminationStartPageCompanyPropertyInformation",
    TerminationStartPageConfirmation: "TerminationStartPageConfirmation",
    TerminationStartPageFormEmployeeName: "TerminationStartPageFormEmployeeName",
    TerminationStartPageFormEmployeeGender: "TerminationStartPageFormEmployeeGender",
    TerminationStartPageFormEmployeeLanguage: "TerminationStartPageFormEmployeeLanguage",
    TerminationStartPageFormEmployeeBirthday: "TerminationStartPageFormEmployeeBirthday",
    TerminationStartPageFormEmployeeStartDate: "TerminationStartPageFormEmployeeStartDate",
    TerminationStartPageFormEmployeePersonalEmail: "TerminationStartPageFormEmployeePersonalEmail",
    TerminationStartPageFormEmployeeHRMId: "TerminationStartPageFormEmployeeHRMId",
    TerminationStartPageFormSupervisorName: "TerminationStartPageFormSupervisorName",
    TerminationStartPageFormSupervisorEmail: "TerminationStartPageFormSupervisorEmail",
    TerminationStartPageFormPositionDepartment: "TerminationStartPageFormPositionDepartment",
    TerminationStartPageFormWorksiteLocation: "TerminationStartPageFormWorksiteLocation",
    TerminationStartPageFormLocation: "TerminationStartPageFormLocation",
    TerminationStartPageFormLastDayWorked: "TerminationStartPageFormLastDayWorked",
    TerminationStartPageFormNotifiactionOfTerminationDate: "TerminationStartPageFormNotifiactionOfTerminationDate",
    TerminationStartPageFormTerminationDate: "TerminationStartPageFormTerminationDate",
    TerminationStartPageFormPositionAtTermination: "TerminationStartPageFormPositionAtTermination",
    TerminationStartPageFormElegibleForRehire: "TerminationStartPageFormElegibleForRehire",
    TerminationStartPageFormCompanyPropertyReturned: "TerminationStartPageFormCompanyPropertyReturned",
    TerminationStartPageFormCompanyPropertyNotReturned: "TerminationStartPageFormCompanyPropertyNotReturned",
    TerminationStartPageFormTerminationPhase: "TerminationStartPageFormTerminationPhase",
    TerminationStartPageFormTerminationType: "TerminationStartPageFormTerminationType",
    TerminationStartPageMale: "TerminationStartPageMale",
    TerminationStartPageFemale: "TerminationStartPageFemale",

    // ----------------------Components---------------------- //
    NoResultsComponentTitle: 'NoResultsComponentTitle',
    NoResultsComponentMiniTitle: 'NoResultsComponentMiniTitle',
    NoResultsComponentDescription: 'NoResultsComponentDescription',

    NotFoundComponentMiniTitle: 'NotFoundComponentMiniTitle',
    NotFoundComponentTitle: 'NotFoundComponentTitle',
    NotFoundComponentDescription: 'NotFoundComponentDescription',
    NotFoundComponentGoHome: 'NotFoundComponentGoHome',

    CardCounterComponentSeeAll: 'CardCounterComponentSeeAll',
    CardCounterComponentAddOne: 'CardCounterComponentAddOne',

    StepCreatedComponentMiniTitle: 'StepCreatedComponentMiniTitle',
    StepCreatedComponentTitle: 'StepCreatedComponentTitle',
    StepCreatedComponentGoToCourse: 'StepCreatedComponentGoToCourse',

    SocialLoginComponentSignInWithMicrosoft: 'SocialLoginComponentSignInWithMicrosoft',
    SocialLoginComponentOr: 'SocialLoginComponentOr',

    SearchWithGroupsComponentBusinessUnitIds: 'SearchWithGroupsComponentBusinessUnitIds',
    SearchWithGroupsComponentSupervisorsIds: 'SearchWithGroupsComponentSupervisorsIds',
    SearchWithGroupsComponentEmployeesIds: 'SearchWithGroupsComponentEmployeesIds',
    SearchWithGroupsComponentGroupId: 'SearchWithGroupsComponentGroupId',
    SearchWithGroupsComponentCreateGroup: 'SearchWithGroupsComponentCreateGroup',
    BulkImportComponent: 'BulkImportComponent',
    SearchWithGroupsComponentGroups: 'SearchWithGroupsComponentGroups',
    SearchWithGroupsComponentErrorLoadingGroup: 'SearchWithGroupsComponentErrorLoadingGroup',
    SearchWithGroupsComponentAGroupIsSelected: 'SearchWithGroupsComponentAGroupIsSelected',

    AddShiftComponentAddShift: 'AddShiftComponentAddShift',
    AddShiftComponentAssignedShift: 'AddShiftComponentAssignedShift',
    AddShiftComponentAddFromGroup: 'AddShiftComponentAddFromGroup',
    AddShiftComponentDeleteAllSelected: 'AddShiftComponentDeleteAllSelected',
    AddShiftComponentSearchUsers: 'AddShiftComponentSearchUsers',
    AddShiftComponentEmployeesAdded: 'AddShiftComponentEmployeesAdded',
    AddShiftComponentShift: 'AddShiftComponentShift',
    AddShiftComponentAssignShift: 'AddShiftComponentAssignShift',
    AddShiftComponentOtherShift: 'AddShiftComponentOtherShift',
    AddShiftComponentCustomShift: 'AddShiftComponentCustomShift',
    AddShiftComponentInitHour: 'AddShiftComponentInitHour',
    AddShiftComponentEndHour: 'AddShiftComponentEndHour',

    CreateGroupModalComponentErrorCreating: 'CreateGroupModalComponentErrorCreating',
    CreateGroupModalComponentSearchUsers: 'CreateGroupModalComponentSearchUsers',
    CreateGroupModalComponentAddedUsers: 'CreateGroupModalComponentAddedUsers',

    CalendarWeekComponentOtherEvent: 'CalendarWeekComponentOtherEvent',
    CalendarWeekComponentHour: 'CalendarWeekComponentHour',
    CalendarWeekComponentErrorEventBeforeToday: 'CalendarWeekComponentErrorEventBeforeToday',
    
    CreateEventFormComponentEventCreated: 'CreateEventFormComponentEventCreated',
    CreateEventFormComponentEventUpdated: 'CreateEventFormComponentEventUpdated',

    SearchUsersModalComponentSearchUsers: 'SearchUsersModalComponentSearchUsers',
    SearchWithGroupsComponentGroupCreated: 'SearchWithGroupsComponentGroupCreated',
    SearchUsersModalComponentNoMoreUsers: 'SearchUsersModalComponentNoMoreUsers',

    CreateInteractionFormComponentEventCreated: 'CreateInteractionFormComponentEventCreated',
    CreateInteractionFormComponentEventUpdated: 'CreateInteractionFormComponentEventUpdated',

    EditAttendancePageAddAttendance: 'EditAttendancePageAddAttendance',
    EditAttendancePageEditAttendance: 'EditAttendancePageEditAttendance',
    EditAttendancePageNoEditions: 'EditAttendancePageNoEditions',
    EditAttendancePageCreateInteraction: 'EditAttendancePageCreateInteraction',
    EditAttendancePageEditInteraction: 'EditAttendancePageEditInteraction',
    EditAttendancePagePlanned: 'EditAttendancePagePlanned',
    EditAttendancePageReal: 'EditAttendancePageReal',
    EditAttendancePageSupervisorActions: 'EditAttendancePageSupervisorActions',
    EditAttendancePageSupervisorHistoric: 'EditAttendancePageSupervisorHistoric',

    TrackingStatusEmployeesComponentTitle: "TrackingStatusEmployeesComponentTitle",

    TrackingCompareLineComponentTitle: "TrackingCompareLineComponentTitle",

    LineHistoricGraphComponentTitle: "LineHistoricGraphComponentTitle",
    LineHistoricGraphComponentDone: "LineHistoricGraphComponentDone",
    LineHistoricGraphComponentPending: "LineHistoricGraphComponentPending",

    TrackingCardTableEventCompareComponentTitle: "TrackingCardTableEventCompareComponentTitle",
    TrackingCardTableEventCompareComponentTableHeaderEventName: "TrackingCardTableEventCompareComponentTableHeaderEventName",
    TrackingCardTableEventCompareComponentTableHeaderPlanned: "TrackingCardTableEventCompareComponentTableHeaderPlanned",
    TrackingCardTableEventCompareComponentTableHeaderReal: "TrackingCardTableEventCompareComponentTableHeaderReal",
    TrackingCardTableEventCompareComponentTableHeaderDiferencePercentual: "TrackingCardTableEventCompareComponentTableHeaderDiferencePercentual",

    StaffPerShiftListPageAbsent: "StaffPerShiftListPageAbsent",
    StaffPerShiftListPageActive: "StaffPerShiftListPageActive",
    StaffPerShiftListPageTraining: "StaffPerShiftListPageTraining",

    CreateShiftFormComponentShiftCreated: "CreateShiftFormComponentShiftCreated",
    CreateShiftFormComponentShiftUpdated: "CreateShiftFormComponentShiftUpdated",
    
    DataLoaderSpinnerComponentMessage: "DataLoaderSpinnerComponentMessage",

    NewFeaturesComponentRemindMeTomorrow: "NewFeaturesComponentRemindMeTomorrow",
    NewFeaturesComponentGotIt: "NewFeaturesComponentGotIt",

    // ----------------------Server---------------------- //
    AuthRepositoryErrorUserNotConfirmed: 'AuthRepositoryErrorUserNotConfirmed',
    AuthRepositoryErrorUserNeedPasswordChange: 'AuthRepositoryErrorUserNeedPasswordChange',
    AuthRepositoryErrorUserAlreadyExists: 'AuthRepositoryErrorUserAlreadyExists',
    AuthRepositoryErrorLimitResetExceeded : 'AuthRepositoryErrorLimitResetExceeded',
    AuthRepositoryErrorCodeReset : 'AuthRepositoryErrorCodeReset',
    AuthRepositoryErrorInvalidPasswordReset : 'AuthRepositoryErrorInvalidPasswordReset',
    AuthRepositoryErrorCodeRecoveryNoMatch : 'AuthRepositoryErrorCodeRecoveryNoMatch',
    AuthRepositoryErrorCodeRecoveryExpired : 'AuthRepositoryErrorCodeRecoveryExpired',
    AuthRepositoryErrorInvalidPassword : 'AuthRepositoryErrorInvalidPassword',
    AuthRepositoryErrorUserDisabled : 'AuthRepositoryErrorUserDisabled',
    AuthRepositoryErrorTokenError: 'AuthRepositoryErrorTokenError',

    ErrorHostGlobalError: 'ErrorHostGlobalError',
    ErrorHostMissingPermission: 'ErrorHostMissingPermission',
    ErrorHostDataValidationError: 'ErrorHostDataValidationError',
    ErrorHostInvalidRole: 'ErrorHostInvalidRole',
    ErrorHostValidationError: 'ErrorHostValidationError',
    ErrorHostRoleNotFound: 'ErrorHostRoleNotFound',
    ErrorHostDatabaseInsertionError: 'ErrorHostDatabaseInsertionError',
    ErrorHostCognitoGroupCreationError: 'ErrorHostCognitoGroupCreationError',
    ErrorHostCognitoGroupError: 'ErrorHostCognitoGroupError',
    ErrorHostDatabaseError: 'ErrorHostDatabaseError',
    ErrorHostCognitoGroupDeletionError: 'ErrorHostCognitoGroupDeletionError',
    ErrorHostAssignNewRoleFirst: 'ErrorHostAssignNewRoleFirst',
    ErrorHostPermissionNotFound: 'ErrorHostPermissionNotFound',
    ErrorHostUpdatePermissionError: 'ErrorHostUpdatePermissionError',
    ErrorHostDeletePermissionError: 'ErrorHostDeletePermissionError',
    ErrorHostPermissionIsUsedByAPolicy: 'ErrorHostPermissionIsUsedByAPolicy',
    ErrorHostPolicyNotFound: 'ErrorHostPolicyNotFound',
    ErrorHostResourceNotFound: 'ErrorHostResourceNotFound',
    ErrorHostUpdateResourceError: 'ErrorHostUpdateResourceError',
    ErrorHostDeleteResourceError: 'ErrorHostDeleteResourceError',
    ErrorHostResourceIsUsedByAPolicy: 'ErrorHostResourceIsUsedByAPolicy',
    ErrorHostPoliciesNotFound: 'ErrorHostPoliciesNotFound',
    ErrorHostRolesNotFound: 'ErrorHostRolesNotFound',
    ErrorHostAssociationAlreadyExists: 'ErrorHostAssociationAlreadyExists',
    ErrorHostDuplicateShifts: 'ErrorHostDuplicateShifts',
    ErrorHostTimeDifference: 'ErrorHostTimeDifference',
    ErrorHostShiftNotCreated: 'ErrorHostShiftNotCreated',
    ErrorHostErrorEditAttendanceOverdue: 'ErrorHostErrorEditAttendanceOverdue',
    ErrorHostEmailNotFound: 'ErrorHostEmailNotFound',
    ErrorHostEventOutoOfShift: 'ErrorHostEventOutoOfShift',

    TrackingInteractionAddedBySupervisor: 'TrackingInteractionAddedBySupervisor',

    UserNotAuthorized: 'UserNotAuthorized',
    NotAuthorizedException: "NotAuthorizedException",
    UserNotFoundException : 'UserNotFoundException',

    TrackingRepositoryStopForRestroom : 'TrackingRepositoryStopForRestroom',
    TrackingRepositoryTechnicalBreak: 'TrackingRepositoryTechnicalBreak',
    TrackingRepositoryTechnicalIssue: 'TrackingRepositoryTechnicalIssue',
    TrackingRepositoryOtherBreak: 'TrackingRepositoryOtherBreak',

    ModulePermisionNamePermission: 'ModulePermisionNamePermission',
    ModulePermisionNamePolicy: 'ModulePermisionNamePolicy',
    ModulePermisionNameUser: 'ModulePermisionNameUser',
    ModulePermisionNameRole: 'ModulePermisionNameRole',
    ModulePermisionNameResource: 'ModulePermisionNameResource',
    ModulePermisionNameAttendance: 'ModulePermisionNameAttendance',
    ModulePermisionNameSchedule: 'ModulePermisionNameSchedule',
    ModulePermisionNameShifttemplate: 'ModulePermisionNameShifttemplate',
    ModulePermisionNameTimetracking: 'ModulePermisionNameTimetracking',
    ModulePermisionNameShiftswap: 'ModulePermisionNameShiftswap',
    ModulePermisionNameEmployee: 'ModulePermisionNameEmployee',

    ErrorAsignShiftBulkImportErrorOnShift: 'ErrorAsignShiftBulkImportErrorOnShift',
    ErrorAsignShiftBulkImportErrorOnUser: 'ErrorAsignShiftBulkImportErrorOnUser',
    ErrorAsignShiftBulkImportErrorOnTasks: 'ErrorAsignShiftBulkImportErrorOnTasks',
  }

export default KeyWordLocalization;