import { FC } from "react";
import UserInListComponentProps, { UserInListAction } from "./UserInListComponentProps";
import './UserInListComponentStyle.scss';
import Icons from "../../../assets/Icons";
import TextOperations from "../../../utils/TextOperations";

const UserInListComponent: FC<UserInListComponentProps> = ({ user, onClick, action }) => {

    const handleImageError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
        const target = event.target as HTMLImageElement;
        target.src = '/assets/no_photo_profile.svg';
    };
    
    return <section className="user-by-role-container">
        <div className="user-by-role">
            <div>
                <div className="circle-image-container">
                    <img
                        src={user?.profile_picture ?? '/assets/no_photo_profile.svg'}
                        alt=""
                        className="img_circle"
                        onError={handleImageError}
                    />
                </div>
            </div>

            <div className="w-100 d-flex align-items-center">
                <div>
                    <strong>{TextOperations.capitalizeFirstLetter(`${user.firstname} ${user.lastname}`)}</strong> <br/>
                    <span>{user.role?.name}</span>
                    <div style={{fontSize: "12px"}}>{user.email}</div>

                </div>
                <div className="last-item" onClick={onClick}>
                    {action === UserInListAction.ADD && <div onClick={onClick}>
                        <Icons.Plus className='trash_icon hover' /> </div>
                    }

                    {action === UserInListAction.REMOVE && <div onClick={onClick}>
                        <Icons.Trash className='trash_icon hover' /></div>}
                </div>
            </div>



        </div>


    </section>
}

UserInListComponent.defaultProps = {
    action: UserInListAction.SEE,
}

export default UserInListComponent;  