import { FC, useEffect, useState } from 'react';
import './LayoutComponent.scss';
import LayoutProps from './LayoutComponentProps';
import { MdMenuOpen, MdMenu, MdOutlineLogout  } from "react-icons/md";
import { MdOutlineHouse  } from "react-icons/md";
import { useContext } from 'react';
import UserContext from '../../../domain/providers/user/UserContext';
import UserContextType from '../../../domain/providers/user/UserContextType';
import { routes, modules, iRouteModule } from '../../routes/RoutesComponent';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import AuthLayoutComponent from './authLayout/AuthLayoutComponent';
import di from '../../../di/DependencyInjection';
import LocalizationContext from '../../../domain/providers/localization/LocalizationContext';
import LocalizationContextType from '../../../domain/providers/localization/LocalizationContextType';
import KeyWordLocalization from '../../providers/localization/dictionaries/KeyWordLocalization';
import ModalsComponent from '../../providers/modals/modals/ModalsComponent';
import SignOutUseCase, { SignOutUseCaseName } from '../../../domain/use_cases/auth/SignOutUseCase';
import TrackingTimeComponent from '../trackingTime/TrackingTimeComponent';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

const LayoutComponent: FC<LayoutProps> = ({ children }) => {
  const { i18n } = useContext(LocalizationContext) as LocalizationContextType;
  const { user } = useContext(UserContext) as UserContextType;
  const location = useLocation();
  const navigate = useNavigate();

  const [open, setOpen] = useState<boolean>(window.screen.width > 780);
  const [openOptionMenu, setOpenOptionMenu] = useState<string | undefined>(undefined);
  const _handleSignOut = async () => {
    await di.get<SignOutUseCase>(SignOutUseCaseName).call();
    navigate(routes.signin.path);
  }

  const _userCanAccessToModule = (module: iRouteModule) => module.page?.auth(user) || module.pages.some(page => page.auth(user));

  const _handleToggleOptionMenu = (name: string) => {
    if (openOptionMenu == name) setOpenOptionMenu(undefined);
    else setOpenOptionMenu(name);
  }

  //on Changed page close the menu
  useEffect(() => {
    if(window.screen.width <= 780) setOpen(false);
    (window as any).gtag('event', 'page_view', {'page_path': location.pathname});
  }, [location.pathname])
  

  if (user == undefined) return <ModalsComponent><AuthLayoutComponent>{children}</AuthLayoutComponent></ModalsComponent>
  return <>
    <div className={`LayoutComponent ${open ? 'is_open' : ''}`}>
      {
        location.pathname !== "/home" ?
        <>
          <div className={`side_menu ${open ? 'open' : 'close'}`} style={{ backgroundImage: 'url(assets/bg/sidebar.png)' }}>
          <div className="header_side">
            <img src="/assets/logos/logo.png" className='logo' alt="" />
            <div className="text">
              <div className='title_app'><div className="text_bold">{i18n(KeyWordLocalization.TitleApp)}</div>{i18n(KeyWordLocalization.TitleApp2)}</div>
              <span>{user?.name}</span>
            </div>
          </div>
          <div className='home_link' onClick={() => {
            navigate("/home")
          }}>
            <MdOutlineHouse size={32} />
            <span>{i18n(KeyWordLocalization.RouteGoHome)}</span>
          </div>
          

          {modules.map((module, index) => _userCanAccessToModule(module)? <div className="module" key={index}>
            {module.page ? <Link to={module.page.relativePath as string} className={`header_module hover ${module.page.relativePath == window.location.pathname ? 'active_link' : ''}`}>
              <module.icon width={24} height={24} className="icon" />
              <span>{i18n(module.name)}</span>
            </Link> : <div className="header_module hover justify-content-between" onClick={() => _handleToggleOptionMenu(module.name)}>
              <div className='d-flex align-items-center'>
                <module.icon width={24} height={24} className="icon" />
                <span>{i18n(module.name)}</span>
              </div>
              {openOptionMenu == module.name ? <FaChevronUp size={24} className="icon" /> : <FaChevronDown size={24} className="icon rotate" />}
            </div>}
            {module.name == openOptionMenu && module.pages.filter(page => page.auth(user)).map((page, index) =>
              <Link to={page.relativePath as string} className={`page_link hover ${page.relativePath == window.location.pathname ? 'active_link' : ''}`} key={index}>
                {i18n(page.name)}
              </Link>)}
          </div> : '')}

          <div onClick={_handleSignOut} className="header_module hover mt-3">
            <MdOutlineLogout size={32} className="icon" />
            <span>{i18n(KeyWordLocalization.SignOut)}</span>
          </div>

        </div>
        <div className="right_side">
          <div className="header">
            
            {open ? <MdMenuOpen size={32} onClick={() => setOpen(false)} className="hover" /> : <MdMenu onClick={() => setOpen(true)} size={32} className="hover" />}
            <div onClick={_handleSignOut} className="header_module hover mt-3">
          </div>
            <div className="flex-grow-1 text-capitalize d-none d-md-block">
            </div>
            
            <div className="d-flex flex-row align-items-end align-items-center">
              <div className='d-flex flex-column-reverse flex-md-row align-items-end align-items-md-center'>
                {user?.permissions?.timeTracking?.read ? 
                  <div className="me-md-4">
                    <TrackingTimeComponent />
                  </div>
                  : 
                  <></>
                }
                <div className="d-flex flex-column align-items-end">
                  <strong>{user?.name}</strong>
                  <span className='d-none d-md-block'>{user.email}</span>
                </div>
              </div>
              <div className="image_container">
                <img src={user?.image ?? '/assets/no_photo_profile.svg'} className='' alt="" />
              </div>
            </div>
            
          </div>
          <div className="content" id='content'>
            {children}
          </div>
        </div>
        </> : 
        <div className="content" id='content'>
          {children}
        </div>
      }
      
      
    </div>
  </>
};

export default LayoutComponent;
