import { FC } from "react";

const TrackingTimeNoTasksComponent:FC<{}> = () => {
    return <div className="tracking_time_no_tasks_component">
        <div className="d-flex flex-column align-items-center">
            <h2 className="text-center">No tasks</h2>
            <div className="text-center">
                <span>Start adding tasks to your calendar</span>
            </div>
        </div>
    </div>
}

export default TrackingTimeNoTasksComponent;