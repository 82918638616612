/********************************************************************************
 * File Header - AWS Sign Out API Implementation
 * Created By: Marlon Alejandro Mendez Castañeda
 * Contact: +57 3234686680
 * Date: August 9, 2023
 *
 * Description:
 * This file contains the implementation of the AWS Sign Out API.
 ********************************************************************************/

import { left, right, Either } from "fp-ts/lib/Either"
import ExceptionEntity from "../../../../../domain/entities/ExceptionEntity"
import { Auth } from "aws-amplify";

const SignOutApiImpl = async (): Promise<Either<ExceptionEntity, void>> => {
    try {
        await Auth.signOut();
        return right(undefined);
    }
    catch (error: any) {
        return left({});
    }
}

export default SignOutApiImpl;