import { injectable } from "inversify";
import GroupEntity from "../../entities/GroupEntity";
import GroupRepository from "../../repositories/GroupRepository";
import { Either } from "fp-ts/lib/Either";
import ExceptionEntity from "../../entities/ExceptionEntity";
import UserEntity from "../../entities/UserEntity";

interface props {
    groupRepository: GroupRepository;
}

@injectable()
export default class CreateGroupUseCase {
    _groupRepository: GroupRepository;

    constructor(_: props) {
        this._groupRepository = _.groupRepository;
    }
    public call = async (name: string, description: string, users: UserEntity[]): Promise<Either<ExceptionEntity, GroupEntity>> => {
        return this._groupRepository.createGroup(name, description, users);
    }
}

export const CreateGroupUseCaseName = "CreateGroupUseCaseName";
