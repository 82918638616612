import React, { FC, RefObject, useEffect, useState } from 'react';
import './AutoCompleteStyles.scss';
import AutoCompleteProps from './AutoCompleteComponentProps';
import { Typeahead } from 'react-bootstrap-typeahead';
import { ErrorMessage } from '@hookform/error-message';
import Validators from '../../../utils/Validators';

import './AutoCompleteStyles.scss';
import { AutoCompleteItem } from '../autocompleteWithCheck/AutoCompleteWithCheckProps';

const AutoCompleteComponent: FC<AutoCompleteProps<any>> = ({ isOpen, placeholder, keyName, rounded, label, formInfo, onSearch, required, disabled, onSelect }) => {
  const { formState: { errors }, register, setValue } = formInfo;
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [options, setOptions] = useState<AutoCompleteItem<unknown>[]>([]);

  let _timerTap: any;
  const _handleChangeText = async (data: string) => {
    clearTimeout(_timerTap);
    setIsLoading(true);
    await _handleSearch(data ?? '');

  }


  const _handleSearch = async (word: string) => {
    setIsLoading(true);
    const response = await onSearch(word);
    setOptions(response);
    setIsLoading(false);
  }

  const _handlePickOption = (data: any) => {
    setValue(keyName, data?.[0]?.id ?? undefined);
    // setValue(keyName, data?.[0]?.id ?? undefined);
    if (data.length == 0) return;
    onSelect?.(data?.[0]?.aditionalValue || data);
  }

  useEffect(() => {
    _handleSearch('');
  }, []);


  return <div className={`form-group autocomplete_component pt-1 ${errors[keyName] ? 'error' : ''}`}>
    <Typeahead
      options={options}
      disabled={disabled}
      onInputChange={_handleChangeText}
      onChange={_handlePickOption}
      id={`${keyName}_autocomplete`}
      // selected={options.filter((option: any) => selected != undefined ? option.id == selected.id : false)}
      placeholder={placeholder ?? label}
      isLoading={isLoading}
      defaultOpen={isOpen}
      renderMenuItemChildren={(option: any) => (
        <div className="item-by-content">
          {option.image && <img className={`option_image ${rounded && 'rounded_image'}`} src={option.image} alt="" />}
          {option.label}
        </div>
      )}
    />
    <input type="hidden"  {...register(keyName, Validators({ required: required }))} />
    <ErrorMessage as="aside" errors={errors} name={keyName} />
  </div>

};

AutoCompleteComponent.defaultProps = {
  required: false,
  disabled: false,
  rounded: false,
  isOpen: false,
}

export default AutoCompleteComponent;
