export default interface AIRepository {
    getAISumaries: (params: string) => Promise<any>;
    getAISumaryByRecord: (record: string) => Promise<any>;
    updateAISumaryByRecord: (record: string, content: any) => Promise<any>;
    getAIEmbedURL: () => Promise<any>;
    searchAISummaries: (query: any) => Promise<any>;
    searchAISummariesLanguages: () => Promise<any>;
    searchAISummariesEntities: () => Promise<any>;
    getAIAgents: () => Promise<any>;
    getAICustomers: () => Promise<any>;
    getAIPerson: (person: string, type: string, params: string) => Promise<any>;
    getAIMoments: (keywords: string[], page: number, type: string, customer: string) => Promise<any>;
    UpdateAIMomentById: (content: any, keyword_id: number, value: any | undefined) => Promise<any>;
    CreateAIMoment: (customer: string, type: string, description: string, weight: number) => Promise<any>;
    DeleteAIMoment: (keyword_id: number) => Promise<any>;
}

export const AIRepositoryName = "AIRepositoryName";