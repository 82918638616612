import './SignInStyles.scss';
import { ErrorMessage } from "@hookform/error-message";
import { FC, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { routes } from '../../../routes/RoutesComponent';
import ModalsContext from '../../../../domain/providers/modal/ModalsContext';
import ModalsContextType from '../../../../domain/providers/modal/ModalsContextType';
import LocalizationContext from '../../../../domain/providers/localization/LocalizationContext';
import LocalizationContextType from '../../../../domain/providers/localization/LocalizationContextType';
import KeyWordLocalization from '../../../providers/localization/dictionaries/KeyWordLocalization';
import Validators from '../../../utils/Validators';
import di from '../../../../di/DependencyInjection';
import SignInUseCase, { SignInUseCaseName } from '../../../../domain/use_cases/auth/SignInUseCase';
import { isRight } from 'fp-ts/lib/Either';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
import SocialLoginComponent from '../../../components/socialLogin/SocialLoginComponent';

const SignInPage: FC<{}> = () => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const { i18n } = useContext(LocalizationContext) as LocalizationContextType;
    const { addToast } = useContext(ModalsContext) as ModalsContextType;
    
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState<boolean>(false);


    const onSubmit = async (data: any) => {

        const response = await di.get<SignInUseCase>(SignInUseCaseName).call(data.email, data.password);

        console.log(response);
        

        if (isRight(response)) {
            if (!(response.right?.permissions?.employee != undefined)) {
                navigate(routes.rolesAndPermissions.relativePath)
            } else {
                navigate(routes.home.relativePath);
            }
        } else {
            console.log(routes.reset_passowrd);
            
            if (response.left.code === KeyWordLocalization.AuthRepositoryErrorUserNotConfirmed) navigate(routes.confimr_account.relativePath + '/' + data.email);
            if (response.left.code === KeyWordLocalization.AuthRepositoryErrorUserNeedPasswordChange) navigate(routes.reset_passowrd.relativePath + '/' + data.email, { state: response.left })
            else addToast(i18n(response.left.code ?? KeyWordLocalization.SingInPageWrongCredentials), 'error', null, true);
        }
    };


    return (
        <div className='sign_in_page'>
            <h5 className='text-center'>{i18n(KeyWordLocalization.SignInPageTitle)}</h5>
            <form className="row" onSubmit={handleSubmit(onSubmit)}>
                <div className={`col-12 mb-3 form-group ${errors.email ? 'error' : ''}`}>
                    <label>{i18n(KeyWordLocalization.SignInPageEmail)}</label>
                    <input type="text" className="form-control" placeholder={i18n(KeyWordLocalization.SignInPageEmail)}
                        {...register('email', Validators({ required: true, email: true, }))} />
                    <ErrorMessage as="aside" errors={errors} name="email" />
                </div>
                <div className={`col-12 mb-3 form-group ${errors.password ? 'error' : ''}`}>
                    <label>{i18n(KeyWordLocalization.SignInPagePassword)}</label>
                    <div className="password_input_wrapper">
                        <input type={showPassword ? 'text' : 'password'} className="form-control" placeholder={i18n(KeyWordLocalization.SignInPagePassword)}
                            {...register('password', Validators({ required: true }))} />
                        <div className="visibility_icon" onClick={() => setShowPassword(!showPassword)}>
                            {showPassword ? <MdVisibility /> : <MdVisibilityOff />}
                        </div>
                    </div>
                    <ErrorMessage as="aside" errors={errors} name="password" />
                </div>
                <Link className="forget_password_link mt-0 small" to={routes.send_code_recovery.relativePath}>{i18n(KeyWordLocalization.SignInPageDoYouForgetPassword)}</Link>
                <button type="submit" className="btn btn_primary mt-4">{i18n(KeyWordLocalization.SignInPageSubmit)}</button>
                {
                    /**
                     * 
                     * <Link to={routes.signup.relativePath} className='mt-4 text-center'>{i18n(KeyWordLocalization.SignInPageSignUp)}</Link>
                     */
                }
            </form >
            <SocialLoginComponent />
        </div >
    );
};

export default SignInPage;
