import { injectable } from "inversify";
import ExceptionEntity from "../../entities/ExceptionEntity";
import { Either, isLeft, right } from "fp-ts/lib/Either";
import TrackingTimeRepository from "../../repositories/TrackingTimeRepository";
import TrackingTimeProvider from "../../providers/trackingTime/TrackingTimeProvider";
import GetTodayTrackingCalendarUseCase from "./GetTodayTrackingCalendarUseCase";
import EventScheduleEntity, { EventScheduleEntityStatus } from "../../entities/EventScheduleEntity";

interface props {
    trackingTimeRepository: TrackingTimeRepository;
    trackingTimeProvider: TrackingTimeProvider;
    getTodayTrackingCalendarUseCase: GetTodayTrackingCalendarUseCase;
}

@injectable()
export class StartClockTrackingUseCase {
    _trackingTimeRepository: TrackingTimeRepository;
    _trackingTimeProvider: TrackingTimeProvider;
    _getTodayTrackingCalendarUseCase: GetTodayTrackingCalendarUseCase;
    constructor(_: props) {
        this._trackingTimeRepository = _.trackingTimeRepository;
        this._trackingTimeProvider = _.trackingTimeProvider;
        this._getTodayTrackingCalendarUseCase = _.getTodayTrackingCalendarUseCase;
    }
    public call = async (event: EventScheduleEntity): Promise<Either<ExceptionEntity, void>> => {
        //end the current task
        //TODO filled with fake
        const anyRunning = this._trackingTimeProvider.Actions.todayCalendar?.events?.find((e) => e.status != EventScheduleEntityStatus.pending && e.status != EventScheduleEntityStatus.ended) != null ?? false;
        //console.log('shiftssss', this._trackingTimeProvider.Actions.todayCalendar, anyRunning);
        const startClockResponse = await this._trackingTimeRepository.startClock(event.id, event.type.id, event.shiftId!, anyRunning);
        if (isLeft(startClockResponse)) return startClockResponse;
        event.status = EventScheduleEntityStatus.running;
        this._trackingTimeProvider.Actions.setCurrentEvent(event);
        await this._getTodayTrackingCalendarUseCase.call();
        return right(undefined);
    }
};

export const StartClockTrackingUseCaseName = "StartClockTrackingUseCaseName";