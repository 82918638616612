import { injectable } from "inversify";
import FilterOptionEntity from "../../entities/FilterOptionEntity";
import RolesEntity from "../../entities/RolesEntity";
import RoleRepository from "../../repositories/RoleRepository";
import ModuleEntity from "../../entities/ModuleEntity";
import RoleEntity from "../../entities/RoleEntity";
import ExceptionEntity from "../../entities/ExceptionEntity";
import { Either, isRight, left, right } from "fp-ts/lib/Either";
import RolesProvider from "../../providers/roles/RolesProvider";

interface props { roleRepository: RoleRepository, rolesProvider: any }

@injectable()
export default class GetAllRolesUseCase {
    _roleRepository: RoleRepository;

    _rolesProvider: RolesProvider;

    constructor(_: props) {
        this._roleRepository = _.roleRepository;
        this._rolesProvider = _.rolesProvider;
    }

    public call = async (forceLoad: boolean): Promise<Either<ExceptionEntity, RoleEntity[]>> => {
        if (!forceLoad && this._rolesProvider.Actions.roles != null && this._rolesProvider.Actions.roles.length > 0) return right(this._rolesProvider.Actions.roles);
        try {
            const response = await this._roleRepository.getAllRoles();
            if (isRight(response)) this._rolesProvider.Actions.roles = response.right;
            return response;
        } catch (error) {
            return left({});
        }
    }
}

export const GetAllRolesUseCaseName = "GetAllRolesUseCaseName";