import { FC, useContext, useEffect, useState } from 'react';
import di from '../../../../di/DependencyInjection';

import SearchAllUsersUseCase, { SearchAllUsersUseCaseName } from '../../../../domain/use_cases/allUsers/SearchAllUserUseCase';

import RoleInformationComponent from './components/roleInformation/RoleInformationComponent';

import ModulePermisionsDetailComponent from './components/ModulePermissionsDetail/ModulePermisionsDetailComponent';
import './RolesPageDetailStyles.scss';

import { useNavigate, useParams } from 'react-router-dom';
import UserInListComponent from '../../../components/user/userInList/UserInListComponent';
import UserEntity from '../../../../domain/entities/UserEntity';
import { UserInListAction } from '../../../components/user/userInList/UserInListComponentProps';
import GetAllRolesUseCase, { GetAllRolesUseCaseName } from '../../../../domain/use_cases/roles/GetAllRolesUseCase';
import RoleEntity from '../../../../domain/entities/RoleEntity';
import LoadingComponent from '../../../components/LoadingComponent/LoadingComponent';
import AutoCompleteComponent from '../../../components/forms/autocomplete/AutoCompleteComponent';
import { FieldValues, useForm, UseFormReturn } from 'react-hook-form';
import ModalsContext from '../../../../domain/providers/modal/ModalsContext';
import ModalsContextType from '../../../../domain/providers/modal/ModalsContextType';
import { isRight } from 'fp-ts/lib/Either';
import UpdateUserRoleIdUseCase, { UpdateUserRoleIdUseCaseName } from '../../../../domain/use_cases/user/UpdateUserRoleIdCase';
import { Auth } from 'aws-amplify';
import UserContext from '../../../../domain/providers/user/UserContext';
import UserContextType from '../../../../domain/providers/user/UserContextType';
import { AutoCompleteItem } from '../../../components/forms/autocompleteWithCheck/AutoCompleteWithCheckProps';
import BusinessUnitEntity from '../../../../domain/entities/BusinessUnitEntity';
import { routes } from '../../../routes/RoutesComponent';
import { isEmpty } from 'fp-ts/lib/ReadonlyRecord';
import RolesContext from '../../../../domain/providers/roles/RolesContext';
import RolesContextType from '../../../../domain/providers/roles/RolesContextType';

const RolePageDetail: FC<{ access: boolean }> = () => {


    const [data, setData] = useState<RoleEntity>();
    const [dataAllFilterUsers, setDataAllFilterUsers] = useState<UserEntity[]>([]);
    const [dataAllFilterUsersToAutoComplete, setDataAllFilterUsersToAutoComplete] = useState<AutoCompleteItem<UserEntity>[]>([]);

    const { roles, setRoles, users, setUsers } = useContext(RolesContext) as RolesContextType;

    const [dataAllUsers, setDataAllUsers] = useState<any>([]);

    const [usersToAdd, setUsersToAdd] = useState<UserEntity[]>([]);

    const [itemSelected, setItemSelected] = useState();

    const [isLoading, seIsLoading] = useState(false);

    const [isContentLoading, seIsContentLoading] = useState(true);

    const { addToast } = useContext(ModalsContext) as ModalsContextType;

    const { id } = useParams();

    //const _roleById = async () => await di.get<GetRoleInfoByIdUseCase>(GetRoleInfoByIdUseCaseName).call(id as string);

    const _searchAllUsers = async () => await di.get<SearchAllUsersUseCase>(SearchAllUsersUseCaseName).call();

    const _updateUserRoleById = async () => await di.get<UpdateUserRoleIdUseCase>(UpdateUserRoleIdUseCaseName);

    const _search = async () => await di.get<GetAllRolesUseCase>(GetAllRolesUseCaseName).call(true);

    const { user } = useContext(UserContext) as UserContextType;

    const navigate = useNavigate();


    const _firstSearch = async () => {

        let _allUsers = users;
        let _allRoles = roles;

        if(isEmpty(_allRoles)){
            const allRoles = await _search();
            if(isRight(allRoles)){
                
                setRoles(allRoles.right);
                _allRoles = allRoles.right;
            } else {
                setRoles([]);
                _allRoles = [];
            }
        }
        
        if(isEmpty(_allUsers)){
            
            const allUsersResponse = await _searchAllUsers();
            if(isRight(allUsersResponse)){
                setUsers(allUsersResponse.right);
                _allUsers = allUsersResponse.right;
            } else {
                setUsers([]);
                _allUsers = [];
            }
            
        }
        
            const roleByIdAPI = _allRoles?.filter((role: RoleEntity) => role.id == id);

                setDataAllUsers(_allUsers);
                const filterByRole = _allUsers?.filter((user: UserEntity) => user.role_id === roleByIdAPI[0].id);
                setData(roleByIdAPI[0]);
                setDataAllFilterUsers(filterByRole);

                const emails = filterByRole?.map((user: any) => user.email);
                const newUserList = _allUsers?.filter((user: any) => {
                    if (
                        !emails.includes(user?.email)
                    ) {
                        return user;
                    }
                });
                const castFilterByRole = newUserList?.map((item: UserEntity) => {
                    return {
                        id: (item.id || item.user_id) as string,
                        label: item.name || item.email.split("@")[0].split(".").join(" "),
                        image: item.image || "",
                        aditionalValue: item
                    }
                });
                setDataAllFilterUsersToAutoComplete(castFilterByRole);

                seIsContentLoading(false);

    }

    const handleInputUsers = async (word: string): Promise<AutoCompleteItem<BusinessUnitEntity>[]> => {

                if (word !== "") {
                    const newUserList = dataAllUsers?.filter((user: any) => {
                        if (
                            word !== ""
                        ) {
                            return user;
                        }
                    });


                    const castFilterByRole = newUserList.map((user: UserEntity) => {
                        return {
                            id: (user.id || user.user_id) as string,
                            label: user.firstname + " " + user.lastname,
                            image: user.image || "",
                            aditionalValue: user
                        };
                    });

                    setDataAllFilterUsersToAutoComplete(castFilterByRole);

                    const filterByRole = dataAllUsers.filter((user: UserEntity) => user.role_id === data?.id);
                    
                    const dataWithRole = filterByRole?.filter((user: any) => {
                        if((user.firstname + " " + user.lastname).includes(word)){
                            return user;
                        }
                    });
                    setDataAllFilterUsers(dataWithRole);
                    
                    return castFilterByRole;

                } else {

                    const emails = dataAllFilterUsers?.map((user) => user.email);
                    const newUserList = dataAllUsers?.filter((user: any) => {
                        if (
                            !emails.includes(user?.email)
                        ) {
                            return user;
                        }
                    });
                    const castFilterByRole = dataAllUsers.map((item: UserEntity) => {
                        return {
                            id: (item.id || item.user_id) as string,
                            label: item.firstname + " " + item.lastname,
                            image: "",
                            aditionalValue: item
                        }
                    });
                    
                    const filterByRole = dataAllUsers.filter((user: UserEntity) => user.role_id === data?.id);
                    
                    setDataAllFilterUsers(filterByRole);
                    setDataAllFilterUsersToAutoComplete(castFilterByRole);
                    return castFilterByRole;
                }

                
        };

    const handleSelected = (userSelected: UserEntity) => {
        

        if (usersToAdd.length > 0) {
            const emails = usersToAdd?.map(user => {
                return user.email.toLocaleLowerCase();
            });
            const filterByRole = dataAllUsers.filter((user: UserEntity) => user.role_id === data?.id);
            
                    
                    const dataWithRole = filterByRole?.filter((user: any) => {
                        if((user.firstname.toLocaleLowerCase() + " " + user.lastname.toLocaleLowerCase()).includes(userSelected.firstname.toLocaleLowerCase() + " " + userSelected.lastname.toLocaleLowerCase())){
                            return user;
                        }
                    });
            const usersInRoleList = dataWithRole?.map((user: any) => {
                return user.email.toLocaleLowerCase();
            });
            
            if (!emails?.includes(userSelected.email.toLocaleLowerCase()) && !usersInRoleList?.includes(userSelected.email.toLocaleLowerCase())) {
                let newUserToAddList = [...usersToAdd, userSelected];
                setUsersToAdd(newUserToAddList);
            }

            if (!isEmpty(dataWithRole)) {
                setDataAllFilterUsers(dataWithRole);
                
            } else {
                setDataAllFilterUsers(filterByRole);
            }

                    
        } else {
            const filterByRole = dataAllUsers.filter((user: UserEntity) => user.role_id === data?.id);
                    
                    const dataWithRole = filterByRole?.filter((user: any) => {
                        if((user.firstname.toLocaleLowerCase() + " " + user.lastname.toLocaleLowerCase()).includes(userSelected.firstname.toLocaleLowerCase() + " " + userSelected.lastname.toLocaleLowerCase())){
                            return user;
                        }
                    });

            const usersInRoleList = dataWithRole?.map((user: any) => {
                return user.email.toLocaleLowerCase();
            });
            
            if (!usersInRoleList?.includes(userSelected.email.toLocaleLowerCase())) {
                
                const filterByRole = dataAllUsers.filter((user: UserEntity) => user.role_id === data?.id);
                    
                setDataAllFilterUsers(filterByRole);

                setUsersToAdd([userSelected]);
                
            } else {
                    setDataAllFilterUsers(dataWithRole);

            }

            
        }
    }

    const handleAddUser = async (userToAdd: UserEntity) => {

        seIsLoading(true);
        handleInputUsers("")

        const response = await (await _updateUserRoleById()).call((userToAdd?.user_id as number), (id as unknown as number));

        if (isRight(response)) {

            const allUsersResponse = await _searchAllUsers();
            if(isRight(allUsersResponse)){
                setUsers(allUsersResponse.right);
                setDataAllUsers(allUsersResponse.right);
            } else {
                setUsers([]);
            }

            setDataAllFilterUsers([...dataAllFilterUsers, userToAdd]);

            const actualUser = userToAdd.user_id as unknown as string;

            const newUserList = dataAllFilterUsersToAutoComplete?.filter(user => user.id !== actualUser);
            setDataAllFilterUsersToAutoComplete(newUserList);

            const userEmail = userToAdd.email;
            const filteringList = usersToAdd.filter(userInList => userEmail !== userInList.email);
            setUsersToAdd(filteringList);

            seIsLoading(false);

            if (user?.email.toLocaleLowerCase() === userToAdd.email.toLowerCase()) {
                try {
                    const cognitoUser = await Auth.currentAuthenticatedUser();
                    const currentSession = cognitoUser.signInUserSession;
                    cognitoUser.refreshSession(currentSession.refreshToken, (err: any, session: any) => {
                        navigate(routes.rolesAndPermissions.relativePath + "", { replace: true });
                    });
                } catch (e) {
                // whatever log for this error eror error
                }
            }

        } else {
            seIsLoading(false);
            addToast(response.left.message ? response.left.message : ' ', 'error', null, response.left.message?.toLocaleLowerCase().replaceAll(' ', '') === 'failedtofetch' ? false : true);
        }

        

    }

    const handleRemovingUser = (user: UserEntity) => {
        const userEmail = user.email;
        const filteringList = dataAllFilterUsers.filter(userInList => userEmail !== userInList.email);
        setDataAllFilterUsers(filteringList);
    }


    useEffect(() => {
        _firstSearch();
    }, [])

    useEffect(() => {
        if (itemSelected) {
            const userSelected = dataAllUsers?.filter((userInList: UserEntity) => userInList.user_id === itemSelected["id"]);
            handleSelected(userSelected[0]);
        }

    }, [itemSelected])



    const formInfo: UseFormReturn<FieldValues, any> = useForm({
        // Your useForm configuration options...
    });


    return <div className="roles-edition">
        {
            data ?
                <>
                    <section className='right_role_info_container' style={{ width: "80%" }}>
                        <RoleInformationComponent role={data} hasEdition={user?.permissions?.role} />
                        <div style={{ height: "20px" }}></div>
                        <ModulePermisionsDetailComponent role={data} hasEdition={user?.permissions?.role} />
                    </section>

                    <section className='users-by-roles-container'>
                        {user?.permissions?.user.assign &&
                            <div className="flex-grow-1 search_container" style={{ marginBottom: "20px" }}>

                            <AutoCompleteComponent
                                formInfo={formInfo}
                                onSearch={handleInputUsers}
                                keyName='Search users'
                                onSelect={handleSelected}
                            /> 

                            </div>
                        }

                        <p style={{ fontWeight: "600" }}>Users with role</p>
                        <div className='users-by-role'>
                            {dataAllFilterUsers.length > 0 ?
                                dataAllFilterUsers?.map((user: UserEntity, idx: number) => {

                                    return <UserInListComponent user={user} action={UserInListAction.NONE} onClick={() => handleRemovingUser(user)} key={idx} />
                                })
                                :

                                <div style={{ fontWeight: "lighter" }}>Users not found</div>
                            }

                        </div>

                        {
                            user?.permissions?.user.assign && usersToAdd?.length > 0 &&

                            <>
                                <p>Add user to role</p>
                                {!isLoading ?
                                    <div className='users-add-by-role'>
                                        {
                                            usersToAdd.length > 0 && usersToAdd?.map((user: UserEntity, idx: number) => {
                                                return <UserInListComponent user={user} action={UserInListAction.ADD} onClick={() => handleAddUser(user)} key={idx} />
                                            })
                                        }
                                    </div>
                                    :
                                    <div style={{ height: "50px" }}>
                                        <LoadingComponent />
                                    </div>

                                }
                            </>
                        }


                    </section>
                </>
                :
                isContentLoading ?
                    <LoadingComponent />
                    :
                    <div className='no_data_retrieved'>
                        Role not found
                    </div>
        }


    </div >
}



export default RolePageDetail;