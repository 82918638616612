import { FC, useContext, useEffect, useState } from "react";
import CreateEventFormComponentProps from "./CreateEventFormComponentProps";
import EventScheduleEntity from "../../../../domain/entities/EventScheduleEntity";
import di from "../../../../di/DependencyInjection";
import CreateEventInCalendarUseCase, { CreateEventInCalendarUseCaseName } from "../../../../domain/use_cases/calendar/CreateEventInCalendarUseCase";
import ModalsContext from "../../../../domain/providers/modal/ModalsContext";
import ModalsContextType from "../../../../domain/providers/modal/ModalsContextType";
import { Either, isRight, right } from "fp-ts/lib/Either";
import { FieldValues, UseFormReturn, useForm } from "react-hook-form";
import KeyWordLocalization from "../../../providers/localization/dictionaries/KeyWordLocalization";
import MastersContext from "../../../../domain/providers/master/MastersContext";
import MastersContextType from "../../../../domain/providers/master/MastersContextType";
import ExceptionEntity from "../../../../domain/entities/ExceptionEntity";
import LocalizationContext from "../../../../domain/providers/localization/LocalizationContext";
import LocalizationContextType from "../../../../domain/providers/localization/LocalizationContextType";
import DateFormComponent from "../../forms/date/DateFormComponent";
import SwitchFormComponent from "../../forms/switch/SwitchFormComponent";
import SelectFormComponent from "../../forms/select/SelectFormComponent";
import TypeEventScheduleEntity from "../../../../domain/entities/TypeEventScheduleEntity";
import { ErrorMessage } from "@hookform/error-message";
import Validators from "../../../utils/Validators";
import DateParse, { getDayOfCalendar } from "../../../utils/DateParse";
import AutoCompleteComponent from "../../forms/autocomplete/AutoCompleteComponent";
import { DateOperations } from "../../../utils/DateOperations";
import { AutoCompleteItem } from "../../forms/autocompleteWithCheck/AutoCompleteWithCheckProps";

const CreateEventFormComponent: FC<CreateEventFormComponentProps> = ({
    handleAddEvent,
    handleEditEvent,
    editEvent,
    type,
    date,
    dates,
}) => {
    const formFunctions = useForm();
    const { closeModalCustom } = useContext(ModalsContext) as ModalsContextType;
    const { register, handleSubmit, getValues, setValue, watch, formState: { errors } } = formFunctions;
    const { i18n } = useContext(LocalizationContext) as LocalizationContextType;
    const { typeSchedules } = useContext(MastersContext) as MastersContextType;
    const { addToast } = useContext(ModalsContext) as ModalsContextType;
    const typeValue = watch(KeyWordLocalization.EventScheduleEntityType);

    const [daySelected, setDaySelected] = useState<Date>(date ? new Date(date) : new Date());

    const canEdit = (dateToEdit: Date | undefined) => {
        if (dateToEdit == undefined) return true;
        return dateToEdit.getTime() >= (new Date()).getTime();
    }

    const minEditabledDate = () => {
        const now = new Date();
        const currentTime = `${now.getHours()}:${now.getMinutes()}`;
        const isToday = (d: Date) => DateOperations.isSameDate(d, new Date());

        if (daySelected && isToday(daySelected)) return currentTime;
        if (date && isToday(date)) return currentTime;
        if (editEvent && isToday(editEvent.dateStart)) return currentTime;
        return undefined;
    };


    const _handleCreateEvent = async (data: any) => {
        if (handleAddEvent == undefined) return;
        const startDate = new Date(daySelected || new Date());
        const year = startDate.getFullYear();
        const month = String(startDate.getMonth() + 1).padStart(2, '0');
        const day = String(startDate.getDate()).padStart(2, '0');

        const formattedDate = `${year}-${month}-${day}`;

        const event: EventScheduleEntity = {
            id: "",
            name: data[KeyWordLocalization.EventScheduleEntityName],
            dateStart: new Date(formattedDate + 'T' + data[KeyWordLocalization.EventScheduleEntityDateStartHour]),
            dateEnd: new Date(formattedDate + 'T' + data[KeyWordLocalization.EventScheduleEntityDateEnd]),
            type: typeSchedules.find((typeSchedule) => typeSchedule.id == data[KeyWordLocalization.EventScheduleEntityType])!,
        }

        const response = await handleAddEvent(event);
        if (isRight(response)) {
            // addToast(i18n(KeyWordLocalization.CreateEventFormComponentEventCreated), "success", undefined);
            closeModalCustom();
        } else {
            addToast(i18n(response.left.code ?? KeyWordLocalization.UnknownError), "error", undefined);
        }

    }

    const _handleCancel = () => closeModalCustom();

    const _handleEditEvent = async (data: any) => {
        if (handleEditEvent == undefined || editEvent == undefined) return;
        const startDate = new Date(daySelected || new Date());
        const year = startDate.getFullYear();
        const month = String(startDate.getMonth() + 1).padStart(2, '0');
        const day = String(startDate.getDate()).padStart(2, '0');

        const formattedDate = `${year}-${month}-${day}`;

        const copyEvent = editEvent;
        copyEvent.name = data[KeyWordLocalization.EventScheduleEntityName];
        copyEvent.dateStart = new Date(formattedDate + 'T' + data[KeyWordLocalization.EventScheduleEntityDateStartHour]);
        copyEvent.dateEnd = new Date(formattedDate + 'T' + data[KeyWordLocalization.EventScheduleEntityDateEnd]);
        copyEvent.type = typeSchedules.find((typeSchedule) => typeSchedule.id == data[KeyWordLocalization.EventScheduleEntityType])!;
        const response = await handleEditEvent(copyEvent);
        if (isRight(response)) {
            addToast(i18n(KeyWordLocalization.CreateEventFormComponentEventUpdated), "success", undefined);
            closeModalCustom();
        } else {
            addToast(i18n(response.left.code ?? KeyWordLocalization.UnknownError), "error", undefined);
        }
    }

    const _loadDefaultData = () => {
        if (date != null) {
            setValue(KeyWordLocalization.EventScheduleEntityDateStart, DateParse.getDateForInput(date));
            setValue(KeyWordLocalization.EventScheduleEntityDateStartHour, DateParse.getTimeForInput(date));
            const datePlusOneHour = new Date(date.getTime());
            datePlusOneHour.setHours(datePlusOneHour.getHours() + 1);
            setValue(KeyWordLocalization.EventScheduleEntityDateEnd, DateParse.getTimeForInput(datePlusOneHour));
        }
        if (type != undefined) {
            setValue(KeyWordLocalization.TypeEventScheduleEntityBillable, type.billable);
            setValue(KeyWordLocalization.TypeEventScheduleEntityPayable, type.payable);
            setValue(KeyWordLocalization.EventScheduleEntityType, type.id);
        }

        if (editEvent != undefined) {
            setDaySelected(editEvent.dateStart);
            setValue(KeyWordLocalization.EventScheduleEntityDateStart, DateParse.getDateForInput(editEvent.dateStart));
            setValue(KeyWordLocalization.EventScheduleEntityDateStartHour, DateParse.getTimeForInput(editEvent.dateStart));
            setValue(KeyWordLocalization.EventScheduleEntityDateEnd, DateParse.getTimeForInput(editEvent.dateEnd));
            setValue(KeyWordLocalization.EventScheduleEntityType, editEvent.type.id);
        }
    }

    const _handleChangeType = (typeSchedule: TypeEventScheduleEntity) => {
        const schedule = typeSchedules.find((typeSchedule) => typeSchedule.id == typeValue);
        setValue(KeyWordLocalization.EventScheduleEntityName, schedule?.name);
        setValue(KeyWordLocalization.TypeEventScheduleEntityBillable, schedule?.billable);
        setValue(KeyWordLocalization.TypeEventScheduleEntityPayable, schedule?.payable);
    }

    useEffect(() => {
        _handleChangeType(typeValue);
    }, [typeValue])

    useEffect(() => {
        _loadDefaultData();
    }, [date, editEvent])

    // useEffect(() => {
    //     if (dates) {
    //         setDaySelected(date as Date);
    //     }

    // }, [])

    const _getDays = async (): Promise<any> => {
        const daysCasted = dates?.map(item => {
            return (
                {
                    label: item.label,
                    id: item.label,
                    value: item.date
                }
            )
        });
        return daysCasted;
    };


    const handleClickListed = (e: any) => {
        setDaySelected(e[0].value);
    }

    const formInfo: UseFormReturn<FieldValues, any> = useForm({
        // Your useForm configuration options...
    });

    return <div className="create_event_component">
        <form onSubmit={editEvent != undefined ? handleSubmit(_handleEditEvent) : handleSubmit(_handleCreateEvent)}>
            <div className="row">
                <div className="col-md-6 my-2">
                    <div className="form-group">
                        {
                            /*
                                <DateFormComponent errors={errors} label={i18n(KeyWordLocalization.EventScheduleEntityDateStart)} keyName={KeyWordLocalization.EventScheduleEntityDateStart} {...formFunctions} onChange={setValue} required />
                            */
                        }
                        <p style={{ marginBottom: "0px", marginTop: "-3px" }}>Date</p>
                        <AutoCompleteComponent
                            formInfo={formInfo}
                            disabled={editEvent != undefined}
                            onSearch={_getDays}
                            keyName={KeyWordLocalization.EventScheduleEntityDate}
                            label={`${getDayOfCalendar(daySelected)} ${daySelected.getDate() ?? ''}`}
                            onSelect={handleClickListed}
                        />
                    </div>
                </div>
                <div className="col-6 my-2 col-md-3">
                    <div className="form-group">
                        <label>Start</label>
                        <input type="time" className="form-control"
                            disabled={!canEdit(editEvent?.dateStart)}
                            {...register(KeyWordLocalization.EventScheduleEntityDateStartHour, Validators({
                                required: true, lessThan: watch(KeyWordLocalization.EventScheduleEntityDateEnd),
                                minTime: canEdit(editEvent?.dateStart) ? minEditabledDate() : undefined,

                            }))} />
                        <ErrorMessage as="aside" errors={errors} name={KeyWordLocalization.EventScheduleEntityDateStartHour} />
                    </div>
                </div>
                <div className="col-6 my-2 col-md-3">
                    <div className="form-group">
                        <label>End</label>
                        <input type="time" className="form-control"
                            disabled={!canEdit(editEvent?.dateEnd)}
                            {...register(KeyWordLocalization.EventScheduleEntityDateEnd, Validators({
                                required: true, greaterThan: watch(KeyWordLocalization.EventScheduleEntityDateStartHour),
                                minTime: canEdit(editEvent?.dateEnd) ? minEditabledDate() : undefined,
                            }))} />
                        <ErrorMessage as="aside" errors={errors} name={KeyWordLocalization.EventScheduleEntityDateEnd} />
                    </div>
                </div>
                {/* <div className="col-6 my-2">
                    <SwitchFormComponent label={i18n(KeyWordLocalization.TypeEventScheduleEntityPayable)} errors={errors} keyName={KeyWordLocalization.TypeEventScheduleEntityPayable} {...formFunctions} onChange={setValue} disabled />
                </div>
                <div className="col-6 my-2">
                    <SwitchFormComponent label={i18n(KeyWordLocalization.TypeEventScheduleEntityBillable)} errors={errors} keyName={KeyWordLocalization.TypeEventScheduleEntityBillable} {...formFunctions} onChange={setValue} disabled />
                </div> */}
                <div className={`col-12 ${typeValue != null && typeValue != "" ? '' : ''}`}>
                    <SelectFormComponent label={i18n(KeyWordLocalization.EventScheduleEntityType)} errors={errors} keyName={KeyWordLocalization.EventScheduleEntityType} {...formFunctions} onChange={setValue} options={typeSchedules.map((typeSchedule) => {
                        return {
                            id: typeSchedule.id,
                            label: typeSchedule.name,
                        }
                    })} />
                </div>

                <div className="col-6 my-2">
                    <div onClick={_handleCancel} className="w-100 btn btn_light">{i18n(KeyWordLocalization.Cancel)}</div>
                </div>
                <div className="col-6 my-2">
                    <button type="submit" className="w-100 btn btn_primary">{i18n(KeyWordLocalization.Create)}</button>
                </div>
            </div>
        </form>
    </div>
}

export default CreateEventFormComponent;