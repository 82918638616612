/********************************************************************************
 * File Header - AWS Sign In API Implementation
 * Created By: Marlon Alejandro Mendez Castañeda
 * Contact: +57 3234686680
 * Date: August 9, 2023
 *
 * Description:
 * This file contains the implementation of the AWS Sign In API.
 ********************************************************************************/

import { Either, left } from "fp-ts/lib/Either";
import UserEntity from "../../../../../domain/entities/UserEntity";
import { Auth } from "aws-amplify";
import ExceptionEntity from "../../../../../domain/entities/ExceptionEntity";
import KeyWordLocalization from "../../../../../ui/providers/localization/dictionaries/KeyWordLocalization";
import GetCurrentUserApiImpl from "./GetCurrentUserApiImpl";
import HostApi, { HostApiName } from "../../../../settings/HostApi";
import di from "../../../../../di/DependencyInjection";

const SignInApiImpl = async (email: string, password: string): Promise<Either<any, UserEntity>> => {
  try {
    const _connect = await Auth.signIn(email, password);
    
    if (_connect['challengeName'] === "NEW_PASSWORD_REQUIRED") {
      return left({
        code: KeyWordLocalization.AuthRepositoryErrorUserNeedPasswordChange,
        data: _connect
      });
    }
    

    const response = await GetCurrentUserApiImpl();
    Auth.currentSession().then(res=>{
      let accessToken = res.getIdToken()
      let jwt = accessToken.getJwtToken()
      di.get<HostApi>(HostApiName).setToken(jwt)
      localStorage.setItem('jwt', jwt)

    }).catch((errors) => {
      console.log(errors);
      
    });

    return response;
  }
  catch (error: any) {
    console.log(error, 'here error');
    
    if (error?.code == 'UserNotConfirmedException') {
      return left({
        code: KeyWordLocalization.AuthRepositoryErrorUserNotConfirmed,
      });
    }
    return left(error);
  }
}

export default SignInApiImpl;
