import { Either, isLeft, right } from "fp-ts/lib/Either";
import ShiftEntity from "../../../../../domain/entities/ShiftEntity";
import ExceptionEntity from "../../../../../domain/entities/ExceptionEntity";
import GraphApi from "../../../../settings/GraphApi";
import ShiftHostDto from "../../../../dto/impl/ShiftHostDto";

const GetShiftByIdApiImpl = async (shiftId: string): Promise<Either<ExceptionEntity, ShiftEntity>> => {
    const shiftAsInt = parseInt(shiftId);
    const response = await GraphApi.query(`getShift(shift_id:${shiftAsInt})`, {}, ['employee_id', 'end_time', 'start_time', 'shift_id']);
    if (isLeft(response)) return response;
    const shift = ShiftHostDto.fromJson(response.right.data.getShift);
    return right(shift);


}

export default GetShiftByIdApiImpl;