import React, { useContext, useState } from 'react';
import { injectable } from 'inversify';
import ProviderProps from '../../../domain/providers/ProviderProps';
import MastersContextType from '../../../domain/providers/master/MastersContextType';
import MastersProvider from '../../../domain/providers/master/MastersProvider';
import MastersContext from '../../../domain/providers/master/MastersContext';
import TypeEventScheduleEntity from '../../../domain/entities/TypeEventScheduleEntity';

const _Actions: MastersContextType = {
  typeSchedules: [],
  workTime: undefined,
  setWorkTime: (workTime: TypeEventScheduleEntity | undefined) => { },
  setTypeSchedules: (types: TypeEventScheduleEntity[]) => { },
}

const _Provider = ({ children }: ProviderProps) => {
  const [typeSchedules, setTypeSchedules] = useState<TypeEventScheduleEntity[]>([]);
  const [workTime, setWorkTime] = useState<TypeEventScheduleEntity | undefined>(undefined);
  _Actions.typeSchedules = typeSchedules;
  _Actions.setTypeSchedules = setTypeSchedules;
  _Actions.workTime = workTime;
  _Actions.setWorkTime = setWorkTime;
  return (
    <MastersContext.Provider value={{ typeSchedules, setTypeSchedules, workTime, setWorkTime }}>
      {children}
    </MastersContext.Provider>
  );
};

@injectable()
class MastersProviderImpl implements MastersProvider {
  public context = MastersContext;

  public Provider = _Provider;

  Actions = _Actions
}

export default new MastersProviderImpl();

