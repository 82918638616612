import './CardEmployeeComponentStyles.scss';
import { FC } from "react";
import CardEmployeeComponentProps from "./CardEmployeeComponentProps";
import Icons from '../../../../../assets/Icons';
import UserNameWithPhotoComponent from '../userNameWithPhoto/UserNameWithPhotoComponent';
import UserContactInformationComponent from '../userContactInformation/UserContactInformationComponent';

function capitalizeFirstLetter(str: string) {
    return str.replace(/\b\w/g, function(char) {
        return char.toUpperCase();
    });
}


const CardEmployeeComponent: FC<CardEmployeeComponentProps> = ({ employee, onClick }) => {
    return <div className="card-employee-component" onClick={() => onClick(employee)}>
        <div className="card-body">
            <UserNameWithPhotoComponent
                image={employee.image as string}
                title={capitalizeFirstLetter(employee.name)}
                subtitle={employee.position}
                description={(employee.area ? employee.area + " - " : " ") + employee?.role?.name}
            />
            <UserContactInformationComponent information01={employee.email} information02={employee.phone} />
        </div>
    </div>
}

export default CardEmployeeComponent;