import { injectable } from "inversify";
import ShiftRepository from "../../repositories/ShiftRepository";
import ShiftEntity from "../../entities/ShiftEntity";
import ExceptionEntity from "../../entities/ExceptionEntity";
import { Either } from "fp-ts/lib/Either";

interface props {
    shiftRepository: ShiftRepository;
}
@injectable()
export default class GetShiftByIdUseCase {
  private shiftRepository: ShiftRepository;

  public constructor(_:props) {
    this.shiftRepository = _.shiftRepository;
  }

  public async call(shiftId: string): Promise<Either<ExceptionEntity, ShiftEntity>> {
    return this.shiftRepository.getShiftById(shiftId);
  }
}

export const GetShiftByIdUseCaseName = "GetShiftByIdUseCase";