import di from "../../../../../di/DependencyInjection";
import UserHostApi, { UserHostApiName } from "../../../../settings/UserHostApi";

const DeleteGroupByIdApiImpl = async (groupId: string): Promise<boolean> => {
    try {
        const relativeUrl = "/groups";
        const params = `/${groupId}`
        const groupsList = await di.get<UserHostApi>(UserHostApiName).remove(relativeUrl + params);
        if (groupsList) {
            return true;
        } else {
            return false;
        }
    }
    catch (error: any) {
        return false;
    }
}

export default DeleteGroupByIdApiImpl;