import React, { useContext } from "react";
import ModalsContext from "../../../../../domain/providers/modal/ModalsContext";
import ModalsContextType from "../../../../../domain/providers/modal/ModalsContextType";
import ModalCustomComponentProps from "./ModalCustomComponentProps";
import ModalComponent from "../modalItem/ModalComponent";

const ModalCustomComponent: React.FC<ModalCustomComponentProps> = () => {
    const { isOpenModalCustom, closeModalCustom, customModal, } = useContext(ModalsContext) as ModalsContextType;

    if (customModal === undefined) return <></>

    return <div className="modal_rerffers_component">
        <ModalComponent title={customModal.title} hideOnClick={customModal.hideOnTap ?? true} centered size={customModal.size ?? 'lg'} isOpen={isOpenModalCustom} toggle={() => closeModalCustom()} >
            <div>
                {customModal.content}
            </div>
        </ModalComponent>
    </div>
}

export default ModalCustomComponent;