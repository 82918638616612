import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import UserEntity from "../../../../../domain/entities/UserEntity";
import LoadingComponent from "../../../../components/LoadingComponent/LoadingComponent";
import NotFoundComponent from "../../../../components/notFound/NotFoundComponent";
import di from "../../../../../di/DependencyInjection";
import { GetUserInfoByIdUseCase, GetUserInfoByIdUseCaseName } from "../../../../../domain/use_cases/user/GetUserInfoByIdUseCase";
import GetUserTodayCalendarUseCase, { GetUserTodayCalendarUseCaseName } from "../../../../../domain/use_cases/calendar/GetUserTodayCalendarUseCase";
import { isRight } from "fp-ts/lib/Either";
import EventScheduleEntity from "../../../../../domain/entities/EventScheduleEntity";

const PersonDetailedPage: FC<{}> = () => {
    const { userId } = useParams<{ userId: string }>();
    const [user, setUser] = useState<UserEntity | undefined | null>(undefined); //undefined: loading, null: not found
    const [calendar, setCalendar] = useState<EventScheduleEntity[] | undefined>(undefined); //undefined: loading, null: not found

    const _getUser = async () => {
        if (userId === undefined || userId === "") return setUser(null);
        const response = await di.get<GetUserInfoByIdUseCase>(GetUserInfoByIdUseCaseName).call(userId!);
        if (response === undefined) return setUser(null);
        setUser(response);
    }

    const _getTodayCalendar = async () => {
        if (userId === undefined || userId === "") return setCalendar([]);
        const response = await di.get<GetUserTodayCalendarUseCase>(GetUserTodayCalendarUseCaseName).call(userId!);
        if (isRight(response)) return setCalendar(response.right.events);
        else return setCalendar([]);
    }

    const _addEventToCalendar = async () => {
        //you can use CreateEventInCalendarUseCase
    }

    useEffect(() => {
        _getUser();
        _getTodayCalendar();
    }, [userId]);

    if (user === undefined) return <LoadingComponent />
    if (user === null) return <NotFoundComponent />
    return <div className="person_detailed_page">
        <h1>PersonDetailedPage</h1>
    </div>
}

export default PersonDetailedPage;