import { FC, useContext, useEffect, useState } from "react";
import di from "../../../../../di/DependencyInjection";
import LoadingComponent from "../../../../components/LoadingComponent/LoadingComponent";

import './PermissionsInfoPageStyles.scss';
import Icons from "../../../../assets/Icons";
import GetAllRolesUseCase, { GetAllRolesUseCaseName } from "../../../../../domain/use_cases/roles/GetAllRolesUseCase";
import ModuleEntity from "../../../../../domain/entities/ModuleEntity";
import RolesEntity from "../../../../../domain/entities/RolesEntity";
import ModulePermision from "../../../../../domain/entities/ModulePermision";
import { isRight } from "fp-ts/lib/Either";
import ModalsContext from "../../../../../domain/providers/modal/ModalsContext";
import ModalsContextType from "../../../../../domain/providers/modal/ModalsContextType";
import Tooltip from "react-bootstrap/esm/Tooltip";
import OverlayTrigger from "react-bootstrap/esm/OverlayTrigger";

interface Resources {
    roles: {
        policies: ModulePermision,
        role: string
    }[]
    resourceName: String,
}

const PermissionsPage: FC<{}> = () => {

    const renderTooltip = (value: string) => (
        <Tooltip id="button-tooltip">
            {value}
        </Tooltip>
    );

    const [ modulesInfo, setModulesInfo ] = useState<Resources[]>();
    const _search = async () => await di.get<GetAllRolesUseCase>(GetAllRolesUseCaseName).call(true);
    const { addToast } = useContext(ModalsContext) as ModalsContextType;

    const [ isLoading, setIsLoading ] = useState(true);

    const _firstSearch = async () => {
        let allRoles = await _search();

        if(isRight(allRoles)){
            const allRolesToSet = allRoles.right;

            let resources = allRolesToSet.map((role: RolesEntity) => {
                return role.modules.map((policy: ModuleEntity) => {
                    return policy.permissions.map(policy => {
                        return policy.name;
                    }).flat();
                }).flat();
            }).flat();

            const uniqueResources = new Set(resources);
            resources = Array.from(uniqueResources);

            let rolesWithResource = resources.map(resource => {
                return allRolesToSet.map((role: RolesEntity) => {
                    return role.modules.map((policy: ModuleEntity) => {
                        return policy.permissions.map(policy => {
                            if(policy.name === resource){
                                return {
                                    roleName: role.name,
                                    permissions: policy,
                                    resource: policy.name
                                }
                            } else {
                                return undefined;
                            };
                        }).filter(item => item !== undefined).flat();
                    }).flat();
                }).flat();
            });

            let resourceByRole = resources.map(resource => {
                return (
                    {
                        resourceName: resource,
                        roles: rolesWithResource.map(groupOfPolicies => {
                            return groupOfPolicies.map(policies => {
                                if (policies?.resource === resource) {
                                    return {
                                        role: policies.roleName,
                                        policies: policies?.permissions
                                    };
                                } else {
                                    return undefined;
                                }
                            });
                        }).flat().filter(item => item !== undefined)
                    }
                );
            }) as unknown as Resources[];

            setIsLoading(false);

            setModulesInfo(resourceByRole);
            
        } else {
            setIsLoading(false);
            addToast(allRoles.left.message ? allRoles.left.message : ' ', 'error', null, allRoles.left.message?.toLocaleLowerCase().replaceAll(' ', '') === 'failedtofetch' ? false : true);
        }
    }
        

    useEffect(() => {
        _firstSearch();
    }, [])


    return (
    
        modulesInfo ? 
            <div className="container">
                <div className="permissions-info">
                    <p className="permissions-info-title">Policies</p>
                        <section id="permissions-info-container">
                        {
                            
                            modulesInfo?.map((moduleItem, index) => {
                                
                                return <div className="module-card-info" key={index}>
                                    <div className="module-info-item" style={{width: "100%", textAlign: "center", marginBottom: "20px"}}>
                                        {moduleItem?.resourceName?.split("_").join(" ")}
                                    </div>
                                    {
                                        moduleItem.roles?.map((resource, idx) => {
                                            return <div className="module-info-item" key={idx}>
                                                <div className='d-flex'>
                                                    <div className="flex-grow-1 text_ellipsis text-left modules-title">
                                                        {resource?.role}
                                                    </div>
                                                    {
                                                        resource.policies.canView &&
                                                        
                                                        <OverlayTrigger
                                                            placement="top"
                                                            delay={{ show: 5, hide: 5 }}
                                                            overlay={renderTooltip('View')}
                                                        >
                                                            <div style={{ width: 25 }}>
                                                                <Icons.ViewEye className='view-eye-icon' />
                                                            </div>
                                                        </OverlayTrigger>
                                                    }
                                                    {
                                                        resource.policies.canEdit &&
                                                        
                                                        <OverlayTrigger
                                                            placement="top"
                                                            delay={{ show: 5, hide: 5 }}
                                                            overlay={renderTooltip('Edit')}
                                                        >
                                                            <div style={{ width: 25 }}>
                                                                <Icons.EditPencil className='edit-pencil-icon' />
                                                            </div>
                                                        </OverlayTrigger>
                                                    }
                                                    {
                                                        resource.policies.canDelete &&
                                                        
                                                        <OverlayTrigger
                                                            placement="top"
                                                            delay={{ show: 5, hide: 5 }}
                                                            overlay={renderTooltip('Delete')}
                                                        >
                                                            <div style={{ width: 25 }}>
                                                                <Icons.Trash className='trash-icon' />
                                                            </div>
                                                        </OverlayTrigger>
                                                    }
                                                    {
                                                        resource.policies.canCreate &&
                                                        
                                                        <OverlayTrigger
                                                            placement="top"
                                                            delay={{ show: 5, hide: 5 }}
                                                            overlay={renderTooltip('Create')}
                                                        >
                                                            <div style={{ width: 25 }}>
                                                                <Icons.Plus className='add-icon' />
                                                            </div>
                                                        </OverlayTrigger>
                                                    }
                                                    {
                                                        resource.policies.canAssign &&
                                                        
                                                        <OverlayTrigger
                                                            placement="top"
                                                            delay={{ show: 5, hide: 5 }}
                                                            overlay={renderTooltip('Assign')}
                                                        >
                                                            <div style={{ width: 25 }}>
                                                                <Icons.AssignIcon className='assign-icon' />
                                                            </div>
                                                        </OverlayTrigger>
                                                    }
                                                    
                                                </div>
                                            
                                            </div>
                                        })
                                    }
                                </div>
                            })
                        }

                        </section>
                        
                        
                    </div>
            </div>
        
    : 
    (isLoading ?
        <LoadingComponent />
        :
        <div className='no_data_retrieved'>
            Resource not found
        </div>
    )
    )
}

export default PermissionsPage;