import './ButtonComponentStyles.scss';
import { FC } from "react";
import ButtonComponentProps, { ButtonType } from "./ButtonComponentProps";
import Spinner from 'react-bootstrap/Spinner';

const ButtonComponent: FC<ButtonComponentProps> = ({ onClick, text, disabled, icon, onClickIcon, colorLine, type, height, width, isLoading, typeAction = "button" }) => {
    return <button type={typeAction} className={`button_component hover ${disabled && 'disabled'} ${type}`} onClick={() => { if (onClickIcon == undefined) onClick?.() }}>
        <div style={{ display: "flex" }}>
            <div className="icon_container" onClick={onClickIcon}>
                {isLoading ? <Spinner style={{ height: "20px", width: "20px" }} /> : icon}
            </div>
            <div className='text' onClick={() => { if (onClickIcon != undefined) onClick?.() }}>
                <div className='container_text'>
                    {text}
                </div>
                <div className="color_line" style={{ background: colorLine }}></div>
            </div>
        </div>
    </button>
}

ButtonComponent.defaultProps = {
    disabled: false,
    icon: undefined,
    onClickIcon: undefined,
    colorLine: undefined,
    onClick: undefined,
    height: 'auto',
    width: 'auto',
    type: ButtonType.MAIN
}

export default ButtonComponent;