import './DropDownComponentStyles.scss';
import { FC, useEffect, useRef, useState } from "react";
import DropDownComponentProps from "./DropDownComponentProps";
import AutoCompleteComponent from '../forms/autocomplete/AutoCompleteComponent';
import { useForm } from 'react-hook-form';
import { AutoCompleteItem } from '../forms/autocompleteWithCheck/AutoCompleteWithCheckProps';
import GroupEntity from '../../../domain/entities/GroupEntity';

const DropDownComponent: FC<DropDownComponentProps<any>> = ({ text, disabled, icon, onClickIcon, onSelected, colorLine, type, height, width, onSearch }) => {
    const formFunctions = useForm();
    const DROP_DOWN_KEY = 'drop_down_key';
    const { register, handleSubmit, watch, getValues, formState: { errors }, setValue } = formFunctions;
    const refComponent = useRef<HTMLDivElement>(null);

    const [isOpen, setIsOpen] = useState<boolean>(false);

    const _handleClick = () => {
        setIsOpen(!isOpen);
    }

    const _submited = (data: any) => { }

    const _handleOnChange = (value: GroupEntity) => {
        onSelected?.(value);
        setIsOpen(false);
    }

    const onClickScreen = (event: any) => {
        if (refComponent.current?.contains(event.target)) return;
        setIsOpen(false);
    }

    const _addOnClickOut = () => {
        //detects if click is outside refComponent
        document.addEventListener('click', onClickScreen);
    }

    useEffect(() => {
        _addOnClickOut();
    }, []);

    useEffect(() => {
        if (isOpen) {
            document.removeEventListener('click', onClickScreen);
        }
    }, [isOpen])

    return <div className='drop_down_component' ref={refComponent}>
        <div className={`btn_drop hover ${disabled && 'disabled'} ${type}`} onClick={() => { if (onClickIcon == undefined) _handleClick() }}>
            <div className="icon_container" onClick={onClickIcon}>
                {icon}
            </div>
            <div className='text' onClick={() => { if (onClickIcon != undefined) _handleClick() }}>
                <div className='container_text'>
                    {text}
                </div>
                <div className="color_line" style={{ background: colorLine }}></div>
            </div>
        </div>

        {isOpen && <div className="drop_down_container">
            <form onSubmit={_submited} >
                <AutoCompleteComponent placeholder={text} isOpen formInfo={formFunctions} keyName={DROP_DOWN_KEY} onSearch={onSearch} onSelect={_handleOnChange} />
            </form>
        </div>}
    </div>
}

DropDownComponent.defaultProps = {
    disabled: false,
    icon: undefined,
    onClickIcon: undefined,
    colorLine: undefined,
    height: 'auto',
    width: 'auto',
}

export default DropDownComponent;