import { right } from "fp-ts/lib/Either";
import Constants, { ConstantsName } from "../../../../../constants/Constants";
import di from "../../../../../di/DependencyInjection";
import callAPI from "../../../fetchCallImplementation";

//TODO implement
const SearchAISummariesEntitiesApiImpl = async (): Promise<any> => {

    const baseUrl = di.get<Constants>(ConstantsName).AI;

    const url = new URL(`${baseUrl}/entities`);

    const response = await callAPI(url.toString(), 'GET', {});
    
    const reader = response.body?.getReader();

    if (!reader) {
        throw new Error("Readable stream not supported or body is empty.");
    }
    
    let result = '';
    const decoder = new TextDecoder();

    while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        result += decoder.decode(value, { stream: true });
    }

    // Decoding the last chunk
    result += decoder.decode();
    
    return right(result);
}

export default SearchAISummariesEntitiesApiImpl;