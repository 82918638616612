import { injectable } from "inversify";
import RoleRepository, { GetModuleWithAllRolesResponse } from "../../../domain/repositories/RoleRepository";
import { Either, right } from "fp-ts/lib/Either";
import ExceptionEntity from "../../../domain/entities/ExceptionEntity";
import ModuleEntity from "../../../domain/entities/ModuleEntity";
import RoleEntity from "../../../domain/entities/RoleEntity";
import Testing from "../../../ui/utils/Testing";
import GetAllRolesApiImpl from "./api/GetAllRolesApiImpl";
import GetRoleByIdApiImpl from "./api/GetRoleByIdApiImpl";
import UpdateRoleInfoApiImpl from "./api/UpdateRoleInfoApiImpl";
import DeleteRoleByIdApiImpl from "./api/DeleteRoleByIdApiImpl";
import UpdateRolPermisionsApiImpl from "./api/UpdateRolPermisionsApiImpl";
import GetAllPermissionsApiImpl from "./api/GetAllPermissionsApiImpl";
import CreateRoleInfoApiImpl from "./api/CreateRoleInfoApiImpl";

const tempModule: ModuleEntity = {
    id: '2',
    name: 'Time and attendance Module',
    description: 'Time and attendance module is used for … Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut dapibus velit arcu, finibus vulputate nisl semper quis. Sed lacinia auctor sodal',
    logo: '/assets/modules/clock_module.png',
    permissions: Array(4).fill({
        id: '3',
        name: 'Shrinkage Aux Code',
        canRead: true,
        canWrite: true,
        canDelete: false,
        canUpdate: true,
    })
};


@injectable()
export default class RoleRepositoryDev implements RoleRepository {
    getRoleById = async (id: string): Promise<Either<ExceptionEntity, RoleEntity>>  => GetRoleByIdApiImpl(id);
    updateRoleInfo = async (id: string, name: string, description: string): Promise<Either<ExceptionEntity, string>> => UpdateRoleInfoApiImpl(id, name, description);
    updateRolePermissions = async (id: string, bodyContent: any): Promise<Either<ExceptionEntity, string>> => UpdateRolPermisionsApiImpl(id, bodyContent);
    deleteRole = async (id: string): Promise<Either<ExceptionEntity, string>> => DeleteRoleByIdApiImpl(id);
    getAllRoles = async (): Promise<Either<ExceptionEntity, RoleEntity[]>> => GetAllRolesApiImpl();
    getModuleWithAllRoles = async (moduleId: string): Promise<Either<ExceptionEntity, GetModuleWithAllRolesResponse>> => {
        await Testing.sleeper(1000);
        const temp: GetModuleWithAllRolesResponse = {
            module: tempModule,
            submodules: Array(4).fill({
                name: "Tracking time",
                roleWithPermisions: Array(4).fill({
                    name: "Admin",
                    canRead: true,
                    canWrite: true,
                    canDelete: true,
                    canUpdate: true,
                }),
            }),
        };
        return right(temp);
    }

    getAllPermissions = async (): Promise<Either<ExceptionEntity, any[]>> => GetAllPermissionsApiImpl();
    createRole = async(name: string, description: string, permissions: any[]): Promise<Either<ExceptionEntity, string>> =>  CreateRoleInfoApiImpl(name, description, permissions);

}
