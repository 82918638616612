import { injectable } from "inversify";
import AllUserRepository from "../../repositories/AllUsersRepository";
import { Either } from "fp-ts/lib/Either";
import ExceptionEntity from "../../entities/ExceptionEntity";
import RolesEntity from "../../entities/RolesEntity";
import UserEntity from "../../entities/UserEntity";
import RolesProvider from "../../providers/roles/RolesProvider";

interface props { allUserRepository: AllUserRepository, rolesProvider: any  }

@injectable()
export default class SearchAllUserUseCase {
    _allUserRepository: AllUserRepository;
    
    _rolesProvider: RolesProvider;

    constructor(_: props) {
        this._allUserRepository = _.allUserRepository;
        this._rolesProvider = _.rolesProvider;
    }

    public call = async () => new Promise<Either<ExceptionEntity, UserEntity[]>>(async (resolve, reject) => {
        try {
            const response = await this._allUserRepository.search();
            return resolve(response);
        } catch (error) {
            return reject();
        }
    });
}

export const SearchAllUsersUseCaseName = "SearchAllUsersUseCaseName";