import { right } from "fp-ts/lib/Either";
import Constants, { ConstantsName } from "../../../../../constants/Constants";
import di from "../../../../../di/DependencyInjection";
import callAPI from "../../../fetchCallImplementation";

//TODO implement
const SearchAISummariesApiImpl = async (query: any): Promise<any> => {

    const baseUrl = di.get<Constants>(ConstantsName).AI;

    let queryParsed = {...query};
    
    if (query['timestampFrom'] !== undefined) {
        queryParsed['timestampFrom'] = new Date(query['timestampFrom']).getTime();
    }
    if (query['timestampTo'] !== undefined) {
        queryParsed['timestampTo'] = new Date(query['timestampTo']).setUTCHours(23, 59, 59, 999);
    }
    
    const url = new URL(`${baseUrl}/search`);

    if (queryParsed != null) {
        for (const key in queryParsed) {
            url.searchParams.append(key, queryParsed[key]);
        }
    }

    const response = await callAPI(url.toString(), 'GET', {});
    
    const reader = response.body?.getReader();

    if (!reader) {
        throw new Error("Readable stream not supported or body is empty.");
    }
    
    let result = '';
    const decoder = new TextDecoder();

    while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        result += decoder.decode(value, { stream: true });
    }

    // Decoding the last chunk
    result += decoder.decode();
    
    return right(result);
}

export default SearchAISummariesApiImpl;