import TypeEventScheduleEntity from "./TypeEventScheduleEntity";

export enum EventScheduleEntityStatus {
    pending = 'pending',
    paused = 'paused',
    running = 'running',
    ended = 'ended',
  }
export default interface EventScheduleEntity {
    id: string;
    name: string;
    description?: string | undefined;
    dateStart: Date;
    dateEnd: Date;
    type: TypeEventScheduleEntity;
    startedAt?: Date | undefined;
    endedAt?: Date | undefined;
    typeEventId?: string | undefined;
    editable?: boolean;
    shiftId?: string | undefined;
    status?: EventScheduleEntityStatus | undefined; 
    userid?: number,
}