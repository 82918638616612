import React, { useEffect } from 'react';
import LocalizationEntity from '../../../domain/entities/LocalizationEntity';
import ProviderProps from '../../../domain/providers/ProviderProps';
import LocalizationContext from '../../../domain/providers/localization/LocalizationContext';
import LocalizationList from './dictionaries/LocalizationList';
import LocalizationContextType from '../../../domain/providers/localization/LocalizationContextType';
import { injectable } from 'inversify';
import LocalizationProvider from '../../../domain/providers/localization/LocalizationProvider';

const keyNameLocalLocalization = "localization_selected";
let _Actions:LocalizationContextType;
const _Provider: React.FC<ProviderProps> = ({ children }) => {
    const [localization, setLocalization] = React.useState<LocalizationEntity>(LocalizationList[0]);
    const [localizations, setLocalizations] = React.useState<LocalizationEntity[]>(LocalizationList);
    const i18n = (keyword: string, values: any = null) => {
        if (values == null) return localization.dictionary[keyword] ?? keyword;
        let result = localization.dictionary[keyword] ?? keyword;
        Object.keys(values).forEach((key) => {
            result = result.replace(`{${key}}`, values[key]);
        }
        );
        return result;
    };

    _Actions = {
        localization, setLocalization, localizations, setLocalizations, i18n
    };

    const _readDefault = () => {
        const defaultLocalization = window.localStorage.getItem(keyNameLocalLocalization);
        if (defaultLocalization != null && defaultLocalization != '') {
            const findedLocalization = LocalizationList.find((localization) => localization.code == defaultLocalization);
            setLocalization(findedLocalization ?? LocalizationList[0]);
        }
    }

    useEffect(() => {
        _readDefault();
    }, []);
    return <LocalizationContext.Provider value={{ i18n, localization, setLocalization, localizations, setLocalizations }}>{children}</LocalizationContext.Provider>;
}

@injectable()
class LocalizationProviderImpl implements LocalizationProvider{
    public context = LocalizationContext;
    public Provider = _Provider;
    public Actions = _Actions;
}

export default new LocalizationProviderImpl();