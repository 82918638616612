import { FC, useContext, useState } from "react";
import EventScheduleEntity, { EventScheduleEntityStatus } from "../../../../../domain/entities/EventScheduleEntity";
import di from "../../../../../di/DependencyInjection";
import { StartClockTrackingUseCase, StartClockTrackingUseCaseName } from "../../../../../domain/use_cases/trackingTime/StartClockTrackingUseCase";
import ButtonComponent from "../../../button/ButtonComponent";
import Icons from "../../../../assets/Icons";
import { isLeft } from "fp-ts/lib/Either";
import CardEventScheduleComponent from "../../../calendar/cardEventSchedule/CardEventScheduleComponent";

import './TrackingTimeOnHoldComponentStyles.scss';
import ModalsContext from "../../../../../domain/providers/modal/ModalsContext";
import ModalsContextType from "../../../../../domain/providers/modal/ModalsContextType";

//TODO implement this component
const TrackingTimeOnHoldComponent: FC<{ currentEvent: EventScheduleEntity | undefined }> = ({ currentEvent }) => {

    const { closeModalCustom } = useContext(ModalsContext) as ModalsContextType;
    const [loading, setLoading] = useState(false);

    const _handleOnContinue = async () => {
        if (currentEvent === undefined || loading) return;
        setLoading(true);
        const response = await di.get<StartClockTrackingUseCase>(StartClockTrackingUseCaseName).call(currentEvent);
        if (isLeft(response)) console.error(response.left.code ?? "Error starting task");
        else closeModalCustom();
        setLoading(false);
    }

    return <div className="tracking_time_on_hold_component">
        
        <div className="onhold_left_container">
            <div className=""><img src="/assets/images/onhold.png" className='img-fluid' alt="" /></div>
        </div>
        <div className="onhold_right_container">

            <p className="onhold_title">On Hold</p>
            <p className="onhold_subtitle">You are unable to use the app until you continue the clock</p>
            <div className="tracking_card">
                <CardEventScheduleComponent event={currentEvent} />
            </div>

            <ButtonComponent icon={<Icons.Play height={20} width={20} />} isLoading={loading} onClick={_handleOnContinue} text={`Continue to "${currentEvent?.name}"`}></ButtonComponent>
        </div>

    </div>
}

export default TrackingTimeOnHoldComponent;