import { Either, left, right } from "fp-ts/lib/Either";
import ExceptionEntity from "../../../../domain/entities/ExceptionEntity";
import { Auth } from "aws-amplify";
import callAPI from "../../fetchCallImplementation";
import Constants, { ConstantsName } from "../../../../constants/Constants";
import di from "../../../../di/DependencyInjection";

const DeleteRoleByIdApiImpl = async (id: string): Promise<Either<ExceptionEntity, string>> => {
    try {

        const devPath = "https://82jtqezjva.execute-api.us-east-1.amazonaws.com/devapi/";
        const prodPath = "https://8ciupnbqfe.execute-api.us-east-1.amazonaws.com/prod/";
        
        const baseUrl = di.get<Constants>(ConstantsName).HOST_API_1_URL;
        const response = await callAPI(`${baseUrl}/roles/${id}`, 'DELETE', {});

        const value  = await response.body?.getReader().read();
        const result = new TextDecoder().decode(value?.value);
        
        if(response){
            return right(result);
        } else {
            const exception: ExceptionEntity = {code: 'An error has occurred'};
            return left(exception);
        }
    } catch (error: any) {
        const exception: ExceptionEntity = {code: 'An error has occurred'};
            return left(exception);
    }
}

export default DeleteRoleByIdApiImpl;