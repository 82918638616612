import { Either, isLeft, right } from "fp-ts/lib/Either";
import ExceptionEntity from "../../../../../domain/entities/ExceptionEntity";
import ShiftEntity from "../../../../../domain/entities/ShiftEntity";
import SearchUsersWithShiftApiImpl from "../../../shift/api/impl/SearchUsersWithShiftApiImpl";
import GetAllModifiersApiImpl from "./GetAllModifiersApiImpl";
import EventScheduleEntity from "../../../../../domain/entities/EventScheduleEntity";

//TODO modify to subdive the tasks like in today calendar
const GetEmployeeCalendarApiImpl = async (employeeId: string, startDate: Date, endDate: Date): Promise<Either<ExceptionEntity, ShiftEntity[]>> => {
    //TODO add modifiers
    const modifiersResponse = (await GetAllModifiersApiImpl(employeeId, startDate, endDate, 1, 1000));
    let modifiers: EventScheduleEntity[] = isLeft(modifiersResponse) ? [] : modifiersResponse.right;


    // const modifiers = [];
    const shiftsResponse = (await SearchUsersWithShiftApiImpl([], [], [employeeId], [], startDate, endDate, 1, 1000)).items;

    if (shiftsResponse.length == 0 || shiftsResponse[0].shifts.length == 0) return right([]);
    const shifts = shiftsResponse[0].shifts;

    //now find the modifiers of each shift
    shifts.forEach(shift => {
        shift.events = modifiers.filter(modifier => modifier.dateStart >= shift.initHour && modifier.dateEnd <= shift.endHour);
    });
    return right(shifts);
    // else if(modifiers.length === 0) return right(shiftsResponse.shift);

    //run all modifiers and assign to a shift
}

export default GetEmployeeCalendarApiImpl;
