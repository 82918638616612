import { injectable } from "inversify";
import AIRepository from "../../repositories/AIRepository";

interface props {
    aiRepository: AIRepository;
}

@injectable()
export class GetAICustomersUseCase {
    _aiRepository: AIRepository;

    constructor(_: props) {
        this._aiRepository = _.aiRepository;
    }

    public call = async (): Promise<any> => {
        return await this._aiRepository.getAICustomers();
    }
}

export const GetAICustomersUseCaseName = "GetAICustomersUseCaseName";