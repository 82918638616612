import { injectable } from "inversify";
import UserEntity from "../../entities/UserEntity";
import UserRepository from "../../repositories/UserRepository";
import { Either } from "fp-ts/lib/Either";
import ExceptionEntity from "../../entities/ExceptionEntity";

interface props { createUsersRepository: UserRepository }

@injectable()
export default class CreateUserUseCase {
    _createUsersRepository: UserRepository;

    constructor(_: props) {
        this._createUsersRepository = _.createUsersRepository;
    }

    public call = async (data: any[]) => new Promise<Either<ExceptionEntity, string>>(async (resolve, reject) => {
        try {
            const response = await this._createUsersRepository.createUsers(data);
            return resolve(response);
        } catch (error) {
            return reject();
        }
    });
}

export const CreateUserUseCaseName = "CreateUserUseCaseName";