import "./DataLoaderSpinnerComponentStyles.scss";
import { FC, useContext } from "react";
import DataLoaderSpinnerComponentProps from "./DataLoaderSpinnerComponentProps";
import KeyWordLocalization from "../../../../../providers/localization/dictionaries/KeyWordLocalization";
import LocalizationContext from "../../../../../../domain/providers/localization/LocalizationContext";
import LocalizationContextType from "../../../../../../domain/providers/localization/LocalizationContextType";

const DataLoaderSpinnerComponent: FC<DataLoaderSpinnerComponentProps> = ({ loading, current, total }) => {
    const { i18n } = useContext(LocalizationContext) as LocalizationContextType;
    if (!loading) return <></>
    return <div className="data_loader_spinner_component">
        {loading && <div className="d-flex justify-content-center w-100">
            <div className="loader_spinner">
                <div className="d-flex justify-content-center align-items-center flex-column">
                    <img src="/assets/logos/logo.png" className='logo' alt="" />
                    <strong className='mb-3'>{i18n(KeyWordLocalization.DataLoaderSpinnerComponentMessage)}</strong>
                </div>

                <div className="progress">
                    <div className="progress-bar" role="progressbar" style={{ width: `${(current / total) * 100}%` }} aria-valuenow={current} aria-valuemin={0} aria-valuemax={total}></div>
                </div>
                <div className="w-100 d-flex justify-content-between">
                    <div>{current} / {total}</div>
                    <div>{Math.round((current / total) * 100)}%</div>
                </div>
            </div>
        </div>}
    </div>
}

export default DataLoaderSpinnerComponent;