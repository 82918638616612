import { injectable } from "inversify";
import FilterOptionEntity from "../../entities/FilterOptionEntity";
import UserEntity from "../../entities/UserEntity";
import BusinessUnitRepository from "../../repositories/BusinessUnitRepository";
import BusinessUnitEntity from "../../entities/BusinessUnitEntity";
import SearchResultEntity from "../../entities/SearchResultEntity";

interface props { businessUnitRepository: BusinessUnitRepository }

@injectable()
export default class SearchBusinessUnitUseCase {
    _businessUnitRepository: BusinessUnitRepository;

    constructor(_: props) {
        this._businessUnitRepository = _.businessUnitRepository;
    }

    public call = async (word: string, page: number, itemsPerPage: number, filter: FilterOptionEntity[]): Promise<SearchResultEntity<BusinessUnitEntity>> => {
        try {
            const response = await this._businessUnitRepository.search(word, page, itemsPerPage, filter);
            return response;
        } catch (error) {
            return {
                items: [],
                total: 0,
                pages: 0,
            }
        }
    };
}

export const SearchBusinessUnitUseCaseName = "SearchBusinessUnitUseCaseName";