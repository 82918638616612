import { injectable } from "inversify";
import AllUserRepository from "../../../domain/repositories/AllUsersRepository";
import SearchAllUsersApiImpl from "./api/impl/SearchAllUsersApiImpl";


@injectable()
class AllUserRepositoryDev implements AllUserRepository {
    search = async (): Promise<any> => await SearchAllUsersApiImpl();
}

export default AllUserRepositoryDev;