import { Either } from "fp-ts/lib/Either";
import ExceptionEntity from "../../../../../domain/entities/ExceptionEntity";
import EventScheduleEntity from "../../../../../domain/entities/EventScheduleEntity";

//TODO implement
const UpdateEventCalendarApiImpl = async (event: EventScheduleEntity): Promise<Either<ExceptionEntity, EventScheduleEntity>> => {
    throw new Error("Not implemented");
}

export default UpdateEventCalendarApiImpl;
