import { FC, useContext, useEffect, useState } from "react";
import di from "../../../../di/DependencyInjection";
import AsignShiftUseCase, { AsignShiftUseCaseName } from "../../../../domain/use_cases/shift/AsignShiftUseCase";
import KeyWordLocalization from "../../../providers/localization/dictionaries/KeyWordLocalization";
import { isRight } from "fp-ts/lib/Either";
import ModalsContext from "../../../../domain/providers/modal/ModalsContext";
import ModalsContextType from "../../../../domain/providers/modal/ModalsContextType";
import LocalizationContext from "../../../../domain/providers/localization/LocalizationContext";
import LocalizationContextType from "../../../../domain/providers/localization/LocalizationContextType";
import CreateShiftUseCase, { CreateShiftUseCaseName } from "../../../../domain/use_cases/shift/CreateShiftUseCase";
import ShiftEntity from "../../../../domain/entities/ShiftEntity";
import { useForm } from "react-hook-form";
import AutoCompleteComponent from "../../forms/autocomplete/AutoCompleteComponent";
import { AutoCompleteItem } from "../../forms/autocompleteWithCheck/AutoCompleteWithCheckProps";
import BusinessUnitEntity from "../../../../domain/entities/BusinessUnitEntity";
import UserEntity from "../../../../domain/entities/UserEntity";
import { DateOperations } from "../../../utils/DateOperations";
import SearchShiftTemplateUseCase, { SearchShiftTemplateUseCaseName } from "../../../../domain/use_cases/shift/SearchShiftTemplateUseCase";
import DateParse from "../../../utils/DateParse";
import Validators from "../../../utils/Validators";
import { ErrorMessage } from "@hookform/error-message";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../routes/RoutesComponent";
import AddShiftComponentProps from "./AddShiftComponentProps";

const itemsPerPage = 20;
const AddShiftComponent: FC<AddShiftComponentProps> = ({ weekSelected, employees, handleOnAddShift }) => {
    const { addToast, closeModalCustom } = useContext(ModalsContext) as ModalsContextType;
    const { i18n } = useContext(LocalizationContext) as LocalizationContextType;
    const formFunctions = useForm();
    const { register, handleSubmit, watch, getValues, formState: { errors }, setValue } = formFunctions;
    const [shiftSearched, setShiftSearched] = useState<ShiftEntity[]>([]);
    const useCustomShift = true;

    const buSelected = watch(KeyWordLocalization.SearchWithGroupsComponentBusinessUnitIds);

    const _handleChangeBuSelected = (bu: BusinessUnitEntity) => {
        setValue(KeyWordLocalization.ShiftEntity, undefined);
        if (bu == undefined) return;
        // _handleSearchShift('');
    }

    const _handleAssignShiftShift = async (data: any) => {
        let initHour: Date, endHour: Date, datesPicked: Date[];
        if (useCustomShift) {
            //get first day picked and 
            datesPicked = data[KeyWordLocalization.ShiftEntityDays].filter((day: string | null | boolean) => day != undefined && day != false).map((day: string) => new Date(day));
            initHour = new Date(datesPicked[0]);
            initHour.setHours(data[KeyWordLocalization.AddShiftComponentInitHour].split(':')[0]);
            initHour.setMinutes(data[KeyWordLocalization.AddShiftComponentInitHour].split(':')[1]);
            endHour = new Date(datesPicked[datesPicked.length - 1]);
            endHour.setHours(data[KeyWordLocalization.AddShiftComponentEndHour].split(':')[0]);
            endHour.setMinutes(data[KeyWordLocalization.AddShiftComponentEndHour].split(':')[1]);
        } else {
            //get initHour and endHour from shift selected
            //TODO for now its not really working
            return addToast("Error selected shift", 'alert', {});
        }

        const shiftsTempCreated = datesPicked.map(element => {
            const tempInitHour = new Date(element);
            tempInitHour.setHours(initHour.getHours());
            tempInitHour.setMinutes(initHour.getMinutes());

            const tempEndHour = new Date(element);
            tempEndHour.setHours(endHour.getHours());
            tempEndHour.setMinutes(endHour.getMinutes());

            const tempShift: ShiftEntity = {
                id: '',
                users: employees,
                initHour: tempInitHour,
                endHour: tempEndHour,
                rating: 0,
                shiftGroup: '',
                events: [],
            }
            return tempShift;
        });
        handleOnAddShift?.(shiftsTempCreated);
    }

    // const _handleSearchShift = async (search: string): Promise<AutoCompleteItem<ShiftEntity>[]> => {
    //     const response = await di.get<SearchShiftTemplateUseCase>(SearchShiftTemplateUseCaseName).call('', weekSelected ?? new Date(), search, 1, itemsPerPage);
    //     const mapped = response.items.map<AutoCompleteItem<ShiftEntity>>((shift: ShiftEntity) => {
    //         return {
    //             label: `${DateParse.getDayOfCalendar(shift.days[0].initHour)} ${shift.days[0].initHour.getDate()} to ${DateParse.getDayOfCalendar(shift.days[shift.days.length - 1].initHour)} ${shift.days[shift.days.length - 1].initHour.getDate()} - ${DateParse.getHourAmPm(shift.days[0].initHour)} to ${DateParse.getHourAmPm(shift.days[shift.days.length - 1].endHour)}`,
    //             image: '',
    //             id: shift.id,
    //             aditionalValue: shift,
    //         }
    //     });
    //     return mapped;
    // }

    useEffect(() => {
        _handleChangeBuSelected(buSelected);
    }, [buSelected]);

    return (
        <div>
            <form onSubmit={handleSubmit(_handleAssignShiftShift)}>
                <div className="row">
                    {/* {!useCustomShift &&
                        <div className="col-12 my-2">
                            <div className="form-group">
                                <AutoCompleteComponent label={i18n(KeyWordLocalization.AddShiftComponentShift)}
                                    onSearch={_handleSearchShift}
                                    onSelect={(val: ShiftEntity) => setValue('_' + KeyWordLocalization.ShiftEntity, val)}
                                    keyName={KeyWordLocalization.ShiftEntity}
                                    formInfo={formFunctions}
                                />
                            </div>
                            <div className="row">
                                {shiftSearched.map((shift, index) => <div className="col-6 col-md-4 col-lg-3" key={index}>{shift.name}</div>)}
                            </div>
                        </div>

                    } */}
                    {/* <div className="col-12">
                        <div className="form-check">
                            <input id={KeyWordLocalization.AddShiftComponentOtherShift} type="checkbox" className="form-check-input" {...register(KeyWordLocalization.AddShiftComponentOtherShift)} />
                            <label htmlFor={KeyWordLocalization.AddShiftComponentOtherShift} className="form-check-label">{i18n(KeyWordLocalization.AddShiftComponentOtherShift)}</label>
                        </div>
                    </div> */}
                    {useCustomShift && <>
                        <div className={`form-group col-12 my-3 ${errors[KeyWordLocalization.ShiftEntityDays] && 'error'}`}>
                            <label>{i18n(KeyWordLocalization.AddShiftComponentCustomShift)}</label>
                            <div className="d-flex flex-wrap">
                                {weekSelected.map((day: Date, index: number) => <div className="form-check mx-2" key={index}>
                                    <input id={`${KeyWordLocalization.ShiftEntityDays}[${index}]`} value={day.toISOString()} type="checkbox" className="form-check-input" {...register(`${KeyWordLocalization.ShiftEntityDays}`, Validators({
                                        required: true, validate: (val: any) => {
                                            return val.filter((item: any) => item != null && item != false).length > 0 ? true : i18n(KeyWordLocalization.ValidatorRequired);
                                        }
                                    }))} />
                                    <label htmlFor={`${KeyWordLocalization.ShiftEntityDays}[${index}]`} className="form-check-label">{DateParse.getDayOfCalendar(day)} {day.getDate()}</label>
                                </div>)}
                            </div>
                            {/* <input type="hidden" {...register(KeyWordLocalization.ShiftEntityDays, Validators({
                                required: true, validate: (val: any) => {
                                    return val.filter((item: any) => item != null && item != false).length > 0 ? true : i18n(KeyWordLocalization.ValidatorRequired);
                                }
                            }))} /> */}
                            <ErrorMessage as="aside" errors={errors} name={KeyWordLocalization.ShiftEntityDays} />
                        </div>
                        <div className="col-6 my-3">
                            <div className={`form-group ${errors[KeyWordLocalization.AddShiftComponentInitHour] ? 'error' : ''}`}>
                                <label>{i18n(KeyWordLocalization.AddShiftComponentInitHour)}</label>
                                <input type="time" className="form-control" {...register(KeyWordLocalization.AddShiftComponentInitHour, Validators({ required: true }))} />
                                <ErrorMessage as="aside" errors={errors} name={KeyWordLocalization.AddShiftComponentInitHour} />
                            </div>
                        </div>
                        <div className="col-6 my-3">
                            <div className={`form-group ${errors[KeyWordLocalization.AddShiftComponentEndHour] ? 'error' : ''}`}>
                                <label>{i18n(KeyWordLocalization.AddShiftComponentEndHour)}</label>
                                <input type="time" className="form-control" {...register(KeyWordLocalization.AddShiftComponentEndHour, Validators({
                                    greaterThan: watch(KeyWordLocalization.AddShiftComponentInitHour),
                                    required: true,
                                }))} />
                                <ErrorMessage as="aside" errors={errors} name={KeyWordLocalization.AddShiftComponentEndHour} />
                            </div>
                        </div>
                    </>}
                    <div className="d-flex justify-content-end">
                        <button className="btn btn_primary" type="submit">{i18n(KeyWordLocalization.AddShiftComponentAssignShift)}</button>
                    </div>
                </div>

            </form>
        </div>
    );
};

export default AddShiftComponent;