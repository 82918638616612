import './RoleInformationComponentStyles.scss';
import { FC, useContext, useEffect, useState } from "react";
import RoleInformationComponentProps from './RoleInformationComponentProps';
import ActionButtonRolesComponent from '../../../../../components/actionButton/ActionButtonRolesComponent';
import Icons from '../../../../../assets/Icons';
import DeleteModalComponent from '../../../../../providers/modals/modals/delete/DeleteModalComponent';
import ModalsContext from '../../../../../../domain/providers/modal/ModalsContext';
import ModalsContextType from '../../../../../../domain/providers/modal/ModalsContextType';
import CreateModalComponent from '../../../../../providers/modals/modals/create/CreateModalComponent';

import UpdateRoleUseCaseCase, { UpdateRoleUseCaseCaseName } from '../../../../../../domain/use_cases/roles/UpdateRoleInfoUseCase';
import di from '../../../../../../di/DependencyInjection';
import LoadingComponent from '../../../../../components/LoadingComponent/LoadingComponent';
import DeleteRoleByIdUseCase, { DeleteRoleByIdUseCaseName } from '../../../../../../domain/use_cases/roles/DeleteRoleByIdUseCase';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../../../../routes/RoutesComponent';
import { isRight } from 'fp-ts/lib/Either';
import RolesContext from '../../../../../../domain/providers/roles/RolesContext';
import RolesContextType from '../../../../../../domain/providers/roles/RolesContextType';


interface RoleBasicInformation {
    name: string,
    content: string
}

const RoleInformationComponent: FC<RoleInformationComponentProps> = ({ role, hasEdition, hasButtonEdition = true }) => {

    const navigate = useNavigate();
    
    const { openModalDelete } = useContext(ModalsContext) as ModalsContextType;

    const { openModalCreate } = useContext(ModalsContext) as ModalsContextType;

    const [ isLoading, setIsLoading ] = useState(false);
    
    const {addToast} = useContext(ModalsContext) as ModalsContextType;

    const [ modalItems, setModalItems ] = useState<any>();

    const { roles, setRoles, users, setUsers } = useContext(RolesContext) as RolesContextType;
    

    const [ roleInfo, setRoleInfo ] = useState({
        id: role.id as string,
        name: role.name,
        description: role.description
    });

    const saveUpdate = async (value: RoleBasicInformation = {name: '', content: ''}) => {
        setIsLoading(true);
        const _updateRoleById = await di.get<UpdateRoleUseCaseCase>(UpdateRoleUseCaseCaseName).call(roleInfo.id, value.name, value.content);

        if (isRight(_updateRoleById)) {
            setRoleInfo(
                {
                    id: role.id as string,
                    name: value.name,
                    description: value.content
                }
            );
            setIsLoading(false);
        } else {
            setIsLoading(false);
            
            addToast(_updateRoleById.left.message ? _updateRoleById.left.message : ' ', 'error', null, _updateRoleById.left.message?.toLocaleLowerCase().replaceAll(' ', '') === 'failedtofetch' ? false : true);
        }
        
    };

    const deleteAction = async () => {
        setIsLoading(true);
        
        const _updateRoleById = await di.get<DeleteRoleByIdUseCase>(DeleteRoleByIdUseCaseName).call(roleInfo.id);

        if(isRight(_updateRoleById)){
            let key = '';
            try {
                key = JSON.parse(_updateRoleById.right).key
            } catch (error) {
                key = ''
            }

            if(key === "AssignNewRoleFirst"){
                const message = 'The role can’t be deleted. There are users assigned to this role. Reassign the users to another role before attempting to delete it';
                addToast(message, 'error', null, true);
            } 
            navigate(routes.rolesAndPermissions.relativePath + "", { replace: true });
            setIsLoading(false);
            
            
        } else {
            addToast(_updateRoleById.left.message ? _updateRoleById.left.message : ' ', 'error', null, _updateRoleById.left.message?.toLocaleLowerCase().replaceAll(' ', '') === 'failedtofetch' ? false : true);
        }

        setRoles([]);
        
    };

    useEffect(() => {
        const deleteItem = {
                id: "1",
                icon: Icons.Trash,
                content: "Delete Role",
                action: () => openModalDelete("Delete Role", 'Deleting a role is an irreversible action in the platform. Are you sure you want to delete this role and all associated settings?. if you are sure please input "CONFIRM" in the next field.', 'Are you sure?, input "CONFIRM"', "CONFIRM", deleteAction)
                
            };
        const edtiItem =
            {
                id: "2",
                icon: Icons.EditPencil,
                content: "Edit Role info",
                action: () => openModalCreate('Edit Role Info', roleInfo.name, roleInfo.description, saveUpdate),
            }
        const finalItems = []; 

            if (hasEdition?.update) {
                finalItems.push(edtiItem);
            }
            if(hasEdition?.delete){
                finalItems.push(deleteItem);
            }

        setModalItems(finalItems)
        
    }, [roleInfo])

    return <div className="card-role-component-edit">
        {
            !isLoading ? 
            <div className="card-body-edit">
            <div className="role-title-detail">
                {
                    roleInfo?.name + ''
                }

                {
                    hasButtonEdition && 
                        <ActionButtonRolesComponent text='Actions' onClick={() => null} icon={Icons.EditPencil} colorLine="#E6C860" hasModal={true} modalItems={modalItems} />
                }
                
                
            </div>
            <div className="role-description-detail">
                {roleInfo?.description}
            </div>
            <DeleteModalComponent />
            <CreateModalComponent />
        </div>

        :

        <LoadingComponent />
        }
        
    </div>
}

export default RoleInformationComponent;