/********************************************************************************
 * File Header - Constants
 * Created By: Marlon Alejandro Mendez Castañeda
 * Contact: +57 3234686680
 * Date: March 14, 2024
 *
 * Description:
 * This is the file is irrelevant, it is only used to complete the branch test, and avoid errors
 * when the app tries to inject the constants
 ********************************************************************************/

import Constants from "./Constants";

const ConstantsFake: Constants = {
    GRAPH_API_URL: "https://obw26pjgcfg57ijmwweqanejfu.appsync-api.us-east-1.amazonaws.com/graphql",
    BASE_URL: "https://staging.itelligencecx.com",
    HOST_API_1_URL: "https://ragyn9brr1.execute-api.us-east-1.amazonaws.com/dev",
    HOST_API_2_URL: "https://i6odgwi3w3.execute-api.us-east-1.amazonaws.com/dev",
    HOST_API_USERS_URL: "https://9q6rape8td.execute-api.us-east-1.amazonaws.com/dev",
    GA4: 'G-TM7KCSV579',
    AI: "https://cxkj7px2p1.execute-api.us-east-1.amazonaws.com/dev"
}

export default ConstantsFake;