import { injectable } from "inversify";
import ShiftRepository, { AsingShiftBulkImportResponse } from "../../repositories/ShiftRepository";
import ShiftEntity from "../../entities/ShiftEntity";
import EventScheduleEntity from "../../entities/EventScheduleEntity";

interface props {
    shiftRepository: ShiftRepository;
}

@injectable()
class AsignShiftBulkImportUseCase {
    private shiftRepository: ShiftRepository;

    constructor({ shiftRepository }: props) {
        this.shiftRepository = shiftRepository;
    }

    async call(
        data: { email: string, shifts: ShiftEntity[], outEvents: EventScheduleEntity[] }[],
        onCallBack: (data: AsingShiftBulkImportResponse) => void) {
        return await this.shiftRepository.asignShiftBulkImport(data, onCallBack);
    }
}

export default AsignShiftBulkImportUseCase;

export const AsignShiftBulkImportUseCaseName = "AsignShiftBulkImportUseCase";