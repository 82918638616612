import { FC, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import GetRoleInfoByIdUseCase, { GetRoleInfoByIdUseCaseName } from "../../../../domain/use_cases/roles/GetRoleInfoByIdUseCase";
import di from "../../../../di/DependencyInjection";
import LoadingComponent from "../../../components/LoadingComponent/LoadingComponent";

import './RoleCreatePermissionsPageStyles.scss';
import Icons from "../../../assets/Icons";
import ModulePermision from "../../../../domain/entities/ModulePermision";
import { isRight } from "fp-ts/lib/Either";
import ModalsContext from "../../../../domain/providers/modal/ModalsContext";
import ModalsContextType from "../../../../domain/providers/modal/ModalsContextType";
import GetAllPermissionsUseCase, { GetAllPermissionsUseCaseName } from "../../../../domain/use_cases/roles/GetAllPermissionsUseCase";
import CreateRoleInfoUseCase, { CreateRoleInfoUseCaseName } from "../../../../domain/use_cases/roles/CreateRoleInfoUseCase";
import { routes } from "../../../routes/RoutesComponent";
import ModuleEntity from "../../../../domain/entities/ModuleEntity";
import ModulePermisionsEditableComponent from "../edit/components/ModulePermisionsEditableComponent";
import RolesContext from "../../../../domain/providers/roles/RolesContext";
import RolesContextType from "../../../../domain/providers/roles/RolesContextType";
import { toast } from "react-toastify";

interface RolePermissionsGroups {
    [key: string]: {
        permissions: Array<string>;
        resources: Array<string>;
    };
}
interface PolicyToUpdate {
    policy_name: string | undefined,
    effect: string,
    policy: {
        permissions: string[],
        resource: string | undefined
    }
}

interface ModulesPermission {
    [key: number]: any;
    name: string;
    policy_id: string
}

const RoleCreatePermissionsPage: FC<{}> = () => {
    
    const {addToast} = useContext(ModalsContext) as ModalsContextType;

    const navigate = useNavigate();
    
    const [ roleInfoById, setRoleInfoById ] = useState<any>();
    const _roleById = async() => await di.get<GetRoleInfoByIdUseCase>(GetRoleInfoByIdUseCaseName).call("1");

    const _allPermissions = async() => await di.get<GetAllPermissionsUseCase>(GetAllPermissionsUseCaseName).call();
    
    const [ updatedData, setUpdatedData ] = useState<any[]>([]);

    const [ allPermissions, setAllPermissions ] = useState<RolePermissionsGroups>({});

    const [ isLoading, setIsLoading ] = useState(false);
    const [isContentLoading, setisContentLoading] = useState(true);

    const [ nameValue, setNameValue ] = useState('');
    const [ descriptionValue, setDescriptionValue ] = useState('');

    const { roles, setRoles, users, setUsers } = useContext(RolesContext) as RolesContextType;

    const createRole = async (name: string, description: string, dataToUpdate: any[]) => {
        const _createRole = async () => await di.get<CreateRoleInfoUseCase>(CreateRoleInfoUseCaseName).call(name, description, dataToUpdate);
        const responsePermission = await _createRole();

        return responsePermission;
        
    }

    const _firstSearch = async () => {
        const allPermissions = await _allPermissions();

            if(isRight(allPermissions)){

                let modules = [];
                
                for (let key in allPermissions.right){
                    let counter = 1;
                    let permissionModules: any = [];
                    
                    let policy_name = '';
                    allPermissions.right[key].resources.map((resource: any) => {
                        policy_name = resource;
                        permissionModules.push({
                            name: resource,
                            id: "" + counter,
                            canAssign: false,
                            canCreate: false,
                            canDelete: false,
                            canEdit: false,
                            canView: false,
                        });
                        counter++;
                    })

                    if(key !== undefined){

                    }
                    
                    modules.push({
                        permissions: permissionModules,
                        name: key,
                    })
                }
                setRoleInfoById({
                    modules: modules
                })
                setisContentLoading(false);
                
                setAllPermissions((allPermissions.right) as unknown as RolePermissionsGroups);
            }else {
                setisContentLoading(false);
                setisContentLoading(false);
                addToast(allPermissions.left.message ? allPermissions.left.message : ' ', 'error', null, allPermissions.left.message?.toLocaleLowerCase().replaceAll(' ', '') === 'failedtofetch' ? false : true);
            }
        
    }

    const handleUpdatePermissions = (value: ModulePermision[] = [], policy: any) => {
        
        let permisions = value.map((el: any) => {
            return [
                el.canAssign === true ? `Assign${el.name}`:'',
                el.canCreate === true ? `Create${el.name}`:'',
                el.canDelete === true ? `Delete${el.name}`:'',
                el.canView === true ? `View${el.name}`:'',
                el.canEdit === true ? `Edit${el.name}`:'',
            ]
        }).flat().filter((permission: string) => permission !== '');

    
        let modulesUpdate = roleInfoById?.modules.map((module: any) => {

            if (module.name.toLowerCase().replaceAll(" ", "_") === policy?.toLowerCase().replaceAll(" ", "_")) {
                let policiesCast = {
                    "resource": (module.name === 'Roles And Permissions' ? 'User Management' : module.name).replaceAll("_", " "),
                    "permissions": permisions,
                };
                

                return policiesCast;
            }
            

        }).filter((item: any) => item !== undefined);

        let array = updatedData;
        let replacement = modulesUpdate[0];

        let index = array.findIndex(item => item?.resource === replacement?.resource);

        if (index !== -1) {
            array[index] = replacement;
        } else {
            array = [...array, replacement]
        }

        array = array.filter(item => item !== undefined);

        if (array?.length === 0) {
            setUpdatedData([
                replacement
            ]); 
        } else {
            setUpdatedData([
                ...array
            ]); 
        }

        
        
    };

    const handleSaveUpdate = async () => {
        setIsLoading(true);
        const dataToUpdate = updatedData.filter(item => item !== undefined);
        if (nameValue !== '' && descriptionValue !== '') {
            setRoles([]);

            const reponse = await createRole(nameValue.replaceAll(" ", "_"), descriptionValue, dataToUpdate);

            if(isRight(reponse)){
                setIsLoading(false);
                navigate(routes.rolesAndPermissions.relativePath + "", { replace: true });
                setIsLoading(false);
            } else {
                addToast(reponse.left.message ? reponse.left.message : ' ', 'error', null, reponse.left.message?.toLocaleLowerCase().replaceAll(' ', '') === 'failedtofetch' ? false : true);
            }
            
            setIsLoading(false);
            
        } else {
            setIsLoading(false);
            if (descriptionValue === '') {
                addToast('The description field must not be empty', "error", undefined);
            }
            if (nameValue === '') {
                addToast('The name field must not be empty', "error", undefined);
            }
        }
        
        
    }

    useEffect(() => {
        _firstSearch();
    }, [])

    return <div className="role_edit_permissions_page_props">
        {
            roleInfoById ? !isLoading ?
            <>
                <div className="create_modal_component_content">
                    <p className="create_modal_component_content_titles">Name</p>
                    <textarea
                        className="create_modal_component_content_input_name"
                        name=""
                        id=""
                        spellCheck="false"
                        onChange={(e) => setNameValue(e.target.value)}
                        value={nameValue}
                    >
                    </textarea>
                    <p className="create_modal_component_content_titles">Description</p>
                    <textarea 
                        className="create_modal_component_content_input_description"
                        spellCheck="false"
                        onChange={(e) => setDescriptionValue(e.target.value)}
                        value={descriptionValue}
                    >
                    </textarea>
                </div>
                <div style={{height: "20px"}}></div>
                {
                    <section className="role-modules-policies-container">
                        <p style={{fontWeight: 'bold'}}>Permissions</p>
                        <section>

                            {roleInfoById?.modules.map((moduleItem: any, idx: any) => {
                                let moduleItemCasted = moduleItem as any;
                                
                                    {
                                        return <section key={idx}>
                                            <ModulePermisionsEditableComponent moduleItem={moduleItemCasted} handleUpdate={handleUpdatePermissions} allPermissions={allPermissions} />
                                        </section>
                                        
                                    }
                                })
                                
                            }

                        </section>
                        </section>
                }
                
                <section className="role-edition-save-button-section">
                    <button onClick={() => handleSaveUpdate()} className="role-edition-save-button">
                        <Icons.Plus className="role-edition-save-button-icon"/>
                        Save
                    </button>
                </section>
                

            </> : <LoadingComponent />
            :
            isContentLoading ?
                <LoadingComponent />
                :
                <div className='no_data_retrieved'>
                    Policies for Role not found
                </div>
        }
        
    </div>
}

export default RoleCreatePermissionsPage;