import { injectable } from "inversify";
import UserEntity, { UserEntityStatus } from "../../../domain/entities/UserEntity";
import UserRepository from "../../../domain/repositories/UserRepository";
import SearchUsersApiImpl from "./api/impl/SearchUsersApiImpl";
import GetEmployeeByIdApiImpl from "./api/impl/GetEmployeeByIdApiImpl";
import SearchResultEntity from "../../../domain/entities/SearchResultEntity";
import { Either } from "fp-ts/lib/Either";
import ExceptionEntity from "../../../domain/entities/ExceptionEntity";
import UpdateUserRoleIdApiImpl from "./api/impl/UpdateUserRoleIdApiImpl";
import KeyWordLocalization from "../../../ui/providers/localization/dictionaries/KeyWordLocalization";
import SearchAllUsersApiImpl from "./api/impl/SearchAllUsersApiImpl";
import DeleteUsersApiImpl from "./api/impl/DeleteUsersApiImpl";
import SearchAllPositionsApiImpl from "./api/impl/SearchAllPositionsApiImpl";
import SearchAllBUnitsApiImpl from "./api/impl/SearchAllBUnitsApiImpl";
import SearchAllSupervisorsApiImpl from "./api/impl/SearchAllSupervisorsApiImpl";
import UpdateUsersApiImpl from "./api/impl/UpdateUsersApiImpl";
import CreateUsersApiImpl from "./api/impl/CreateUsersApiImpl";

@injectable()
class UserRepositoryImpl implements UserRepository {
    getEmployeeById = (id: string) : Promise<UserEntity | undefined> => GetEmployeeByIdApiImpl(id);
    search = async (word: string, page: number, itemsPerPage: number, filter: {[key: string]:any}): Promise<SearchResultEntity<UserEntity>> => SearchUsersApiImpl(word, page, itemsPerPage, filter);
    searchAgents = async (word: string, page: number, itemsPerPage: number, filter: { [key: string]: any }): Promise<SearchResultEntity<UserEntity>> => SearchUsersApiImpl(word, page, itemsPerPage, filter);
    searchSupervisor = async (word: string, page: number, itemsPerPage: number, filter: { [key: string]: any }): Promise<SearchResultEntity<UserEntity>> => SearchUsersApiImpl(word, page, itemsPerPage, {...filter, [KeyWordLocalization.UserEntityRole]: 'supervisor'});
    updateUserRoleId = async (userId: number, roleId: number): Promise<Either<ExceptionEntity, string>> => UpdateUserRoleIdApiImpl(userId, roleId);
    searchAllUsers = async (id: string): Promise<Either<ExceptionEntity, UserEntity[]>> => SearchAllUsersApiImpl(id);
    deleteUsers = async (users: string[]): Promise<Either<ExceptionEntity, string>> => DeleteUsersApiImpl(users);
    searchUserPositions = async (): Promise<Either<ExceptionEntity, any[]>> => SearchAllPositionsApiImpl();
    searchUserBunits = async (): Promise<Either<ExceptionEntity, any[]>> => SearchAllBUnitsApiImpl();
    searchAllSupervisors = async (): Promise<Either<ExceptionEntity, any[]>> => SearchAllSupervisorsApiImpl();
    updateUsers = async (data: any[]): Promise<Either<ExceptionEntity, string>> => UpdateUsersApiImpl(data);
    createUsers = async (data: any[]): Promise<Either<ExceptionEntity, string>> => CreateUsersApiImpl(data);
}

export default UserRepositoryImpl;