import { injectable } from "inversify";
import GroupRepository from "../../../domain/repositories/GroupRepository";
import GroupEntity from "../../../domain/entities/GroupEntity";
import SearchResultEntity from "../../../domain/entities/SearchResultEntity";
import UserEntity from "../../../domain/entities/UserEntity";
import { userTest } from "../user/UserRepositoryFake";
import Testing from "../../../ui/utils/Testing";
import { Either, right } from "fp-ts/lib/Either";
import ExceptionEntity from "../../../domain/entities/ExceptionEntity";
import CreateGroupApiImpl from "./api/impl/CreateGroupApiImpl";
import DeleteGroupByIdApiImpl from "./api/impl/DeleteGroupByIdApiImpl";

const testGroup: GroupEntity = {
    id: '1',
    name: 'Group A',
    description: '',
    users: Array(10).fill(userTest).map((user, index) => {
        return {
            ...user,
            id: index.toString()
        }
    }),
}
@injectable()
export default class GroupRepositoryFake implements GroupRepository {
    getUsersOfGroup = async (groupdIds: string[]): Promise<Either<ExceptionEntity, UserEntity[]>> => {
        await Testing.sleeper(1000);
        return right(Array(10).fill(userTest));
    }
    searchGroup = async (word: string, page: number, itemsPerPage: number): Promise<SearchResultEntity<GroupEntity>> => {
        await Testing.sleeper(1000);
        if (page > 3) {
            return {
                items: [],
                pages: 4,
                total: itemsPerPage * 4
            }
        }
        return {
            items: Array(itemsPerPage).fill(testGroup),
            pages: page,
            total: itemsPerPage * 3
        }

    }
    createGroup = async (name: string, description: string, users: UserEntity[]): Promise<Either<ExceptionEntity, GroupEntity>> => {
        const response = await CreateGroupApiImpl(name, description, users);
        return response;
    }
    deleteGroup = async (groupdId: string) => DeleteGroupByIdApiImpl(groupdId);
}