import { Auth } from "aws-amplify";

const callAPI = async (url: string, method: string, body: any): Promise<Response> => {
    const session = await Auth.currentSession();
    if(method === 'GET' || method === 'DELETE'){
        const response = await fetch(url, {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': session.getIdToken().getJwtToken() || ''
            }
        });

        return response;
    } else {

        const response = await fetch(url, {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': session.getIdToken().getJwtToken() || ''
            },
            body: JSON.stringify(body),
        });

        return response;

    }

}

export default callAPI;