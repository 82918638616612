import './TrackingCardResultComponentStyles.scss';
import { FC } from "react";
import TrackingCardResultComponentProps from "./TrackingCardResultComponentProps";
import Icons from "../../../../../assets/Icons";

const TrackingCardResultComponent: FC<TrackingCardResultComponentProps> = ({ summary }) => {
    const plannedVsReal = summary.totalReal - summary.totalPlanned;
    const plannedVsRealPercentage = (plannedVsReal / (summary.totalPlanned != 0 ? summary.totalPlanned : 1)) * 100;
    return (
        <div className="tracking_card_result_component">
            <div className="card">
                <div className="card-body">
                    <div className="d-flex align-items-center">
                        <div className="card_icon me-3" style={{ backgroundColor: summary.typeEvent.color + '59' }}>
                            <Icons.TaskList width={30} color={summary.typeEvent.color} />
                        </div>
                        <div className="d-flex flex-column">
                            <span>{summary.typeEvent.name}</span>
                            <div className='d-flex'> <h3>{summary.totalReal.toFixed(1)}</h3>hr</div>
                        </div>
                        <div className={`ms-3 ${plannedVsRealPercentage <= -4 && 'text-danger'} ${plannedVsRealPercentage >= 4 && 'text-success'}`}>{plannedVsRealPercentage.toFixed(1)}%</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TrackingCardResultComponent;