import { Either } from "fp-ts/lib/Either";
import SearchResultEntity from "../entities/SearchResultEntity";
import UserEntity from "../entities/UserEntity";
import ExceptionEntity from "../entities/ExceptionEntity";

export default interface UserRepository {
    search: (word: string, page: number, itemsPerPage: number, filter: {[key: string]:any}) => Promise<SearchResultEntity<UserEntity>>;
    searchSupervisor: (word: string, page: number, itemsPerPage: number, filter: {[key: string]:any}) => Promise<SearchResultEntity<UserEntity>>;
    searchAgents: (word: string, page: number, itemsPerPage: number, filter: {[key: string]:any}) => Promise<SearchResultEntity<UserEntity>>;
    getEmployeeById: (id: string) => Promise<UserEntity | undefined>;
    updateUserRoleId: (userId: number, roleId: number) => Promise<Either<ExceptionEntity, string>>;
    searchAllUsers: (id: string) => Promise<Either<ExceptionEntity, UserEntity[]>>;
    deleteUsers: (users: string[]) => Promise<Either<ExceptionEntity, string>>;
    searchUserPositions: () => Promise<Either<ExceptionEntity, any[]>>;
    searchUserBunits: () => Promise<Either<ExceptionEntity, any[]>>;
    searchAllSupervisors: () => Promise<Either<ExceptionEntity, any[]>>;
    updateUsers: (data: any[]) => Promise<Either<ExceptionEntity, string>>;
    createUsers: (data: any[]) => Promise<Either<ExceptionEntity, string>>;
}

export const UserRepositoryName = "UserRepositoryName";