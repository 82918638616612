import './CalendarWeekFormComponentStyles.scss';
import { FC, useEffect, useRef, useState } from "react";
import CalendarWeekComponentFormProps from "./CalendarWeekFormComponentProps";
import DateParse from "../../../utils/DateParse";
import Icons from "../../../assets/Icons";
import 'react-calendar/dist/Calendar.css';
import Calendar from "react-calendar";
import { DateOperations } from "../../../utils/DateOperations";
import { LooseValue, Range, Value } from "react-calendar/dist/cjs/shared/types";

const CalendarWeekComponentForm: FC<CalendarWeekComponentFormProps> = ({ onChange, selectedWeek }) => {
    const [openCalendar, setOpenCalendar] = useState<boolean>(false);

    useEffect(() => {
        const handleOutsideClick = (event: MouseEvent) => {
            if (
                calendarContainerRef.current &&
                !calendarContainerRef.current.contains(event.target as Node)
            ) {
                setOpenCalendar(false);
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    const looseValueRange: Range<Date> = [selectedWeek[0], selectedWeek[selectedWeek.length - 1]];

    
    const calendarContainerRef = useRef<HTMLDivElement>(null);

    const _changedDage = (value: Date) => {
        const week = DateOperations.getWeekDays(value as Date);
        onChange(week);
    }

    const _selectNextWeek = () => {
        const first = selectedWeek[0];
        first.setDate(first.getDate() + 7);
        const week = DateOperations.getWeekDays(first);
        onChange(week);
    }

    const _previousWeek = () => {
        const first = selectedWeek[0];
        first.setDate(first.getDate() - 7);
        const week = DateOperations.getWeekDays(first);
        onChange(week);
    }

    const _toggleCalendar = () => setOpenCalendar(!openCalendar);


    return (
    <div className='back_container_week_calendar'>
        <div className="calendar_week_form_component d-flex align-items-center position-relative">
            <Icons.CalendarFilled width={20} />
            <strong className="ms-2 hover month_container" onClick={_toggleCalendar}>
                {DateParse.getMonthOfCalendar(selectedWeek[0])} {selectedWeek[0].getFullYear()}
            </strong>
            <div className='hover' onClick={_previousWeek}>
                <Icons.ChevronLeft />
            </div>
            <div className='hover' onClick={_selectNextWeek}>
                <Icons.ChevronRight />
            </div>
            {openCalendar && <div className="react_calendar_container" ref={calendarContainerRef}>
                <Calendar value={looseValueRange} onClickDay={_changedDage} />
            </div>}
        </div>
    </div>
    
    )
}


export default CalendarWeekComponentForm;