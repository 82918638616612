import { Either, left, right } from "fp-ts/lib/Either";
import ExceptionEntity from "../../../../domain/entities/ExceptionEntity";
import callAPI from "../../fetchCallImplementation";
import di from "../../../../di/DependencyInjection";
import Constants, { ConstantsName } from "../../../../constants/Constants";


const CreateRoleInfoApiImpl = async (name: string, description: string, permisions: any): Promise<Either<ExceptionEntity, string>> => {
    
    try {
        const body = {
            "role_name": name,
            "description": description,
            "permissions": permisions
        }

        const devPath = "https://82jtqezjva.execute-api.us-east-1.amazonaws.com/devapi/";
        const prodPath = "https://8ciupnbqfe.execute-api.us-east-1.amazonaws.com/prod/";
        
        const baseUrl = di.get<Constants>(ConstantsName).HOST_API_1_URL;
        const response = await callAPI(`${baseUrl}/roles`, 'POST', body);

        if (!response.ok) {
            const error = await response.json()
            throw new Error(error.message);
        }

        const value  = await response.body?.getReader().read();
        const result = new TextDecoder().decode(value?.value);

        return right(result);
        
    } catch (error: any) {
        
        const exception: ExceptionEntity = {message: error.message};
        
        return left(exception);
    }
}

export default CreateRoleInfoApiImpl;