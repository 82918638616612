import { AutoCompleteItem } from "../forms/autocompleteWithCheck/AutoCompleteWithCheckProps";

export enum DropDownType {
    MAIN = 'main',
    MAIN_OUTLINE = 'main_outline',
    SECONDARY = 'secondary',
    SECONDARY_OUTLINE = 'secondary_outline',
}
export default interface DropDownComponentProps<T> {
    text: string;
    disabled?: boolean;
    icon?: React.ReactNode;
    onClickIcon?: () => void;
    onSearch: (word: string) => Promise<AutoCompleteItem<T>[]>;
    onSelected?: (value: T) => void;
    colorLine?: string;
    type: DropDownType;
    height?: number | string;
    width?: number | string;
}