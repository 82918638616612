import React from "react";
import MastersContextType from "./MastersContextType";
import TypeEventScheduleEntity from "../../entities/TypeEventScheduleEntity";

const MastersContext = React.createContext<MastersContextType>({
    workTime: undefined,
    setWorkTime: (workTime: TypeEventScheduleEntity | undefined) => { },
    typeSchedules: [],
    setTypeSchedules: (types: TypeEventScheduleEntity[]) => { },
});

export default MastersContext;