import StaffPerShiftDayEntity from "../../../domain/entities/StaffPerShiftDayEntity";
import DateParse from "../../../ui/utils/DateParse";

const fromJSON = (json: any): StaffPerShiftDayEntity => {
    const staffPerShiftDayEntity: StaffPerShiftDayEntity = {
        date: DateParse.stringToDate(json.shift_date),
        absent: {
            count: json.Absent,
            color: '#233746',
        },
        active: {
            count: json.Active,
            color: '#5ab529',
        },
        training: {
            count: json.Training,
            color: '#e6c860',
        },
    }

    return staffPerShiftDayEntity;
}

export default {
    fromJSON,
}