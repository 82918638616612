import './FilterSideBarStyles.scss';
import { FC } from "react";
import FilterSideBarComponentProps from "./FilterSideBarComponentProps";
import { MdClose } from "react-icons/md";
import { FilterOptionType } from '../../../../domain/entities/FilterOptionEntity';
import SwitchFormComponent from '../../forms/switch/SwitchFormComponent';
import DateFormComponent from '../../forms/date/DateFormComponent';
import DateRangeFormComponent from '../../forms/dateRange/DateRangeFormComponent';
import MultiSelectFormComponent from '../../forms/multiSelect/MultiSelectFormComponent';
import { ErrorMessage } from '@hookform/error-message';
import SelectFormComponent from '../../forms/select/SelectFormComponent';
import NumberRangeFormComponent from '../../forms/numberRange/NumberRangeFormComponent';

const FilterSideBarComponent: FC<FilterSideBarComponentProps> = (props) => {
    const { open, onClose, onSubmit, filterOptions, onChange, register, watch, errors, sortOptions } = props;
    return <div className={`filter_side_bar_component ${open ? 'open' : ''}`}>
        <div className="backdrop" onClick={onClose}></div>
        <div className="sidebar">
            <div className="header">
                <div className="title">
                    <h3>Filter</h3>
                </div>
                <div className="close hover">
                    <div onClick={onClose}>
                        <MdClose />
                    </div>
                </div>
            </div>
            <div className="content">
                <div className="form-group">
                    <label>Order By</label>
                    <div className="w-100 my-3">
                        <div className="row">
                            <div className="col-8">
                                <select className="form-control" {...register('sort_by')}>
                                    {sortOptions.map((option, index) => <option key={index} value={option.keyName}>{option.label}</option>)}
                                </select>
                            </div>
                            <div className="col-4">
                                <select className="form-control" {...register('sort_by_order')}>
                                    <option value="ASC">Asc</option>
                                    <option value="DESC">Desc</option>
                                </select>
                            </div>

                        </div>
                    </div>
                </div>
                {filterOptions.map((option, index) => {
                    if (option.type == FilterOptionType.BOOLEAN) {
                        return <div key={index} className="filter_option">
                            <SwitchFormComponent {...props} {...option} />
                        </div>
                    } else if (option.type == FilterOptionType.DATE) {
                        return <div key={index} className="filter_option">
                            <DateFormComponent {...props} {...option} />
                        </div>
                    } else if (option.type == FilterOptionType.RANGE_DATE) {
                        return <div key={index} className="filter_option">
                            <DateRangeFormComponent {...props} {...option} />
                        </div>
                    } else if (option.type == FilterOptionType.SELECT) {
                        return <div key={index} className="filter_option">
                            <SelectFormComponent {...props} {...option} options={option.options ?? []} />
                        </div>
                    } else if (option.type == FilterOptionType.MULTI_SELECT) {
                        return <div key={index} className="filter_option">
                            <MultiSelectFormComponent {...props} {...option} options={option.options ?? []} />
                        </div>
                    } else if (option.type == FilterOptionType.TEXT) {
                        return <div className="filter_option" key={index}>
                            <div className={`form-group ${errors[option.keyName] ? 'error' : ''}`}>
                                <label>{option.label}</label>
                                <input type="text" className="form-control" placeholder={option.label}
                                    {...register(option.keyName)} />
                                <ErrorMessage as="aside" errors={errors} name={option.keyName} />
                            </div>
                        </div>
                    } else if (option.type == FilterOptionType.NUMBER) {
                        return <div className="filter_option" key={index}>
                            <div className={`form-group ${errors[option.keyName] ? 'error' : ''}`}>
                                <label>{option.label}</label>
                                <input type="number" className="form-control" placeholder={option.label}
                                    {...register(option.keyName)} />
                                <ErrorMessage as="aside" errors={errors} name={option.keyName} />
                            </div>
                        </div>
                    } else if (option.type == FilterOptionType.NUMBER_RANGE) {
                        return <div className="filter_option" key={index}>
                            <NumberRangeFormComponent {...props} {...option} />
                        </div>
                    }
                    else return <div key={index}>Not created</div>
                })}
            </div>
            <div className="footer">
                <button className="btn btn_primary w-100" onClick={onSubmit}>Apply</button>
            </div>
        </div>
    </div>
}

export default FilterSideBarComponent;