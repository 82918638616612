import { right } from "fp-ts/lib/Either";
import Constants, { ConstantsName } from "../../../../../constants/Constants";
import di from "../../../../../di/DependencyInjection";
import callAPI from "../../../fetchCallImplementation";

//TODO implement
const GetAIMomentsApiImpl = async (keywords: string[], page: number, type: string, customer: string): Promise<any> => {

    let customerParam = '';
    if (customer) {
        customerParam = `&customer=${customer}`
    }
    
    const baseUrl = di.get<Constants>(ConstantsName).AI;

    let keywordsToFilter = '';

    if (keywords.length > 0) {
        keywords.forEach(key => {
            keywordsToFilter = keywordsToFilter + `&keyword=${key}`;
        })
    }
    
    const response = await callAPI(baseUrl + `/keywords?page=${page}&per_page=20&type=${type}${keywordsToFilter}${customerParam}`, 'GET', {});

    const result = await response.json();
    
    return right(result);
}

export default GetAIMomentsApiImpl;