import { FC, useContext } from "react";
import BulkImportComponentProps from "./BulkImportComponentProps";
import KeyWordLocalization from "../../providers/localization/dictionaries/KeyWordLocalization";
import ButtonComponent from "../button/ButtonComponent";
import { ButtonType } from "../button/ButtonComponentProps";
import LocalizationContext from "../../../domain/providers/localization/LocalizationContext";
import LocalizationContextType from "../../../domain/providers/localization/LocalizationContextType";
import Icons from "../../assets/Icons";
import { routes } from "../../routes/RoutesComponent";
import { useNavigate } from "react-router-dom";

const BulkImportComponent: FC<BulkImportComponentProps> = () => {
    const {i18n}= useContext(LocalizationContext) as LocalizationContextType;
    const navigate = useNavigate();
    
    const _handleLoadData = () => {
        navigate(routes.data_bulk_loader.path)
    }
    return <ButtonComponent text={i18n(KeyWordLocalization.BulkImportComponent)} onClick={_handleLoadData} type={ButtonType.MAIN} icon={<Icons.AddPeople />} />
}

export default BulkImportComponent;