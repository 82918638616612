import ExceptionEntity from "../../../../../domain/entities/ExceptionEntity";
import { Either, left, right } from "fp-ts/lib/Either";
import UserEntity from "../../../../../domain/entities/UserEntity";
import di from "../../../../../di/DependencyInjection";
import UserHostApi, { UserHostApiName } from "../../../../settings/UserHostApi";

const SearchAllBUnitsApiImpl = async (): Promise<Either<ExceptionEntity, UserEntity[]>> => {
    try {
        const userList = await di.get<UserHostApi>(UserHostApiName).get("/allbus");
        return right(userList.data);
        
    } catch (error: any) {
        const exception: ExceptionEntity = {message: error.message};

        if(error.message === 'User is not valid or is unable to see other users.'){
            return right([]);
        }
        
        return left(exception);
    }
}

export default SearchAllBUnitsApiImpl;