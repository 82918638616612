import './CardRolesComponentStyles.scss';
import { FC } from "react";
import CardRolesComponentProps from "./CardRolesComponentProps";
import ModulePermisionsEditableComponent from '../../../../../components/roles/modulePermisionsEditable/ModulePermisionsEditableComponent';
import ModuleEntity from '../../../../../../domain/entities/ModuleEntity';

const CardRoleseComponent: FC<CardRolesComponentProps> = ({ role, onClick }) => {

    return <div className="card-role-component" onClick={() => onClick(role)}>
        <div className="card-body">
            <section className="d-flex modules-card-title">
                {role.name}
            </section>
            <div className="modules-container">
                {
                    role.modules.map((module: ModuleEntity, idx: number) => {
                        
                        return <ModulePermisionsEditableComponent module={module} key={idx} />
                        
                    })
                }
            </div>
            
        </div>
    </div>
}

export default CardRoleseComponent;