/********************************************************************************
 * File Header - Get Current User Use Casse
 * Created By: Marlon Alejandro Mendez Castañeda
 * Contact: +57 3234686680
 * Date: August 9, 2023
 *
 * Description:
 * Get the current user and set it in the provider. If user is null then sing out
 ********************************************************************************/

import { Either, isRight, left } from "fp-ts/lib/Either";
import UserEntity from "../../entities/UserEntity";
import UserProvider from "../../providers/user/UserProvider";
import AuthRepository from "../../repositories/AuthRepository";
import ExceptionEntity from "../../entities/ExceptionEntity";
import { injectable } from "inversify";
import GetTodayTrackingCalendarUseCase from "../trackingTime/GetTodayTrackingCalendarUseCase";
import di from "../../../di/DependencyInjection";
import GetAllRolesUseCase, { GetAllRolesUseCaseName } from "../roles/GetAllRolesUseCase";
import GetAllPermissionsUseCase, { GetAllPermissionsUseCaseName } from "../roles/GetAllPermissionsUseCase";
import ModuleEntity from "../../entities/ModuleEntity";
import ModulePermision from "../../entities/ModulePermision";
import { DateOperations } from "../../../ui/utils/DateOperations";
import DateParse from "../../../ui/utils/DateParse";
import TrackingTimeProvider from "../../providers/trackingTime/TrackingTimeProvider";

interface props {
    authRepository: AuthRepository, userProvider: UserProvider,
    getTodayTrackingCalendarUseCase: GetTodayTrackingCalendarUseCase,
    trackingTimeProvider: TrackingTimeProvider;
}

@injectable()
export default class GetCurrentUserUseCase {
    _authRepository: AuthRepository;
    _userProvider: UserProvider;
    _getTodayTrackingCalendarUseCase: GetTodayTrackingCalendarUseCase;
    _trackingTimeProvider: TrackingTimeProvider;

    constructor(props: props) {
        this._authRepository = props.authRepository;
        this._userProvider = props.userProvider;
        this._getTodayTrackingCalendarUseCase = props.getTodayTrackingCalendarUseCase;
        this._trackingTimeProvider = props.trackingTimeProvider;
    }




    private _asignRole = async (user: UserEntity): Promise<UserEntity> => {
        const responseRoles = await di.get<GetAllRolesUseCase>(GetAllRolesUseCaseName).call(false);

        if (isRight(responseRoles)) {
            const role = responseRoles.right.find((role) => role.id == user.role_id);
            const permissions = role?.modules.reduce((acc: ModulePermision[], module: ModuleEntity) => {
                return [...acc, ...module.permissions];
            }, []) ?? [];
            //TODO check permisions
            return user;
        } else {
            return user;
        }

    }

    public call = async (): Promise<Either<ExceptionEntity, UserEntity>> => {
        DateOperations.dateUTC();
        const response = await this._authRepository.getCurrentUser();

        if (isRight(response)) {
            this._getTodayTrackingCalendarUseCase.call(response.right.id);
            const permissionsResult = await this._asignRole(response.right);
            this._userProvider.Actions?.setUser(permissionsResult);

        } else {
            this._trackingTimeProvider.Actions?.setLoaded(true);
            this._userProvider.Actions?.setUser(undefined);
        }
        return response;
    };
}

export const GetCurrentUserUseCaseName = "GetCurrentUserUseCaseName";