import UserEntity from "../../../../../domain/entities/UserEntity";
import di from "../../../../../di/DependencyInjection";
import HostApi, { HostApiName } from "../../../../settings/HostApi";
import UserHostApi, { UserHostApiName } from "../../../../settings/UserHostApi";

const CreateGroupApiImpl = async (name: string, description: string, users: UserEntity[]): Promise<any> => {
    const relativeUrl = "/groups";
    const body = {
            name,
            description,
            users
    };

    const groupsList = await di.get<UserHostApi>(UserHostApiName).post(relativeUrl, body);
    
}

export default CreateGroupApiImpl;
