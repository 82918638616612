import { FC } from "react";
import NumberFormComponentProps from "./NumberRangeFormComponentProps";
import { ErrorMessage } from "@hookform/error-message";
import Validators, { ValidatorProps } from "../../../utils/Validators";

const NumberRangeFormComponent: FC<NumberFormComponentProps> = ({ label, keyName, disabled, register, onChange, watch, errors, validatorProps }) => {
    //add validator that has to be >= to the other number
    const _validatorLast: ValidatorProps = {
        ...validatorProps,
        greaterThan: watch(keyName + '.first') as number,
    }

    return <div className="w-100">
        <div className="form-group">
            <label>{label}</label>
            <div className="w-100">
                <div className="row">
                    <div className="col-6">
                        <input type="number" placeholder="Min" className="form-control" {...register(keyName + ".first", Validators(validatorProps!))} />
                    </div>
                    <div className="col-6">
                        <input type="number" placeholder="Max" className="form-control" {...register(keyName + ".last", Validators(_validatorLast))} />
                    </div>
                </div>
                <ErrorMessage errors={errors} name={keyName + '.first'} />
                <ErrorMessage errors={errors} name={keyName + '.last'} />
            </div>
        </div>
    </div>
}

NumberRangeFormComponent.defaultProps = {
    validatorProps: {}
};

export default NumberRangeFormComponent;