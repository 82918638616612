import { injectable } from "inversify";
import EventScheduleEntity from "../../entities/EventScheduleEntity";
import { Either, left } from "fp-ts/lib/Either";
import ExceptionEntity from "../../entities/ExceptionEntity";
import ShiftRepository, { ChangesOfCalendarWeekParam } from "../../repositories/ShiftRepository";
import GetTodayTrackingCalendarUseCase from "../trackingTime/GetTodayTrackingCalendarUseCase";
import UserProvider from "../../providers/user/UserProvider";
import KeyWordLocalization from "../../../ui/providers/localization/dictionaries/KeyWordLocalization";

interface props {
    shiftRepository: ShiftRepository;
    getTodayTrackingCalendarUseCase: GetTodayTrackingCalendarUseCase;
    userProvider: UserProvider;
}
@injectable()
export default class UpdateUserWeekUseCase {
    private _shiftRepository: ShiftRepository;
    private _getTodayTrackingCalendarUseCase: GetTodayTrackingCalendarUseCase;
    private _userProvider: UserProvider;

    public constructor(_: props) {
        this._shiftRepository = _.shiftRepository;
        this._getTodayTrackingCalendarUseCase = _.getTodayTrackingCalendarUseCase;
        this._userProvider = _.userProvider;
    }

    public call = async (email: string, changes: ChangesOfCalendarWeekParam): Promise<Either<ExceptionEntity, void>> => {
        if (!this._userProvider.Actions.user?.permissions?.shift.calendar.update) return left({ code: KeyWordLocalization.UserNotAuthorized });
        const response = this._shiftRepository.updateUserWeek(email, changes);
        if (email == this._userProvider.Actions.user?.email) this._getTodayTrackingCalendarUseCase.call();
        return response;
    }
}

export const UpdateUserWeekUseCaseName = "UpdateUserWeekUseCase";
