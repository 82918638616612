import { injectable } from "inversify";
import GroupRepository from "../../../domain/repositories/GroupRepository";
import GroupEntity from "../../../domain/entities/GroupEntity";
import SearchResultEntity from "../../../domain/entities/SearchResultEntity";
import UserEntity from "../../../domain/entities/UserEntity";
import { userTest } from "../user/UserRepositoryFake";
import Testing from "../../../ui/utils/Testing";
import SearchGroupApiImpl from "./api/impl/SearchGroupApiImpl";
import CreateGroupApiImpl from "./api/impl/CreateGroupApiImpl";
import { Either } from "fp-ts/lib/Either";
import ExceptionEntity from "../../../domain/entities/ExceptionEntity";
import DeleteGroupByIdApiImpl from "./api/impl/DeleteGroupByIdApiImpl";
import GetUsersOfGroupApiImpl from "./api/impl/GetUsersOfGroupApiImpl";

const testGroup: GroupEntity = {
    id: '1',
    name: 'Group A',
    description: '',
    users: Array(10).fill(userTest),
}
@injectable()
export default class GroupRepositoryImpl implements GroupRepository {
    getUsersOfGroup = GetUsersOfGroupApiImpl;
    searchGroup = async (word: string, page: number, itemsPerPage: number): Promise<SearchResultEntity<GroupEntity>> => SearchGroupApiImpl(word, page, itemsPerPage);
    createGroup = async (name: string, description: string, users: UserEntity[]): Promise<Either<ExceptionEntity, GroupEntity>> => CreateGroupApiImpl(name, description, users);
    deleteGroup = async (groupdId: string): Promise<boolean> => DeleteGroupByIdApiImpl(groupdId);
}