import { injectable } from "inversify";
import ShiftEntity from "../../entities/ShiftEntity";
import MastersProvider from "../../providers/master/MastersProvider";
import EventScheduleEntity from "../../entities/EventScheduleEntity";

interface props {
    mastersProvider: MastersProvider;
}

@injectable()

class GetShiftAsEventsUseCase {
    mastersProvider: MastersProvider;
    constructor(_: props) {
        this.mastersProvider = _.mastersProvider;
    }

    public call = (shift: ShiftEntity): EventScheduleEntity[] => {
        const _getId = (id: string | undefined, index: number) => id ? "_shift_" + id + "_" + index : "_shift_fake_id_" + index;
        const workTimeType = this.mastersProvider.Actions.workTime;
        if (workTimeType == undefined) return [];
        const events: EventScheduleEntity[] = [];
        const eventsFromShift = shift.events.sort((a, b) => a.dateStart.getTime() - b.dateEnd.getTime());
        if (eventsFromShift.length == 0) return [
            {
                dateStart: shift.initHour,
                dateEnd: shift.endHour,
                type: workTimeType,
                name: workTimeType.name,
                id: _getId(shift.id, 0),
            }
        ];

        // fill with work time the spaces between events
        let indexOfShift = 1;
        if (shift.initHour.getTime() < eventsFromShift[0].dateStart.getTime()) {
            events.push({
                dateStart: shift.initHour,
                dateEnd: eventsFromShift[0].dateStart,
                type: workTimeType,
                name: workTimeType.name,
                id: _getId(shift.id, 0),
            });
            indexOfShift++;
        }
        for (let i = 0; i < eventsFromShift.length; i++) {
            const event = eventsFromShift[i];
            const nextEvent = eventsFromShift[i + 1];
            if (nextEvent) {
                const diff = nextEvent.dateStart.getTime() - event.dateEnd.getTime();
                if (diff > 0) {
                    events.push({
                        dateStart: event.dateEnd,
                        dateEnd: nextEvent.dateStart,
                        type: workTimeType,
                        name: workTimeType.name,
                        id: _getId(shift.id, 0),
                    });
                    indexOfShift++;
                }
            }
            events.push(event);
        }

        if (shift.endHour.getTime() > eventsFromShift[eventsFromShift.length - 1].dateEnd.getTime()) {
            events.push({
                dateStart: eventsFromShift[eventsFromShift.length - 1].dateEnd,
                dateEnd: shift.endHour,
                type: workTimeType,
                name: workTimeType.name,
                id: _getId(shift.id, 0),
            });
            indexOfShift++;
        }
        const eventsParsed = events.sort((a, b) => a.dateStart.getTime() - b.dateEnd.getTime());
        return eventsParsed;
    }
}

export default GetShiftAsEventsUseCase;

export const GetShiftAsEventsUseCaseName = "GetShiftAsEventsUseCaseName";