import './EdtiAttendancePageStyles.scss';
import { FC, useContext, useEffect, useState } from "react";
import { set, useForm } from "react-hook-form";
import SelectFormComponent from "../../../components/forms/select/SelectFormComponent";
import AutoCompleteComponent from "../../../components/forms/autocomplete/AutoCompleteComponent";
import { AutoCompleteItem } from "../../../components/forms/autocompleteWithCheck/AutoCompleteWithCheckProps";
import UserEntity from "../../../../domain/entities/UserEntity";
import Validators from "../../../utils/Validators";
import { ErrorMessage } from "@hookform/error-message";
import SearchAgentsUseCase, { SearchAgentsUseCaseName } from "../../../../domain/use_cases/user/SearchAgentsUseCase";
import di from "../../../../di/DependencyInjection";
import GetDayPlannedVsRealUseCase, { GetDayPlannedVsRealUseCaseName } from "../../../../domain/use_cases/trackingTime/GetDayPlannedVsRealUseCase";
import { Either, isLeft } from "fp-ts/lib/Either";
import EventScheduleEntity from "../../../../domain/entities/EventScheduleEntity";
import TrackingClockInteractionEntity from "../../../../domain/entities/TrackingClockInteractionEntity";
import LoadingComponent from "../../../components/LoadingComponent/LoadingComponent";
import NotResultsComponent from "../../../components/notResults/NotResultsComponent";
import CardEventScheduleComponent from "../../../components/calendar/cardEventSchedule/CardEventScheduleComponent";
import DateParse from "../../../utils/DateParse";
import ButtonComponent from "../../../components/button/ButtonComponent";
import Icons from "../../../assets/Icons";
import CardTrackingInteractionComponent from "./components/cardTrackingInteractionComponent/CardTrackingInteractionComponent";
import KeyWordLocalization from '../../../providers/localization/dictionaries/KeyWordLocalization';
import LocalizationContext from '../../../../domain/providers/localization/LocalizationContext';
import LocalizationContextType from '../../../../domain/providers/localization/LocalizationContextType';
import CreateInteractionFormComponent from './components/createInteractionFormComponent/CreateInteractionFormComponent';
import ExceptionEntity from '../../../../domain/entities/ExceptionEntity';
import ModalsContext from '../../../../domain/providers/modal/ModalsContext';
import ModalsContextType from '../../../../domain/providers/modal/ModalsContextType';
import { EditTrackingInteractionAsSupervisorUseCase, EditTrackingInteractionAsSupervisorUseCaseName } from '../../../../domain/use_cases/trackingTime/EditTrackingInteractionAsSupervisorUseCase';
import { AddTrackingInteractionAsSupervisorUseCase, AddTrackingInteractionAsSupervisorUseCaseName } from '../../../../domain/use_cases/trackingTime/AddTrackingInteractionAsSupervisorUseCase';
import AutoCompleteWithCheckComponent from '../../../components/forms/autocompleteWithCheck/AutoCompleteWithCheckComponent';
import UserContext from '../../../../domain/providers/user/UserContext';
import UserContextType from '../../../../domain/providers/user/UserContextType';


let _minDateAttendance: Date;
const _getMinAttendance = () => {
    const today = new Date();
    const limitDay = new Date(today.getFullYear(), today.getMonth(), today.getDate());
    const day = limitDay.getDate();
    if (day >= 0 && day <= 5) {
        limitDay.setDate(16);
        limitDay.setMonth(limitDay.getMonth() - 1);
    } else if (day > 5 && day <= 20) {
        limitDay.setDate(1);
    } else {
        limitDay.setDate(16);
    }
    _minDateAttendance = limitDay;
}

_getMinAttendance();

const EditAttendancePage: FC<{}> = () => {
    const { i18n } = useContext(LocalizationContext) as LocalizationContextType;
    const { openModalCustom } = useContext(ModalsContext) as ModalsContextType;
    const {user} = useContext(UserContext) as UserContextType;
    const formFunctions = useForm();
    const { register, handleSubmit, watch, formState: { errors }, setValue, getValues } = formFunctions;
    const [attendance, setAttendance] = useState<{ planned: EventScheduleEntity, real: TrackingClockInteractionEntity[] }[] | null | undefined>(null);
    const [shiftId, setShiftId] = useState<string | undefined>(undefined);
    const [isEditable, setisEditable] = useState<boolean>(false);

    const _handleEditAttendance = (attendanceEdit: TrackingClockInteractionEntity) => {
        const editAttendance = async (trackingTime: TrackingClockInteractionEntity, comment: string): Promise<Either<ExceptionEntity, void>> => {
            const response = await di.get<EditTrackingInteractionAsSupervisorUseCase>(EditTrackingInteractionAsSupervisorUseCaseName).call(trackingTime, comment);
            if (isLeft(response)) return response;
            else _handleSearchAttendance('');
            return response;
        }
        const dateString = getValues()[KeyWordLocalization.EditAttendancePageDateField];
        const splittedDate = dateString.split('-');
        const dateAsDate = new Date(splittedDate[0], splittedDate[1] - 1, splittedDate[2]);
        openModalCustom("lg", i18n(KeyWordLocalization.EditAttendancePageEditInteraction), <CreateInteractionFormComponent date={dateAsDate} editInteraction={attendanceEdit} handleEditEvent={editAttendance} />);
    }

    const _handleAddAttendance = () => {
        const addAttendance = async (clockIn: Date, clockOut: Date | null, comment: string, typeId: string): Promise<Either<ExceptionEntity, void>> => {
            const response = await di.get<AddTrackingInteractionAsSupervisorUseCase>(AddTrackingInteractionAsSupervisorUseCaseName).call(clockIn, clockOut, comment, typeId, shiftId!);
            if (isLeft(response)) return response;
            else _handleSearchAttendance('');
            return response;
        }
        const dateString = getValues()[KeyWordLocalization.EditAttendancePageDateField];
        const splittedDate = dateString.split('-');
        const dateAsDate = new Date(splittedDate[0], splittedDate[1] - 1, splittedDate[2]);
        openModalCustom("lg", i18n(KeyWordLocalization.EditAttendancePageCreateInteraction), <CreateInteractionFormComponent handleAddEvent={addAttendance} date={dateAsDate} />);
    }


    const _handleSearchAttendance = async (val: any) => {
        const data = getValues();
        if (data[KeyWordLocalization.EditAttendancePageEmployeeField]?.at(0)?.id == null || data[KeyWordLocalization.EditAttendancePageDateField] == "") return;
        setAttendance(undefined);
        const dateOfSearch = new Date(data[KeyWordLocalization.EditAttendancePageDateField]);
        const response = await di.get<GetDayPlannedVsRealUseCase>(GetDayPlannedVsRealUseCaseName).call(data[KeyWordLocalization.EditAttendancePageEmployeeField][0].id, dateOfSearch)
        if (isLeft(response)) {
            setAttendance(null);
            setShiftId(undefined);
        }
        else {
            //console.log('attendance response', response.right);
            setAttendance(response.right);
            setShiftId(response.right[0].planned.shiftId);
        }
    }

    const _handleSearchEmployees = async (word: string): Promise<AutoCompleteItem<UserEntity>[]> => {
        const response = await di.get<SearchAgentsUseCase>(SearchAgentsUseCaseName).call(word, 1, 20, [], []);
        return response.items.map((item) => {
            return {
                label: item.name,
                id: item.id,
                aditionalValue: item,
                image: item.image
            }
        })
    }

    const _getIfIsEditable = () => {
        const dateString = getValues()[KeyWordLocalization.EditAttendancePageDateField];
        if (dateString == "" || dateString == null || _minDateAttendance == null) return false;
        const splittedDate = dateString.split('-');
        const dateAsDate = new Date(splittedDate[0], splittedDate[1] - 1, splittedDate[2]);
        const minLimit = new Date(dateAsDate.getFullYear(), dateAsDate.getMonth(), dateAsDate.getDate() + 5);
        setisEditable(_minDateAttendance <= dateAsDate);
    }

    useEffect(() => {
        _getIfIsEditable();
    }, [attendance]);

    return <div className="edit_attendance_page">
        <div className="page_content">
            <div className="container" style={{ minHeight: (attendance == null || attendance == undefined) && (watch(KeyWordLocalization.EditAttendancePageEmployeeField) == "" || watch(KeyWordLocalization.EditAttendancePageEmployeeField) == null || watch(KeyWordLocalization.EditAttendancePageDateField) == "" || watch(KeyWordLocalization.EditAttendancePageDateField) == null) ? '70vh' : 'unset' }}>
                <div className="row pb-5">
                    <form onSubmit={handleSubmit(_handleSearchAttendance)} >
                        <div className="row">
                            <div className="form-group autocomplete_employee col-4 ">
                                <AutoCompleteWithCheckComponent label={i18n(KeyWordLocalization.EditAttendancePageEmployeeField)} formInfo={formFunctions} required keyName={KeyWordLocalization.EditAttendancePageEmployeeField} onSearch={_handleSearchEmployees} onChange={_handleSearchAttendance} multiple={false} />
                            </div>
                            <div className="form-group col-4 mt-1">
                                <label>{i18n(KeyWordLocalization.EditAttendancePageDateField)}</label>
                                <input type="date" className="form-control"
                                    max={new Date().toISOString().split('T')[0]}
                                    {...register(KeyWordLocalization.EditAttendancePageDateField, Validators({ required: true, onChange: _handleSearchAttendance }))} />
                                <ErrorMessage as="aside" errors={errors} name={KeyWordLocalization.EditAttendancePageDateField} />
                            </div>
                            {attendance != undefined && isEditable && user?.permissions?.attendance?.create &&
                                <div className="col-4 d-flex justify-content-end align-items-end">
                                    <ButtonComponent icon={<Icons.Plus />} onClick={_handleAddAttendance} text={i18n(KeyWordLocalization.EditAttendancePageAddAttendance)} width={'100%'} />
                                </div>}
                            {attendance != undefined && !isEditable &&
                                <div className="col-4 d-flex flex-column justify-content-center align-items-center text_justify">
                                    <Icons.Clock height={20} /> {i18n(KeyWordLocalization.EditAttendancePageEditionNotAllowedBefore, { date: DateParse.getDateBeauty(_minDateAttendance) })}
                                </div>}
                        </div>
                    </form>
                </div>

                {attendance === undefined && watch(KeyWordLocalization.EditAttendancePageEmployeeField) != "" && watch(KeyWordLocalization.EditAttendancePageDateField) != "" && <LoadingComponent />}
                {attendance === undefined && (watch(KeyWordLocalization.EditAttendancePageEmployeeField) == "" || watch(KeyWordLocalization.EditAttendancePageDateField) == "") && <h4>{i18n(KeyWordLocalization.EditAttendancePageSearchFirstTime)}</h4>}
                {attendance === null && attendance !== undefined && <NotResultsComponent />}
                {attendance != null && attendance.length > 0 && <div className="row">
                    <div className="wrapper_horizontal">
                        <div className="content_tasks">
                            <div className="row">
                                <strong className="col-3 text-center">{i18n(KeyWordLocalization.EditAttendancePagePlanned)}</strong>
                                <strong className="col-3 text-center">{i18n(KeyWordLocalization.EditAttendancePageReal)}</strong>
                                <strong className="col-3 text-center">{i18n(KeyWordLocalization.EditAttendancePageSupervisorActions)}</strong>
                                <strong className="col-3 text-center">{i18n(KeyWordLocalization.EditAttendancePageSupervisorHistoric)}</strong>
                            </div>
                            {attendance.map((item, index) =>
                                <div className="row" key={index}>
                                    <div className="line_middle">
                                        <div className="box_white"><Icons.Clock width={24} className='mx-2' /> {DateParse.timeWithPmAm(item.planned.dateStart)}</div>
                                    </div>
                                    <div className="col-3">
                                        <CardEventScheduleComponent event={item.planned} />
                                    </div>
                                    <div className="col-9">
                                        {item.real.map((real, indexReal) => <div className="row" key={indexReal}>
                                            <div className="col-4 mb-3">
                                                <CardTrackingInteractionComponent interaction={real} />
                                            </div>
                                            <div className="col-4  mb-3">
                                                <div>
                                                    {isEditable && user?.permissions?.attendance?.update ?
                                                        <ButtonComponent icon={<Icons.EditPencil />} onClick={() => _handleEditAttendance(real)} text={i18n(KeyWordLocalization.EditAttendancePageEditAttendance)} /> :
                                                        <div className="text-center">
                                                            {i18n(KeyWordLocalization.EditAttendancePageEditionNotAllowed)}
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-4 mb-3">
                                                {(real.editions ?? "") != "" ? real.editions : i18n(KeyWordLocalization.EditAttendancePageNoEditions)}
                                                {/* {(real.editions?.length ?? 0) > 0 && real.editions!.map((edition, editionIndex) => <div key={editionIndex}>
                                                    <strong> {edition.supervisor.name} - {DateParse.timeWithPmAm(edition.createdAt)}</strong>
                                                    <p> <strong>
                                                        {edition.action}:
                                                    </strong> {edition.coment}</p>
                                                </div>)} */}
                                            </div>
                                        </div>)}
                                    </div>
                                </div>
                            )}
                        </div>

                    </div>
                </div>}
            </div >
        </div >
    </div >
}

export default EditAttendancePage;