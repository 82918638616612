import di from "../../../di/DependencyInjection";
import TrackingClockInteractionEntity from "../../../domain/entities/TrackingClockInteractionEntity";
import TypeEventScheduleEntity from "../../../domain/entities/TypeEventScheduleEntity";
import MastersProvider, { MastersProviderName } from "../../../domain/providers/master/MastersProvider";
import DateParse from "../../../ui/utils/DateParse";

const fromJson = (json: any): TrackingClockInteractionEntity => {
    const mastersProvider = di.get<MastersProvider>(MastersProviderName);
    const workTimeType = mastersProvider.Actions.typeSchedules.find((type) => type.id == "17" || type.name == "Work Time");
    const typeEvent = mastersProvider.Actions.typeSchedules.find((item: TypeEventScheduleEntity) => item.id === json.tag_type_id) ?? workTimeType;

    return {
        id: json.time_tracking_id,
        activityTypeId: json.activity_type_id,
        clockInTime: DateParse.stringToDate(json.clock_in_time),
        clockOutTime: json.clock_out_time ? DateParse.stringToDate(json.clock_out_time) : null,
        details: json.details,
        shiftId: json.shift_id,
        tagTypeId: json.tag_type_id,
        editions: json.description,
        tagType: typeEvent,
        timeTrackingId: json.time_tracking_id,
    }
}

export default {
    fromJson
}