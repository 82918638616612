import { injectable } from "inversify";
import ShiftRepository from "../../repositories/ShiftRepository";
import SearchResultEntity from "../../entities/SearchResultEntity";
import ShiftEntity from "../../entities/ShiftEntity";

interface props {
  shiftRepository: ShiftRepository;
}

@injectable()
export default class SearchShiftUseCase {
  private shiftRepository: ShiftRepository;

  public constructor(_: props) {
    this.shiftRepository = _.shiftRepository;
  }

  public async call(businessUnitId: string[], supervisorId: string[], employeesIds: string[], groupId: string | undefined, days: Date[], word: string, page: number, itemsPerPage: number): Promise<SearchResultEntity<ShiftEntity>> {
    return this.shiftRepository.searchShifts(businessUnitId, supervisorId, employeesIds, groupId, days, word, page, itemsPerPage);
  }
}

export const SearchShiftUseCaseName = "SearchShiftUseCase";