import React, { useEffect, useState } from 'react';
import ProviderProps from '../../../domain/providers/ProviderProps';
import TrackingTimeContext from '../../../domain/providers/trackingTime/TrackingTimeContext';
import TrackingTimeProvider from '../../../domain/providers/trackingTime/TrackingTimeProvider';
import { injectable } from 'inversify';
import TrackingTimeContextType from '../../../domain/providers/trackingTime/TrackingTimeContextType';
import EventScheduleEntity, { EventScheduleEntityStatus } from '../../../domain/entities/EventScheduleEntity';
import TrackingTimeOnHoldComponent from '../../components/trackingTime/components/trackingTimeOnHold/TrackingTimeOnHoldComponent';
import di from '../../../di/DependencyInjection';
import ModalsProvider, { ModalsProviderName } from '../../../domain/providers/modal/ModalsProvider';
import TrackingTimeEndedTaskComponent from '../../components/trackingTime/components/trackingTimeEndedTask/TrackingTimeEndedTaskComponent';
import TrackingTimeStartTaskComponent from '../../components/trackingTime/components/trackingTimeStartTask/TrackingTimeStartTaskComponent';
import { DateOperations } from '../../utils/DateOperations';
import ShiftEntity from '../../../domain/entities/ShiftEntity';

const _Actions: TrackingTimeContextType = {
  text: "",
  isOpen: false,
  setIsOpen: (isOpen: boolean) => { },
  todayCalendar: undefined,
  setTodayCalendar: (todayCalendar: ShiftEntity) => { },
  status: undefined,
  setStatus: (status: EventScheduleEntityStatus) => { },
  currentEvent: undefined,
  setCurrentEvent: (currentEvent: EventScheduleEntity | undefined) => { },
  nextEvent: undefined,
  currentProgress: 0,
  loaded: false,
  setLoaded: (loaded: boolean) => {},
}
var interval: any = undefined;


const _Provider = ({ children }: ProviderProps) => {
  const [todayCalendar, _setTodayCalendar] = useState<ShiftEntity | undefined>(undefined);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [text, setText] = useState<string>("loading..."); 
  const [loaded, setLoaded] = useState<boolean>(false);
  const setTodayCalendar = (todayCalendar: ShiftEntity) => {
    const orderedByDateStart = todayCalendar.events.sort((a, b) => {
      const aDateStart = new Date(a.dateStart);
      const bDateStart = new Date(b.dateStart);
      return aDateStart.getTime() - bDateStart.getTime();
    }
    );
    _setTodayCalendar({ ...todayCalendar, events: orderedByDateStart });

    //set current event
    const now = DateOperations.dateUTC();
    //first where time start is before than now, and where endedAt EventScheduleEntityStatus == EventScheduleEntityStatus.running
    const _currentEvent = orderedByDateStart.find((event) => {
      const eventStartDate = new Date(event.dateStart);
      return eventStartDate < now && (event.status == EventScheduleEntityStatus.running || event.status == EventScheduleEntityStatus.paused || event.status == EventScheduleEntityStatus.pending);
    });
    setCurrentEvent(_currentEvent);
    const nextResponse = getNextEvent(_currentEvent, orderedByDateStart);
  }
  const [status, setStatus] = useState<EventScheduleEntityStatus | undefined>(undefined);
  const [currentEvent, _setCurrentEvent] = useState<EventScheduleEntity | undefined>(undefined);
  const [nextEvent, setNextEvent] = useState<EventScheduleEntity | undefined>(undefined);
  const [currentProgress, setCurrentProgress] = useState<number>(0);
  const [firstLoad, setFirstLoad] = useState<boolean>(true);

  const setCurrentEvent = (event: EventScheduleEntity | undefined) => {
    _setCurrentEvent(event);
    _loadTimer();
    getMissingTimeToEndCurrentTask();
  }

  let timerModalNext: any;

  const openModalNext = () => {
    clearTimeout(timerModalNext);
    if (currentEvent?.status == EventScheduleEntityStatus.running) return;
    di.get<ModalsProvider>(ModalsProviderName).Actions.openModalCustom('lg', '', <TrackingTimeStartTaskComponent event={currentEvent ?? nextEvent} />, false);
  }

  const openModalOvertimed = () => {
    clearTimeout(timerModalNext);
    if (currentEvent?.status == EventScheduleEntityStatus.ended) return;
    di.get<ModalsProvider>(ModalsProviderName).Actions.openModalCustom('lg', '', <TrackingTimeEndedTaskComponent event={currentEvent ?? nextEvent} />, false);
  }

  const _setNextTaskAlarm = (_currentEvent: EventScheduleEntity | undefined, _nextEvent: EventScheduleEntity | undefined) => {
    //if url is different of localhost or staggin.itelligence.com return
    //TODO remove when Kathe test
    if (window.location.href.indexOf('localhost') == -1 && window.location.href != 'https://staging.itelligencecx.com/') return;
    clearTimeout(timerModalNext);
    const now = DateOperations.dateUTC();
    if (_currentEvent?.status == EventScheduleEntityStatus.paused) return;
    else if (_currentEvent?.status == EventScheduleEntityStatus.running) {
      //get time to end
      const diff = _currentEvent.dateEnd.getTime() - now.getTime();
      if (diff <= 0) {
        if (firstLoad) {
          setFirstLoad(false);
        } else {
          return;
        }
        openModalOvertimed();
        return;
      } else {
        timerModalNext = setTimeout(() => {
          setFirstLoad(false);
          openModalOvertimed();
        }, diff);
      }
    } else if (_currentEvent?.status == EventScheduleEntityStatus.pending) {
      //get time to start
      const diff = _currentEvent.dateStart.getTime() - now.getTime();
      if (diff <= 0) {
        if (firstLoad) {
          setFirstLoad(false);
        } else {
          timerModalNext = setTimeout(() => {
            openModalNext();
          }, 60000);
          return;
        }
        openModalNext();
        return;
      } else {
        timerModalNext = setTimeout(() => {
          setFirstLoad(false);
          openModalNext();
        }, diff);
      }

    } else if (_currentEvent == undefined && _nextEvent != undefined) {
      //get time to start
      const diff = _nextEvent.dateStart.getTime() - now.getTime();
      if (diff <= 0) {
        if (firstLoad) {
          setFirstLoad(false);
        } else {
          timerModalNext = setTimeout(() => {
            setFirstLoad(false);
            openModalNext();
          }, 60000);

          return;
        }
        openModalNext();
        return;
      } else {
        timerModalNext = setTimeout(() => {
          setFirstLoad(false);
          openModalNext();
        }, diff);
      }
    }

  }

  const getNextEvent = (_currentEvent: EventScheduleEntity | undefined, orderedByDateStart: EventScheduleEntity[]): EventScheduleEntity | undefined => {
    if (orderedByDateStart.length <= 0) {
      return undefined;
    }
    //get next event
    const indexOfCurrentEvent = orderedByDateStart.findIndex((event) => event.id == _currentEvent?.id);
    if (indexOfCurrentEvent + 1 >= orderedByDateStart.length) return undefined;
    //slice events in index
    const nextEvents = orderedByDateStart.slice(indexOfCurrentEvent + 1);
    //get first event where status is not ended
    const nextEvent = nextEvents.find((event) => event.status != EventScheduleEntityStatus.ended);
    setNextEvent(nextEvent);
    calculateProgress(currentEvent);
    return nextEvent;
  }

  const getMissingTimeToEndCurrentTask = () => {
    if (currentEvent == undefined) {
      if (nextEvent == undefined) return setText("No tasks");
      else return setText("Tasks pending");
    }
    if (currentEvent.status == EventScheduleEntityStatus.pending) return setText("Task pending");
    if (currentEvent.status == EventScheduleEntityStatus.paused) return setText("Task paused");

    const now = DateOperations.dateUTC();
    const end = new Date(currentEvent.dateEnd);
    const diff = Math.abs(end.getTime() - now.getTime());

    const hours = Math.floor(diff / (1000 * 60 * 60));
    const minutes = Math.floor((diff / (1000 * 60)) % 60);
    const seconds = Math.floor((diff / 1000) % 60);
    const _plusZero = (value: number) => value >= 10 ? value : '0' + value;
    calculateProgress(currentEvent);
    setText(`${_plusZero(hours)}:${_plusZero(minutes)}:${_plusZero(seconds)}`);
  }

  const calculateProgress = (currentEvent: EventScheduleEntity | undefined): number => {
    if (currentEvent?.status != EventScheduleEntityStatus.running) return 0;
    const _dateStart = new Date(currentEvent.dateStart);
    const _dateEnd = new Date(currentEvent.dateEnd);
    const _dateNow = DateOperations.dateUTC();
    const _totalTime = _dateEnd.getTime() - _dateStart.getTime();
    const _timeElapsed = _dateNow.getTime() - _dateStart.getTime();
    const calculated = Math.round((_timeElapsed / _totalTime) * 100);
    setCurrentProgress(calculated);
    return calculated;
  }

  _Actions.text = text;
  _Actions.isOpen = isOpen;
  _Actions.setIsOpen = setIsOpen;
  _Actions.todayCalendar = todayCalendar;
  _Actions.setTodayCalendar = setTodayCalendar;
  _Actions.status = status;
  _Actions.setStatus = setStatus;
  _Actions.currentEvent = currentEvent;
  _Actions.setCurrentEvent = setCurrentEvent;
  _Actions.nextEvent = nextEvent;
  _Actions.loaded = loaded;
  _Actions.setLoaded = setLoaded;

  const _loadTimer = () => {
    if (currentEvent == undefined || currentEvent.status != EventScheduleEntityStatus.running) {
      clearInterval(interval);
      return;
    }
    if (interval != undefined) clearInterval(interval);
    interval = setInterval(() => {
      getMissingTimeToEndCurrentTask();
    }, 1000);
  };

  const _handleChangeStatus = () => {
    if (currentEvent?.status == EventScheduleEntityStatus.paused)
      di.get<ModalsProvider>(ModalsProviderName).Actions.openModalCustom('lg', '', <TrackingTimeOnHoldComponent currentEvent={currentEvent} />)
  }
  useEffect(() => {
    _setNextTaskAlarm(currentEvent, nextEvent);
    _handleChangeStatus();
    getMissingTimeToEndCurrentTask();
    _loadTimer();
  }, [currentEvent, nextEvent]);

  useEffect(() => {
  }, [currentEvent]);

  useEffect(() => {
  }, [nextEvent]);

  return (
    <TrackingTimeContext.Provider value={{ loaded, setLoaded, currentProgress, text, isOpen, setIsOpen, todayCalendar, setTodayCalendar, status, setStatus, currentEvent, setCurrentEvent, nextEvent }}>
      {children}
    </TrackingTimeContext.Provider>
  );
};

@injectable()
class TrackingTimeProviderImpl implements TrackingTimeProvider {
  public context = TrackingTimeContext;

  public Provider = _Provider;

  Actions = _Actions
}

export default new TrackingTimeProviderImpl();

