import { injectable } from "inversify";
import UserEntity from "../../entities/UserEntity";
import UserRepository from "../../repositories/UserRepository";
import { Either } from "fp-ts/lib/Either";
import ExceptionEntity from "../../entities/ExceptionEntity";

interface props { allSupervisorsRepository: UserRepository }

@injectable()
export default class SearchAlSupervisorsUseCase {
    _allSupervisorsRepository: UserRepository;

    constructor(_: props) {
        this._allSupervisorsRepository = _.allSupervisorsRepository;
    }

    public call = async () => new Promise<Either<ExceptionEntity, any[]>>(async (resolve, reject) => {
        try {
            const response = await this._allSupervisorsRepository.searchAllSupervisors();
            return resolve(response);
        } catch (error) {
            return reject();
        }
    });
}

export const SearchAlSupervisorsUseCaseName = "SearchAlSupervisorsUseCaseName";