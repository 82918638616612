import { injectable } from "inversify";
import AIRepository from "../../repositories/AIRepository";

interface props {
    aiRepository: AIRepository;
}

@injectable()
export class GetAISummariesUseCase {
    _aiRepository: AIRepository;

    constructor(_: props) {
        this._aiRepository = _.aiRepository;
    }

    public call = async (params: string): Promise<any> => {
        return await this._aiRepository.getAISumaries(params);
    }
}

export const GetAISummariesUseCaseName = "GetAISummariesUseCaseName";