import { right } from "fp-ts/lib/Either";
import Constants, { ConstantsName } from "../../../../../constants/Constants";
import di from "../../../../../di/DependencyInjection";
import callAPI from "../../../fetchCallImplementation";
import { left } from "fp-ts/lib/EitherT";

//TODO implement
const UpdateAISummaryByRecordingApiImpl = async (recording: string, content: any): Promise<any> => {
    
    const baseUrl = di.get<Constants>(ConstantsName).AI;
    const response = await callAPI(baseUrl + `/get/parsedFiles/${recording}`, 'PUT', content);

    if (!response.ok) {
        const error = await response.json();
        return right(error.key);
    }

    const reader = response.body?.getReader();

    if (!reader) {
        throw new Error("Readable stream not supported or body is empty.");
    }
    
    let result = '';
    const decoder = new TextDecoder();

    while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        result += decoder.decode(value, { stream: true });
    }

    // Decoding the last chunk
    result += decoder.decode();
    let data = JSON.parse(result);

    if (data.success) {
        return right(result);
    } else {
        return right('ValidationError');
    }
    
    
}

export default UpdateAISummaryByRecordingApiImpl;