import { Either, isLeft, right } from "fp-ts/lib/Either";
import ExceptionEntity from "../../../../../domain/entities/ExceptionEntity";
import CreateTimeTrackingApiImpl from "./CreateTimeTrackingApiImpl";
import CreateTimeTrackingSwapApiImpl from "./CreateTimeTrackingSwapApiImpl";

//TODO implement
const AddTrackingInteractionAsSupervisorApiImpl = async (clockIn: Date, clockOut: Date | null, comment: string, typeId: string, supervisorId: string, shiftId: string): Promise<Either<ExceptionEntity, void>> => {
    const details = "added_by_supervisor";
    const createTrackingResponse = await CreateTimeTrackingApiImpl(7, clockIn, clockOut, details, parseInt(shiftId), parseInt(typeId));
    if (isLeft(createTrackingResponse)) return createTrackingResponse;
    const newTracking = createTrackingResponse.right;
    //then swap with id
    const responseSwap = await CreateTimeTrackingSwapApiImpl(comment, null, supervisorId, supervisorId, newTracking.id);
    return responseSwap;
}

export default AddTrackingInteractionAsSupervisorApiImpl;