import './ConfirmAccountStyles.scss';
import { ErrorMessage } from "@hookform/error-message";
import { FC, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import ModalsContextType from '../../../../domain/providers/modal/ModalsContextType';
import Validators from '../../../utils/Validators';
import ReactInputVerificationCode from 'react-input-verification-code';
import { routes } from '../../../routes/RoutesComponent';
import di from '../../../../di/DependencyInjection';
import LocalizationContext from '../../../../domain/providers/localization/LocalizationContext';
import LocalizationContextType from '../../../../domain/providers/localization/LocalizationContextType';
import ModalsContext from '../../../../domain/providers/modal/ModalsContext';
import KeyWordLocalization from '../../../providers/localization/dictionaries/KeyWordLocalization';
import ConfirmUserUseCase, { ConfirmUserUseCaseName } from '../../../../domain/use_cases/auth/ConfirmUserUseCase';
import { isRight } from 'fp-ts/lib/Either';
import SendConfirmCodeUseCase, { SendConfirmCodeUseCaseName } from '../../../../domain/use_cases/auth/SendConfirmCodeUseCase';

var interval: any = undefined;

const _countTimer = 20;

const ConfirmAccountPage: FC<{}> = () => {
    const { i18n } = useContext(LocalizationContext) as LocalizationContextType;
    const { addToast } = useContext(ModalsContext) as ModalsContextType;
    const { email } = useParams();
    const navigate = useNavigate();
    const { register, handleSubmit, watch, formState: { errors }, setValue } = useForm();

    const [timer, setTimer] = useState(_countTimer);
    var _timer = _countTimer;

    const onSubmit = async (data: any) => {
        const response = await di.get<ConfirmUserUseCase>(ConfirmUserUseCaseName).call(email!, data.code);
        if (isRight(response)) {
            addToast(i18n(KeyWordLocalization.ConfirmAccountPageConfirmedAccount), 'success', null);
            interval = undefined;
            navigate(routes.signin.relativePath);
        } else {
            addToast(i18n(response.left.code ?? KeyWordLocalization.ConfirmAccountPageErrorConfirmingAccount), 'error', null, true);
        }
    };

    const _countdownFunc = () => {
        _timer = _timer - 1;
        if (_timer <= 0) {
            clearInterval(interval);
            interval = undefined;
            _timer = 0;
            setTimer(0);
            return;
        } else {
            setTimer(_timer);
        }
    }

    const _resendCode = async () => {
        if (timer > 0) return;
        const response = await di.get<SendConfirmCodeUseCase>(SendConfirmCodeUseCaseName).call(email!);
        if (isRight(response)) {
            addToast(i18n(KeyWordLocalization.ConfirmAccountPageResentCode), 'success', null);
        } else {
            addToast(response.left.code ?? i18n(KeyWordLocalization.UnknownError), 'error', null);
        }
        setTimer(_countTimer);
        _timer = _countTimer;
        interval = setInterval(_countdownFunc, 1000);
    }

    useEffect(() => {
        setTimer(_countTimer);
        _timer = _countTimer;
        if (interval == undefined) interval = setInterval(_countdownFunc, 1000);
    }, [email])

    return (
        <div className='update_password_recovery_page'>
            <span className=''>{i18n(KeyWordLocalization.ConfirmAccountPageTitle)}</span>
            <form className="row my-3" onSubmit={handleSubmit(onSubmit)}>
                <div className={`col-12 my-2 mb-4 form-group ${errors.code ? 'error' : ''}`}>
                    <div className="d-flex justify-content-center">
                        <div className="reduced">
                            <ReactInputVerificationCode length={6} onChange={(val) => setValue('code', val)} />
                        </div>
                    </div>
                    <input type="hidden"  {...register('code', Validators({ required: true, mustBeNumber: true, minValue: 0, maxValue: 999999 }))} />
                    <ErrorMessage as="aside" errors={errors} name="code" />
                </div>

                <div className="col-12">
                    <button type="submit" className="btn btn_primary mb-4 w-100">{i18n(KeyWordLocalization.ConfirmAccountPageSubmit)}</button>
                </div>
                <div onClick={_resendCode} className={`${timer > 0 ? 'disabled' : 'hover'} forget_password_link mt-0 small`} >{i18n(KeyWordLocalization.ConfirmAccountPageResendCode, { timer: timer > 0 ? timer : '' })}</div>
            </form>
        </div>
    );
};

export default ConfirmAccountPage;