import { injectable } from "inversify";
import Constants, { ConstantsName } from "../../constants/Constants";
import di from "../../di/DependencyInjection";
import RestApi from "./RestApi";

@injectable()
class UserHostApi extends RestApi {
    public url = di.get<Constants>(ConstantsName).HOST_API_USERS_URL;
}

export default UserHostApi;

export const UserHostApiName = "UserHostApi";