import { FC, useContext, useEffect, useState } from "react";
import TrackingTimeContext from "../../../../../domain/providers/trackingTime/TrackingTimeContext";
import TrackingTimeContextType from "../../../../../domain/providers/trackingTime/TrackingTimeContextType";
import Icons from "../../../../assets/Icons";
import di from "../../../../../di/DependencyInjection";
import GetTodayTrackingCalendarUseCase, { GetTodayTrackingCalendarUseCaseName } from "../../../../../domain/use_cases/trackingTime/GetTodayTrackingCalendarUseCase";
import { DateOperations } from "../../../../utils/DateOperations";


const TrackingTimeClosedComponent: FC<{}> = () => {
    const { isOpen, setIsOpen, currentEvent, status, text } = useContext(TrackingTimeContext) as TrackingTimeContextType;
    const _handleToggle = () => {
        if(!isOpen) {
            //call to get today calendar
            di.get<GetTodayTrackingCalendarUseCase>(GetTodayTrackingCalendarUseCaseName).call();
        }
        setIsOpen(!isOpen);
    }

    const _isOverTime = () => {
        if (currentEvent == undefined) return false;
        const now = DateOperations.dateUTC();
        const end = new Date(currentEvent.dateEnd);
        const diff = end.getTime() - now.getTime();
        return diff <= 0;
    }

    return <div className={`hover time_container d-flex align-items-center ${currentEvent?.status?.toString()} ${_isOverTime() && 'overtimed'}`} onClick={_handleToggle}> <Icons.WorkBag height={16} /> {text} </div>

}

export default TrackingTimeClosedComponent;