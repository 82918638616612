import './TrackingCardTableEventCompareComponentStyles.scss';
import { FC, useContext, useEffect, useState } from "react";
import LocalizationContext from '../../../../../../domain/providers/localization/LocalizationContext';
import LocalizationContextType from '../../../../../../domain/providers/localization/LocalizationContextType';
import KeyWordLocalization from '../../../../../providers/localization/dictionaries/KeyWordLocalization';
import TrackingCardTableEventCompareComponentProps from './TrackingCardTableEventCompareComponentProps';

const TrackingCardTableEventCompareComponent: FC<TrackingCardTableEventCompareComponentProps> = ({ summary }) => {
    const { i18n } = useContext(LocalizationContext) as LocalizationContextType;
    const summaryOrderedByMaximum = summary.sort((a, b) => {
        const maximumA = Math.max(a.totalPlanned, a.totalReal);
        const maximumB = Math.max(b.totalPlanned, b.totalReal);
        return maximumB - maximumA;
    });
    const [maximum, setMaximum] = useState<number>(0);

    const _getMaxmium = () => {
        let max = 0;
        summaryOrderedByMaximum.forEach(item => {
            if (item.totalPlanned > max) {
                max = item.totalPlanned;
            }
            if (item.totalReal > max) {
                max = item.totalReal;
            }
        });
        setMaximum(max);
    }

    useEffect(() => {
        _getMaxmium();
    }, [summary]);

    return <div className="tracking_card_table_event_compare_component">
        <div className="card">
            <div className="px-3 pt-3 header d-flex">
                <div className="flex-grow-1 text_ellipsis me-2">
                    {i18n(KeyWordLocalization.TrackingCardTableEventCompareComponentTitle)}
                </div>

            </div>
            <hr />
            <div className="p-2">
                <div className="w-100">
                    <table className="table table-borderless m-0">
                        <thead>
                            <tr>
                                <th>{i18n(KeyWordLocalization.TrackingCardTableEventCompareComponentTableHeaderEventName)}</th>
                                <th>{i18n(KeyWordLocalization.TrackingCardTableEventCompareComponentTableHeaderPlanned)}</th>
                                <th>{i18n(KeyWordLocalization.TrackingCardTableEventCompareComponentTableHeaderReal)}</th>
                                <th>{i18n(KeyWordLocalization.TrackingCardTableEventCompareComponentTableHeaderDiferencePercentual)}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {summaryOrderedByMaximum.map((item, index) => {
                                let plannedVsRealPercentage = 0;
                                let plannedVsRealPercentageFun = () => {
                                    if (item.totalPlanned == 0 && item.totalReal == 0) {
                                        plannedVsRealPercentage = 0;
                                    } else if (item.totalPlanned == 0) {
                                        plannedVsRealPercentage = 100;
                                    } else if (item.totalReal == 0) {
                                        plannedVsRealPercentage = 0;
                                    } else {
                                        plannedVsRealPercentage = item.totalReal / item.totalPlanned * 100;
                                    }
                                }
                                plannedVsRealPercentageFun();
                                return <tr key={index}>
                                    <td>{item.typeEvent.name}</td>
                                    <td>{item.totalPlanned}</td>
                                    <td>{item.totalReal}</td>
                                    <td>{plannedVsRealPercentage.toFixed(2)}%</td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    </div>
}

export default TrackingCardTableEventCompareComponent;