/********************************************************************************
 * File Header - AWS Sign Up API Implementation
 * Created By: Marlon Alejandro Mendez Castañeda
 * Contact: +57 3234686680
 * Date: August 9, 2023
 *
 * Description:
 * This file contains the implementation of the AWS Sign Up API.
 ********************************************************************************/

import { Auth } from "aws-amplify";
import { Either, left, right } from 'fp-ts/Either';
import ExceptionEntity from "../../../../../domain/entities/ExceptionEntity";
import KeyWordLocalization from "../../../../../ui/providers/localization/dictionaries/KeyWordLocalization";

const SignUpApiImpl = async (email: string, password: string): Promise<Either<ExceptionEntity, void>> => {
  const attributes = {
    email: email,
  };
  try {
    const response = await Auth.signUp({
      username: email,
      password: password,
      attributes: attributes,
    });
    if (!response.userConfirmed) {
      return left({
        code: KeyWordLocalization.AuthRepositoryErrorUserNotConfirmed
      });
    } else {
      return right(undefined);
    }
  } catch (error: any) {
    if(error?.code == "UsernameExistsException") return left({code: KeyWordLocalization.AuthRepositoryErrorUserAlreadyExists});
    return left({});
  }
}

export default SignUpApiImpl;