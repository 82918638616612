import { Either, left } from "fp-ts/lib/Either";
import ExceptionEntity from "../../entities/ExceptionEntity";
import ShiftEntity from "../../entities/ShiftEntity";
import UserEntity from "../../entities/UserEntity";
import ShiftRepository from "../../repositories/ShiftRepository";
import UserProvider from "../../providers/user/UserProvider";
import KeyWordLocalization from "../../../ui/providers/localization/dictionaries/KeyWordLocalization";
import { injectable } from "inversify";
import EventScheduleEntity from "../../entities/EventScheduleEntity";

interface props {
    shiftRepository: ShiftRepository;
    userProvider: UserProvider;
}

@injectable()
export default class AsignShiftUseCase {
    private _shiftRepository: ShiftRepository;
    private _userProvider: UserProvider;

    public constructor(_: props) {
        this._shiftRepository = _.shiftRepository;
        this._userProvider = _.userProvider;
    }

    public call = async (users: UserEntity[], shifts: ShiftEntity[], isBulk: boolean): Promise<Either<ExceptionEntity, void>> => {
        if (!this._userProvider.Actions.user?.permissions?.shift.calendar.create) return left({ code: KeyWordLocalization.UserNotAuthorized });
        const response = await this._shiftRepository.asignShfit(users, shifts);
        return response;
    }
}

export const AsignShiftUseCaseName = "AsignShiftUseCase";