/********************************************************************************
 * File Header - Sign In Use Case
 * Created By: Marlon Alejandro Mendez Castañeda
 * Contact: +57 3234686680
 * Date: August 9, 2023
 *
 * Description:
 * This file contains the call to the login service. and the logic to save the user
 ********************************************************************************/

import { Either, isRight } from "fp-ts/lib/Either";
import UserProvider from "../../providers/user/UserProvider";
import AuthRepository from "../../repositories/AuthRepository";
import ExceptionEntity from "../../entities/ExceptionEntity";
import UserEntity from "../../entities/UserEntity";
import { injectable } from "inversify";
import GetTodayTrackingCalendarUseCase from "../trackingTime/GetTodayTrackingCalendarUseCase";
import di from "../../../di/DependencyInjection";
import LoadUseCase, { LoadUseCaseName } from "../default/LoadUseCase";

interface props {
    authRepository: AuthRepository, userProvider: UserProvider,
    getTodayTrackingCalendarUseCase: GetTodayTrackingCalendarUseCase,
}

@injectable()
class SignInUseCase {

    private _userProvider: UserProvider;
    private _authRepository: AuthRepository;
    private _getTodayTrackingCalendarUseCase: GetTodayTrackingCalendarUseCase;

    constructor(props: props
    ) {
        this._userProvider = props.userProvider;
        this._authRepository = props.authRepository;
        this._getTodayTrackingCalendarUseCase = props.getTodayTrackingCalendarUseCase;
    }

    public call = async (user: string, password: string): Promise<Either<ExceptionEntity, UserEntity>> => {
        const response = await this._authRepository.signIn(user, password);
        
        if (isRight(response)) {
            this._userProvider.Actions.setUser(response.right);
            this._getTodayTrackingCalendarUseCase.call(response.right.id);
            
        } else {
            this._userProvider.Actions.setUser(undefined);
        }
        return response;
    };
}

export default SignInUseCase;

export const SignInUseCaseName = "SignInUseCaseName";