import ModuleEntity from "../../../domain/entities/ModuleEntity";
import ModulePermisionHostDto from "./ModulePermisionHostDto";

const fromJson = (json: any): ModuleEntity => {
    const validJsonString = json.policy.replace(/'/g, '"');
    const jsonPolicy = JSON.parse(validJsonString);

    return {
        id: json.policy_id,
        name: jsonPolicy.resource,
        description: json.description ?? '',
        permissions: ModulePermisionHostDto.multipleFromJson(json.policy),
        policyName: json.policy_name
    }
}

export default {
    fromJson,
}