import { Either, left, right } from "fp-ts/lib/Either";
import ExceptionEntity from "../../../../domain/entities/ExceptionEntity";
import RoleEntity from "../../../../domain/entities/RoleEntity";
import di from "../../../../di/DependencyInjection";
import HostApi, { HostApiName } from "../../../settings/HostApi";
import RoleHostDto from "../../../dto/impl/RoleHostDto";

const GetAllRolesApiImpl = async (): Promise<Either<ExceptionEntity, RoleEntity[]>> => {

    try {
        const response = await di.get<HostApi>(HostApiName).get('/roles/policies');
        const parsedData = response?.map((role: any) => RoleHostDto.fromJson(role)) ?? [];
        return right(parsedData);
    } catch (error: any) {
        const exception: ExceptionEntity = { message: error.message };

        return left(exception);
    }

}

export default GetAllRolesApiImpl;
