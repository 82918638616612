import { Either } from "fp-ts/lib/Either";
import { ChangesOfCalendarWeekParam } from "../../../../../domain/repositories/ShiftRepository";
import ExceptionEntity from "../../../../../domain/entities/ExceptionEntity";
import GraphApi from "../../../../settings/GraphApi";
import DateParse from "../../../../../ui/utils/DateParse";

//TODO implement
const UpdateUserWeekApiImpl = async (email: string, changes: ChangesOfCalendarWeekParam): Promise<Either<ExceptionEntity, void>> => {
    const parsedShiftsToCreate = changes.shiftsCreated.map((shift) => {
        return {
            email: email,
            end_time: DateParse.formatDate(shift.endHour),
            start_time: DateParse.formatDate(shift.initHour),
            eventsToCreate: shift.events.map((event) => {
                return {
                    activity_type_id: 7,
                    end_time: DateParse.formatDate(event.dateEnd),
                    start_time: DateParse.formatDate(event.dateStart),
                    tag_type_id: event.type.id,
                };
            }),
        }
    });
    const parsedEventsToCreate = changes.eventsCreated.filter((event) => event.shiftId != null && event.shiftId != "").map((event) => {
        return {
            shift_id: event.shiftId,
            start_time: DateParse.formatDate(event.dateStart),
            end_time: DateParse.formatDate(event.dateEnd),
            activity_type_id: 7,
            tag_type_id: event.type.id,
        };
    });

    const eventsToDelete = [...changes.eventsDeleted, ...changes.shiftsDeleted.flatMap((shift) => shift.events)]
        .filter((event) => event.id != null && event.id != "");
    const parsedEventsToDelete = eventsToDelete.map((event) => parseInt(event.id));
    const parsedShiftsToDelete = changes.shiftsDeleted.map((shift) => parseInt(shift.id));

    const parsedShiftsToUpdate = changes.shiftsChanged.filter((shift) => shift.id != null && shift.id != '')
    .map((shift) => {
        return {
            shift_id: shift.id,
            start_time: DateParse.formatDate(shift.initHour),
            end_time: DateParse.formatDate(shift.endHour),
        };
    });

    const parsedEventsToUpdate = changes.eventsChanged.filter((event)=>event.id != null && event.id != "").map((event) => {
        return {
            shift_modifier_id: parseInt(event.id), //verificar si es el id o cual
            start_time: DateParse.formatDate(event.dateStart),
            end_time: DateParse.formatDate(event.dateEnd),
            activity_type_id: 7,
            tag_type_id: event.type.id,
            email: email,
        };
    });

    const data = {
        prev: { email, changes },
        parsed: {
            input: {
                shiftsToCreate: parsedShiftsToCreate,
                shiftsToDelete: parsedShiftsToDelete,
                shiftsToUpdate: parsedShiftsToUpdate,
                eventsToCreate: parsedEventsToCreate,
                eventsToDelete: parsedEventsToDelete,
                eventsToUpdate: parsedEventsToUpdate,
            }
        },
    };
    console.log('update user', data)

    const response = await GraphApi.mutation(
        'manageShifts',
        {
            input: {
                shiftsToCreate: parsedShiftsToCreate,
                shiftsToDelete: parsedShiftsToDelete,
                shiftsToUpdate: parsedShiftsToUpdate,
                eventsToCreate: parsedEventsToCreate,
                eventsToDelete: parsedEventsToDelete,
                eventsToUpdate: parsedEventsToUpdate,
            }
        },
        [
            'message', 'success',
        ]
    )

    return response;


}

export default UpdateUserWeekApiImpl;