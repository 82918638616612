import ModulePermision from "../../../domain/entities/ModulePermision";
import KeyWordLocalization from "../../../ui/providers/localization/dictionaries/KeyWordLocalization";

const fromJson = (json: any): ModulePermision => {
    throw new Error('Not implemented');
}

const multipleFromJson = (jsonString: string): ModulePermision[] => {
    try {
        const validJsonString = jsonString.replace(/'/g, '"');
        const json = JSON.parse(validJsonString);

        const prefixes = ['Assign', 'Create', 'Delete', 'View', 'Edit'];
        let modulesList: string[] = [];
        json.permissions.forEach((permisionName: string) => {
            //if permissionstarts with some prefix remove it
            prefixes.forEach((prefix) => {
                if (permisionName.startsWith(prefix)) {
                    const moduleName = permisionName.replace(prefix, '');
                    if (!modulesList.includes(moduleName)) {
                        modulesList.push(moduleName);
                    }
                }
            });
        });
    
        const parsedModules = modulesList.map((moduleName:string) => {
            return {
                id: moduleName,
                name: moduleName,
                canView: json.permissions.includes(`View${moduleName}`),
                canCreate: json.permissions.includes(`Create${moduleName}`),
                canEdit: json.permissions.includes(`Edit${moduleName}`),
                canDelete: json.permissions.includes(`Delete${moduleName}`),
                canAssign: json.permissions.includes(`Assign${moduleName}`),
            }
        });
    
        return parsedModules;
    } catch (error) {
        return [];
    }


}

export default {
    fromJson,
    multipleFromJson,
}