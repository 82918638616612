import ExceptionEntity from "../../../../../domain/entities/ExceptionEntity";
import { Either, left, right } from "fp-ts/lib/Either";
import callAPI from "../../../fetchCallImplementation";
import Constants, { ConstantsName } from "../../../../../constants/Constants";
import di from "../../../../../di/DependencyInjection";

const extractEmailsAndMessages = (data: any) => {
    const result: any = [];
    data.details.forEach((detail: any) => {
        const userInfo = detail[0]; // Accessing the user information object
        if (userInfo && userInfo.data && userInfo.data.email && userInfo.message && detail[1] !== 200) {
            result.push({
                email: userInfo.data.email,
                message: userInfo.message
            });
        }
    });
    return result;
};

const UpdateUsersApiImpl = async (data: any[]): Promise<Either<ExceptionEntity, string>> => {
    
    try {
        const baseUrl = di.get<Constants>(ConstantsName).HOST_API_USERS_URL;
        const response = await callAPI(`${baseUrl}/usersetup`, 'PUT', data);

        const datatest = await response.json();
        
        if (response.status === 207) {
            const emailsAndMessages = extractEmailsAndMessages(datatest);
            const usersFilterd = emailsAndMessages.map((user: any) => user.email).join(",");
            throw new Error(`Error updating some users: ${usersFilterd}`);
        }
        
        return right(datatest);
        
    } catch (error: any) {
        const exception: ExceptionEntity = {message: error.message};
        
        return left(exception);
    }

}

export default UpdateUsersApiImpl;