import { injectable } from "inversify";
import EventScheduleEntity from "../../entities/EventScheduleEntity";
import { Either } from "fp-ts/lib/Either";
import ExceptionEntity from "../../entities/ExceptionEntity";
import UserProvider from "../../providers/user/UserProvider";
import TrackingTimeRepository from "../../repositories/TrackingTimeRepository";
import TrackingClockInteractionEntity from "../../entities/TrackingClockInteractionEntity";

interface props {
    userProvider: UserProvider;
    trackingTimeRepository: TrackingTimeRepository;
}

@injectable()
export default class GetDayPlannedVsRealUseCase {
    _userProvider: UserProvider;
    _trackingTimeRepository: TrackingTimeRepository;

    constructor(_: props) {
        this._trackingTimeRepository = _.trackingTimeRepository;
        this._userProvider = _.userProvider;
    }

    public call = async (userId: string, date: Date): Promise<Either<ExceptionEntity, { planned: EventScheduleEntity, real: TrackingClockInteractionEntity[] }[]>> => {
        const response = await this._trackingTimeRepository.getDayPlannedVsReal(userId, date);
        return response;
    }
}

export const GetDayPlannedVsRealUseCaseName = "GetDayPlannedVsRealUseCase";
