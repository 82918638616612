import { injectable } from "inversify";
import UserEntity from "../../entities/UserEntity";
import UserRepository from "../../repositories/UserRepository";
import { Either } from "fp-ts/lib/Either";
import ExceptionEntity from "../../entities/ExceptionEntity";

interface props { deleteUsersRepository: UserRepository }

@injectable()
export default class DeleteUsersUseCase {
    _deleteUsersRepository: UserRepository;

    constructor(_: props) {
        this._deleteUsersRepository = _.deleteUsersRepository;
    }

    public call = async (users: string[]) => new Promise<Either<ExceptionEntity, string>>(async (resolve, reject) => {
        try {
            const response = await this._deleteUsersRepository.deleteUsers(users);
            return resolve(response);
        } catch (error) {
            return reject();
        }
    });
}

export const DeleteUsersUseCaseName = "DeleteUsersUseCaseName";