import di from './di/DependencyInjection';
import './App.scss';
import { Provider } from 'inversify-react';
import UserProvider, { UserProviderName } from './domain/providers/user/UserProvider';
import ModalsProvider, { ModalsProviderName } from './domain/providers/modal/ModalsProvider';
import LocalizationProvider, { LocalizationProviderName } from './domain/providers/localization/LocalizationProvider';
import 'bootstrap/dist/css/bootstrap.css';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import RoutesComponent from './ui/routes/RoutesComponent';
import { useEffect } from 'react';
import MastersProvider, { MastersProviderName } from './domain/providers/master/MastersProvider';
import TrackingTimeProvider, { TrackingTimeProviderName } from './domain/providers/trackingTime/TrackingTimeProvider';
import RolesProvider, { RolesProviderName } from './domain/providers/roles/RolesProvider';
import ErrorDetector from './ErrorDetector';

ErrorDetector.load();

function App() {
  const userProvider = di.get<UserProvider>(UserProviderName);
  const localizationProvider = di.get<LocalizationProvider>(LocalizationProviderName);
  const mastersProvider = di.get<MastersProvider>(MastersProviderName);
  const modalProvider = di.get<ModalsProvider>(ModalsProviderName);
  const trackingTimeProvider = di.get<TrackingTimeProvider>(TrackingTimeProviderName);
  const rolesProvider = di.get<RolesProvider>(RolesProviderName);

  return (
    <Provider container={di}>
      <localizationProvider.Provider>
        <userProvider.Provider>
          <mastersProvider.Provider>
            <modalProvider.Provider>
              <trackingTimeProvider.Provider>
                <rolesProvider.Provider>
                  <RoutesComponent />
                </rolesProvider.Provider>
              </trackingTimeProvider.Provider>
            </modalProvider.Provider>
          </mastersProvider.Provider>
        </userProvider.Provider>
      </localizationProvider.Provider>
    </Provider>
  );
}

export default App;
