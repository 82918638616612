import { right } from "fp-ts/lib/Either";
import Constants, { ConstantsName } from "../../../../../constants/Constants";
import di from "../../../../../di/DependencyInjection";
import callAPI from "../../../fetchCallImplementation";

//TODO implement
const UpdateAIMomentByIdApiImpl = async (content: any, keyword_id: number, value: any): Promise<any> => {

    const baseUrl = di.get<Constants>(ConstantsName).AI;
    let body: any = {};

    if (value === undefined) {
        body = {
            description: content.description,
            weight: content.weight
        }
    } else {
        
        body = {
            ...value.value
        }
    }
    
    delete body.key;
    
    const response = await callAPI(baseUrl + `/keywords/${keyword_id}`, 'PUT', body);
    
    const result = await response.json();
    
    return right(result);
}

export default UpdateAIMomentByIdApiImpl;