import { injectable } from "inversify";
import AIRepository from "../../repositories/AIRepository";

interface props {
    aiRepository: AIRepository;
}

@injectable()
export class UpdateAIMomentByIdUseCase {
    _aiRepository: AIRepository;

    constructor(_: props) {
        this._aiRepository = _.aiRepository;
    }

    public call = async (description: string, keyword_id: number, value: any | undefined): Promise<any> => {
        return await this._aiRepository.UpdateAIMomentById(description, keyword_id, value);
    }
}

export const UpdateAIMomentByIdUseCaseName = "UpdateAIMomentByIdUseCaseName";