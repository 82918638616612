import { injectable } from "inversify";
import FilterOptionEntity from "../../entities/FilterOptionEntity";
import UserEntity from "../../entities/UserEntity";
import UserRepository from "../../repositories/UserRepository";
import SearchResultEntity from "../../entities/SearchResultEntity";
import KeyWordLocalization from "../../../ui/providers/localization/dictionaries/KeyWordLocalization";

interface props { userRepository: UserRepository }

@injectable()
export default class SearchAgentsUseCase {
    _userRepository: UserRepository;

    constructor(_: props) {
        this._userRepository = _.userRepository;
    }

    public call = async (word: string, page: number, itemsPerPage: number, businessUnitIds: string[], supervisorsId: string[]) => new Promise<SearchResultEntity<UserEntity>>(async (resolve, reject) => {
        try {
            const filterCop:{[key:string]:any} = {}
            filterCop[KeyWordLocalization.UserEntityBUAssociated] = businessUnitIds;
            filterCop[KeyWordLocalization.UserEntitySupervisor] = supervisorsId;
            const response = await this._userRepository.searchAgents(word, page, itemsPerPage, filterCop);
            return resolve(response);
        } catch (error) {
            return reject();
        }
    });
}

export const SearchAgentsUseCaseName = "SearchAgentsUseCaseName";