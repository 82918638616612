import GetCurrentUserUseCase from "../auth/GetCurrentUserUseCase";
import GetAllTypeOfEventsUseCase from "../calendar/GetAllTypeOfEventsUseCase";

interface props { getCurrentUserUseCase: GetCurrentUserUseCase, getAllTypeOfEventsUseCase: GetAllTypeOfEventsUseCase}
export default class LoadUseCase {
    _getCurrentUserUseCase: GetCurrentUserUseCase;
    _getAllTypeOfEventsUseCase: GetAllTypeOfEventsUseCase;

    constructor(_: props) {
        this._getCurrentUserUseCase = _.getCurrentUserUseCase;
        this._getAllTypeOfEventsUseCase = _.getAllTypeOfEventsUseCase;
    }

    public call = async () => new Promise<void>(async (resolve, reject) => {
        try {
            await Promise.all([
                this._getAllTypeOfEventsUseCase.call(),
                this._getCurrentUserUseCase.call(),
            ]);
            return resolve();
        } catch (error) {
            return reject();
        }
    });
}

export const LoadUseCaseName = "LoadUseCaseName";