import { injectable } from "inversify";
import CalendarRepository, { GetAllTypeEventsResponse } from "../../../domain/repositories/CalendarRepository";
import { Either, right } from "fp-ts/lib/Either";
import EventScheduleEntity, { EventScheduleEntityStatus } from "../../../domain/entities/EventScheduleEntity";
import ExceptionEntity from "../../../domain/entities/ExceptionEntity";
import Testing from "../../../ui/utils/Testing";
import TypeEventScheduleEntity from "../../../domain/entities/TypeEventScheduleEntity";
import DateParse from "../../../ui/utils/DateParse";
import ShiftEntity from "../../../domain/entities/ShiftEntity";

let timer = new Date().getMinutes();
let hour = new Date().getHours();
const testEventSchedule: EventScheduleEntity = {
    id: "1",
    name: "Work time",
    description: "Working for Walmart",
    dateStart: new Date(),
    dateEnd: new Date(),
    status: EventScheduleEntityStatus.pending,
    type: {
        color: '#233746',
        id: "1",
        name: "Work time",
        description: "Working time",
        billable: true,
        payable: false,
        outstanding: false,
    },
}

const testEventBreak: EventScheduleEntity = {
    id: "2",
    name: "Lunch",
    dateStart: new Date(),
    dateEnd: new Date(),
    status: EventScheduleEntityStatus.pending,
    type: {
        color: '#E6D7A3',
        id: "1",
        name: "Lunch time",
        billable: true,
        payable: false,
        outstanding: false,
    },
}

const testEventSchedule2: EventScheduleEntity = {
    id: "3",
    name: "Work time",
    description: "Working for Walmart",
    dateStart: new Date(),
    dateEnd: new Date(),
    status: EventScheduleEntityStatus.pending,
    type: {
        color: '#233746',
        id: "1",
        name: "Work time",
        description: "Working time",
        billable: true,
        payable: false,
        outstanding: false,
    },
}
testEventSchedule.dateStart.setHours(hour); testEventSchedule.dateStart.setMinutes(0); testEventSchedule.dateStart.setSeconds(0);
testEventSchedule.dateEnd.setHours(hour + 4); testEventSchedule.dateEnd.setMinutes(0); testEventSchedule.dateEnd.setSeconds(0);

testEventBreak.dateStart.setHours(hour + 4); testEventBreak.dateStart.setMinutes(0); testEventBreak.dateStart.setSeconds(0);
testEventBreak.dateEnd.setHours(hour + 5); testEventBreak.dateEnd.setMinutes(0); testEventBreak.dateEnd.setSeconds(0);

testEventSchedule2.dateStart.setHours(hour + 5); testEventSchedule2.dateStart.setMinutes(0); testEventSchedule2.dateStart.setSeconds(0);
testEventSchedule2.dateEnd.setHours(hour + 9); testEventSchedule2.dateEnd.setMinutes(0); testEventSchedule2.dateEnd.setSeconds(0);



export const types: TypeEventScheduleEntity[] = [
    {
        color: '#233746',
        id: "1",
        name: "Work",
        description: "Working time",
        billable: true,
        payable: false,
        outstanding: false,
    },
    {
        color: '#E6D7A3',
        id: "2",
        name: "Lunch",
        description: "Lunch time",
        billable: false,
        payable: false,
        outstanding: true,
    },
    {
        color: '#8DED5A',
        id: "3",
        name: "Training",
        description: "Training time",
        billable: true,
        payable: true,
        outstanding: true,
    },
    {
        color: '#E6D7A3',
        id: "4",
        name: "Break",
        description: "Break time",
        billable: false,
        payable: false,
        outstanding: true,
    },
    {
        color: '#E6D7A3',
        id: "5",
        name: "Vacation",
        description: "Vacation time",
        billable: false,
        payable: false,
        outstanding: false,
    },
    {
        color: '#E6D7A3',
        id: "6",
        name: "Sick",
        description: "Sick time",
        billable: false,
        payable: false,
        outstanding: false,
    },
    {
        color: '#E6D7A3',
        id: "7",
        name: "Permission",
        description: "Permission time",
        billable: false,
        payable: false,
        outstanding: false,
    },
]

@injectable()
export default class CalendarRepositoryFake implements CalendarRepository {
    getTypeEvents = async (): Promise<Either<ExceptionEntity, GetAllTypeEventsResponse>> => {
        await Testing.sleeper(1000);
        return right({
            events: types,
            workTime: types[0],
        });
    }
    createEventForUser = async (event: EventScheduleEntity, userId: string): Promise<Either<ExceptionEntity, EventScheduleEntity>> => {
        await Testing.sleeper(1000);
        return right(testEventSchedule);
    }
    updateEvent = async (event: EventScheduleEntity): Promise<Either<ExceptionEntity, EventScheduleEntity>> => {
        await Testing.sleeper(1000);
        return right(testEventSchedule);
    }
    deleteEvent = async (eventId: string): Promise<Either<ExceptionEntity, void>> => {
        await Testing.sleeper(1000);
        return right(undefined);
    }
    getEmployeeCalendar = async (employeeId: string, startDate: Date, endDate: Date): Promise<Either<ExceptionEntity, ShiftEntity[]>> => {
        await Testing.sleeper(1000);
        const days = DateParse.fillArrayOfDates(startDate, endDate);
        return right(days.map((day: Date) => {
            const dayEntity: ShiftEntity = {
                initHour: day,
                endHour: day,
                id: "1",
                shiftGroup: "1",
                users: [],
                events: [testEventSchedule, testEventBreak, testEventSchedule2]
            }
            return dayEntity;
        }));
    }
    getEmployeeTodayCalendar = async (employeeId: string): Promise<Either<ExceptionEntity, ShiftEntity>> => {
        if(window.localStorage.getItem('editing test') == 'null') {
            // window.localStorage.setItem('editing test', '1');
            testEventSchedule.status = EventScheduleEntityStatus.running;
            testEventBreak.status = EventScheduleEntityStatus.pending;
            testEventSchedule2.status = EventScheduleEntityStatus.pending;
        }else if(window.localStorage.getItem('editing test') === '1') {
            // window.localStorage.setItem('editing test', '2');
            testEventSchedule.status = EventScheduleEntityStatus.running;
            testEventBreak.status = EventScheduleEntityStatus.pending;
            testEventSchedule2.status = EventScheduleEntityStatus.pending;
        }else if(window.localStorage.getItem('editing test') === '2') {
            // window.localStorage.setItem('editing test', '3');
            testEventSchedule.status = EventScheduleEntityStatus.ended;
            testEventBreak.status = EventScheduleEntityStatus.pending;
            testEventSchedule2.status = EventScheduleEntityStatus.pending;
        }else if(window.localStorage.getItem('editing test') === '3') {
            // window.localStorage.setItem('editing test', '4');
            testEventSchedule.status = EventScheduleEntityStatus.ended;
            testEventBreak.status = EventScheduleEntityStatus.running;
            testEventSchedule2.status = EventScheduleEntityStatus.pending;
        }else if(window.localStorage.getItem('editing test') === '4') {
            // window.localStorage.setItem('editing test', '5');
            testEventSchedule.status = EventScheduleEntityStatus.ended;
            testEventBreak.status = EventScheduleEntityStatus.ended;
            testEventSchedule2.status = EventScheduleEntityStatus.pending;
        }else if(window.localStorage.getItem('editing test') === '5') {
            // window.localStorage.setItem('editing test', '6');
            testEventSchedule.status = EventScheduleEntityStatus.ended;
            testEventBreak.status = EventScheduleEntityStatus.ended;
            testEventSchedule2.status = EventScheduleEntityStatus.running;
        }else if(window.localStorage.getItem('editing test') === '6') {
            // window.localStorage.removeItem('editing test');
            testEventSchedule.status = EventScheduleEntityStatus.ended;
            testEventBreak.status = EventScheduleEntityStatus.ended;
            testEventSchedule2.status = EventScheduleEntityStatus.ended;
        }

        await Testing.sleeper(1000);
        return right({
            initHour: new Date(),
            endHour: new Date(),
            id: "1",
            shiftGroup: "323",
            users: [],
            events: [testEventSchedule, testEventBreak, testEventSchedule2]
        });
    }
    getAllModifiers = async (businessUnitId: string[], supervisorId: string[], employeesIds: string[], groupId: string | undefined, days: Date[], page: number, itemsPerPage: number): Promise<any> => {
        await Testing.sleeper(1000);
        return {
            items: [],
            pages: 1,
            total: [].length
        }
    }

}


export {
    testEventSchedule,
    testEventBreak,
    testEventSchedule2
}