import React, { useEffect, useState } from 'react';
import ProviderProps from '../../../domain/providers/ProviderProps';
import { injectable } from 'inversify';
import RolesProvider from '../../../domain/providers/roles/RolesProvider';
import RolesContext from '../../../domain/providers/roles/RolesContext';
import { Either } from 'fp-ts/lib/Either';
import ExceptionEntity from '../../../domain/entities/ExceptionEntity';
import RoleEntity from '../../../domain/entities/RoleEntity';

const _Actions: any = {
  users: [],
  setUsers: () => {},
  roles: [],
  setRoles: () => {},
  permissions: [],
  setPermissions: () => {}
}



const _Provider = ({ children }: ProviderProps) => {
  const [users, setUsers] = useState<Either<ExceptionEntity, any[]>>();
  const [roles, setRoles] = useState<Either<ExceptionEntity, RoleEntity[]>>();
  const [permissions, setPermissions] = useState<Either<ExceptionEntity, any[]>>();

  _Actions.users = users;
  _Actions.setUsers = setUsers;
  _Actions.roles = roles;
  _Actions.setRoles = setRoles;
  _Actions.roles = permissions;
  _Actions.setRoles = setPermissions;

  useEffect(() => {
  }, []);

  return (
    <RolesContext.Provider value={{ users, setUsers, roles, setRoles, permissions, setPermissions }}>
      {children}
    </RolesContext.Provider>
  );
};

@injectable()
class RolesProviderImpl implements RolesProvider {
  public context = RolesContext;

  public Provider = _Provider;

  Actions = _Actions
}

export default new RolesProviderImpl();

