import { injectable } from "inversify";
import RoleEntity from "../../entities/RoleEntity";
import ExceptionEntity from "../../entities/ExceptionEntity";
import { Either, isLeft, left, right } from "fp-ts/lib/Either";
import TrackingTimeRepository from "../../repositories/TrackingTimeRepository";
import TrackingClockInteractionEntity from "../../entities/TrackingClockInteractionEntity";
import UserProvider from "../../providers/user/UserProvider";
import KeyWordLocalization from "../../../ui/providers/localization/dictionaries/KeyWordLocalization";
import TrackingEditionEntity from "../../entities/TrackingEditionEntity";

interface props {
    trackingTimeRepository: TrackingTimeRepository;
    userProvider: UserProvider;
}

@injectable()
export class EditTrackingInteractionAsSupervisorUseCase {
    _trackingTimeRepository: TrackingTimeRepository;
    _userProvider: UserProvider;
    constructor(_: props) {
        this._trackingTimeRepository = _.trackingTimeRepository;
        this._userProvider = _.userProvider;
    }
    public call = async (trackingTime: TrackingClockInteractionEntity, comment: string): Promise<Either<ExceptionEntity, void>> => {
        if (this._userProvider.Actions.user?.permissions?.attendance?.create != true) return left({ code: KeyWordLocalization.UserNotAuthorized });
        return this._trackingTimeRepository.editTrackingInteractionAsSupervisor(trackingTime, comment, this._userProvider.Actions.user.id);
    }
};

export const EditTrackingInteractionAsSupervisorUseCaseName = "EditTrackingInteractionAsSupervisorUseCase";