import { injectable } from "inversify";
import UserEntity from "../../entities/UserEntity";
import UserRepository from "../../repositories/UserRepository";
import { Either } from "fp-ts/lib/Either";
import ExceptionEntity from "../../entities/ExceptionEntity";

interface props { allUserRepository: UserRepository }

@injectable()
export default class SearchAllUserUseCase {
    _allUserRepository: UserRepository;

    constructor(_: props) {
        this._allUserRepository = _.allUserRepository;
    }

    public call = async (id: string) => new Promise<Either<ExceptionEntity, UserEntity[]>>(async (resolve, reject) => {
        try {
            const response = await this._allUserRepository.searchAllUsers(id);
            return resolve(response);
        } catch (error) {
            return reject();
        }
    });
}

export const SearchAllUserUseCaseName = "SearchAllUserUseCaseName";