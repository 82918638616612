import { injectable } from "inversify";
import ExceptionEntity from "../../entities/ExceptionEntity";
import { Either, isLeft, right } from "fp-ts/lib/Either";
import TrackingTimeRepository from "../../repositories/TrackingTimeRepository";
import TrackingTimeProvider from "../../providers/trackingTime/TrackingTimeProvider";
import GetTodayTrackingCalendarUseCase from "./GetTodayTrackingCalendarUseCase";
import { EventScheduleEntityStatus } from "../../entities/EventScheduleEntity";

interface props {
    trackingTimeRepository: TrackingTimeRepository;
    trackingTimeProvider: TrackingTimeProvider;
    getTodayTrackingCalendarUseCase: GetTodayTrackingCalendarUseCase;
}

@injectable()
export class PauseClockTrackingUseCase {
    _trackingTimeRepository: TrackingTimeRepository;
    _trackingTimeProvider: TrackingTimeProvider;
    _getTodayTrackingCalendarUseCase: GetTodayTrackingCalendarUseCase;
    constructor(_: props) {
        this._trackingTimeRepository = _.trackingTimeRepository;
        this._trackingTimeProvider = _.trackingTimeProvider;
        this._getTodayTrackingCalendarUseCase = _.getTodayTrackingCalendarUseCase;
    }
    public call = async (reason: string, shiftId: string): Promise<Either<ExceptionEntity, void>> => {
        //end the current task
        const pauseClockResponse = await this._trackingTimeRepository.pauseClock(reason, shiftId);
        if (isLeft(pauseClockResponse)) return pauseClockResponse;
        this._trackingTimeProvider.Actions.setStatus(EventScheduleEntityStatus.paused)
        this._getTodayTrackingCalendarUseCase.call();
        return right(undefined);
    }
};

export const PauseClockTrackingUseCaseName = "PauseClockTrackingUseCaseName";