import './LoadingComponent.scss';
import { FC, useContext } from 'react';
import LoadingComponentProps from './LoadingComponentProps';
import LocalizationContext from '../../../domain/providers/localization/LocalizationContext';
import LocalizationContextType from '../../../domain/providers/localization/LocalizationContextType';
import KeyWordLocalization from '../../providers/localization/dictionaries/KeyWordLocalization';


const LoadingComponent: FC<LoadingComponentProps> = ({ showLogo }) => {
  const {i18n} = useContext(LocalizationContext) as LocalizationContextType;
  return <div className="loading_component">
    {showLogo && <img src="/assets/logos/logo.png" className='logo' alt="" />}
    <strong className='mb-3'>{i18n(KeyWordLocalization.Loading)}</strong>
    <div className="centered_spinner">
      <div className="loading-dots">
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
      </div>
    </div>
  </div>
}

LoadingComponent.defaultProps = {
  showLogo: false
}

export default LoadingComponent;
