/********************************************************************************
 * File Header - Constants
 * Created By: Marlon Alejandro Mendez Castañeda
 * Contact: +57 3234686680
 * Date: March 14, 2024
 *
 * Description:
 * This is the implementation of the Constants interface, and it is used to store the
 * constants of the dev enviroment
 ********************************************************************************/

import Constants from "./Constants";

const ConstantsTest: Constants = {
    GRAPH_API_URL: "https://staezjvchvbxlhctzeoowdhd5q.appsync-api.us-east-1.amazonaws.com/graphql",
    BASE_URL: "https://testing.itelligencecx.com/",
    HOST_API_1_URL: "https://89vd5itpkh.execute-api.us-east-1.amazonaws.com/test",
    HOST_API_2_URL: "https://opl6kxfva3.execute-api.us-east-1.amazonaws.com/test",
    HOST_API_USERS_URL: "https://k2xc0t2pp0.execute-api.us-east-1.amazonaws.com/test",
    GA4: 'G-TM7KCSV579',
    AI: "https://bo1b7mlv0k.execute-api.us-east-1.amazonaws.com/test"
    
}

export default ConstantsTest;
