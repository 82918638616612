import './UserContactInformationComponentStyles.scss';
import { FC } from "react";
import UserContactInformationComponenttProps from './UserContactInformationComponentProps';
import Icons from '../../../../../assets/Icons';

const UserContactInformationComponent: FC<UserContactInformationComponenttProps> = ({ information01, information02 }) => {
    return <div className="user_component_info">
        <div className="user_component_info_content">
            <div style={{ width: 40 }}>
                <Icons.Email />
            </div>
            <div className="flex-grow-1 text_ellipsis text-left">
                {information01}
            </div>
        </div>
        {information02 && <div className="user_component_info_content">
            <div style={{ width: 40 }}>
                <Icons.PhoneOutlined />
            </div>
            <div className="flex-grow-1 text_ellipsis text-left">
                {information02}
            </div>
        </div>}
    </div>
}

export default UserContactInformationComponent;