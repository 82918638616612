import { injectable } from "inversify";
import UserEntity from "../../entities/UserEntity";
import UserRepository from "../../repositories/UserRepository";
import { Either } from "fp-ts/lib/Either";
import ExceptionEntity from "../../entities/ExceptionEntity";

interface props { allUserBUnitssRepository: UserRepository }

@injectable()
export default class SearchUserBUnitsUseCase {
    _allUserBUnitssRepository: UserRepository;

    constructor(_: props) {
        this._allUserBUnitssRepository = _.allUserBUnitssRepository;
    }

    public call = async () => new Promise<Either<ExceptionEntity, UserEntity[]>>(async (resolve, reject) => {
        try {
            const response = await this._allUserBUnitssRepository.searchUserBunits();
            return resolve(response);
        } catch (error) {
            return reject();
        }
    });
}

export const SearchUserBUnitsUseCaseName = "SearchUserBUnitsUseCaseName";