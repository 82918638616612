import { Either, left, right } from "fp-ts/lib/Either";
import ExceptionEntity from "../../../../domain/entities/ExceptionEntity";
import callAPI from "../../fetchCallImplementation";
import Constants, { ConstantsName } from "../../../../constants/Constants";
import di from "../../../../di/DependencyInjection";


const UpdateRoleInfoApiImpl = async (id: string, name: string, description: string): Promise<Either<ExceptionEntity, string>> => {
    
    try {
        const body = {
            'role_name': name.toLowerCase().split(" ").join("_"),
            'description': description,
        }
        const baseUrl = di.get<Constants>(ConstantsName).HOST_API_1_URL;
        const response = await callAPI(`${baseUrl}/roles/${id}`, 'PUT', body);

        if (!response.ok) {
            const error = await response.json()
            throw new Error(error.message);
        }

        const value  = await response.body?.getReader().read();
        const result = new TextDecoder().decode(value?.value);

        return right(result);
        
    } catch (error: any) {
        
        const exception: ExceptionEntity = {message: error.message};
        
        return left(exception);
    }
}

export default UpdateRoleInfoApiImpl;