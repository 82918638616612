import { injectable } from "inversify";
import BusinessUnitRepository from "../../../domain/repositories/BusinessUnitRepository";
import BusinessUnitEntity from "../../../domain/entities/BusinessUnitEntity";
import SearchResultEntity from "../../../domain/entities/SearchResultEntity";
import Testing from "../../../ui/utils/Testing";

const testBu:BusinessUnitEntity = {
    id: "1",
    name: "Walmart",
    logo: 'https://purepng.com/public/uploads/large/purepng.com-walmart-logologobrand-logoiconslogos-251519939045oqelv.png',
}

@injectable()
export default class BusinessUnitRepositoryFake implements BusinessUnitRepository {
    search = async (word: string, page: number, itemsPerPage: number, filter: any) : Promise<SearchResultEntity<BusinessUnitEntity>> => {
        await Testing.sleeper(1000);
        if(page > 3) {
            return {
                items: [],
                pages: 4,
                total: itemsPerPage * 4
            }
        }
        return {
            items: Array(itemsPerPage).fill(testBu).map((bu: BusinessUnitEntity, index: number) => {
                return {
                    ...bu,
                    id: `${index}_${page}`
                }
            }),
            pages: page,
            total: itemsPerPage * 3
        }

    }
}