import { injectable } from "inversify";
import UserEntity from "../../entities/UserEntity";
import UserRepository from "../../repositories/UserRepository";
import { Either } from "fp-ts/lib/Either";
import ExceptionEntity from "../../entities/ExceptionEntity";

interface props { allUserPositionsRepository: UserRepository }

@injectable()
export default class SearchUserPositionsUseCase {
    _allUserPositionsRepository: UserRepository;

    constructor(_: props) {
        this._allUserPositionsRepository = _.allUserPositionsRepository;
    }

    public call = async () => new Promise<Either<ExceptionEntity, UserEntity[]>>(async (resolve, reject) => {
        try {
            const response = await this._allUserPositionsRepository.searchUserPositions();
            return resolve(response);
        } catch (error) {
            return reject();
        }
    });
}

export const SearchUserPositionsUseCaseName = "SearchUserPositionsUseCaseName";