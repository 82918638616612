import { useContext } from "react";
import LocalizationContext from "../../../../domain/providers/localization/LocalizationContext";
import LocalizationContextType from "../../../../domain/providers/localization/LocalizationContextType";
import KeyWordLocalization from "../../../providers/localization/dictionaries/KeyWordLocalization";
import AuthLayoutComponentProps from "./AuthLayoutComponentProps";
import './AuthLayoutStyles.scss';

const AuthLayoutComponent: React.FC<AuthLayoutComponentProps> = ({ children }) => {
    const { i18n } = useContext(LocalizationContext) as LocalizationContextType;
    return (
        <div className="auth_layout_component">
            <img src="/assets/images/auth_person.png" alt="" className="boy_img img-fluid" />
            <div className="card shadow">
                <div className="card-body">
                    <div className="d-flex">
                        <img src="/assets/logos/logo.png" alt="" className="img-fluid img_logo" />
                        <div className="flow-grow-1 d-flex flex-column justify-content-center">
                            <div className='title_app'><div className="text_bold">{i18n(KeyWordLocalization.TitleApp)}</div>{i18n(KeyWordLocalization.TitleApp2)}</div>
                            <span></span>
                        </div>
                    </div>
                    {children}
                </div>
            </div>
        </div>
    );
};

export default AuthLayoutComponent;