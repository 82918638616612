import './SignUpStyles.scss';
import { ErrorMessage } from "@hookform/error-message";
import { FC, useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { routes } from '../../../routes/RoutesComponent';
import ModalsContext from '../../../../domain/providers/modal/ModalsContext';
import ModalsContextType from '../../../../domain/providers/modal/ModalsContextType';
import LocalizationContext from '../../../../domain/providers/localization/LocalizationContext';
import LocalizationContextType from '../../../../domain/providers/localization/LocalizationContextType';
import KeyWordLocalization from '../../../providers/localization/dictionaries/KeyWordLocalization';
import Validators from '../../../utils/Validators';
import di from '../../../../di/DependencyInjection';
import { isRight } from 'fp-ts/lib/Either';
import SignUpUseCase, { SignUpUseCaseName } from '../../../../domain/use_cases/auth/SignUpUseCase';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
import SocialLoginComponent from '../../../components/socialLogin/SocialLoginComponent';

const SignUpPage: FC<{}> = () => {
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const { i18n } = useContext(LocalizationContext) as LocalizationContextType;
    const { addToast } = useContext(ModalsContext) as ModalsContextType;
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState<boolean>(false);

    const onSubmit = async (data: any) => {
        const response = await di.get<SignUpUseCase>(SignUpUseCaseName).call(data.email, data.password);
        if (isRight(response)) {
            navigate(routes.confimr_account.relativePath + '/' + data.email);
        } else {
            if (response.left.code == KeyWordLocalization.AuthRepositoryErrorUserNotConfirmed) {
                navigate(routes.confimr_account.relativePath + '/' + data.email);
            } else {
                addToast(i18n(response.left.code ?? KeyWordLocalization.SignUpPageErrorCreating), 'error', null, true);
            }
        }
    };

    return (
        <div className='sign_in_page'>
            <h5 className='text-center'>{i18n(KeyWordLocalization.SignUpPageTitle)}</h5>
            <form className="row" onSubmit={handleSubmit(onSubmit)}>
                <div className={`col-12 mb-3 form-group ${errors.email ? 'error' : ''}`}>
                    <label>{i18n(KeyWordLocalization.SignUpPageEmail)}</label>
                    <input type='text' className="form-control" placeholder={i18n(KeyWordLocalization.SignUpPageEmail)}
                        {...register('email', Validators({ required: true, email: true }))} />
                    <ErrorMessage as="aside" errors={errors} name="email" />
                </div>
                <div className={`col-12 mb-3 form-group ${errors.password ? 'error' : ''}`}>
                    <label>{i18n(KeyWordLocalization.SignUpPagePassword)}</label>
                    <div className="password_input_wrapper">
                        <input type={showPassword ? 'text' : 'password'} className="form-control" placeholder={i18n(KeyWordLocalization.SignUpPagePassword)}
                            {...register('password', Validators({ required: true, isPassword: true }))} />
                        <div className="visibility_icon" onClick={() => setShowPassword(!showPassword)}>
                            {showPassword ? <MdVisibility /> : <MdVisibilityOff />}
                        </div>
                    </div>
                    <ErrorMessage as="aside" errors={errors} name="password" />
                </div>
                <div className={`col-12 my-2 form-group ${errors.confirm_password ? 'error' : ''}`}>
                    <label>{i18n(KeyWordLocalization.SignUpPagePasswordConfirm)}</label>
                    <div className="password_input_wrapper">
                        <input type={showPassword ? 'text' : 'password'} {...register("confirm_password", Validators({
                            required: true, isPassword: true, validate: (val: string) => {
                                if (watch('password') != val) {
                                    return i18n(KeyWordLocalization.SignUpPagePasswordNotMatch);
                                }
                            },
                        }))} className="form-control" placeholder={i18n(KeyWordLocalization.SignUpPagePasswordConfirm)} />
                        <div className="visibility_icon" onClick={() => setShowPassword(!showPassword)}>
                            {showPassword ? <MdVisibility /> : <MdVisibilityOff />}
                        </div>
                    </div>
                    <ErrorMessage as="aside" errors={errors} name="confirm_password" />
                </div>
                <button type="submit" className="btn btn_primary mt-4">{i18n(KeyWordLocalization.SignUpPageSubmit)}</button>
                <Link to={routes.signin.relativePath} className='mt-4 text-center'>{i18n(KeyWordLocalization.SignUpPageSignIn)}</Link>
            </form>
            <SocialLoginComponent />
        </div >
    );
};

export default SignUpPage;