const getWeekDays = (date: Date): Date[] => {
    //adjust calendar to init in monday
    let day = date.getDay();
    if (day === 0) day = 7;
    let monday = new Date(date.getFullYear(), date.getMonth(), date.getDate() - (day - 1));
    let days: Date[] = [];
    for (let i = 0; i < 7; i++) {
        days.push(new Date(monday.getFullYear(), monday.getMonth(), monday.getDate() + i));
    }
    return days;
}

const getDaysBetweenDates = (initDate: Date, endDate: Date): Date[] => {
    let days: Date[] = [];
    let currentDate = new Date(initDate);
    while (currentDate <= endDate) {
        days.push(new Date(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
    }
    return days;
}

const differenceInMinutes = (date1: Date, date2: Date): number => {
    return (date1.getTime() - date2.getTime()) / 60000;
}

const differenceInDays = (date1: Date, date2: Date): number => {
    return (date1.getTime() - date2.getTime()) / 86400000;
}

const isSameDate = (date1: Date, date2: Date): boolean => {
    return date1.toDateString() === date2.toDateString();
}

const dateUTC = (): Date => {
    const diffOfTime = window.localStorage.getItem('time_difference_server') ?? "0";
    const date = new Date();
    date.setTime(date.getTime() + parseInt(diffOfTime));
    return date;
}

const isBeforeToday = (date: Date): boolean => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return date < today;
}

const isAfterToday = (date: Date): boolean => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    today.setDate(today.getDate() + 1);
    return date >= today;
}

const getDayFromMonday = (date: Date): number => {
    //monday == 0 and sunday == 6
    let day = date.getDay() - 1;
    if (day === -1) day = 6;
    return day;
}

const convertAmPmTo24 = (time: string): string => {
    try {
        //first check if is AM PM format
        if (time.indexOf("AM") === -1 && time.indexOf("PM") === -1) return time;
        let hours = parseInt(time.split(":")[0]);
        let minutes = parseInt(time.split(":")[1].split(" ")[0]);
        let ampm = time.split(" ")[1];
        if (ampm === "PM") {
            hours += 12;
        }
        return `${hours}:${minutes}`;

    } catch (error) {
        return time;
    }
}

export const DateOperations = {
    isSameDate,
    getDaysBetweenDates,
    getWeekDays,
    differenceInDays,
    differenceInMinutes,
    dateUTC,
    getDayFromMonday,
    isBeforeToday,
    isAfterToday,
    convertAmPmTo24,
}