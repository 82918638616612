import { injectable } from "inversify";
import ShiftRepository, { AsingShiftBulkImportResponse, ChangesOfCalendarWeekParam, GetUsersWithShiftResponse } from "../../../domain/repositories/ShiftRepository";
import { Either, right } from "fp-ts/lib/Either";
import EventScheduleEntity from "../../../domain/entities/EventScheduleEntity";
import ExceptionEntity from "../../../domain/entities/ExceptionEntity";
import SearchResultEntity from "../../../domain/entities/SearchResultEntity";
import ShiftEntity from "../../../domain/entities/ShiftEntity";
import UserEntity from "../../../domain/entities/UserEntity";
import { userTest } from "../user/UserRepositoryFake";
import Testing from "../../../ui/utils/Testing";
import CalendarRepositoryFake from "../calendar/CalendarRepositoryFake";
import { DateOperations } from "../../../ui/utils/DateOperations";
import BusinessUnitEntity from "../../../domain/entities/BusinessUnitEntity";
import GroupEntity from "../../../domain/entities/GroupEntity";
import KeyWordLocalization from "../../../ui/providers/localization/dictionaries/KeyWordLocalization";

interface Day {
    day: Date;
    initHour: Date;
    endHour: Date;
    users: UserEntity[];
    rating: number;
    usersGroupStatus?: {
        name: string,
        color: string,
        count: number,
    }[],
}

function timeToDate(timeStr: string) {
    const [hours, minutes] = timeStr.split(':');
    const date = new Date();
    date.setHours(parseInt(hours, 10));
    date.setMinutes(parseInt(minutes, 10));
    date.setSeconds(0); // Optional, setting seconds to 0
    return date;
}

// Given hours
const hours = [
    '08:00 - 18:00',
    '09:00 - 19:00',
    '10:00 - 20:00',
    '11:00 - 21:00',
    '12:00 - 22:00',
    '13:00 - 23:00',
    '14:00 - 24:00'
];



const _generateWeek: () => Day[] = () => {
    const week: Day[] = [];
    const initDate = new Date();
    const today = DateOperations.getWeekDays(initDate)[0];

    for (let i = 0; i < 3; i++) {
         // Array to store Date objects
        const dateArray: any = [];

        // Convert each hour range to Date objects
        hours.forEach(hourRange => {
            const [startTime, endTime] = hourRange.split(' - ');
            dateArray.push({
                start: timeToDate(startTime),
                end: timeToDate(endTime)
            });
        });

        const nextDay = new Date(today);
        nextDay.setDate(today.getDate() + i);
        const initHour = new Date(nextDay);
        initHour.setHours(8);
        const endHour = new Date(nextDay);
        endHour.setHours(17);
        const users = Array(10).fill(userTest);
        const rating = 4.2;
        
        
        week.push({
            day: nextDay,
            initHour,
            endHour,
            users,
            rating,
            usersGroupStatus: [
                {
                    name: 'Supervisor',
                    color: '#233746',
                    count: Math.floor(Math.random() * (2 - 1 + 1)) + 1,
                },
                {
                    name: 'Active',
                    color: '#5AB529',
                    count: Math.floor(Math.random() * (10 - 1 + 1)) + 1,
                },
                {
                    name: 'Training',
                    color: '#E6C860',
                    count: Math.floor(Math.random() * (4 - 1 + 1)) + 1,
                },
            ],
        });
    }
    return week;
}
export const tempShift: ShiftEntity = {
    id: '1',
    endHour: new Date(),
    events: [],
    initHour: new Date(),
    shiftGroup: '333',
    users: Array(10).fill(userTest),
}
@injectable()
export class ShiftRepositoryFake implements ShiftRepository {
    updateUserWeek = async (userId: string, changes: ChangesOfCalendarWeekParam): Promise<Either<ExceptionEntity, void>> => {
        console.log('UpdateUserWeekApiImpl', { userId, changes });
        await Testing.sleeper(1000);
        return Promise.resolve(right(undefined));
    }
    asignShiftBulkImport = async (data: { email: string; shifts: ShiftEntity[]; outEvents: EventScheduleEntity[]}[], onCallBack: (data :AsingShiftBulkImportResponse) => void): Promise<Either<ExceptionEntity, AsingShiftBulkImportResponse>> => {
        const passAllAsGood = false;
        const errorMessages = [KeyWordLocalization.ErrorAsignShiftBulkImportErrorOnShift, KeyWordLocalization.ErrorAsignShiftBulkImportErrorOnUser, KeyWordLocalization.ErrorAsignShiftBulkImportErrorOnTasks];
        const response: AsingShiftBulkImportResponse = {
            doneEmails: [],
            failedEmails: [],
        };
        for (let i = 0; i < data.length; i++) {
            const pass = Math.random() > 0.15;
            await Testing.sleeper(100);
            if (passAllAsGood || pass) {
                response.doneEmails.push(data[i].email);
            } else {
                const errorMessageIndex = Math.floor(Math.random() * 3);
                response.failedEmails.push({
                    email: data[i].email,
                    error: errorMessages[errorMessageIndex]
                });
            }
            onCallBack(response);
        }
        return right(response);
    }
    createMultipleEventsForShift = async (events: EventScheduleEntity[], employees: UserEntity[], shiftGroupId: string): Promise<Either<ExceptionEntity, void>> => {
        await Testing.sleeper(1000);
        return Promise.resolve(right(undefined));
    }
    searchShiftTemplate = async (businessUnitId: string, date: Date[], word: string, page: number, itemsPerPage: number): Promise<SearchResultEntity<ShiftEntity>> => {
        await Testing.sleeper(1000);
        return {
            items: Array(itemsPerPage).fill(tempShift),
            pages: page,
            total: itemsPerPage * 3
        }
    }
    asignShfit = async (users: UserEntity[], shifts: ShiftEntity[]): Promise<Either<ExceptionEntity, void>> => {
        await Testing.sleeper(1000);
        return right(undefined);
    }
    getShiftCalendar = async (shiftId: string, startDate: Date, endDate: Date): Promise<Either<ExceptionEntity, ShiftEntity[]>> => {
        return new CalendarRepositoryFake().getEmployeeCalendar('3', startDate, endDate);
    }
    createShift = async (shift: ShiftEntity): Promise<Either<ExceptionEntity, ShiftEntity>> => {
        await Testing.sleeper(1000);
        return Promise.resolve(right(shift));
    }
    createEventForShift = async (event: EventScheduleEntity, shiftId: string): Promise<Either<ExceptionEntity, EventScheduleEntity>> => {
        await Testing.sleeper(1000);
        return Promise.resolve(right(event));
    }
    updateEventForShift = async (event: EventScheduleEntity, shiftId: string): Promise<Either<ExceptionEntity, EventScheduleEntity>> => {
        await Testing.sleeper(1000);
        return Promise.resolve(right(event));
    }
    searchShifts = async (businessUnitId: string[], supervisorId: string[], employeesIds: string[], groupId: string | undefined, date: Date[], word: string, page: number, itemsPerPage: number): Promise<SearchResultEntity<ShiftEntity>> => {
        await Testing.sleeper(1000);
        return {
            items: Array(itemsPerPage).fill(tempShift),
            pages: page,
            total: itemsPerPage * 3
        }
    }
    searchUsersWithShift = async (businessUnit: BusinessUnitEntity[], supervisors: UserEntity[], employees: UserEntity[], groups: GroupEntity[], startDate: Date, endDate: Date, page: number, itemsPerPage: number): Promise<SearchResultEntity<GetUsersWithShiftResponse>> => {
        await Testing.sleeper(1000);
        if (page > 3) {
            return {
                items: [],
                pages: 4,
                total: itemsPerPage * 4
            }
        }
        return {
            items: Array(itemsPerPage).fill({
                shift: tempShift,
                user: userTest,
            }),
            pages: page,
            total: itemsPerPage * 3
        }
    }
    searchUsersOfShift = async (shiftId: string, word: string, page: number, itemsPerPage: number): Promise<SearchResultEntity<UserEntity>> => {
        await Testing.sleeper(1000);
        return {
            items: Array(itemsPerPage).fill(userTest),
            pages: page,
            total: itemsPerPage * 3
        }
    }
    searchWithUserGroupStatus = async (businessUnit: BusinessUnitEntity[], supervisors: UserEntity[], employees: UserEntity[], groups: GroupEntity[],  days: Date[], page: number, itemsPerPage: number): Promise<any> => {
        await Testing.sleeper(1000);
        return {
            items: Array(itemsPerPage).fill(tempShift),
            pages: page,
            total: itemsPerPage * 3
        }
    }
    getShiftById = async (shiftId: string): Promise<Either<ExceptionEntity, ShiftEntity>> => {
        await Testing.sleeper(1000);
        return right(tempShift);
    }

}