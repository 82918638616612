import { injectable } from "inversify";
import Constants, { ConstantsName } from "../../constants/Constants";
import di from "../../di/DependencyInjection";
import RestApi from "./RestApi";

@injectable()
class Host2Api extends RestApi {
    public url = di.get<Constants>(ConstantsName).HOST_API_2_URL;
}

export default Host2Api;

export const Host2ApiName = "Host2Api";