import { injectable } from "inversify";
import di from "../../di/DependencyInjection";
import ExceptionEntity from "../../domain/entities/ExceptionEntity";
import AuthRepository, { AuthRepositoryName } from "../../domain/repositories/AuthRepository";
import KeyWordLocalization from "../../ui/providers/localization/dictionaries/KeyWordLocalization";

@injectable()
class RestApi {
    public AWS_COGNITO_TOKEN_KEY = "aws_cognito_token";
    public AWS_COGNITO_EMAIL_KEY = "aws_cognito_email";
    public url = "";

    public getToken = () => window.localStorage.getItem(this.AWS_COGNITO_TOKEN_KEY) ?? "";
    public getEmail = () => window.localStorage.getItem(this.AWS_COGNITO_EMAIL_KEY) ?? "";

    public setToken = (token: string) => {
        window.localStorage.setItem(this.AWS_COGNITO_TOKEN_KEY, token);
    };
    public setEmail = (email: string) => window.localStorage.setItem(this.AWS_COGNITO_EMAIL_KEY, email);

    public removeToken = () => window.localStorage.removeItem(this.AWS_COGNITO_TOKEN_KEY);
    public removeEmail = () => window.localStorage.removeItem(this.AWS_COGNITO_EMAIL_KEY);

    public _getHeaders = () => {
        return {
            'Content-Type': 'application/json',
            'Authorization': this.getToken(),
        };
    }

    public _parseError = async (response: Response): Promise<ExceptionEntity> => {
        try {
            const error = await response.json();
            return {
                message: error.message,
                code: 'ErrorHost' + error.key,
            }
        } catch (error) {
            const errorAsText = await response.text();
            const key: string = 'ErrorHost' + errorAsText;
            return {
                message: key,
                code: key in KeyWordLocalization ? (KeyWordLocalization as { [key: string]: string })[key] : KeyWordLocalization.UnknownError,
            }
        }
    }


    public refreshToken = async () => await di.get<AuthRepository>(AuthRepositoryName).refreshToken();

    public checkToken = async (callback: Function): Promise<any> => {
        try {
            const response = await callback();
            return response;
        } catch (error: any) {
            if (error.message == "The incoming token has expired") {
                await this.refreshToken();
                return await callback();
            }
        }
    }


    public get = (relativeUrl: string): Promise<any> => new Promise((resolve, reject) => {
        const _petition = (): Promise<any> => new Promise((resolve, reject) => {
            fetch(this.url + relativeUrl, {
                method: 'GET',
                // mode: 'no-cors',
                headers: this._getHeaders(),
            }).then(async response => {
                if (!response.ok || response.status >= 400) {
                    const errorParsed = await this._parseError(response);
                    return reject(errorParsed);
                }
                try {
                    const js = await response.json();
                    return resolve(js);
                } catch (error) {
                    const ts = await response.text();
                    return resolve(ts);
                }
            }).catch((error) => reject(error));
        });
        this.checkToken(_petition).then((response) => resolve(response)).catch((error) => reject(error));
    });


    public post = (relativeUrl: string, body: any): Promise<any> => new Promise((resolve, reject) => {
        const _petition = (): Promise<any> => new Promise((resolve, reject) => {
            fetch(this.url + relativeUrl, {
                method: 'POST',
                headers: this._getHeaders(),
                body: JSON.stringify(body),
            }).then(async response => {
                if (!response.ok || response.status >= 400) {
                    const errorParsed = await this._parseError(response);
                    return reject(errorParsed);
                }
                try {
                    const js = await response.json();
                    return resolve(js);
                } catch (error) {
                    const ts = await response.text();
                    return resolve(ts);
                }
            }).catch((error) => reject(error));
        });
        this.checkToken(_petition).then((response) => resolve(response)).catch((error) => reject(error));
    });

    public put = (relativeUrl: string, body: any): Promise<any> => new Promise((resolve, reject) => {
        const _petition = (): Promise<any> => new Promise((resolve, reject) => {
            fetch(this.url + relativeUrl, {
                method: 'PUT',
                headers: this._getHeaders(),
                body: JSON.stringify(body),
            }).then(async response => {
                if (!response.ok || response.status >= 400) {
                    const errorParsed = await this._parseError(response);
                    return reject(errorParsed);
                }
                try {
                    const js = await response.json();
                    return resolve(js);
                } catch (error) {
                    const ts = await response.text();
                    return resolve(ts);
                }
            }).catch((error) => reject(error));
        });
        this.checkToken(_petition).then((response) => resolve(response)).catch((error) => reject(error));
    });

    public remove = (relativeUrl: string): Promise<any> => new Promise((resolve, reject) => {
        const _petition = (): Promise<any> => new Promise((resolve, reject) => {
            fetch(this.url + relativeUrl, {
                method: 'DELETE',
                headers: this._getHeaders(),
            }).then(async response => {
                if (!response.ok || response.status >= 400) {
                    const errorParsed = await this._parseError(response);
                    return reject(errorParsed);
                }
                try {
                    const js = await response.json();
                    return resolve(js);
                } catch (error) {
                    const ts = await response.text();
                    return resolve(ts);
                }
            }).catch((error) => reject(error));
        });
        this.checkToken(_petition).then((response) => resolve(response)).catch((error) => reject(error));
    });
}
// axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

export default RestApi;