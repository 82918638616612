import { Either } from "fp-ts/lib/Either";
import EventScheduleEntity from "../entities/EventScheduleEntity";
import ExceptionEntity from "../entities/ExceptionEntity";
import TypeEventScheduleEntity from "../entities/TypeEventScheduleEntity";
import ShiftEntity from "../entities/ShiftEntity";

export interface GetAllTypeEventsResponse {
    events: TypeEventScheduleEntity[],
    workTime: TypeEventScheduleEntity | undefined
}
export default interface CalendarRepository {
    createEventForUser: (event: EventScheduleEntity, userId: string) => Promise<Either<ExceptionEntity, EventScheduleEntity>>;
    updateEvent: (event: EventScheduleEntity, userId: string) => Promise<Either<ExceptionEntity, EventScheduleEntity>>;
    deleteEvent: (eventId: string) => Promise<Either<ExceptionEntity, void>>;
    getEmployeeCalendar: (employeeId: string, startDate: Date, endDate: Date) => Promise<Either<ExceptionEntity, ShiftEntity[]>>;
    getEmployeeTodayCalendar: (employeeId: string) => Promise<Either<ExceptionEntity, ShiftEntity>>;
    getTypeEvents: () => Promise<Either<ExceptionEntity, GetAllTypeEventsResponse>>;
}

export const CalendarRepositoryName = "CalendarRepository";
