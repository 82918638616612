/********************************************************************************
 * File Header - AWS Refresh Token API Implementation
 * Created By: Marlon Alejandro Mendez Castañeda
 * Contact: +57 3234686680
 * Date: August 9, 2023
 *
 * Description:
 * This file contains the implementation of the AWS Refresh Token API.
 * Reads the current user and session from AWS Amplify. and refresh the token
 ********************************************************************************/

import { Auth } from "aws-amplify";
import { Either, left, right } from "fp-ts/lib/Either";
import ExceptionEntity from "../../../../../domain/entities/ExceptionEntity";

const RefreshTokenApiImpl = async (): Promise<Either<ExceptionEntity, void>> => {
    try {
        const user = await Auth.currentAuthenticatedUser();
        if(user) {
            await Auth.currentSession();
        }
        return right(undefined);
    }
    catch (error: any) {
        return left({});
    }
}

export default RefreshTokenApiImpl;