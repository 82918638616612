//create a switch component with react hook validation

import { FC } from "react";
import SwitchFormComponentProps from "./SwitchFormComponentProps";
import Validators from "../../../utils/Validators";
import { ErrorMessage } from "@hookform/error-message";
import Switch from "react-switch";
import { useForm } from "react-hook-form";

const SwitchFormComponent: FC<SwitchFormComponentProps> = ({ label, required, keyName, disabled, register, onChange, watch, errors }) => {

    const _handleOnChange = (checked: boolean) => {
        onChange(keyName, checked);
    };

    const checked = watch(keyName);

    return <div className="w-100">
        <div className="d-flex justify-content-between align-items-center">
            <span>{label}</span>
            <Switch onChange={_handleOnChange} checked={checked == true} onColor="#E6C860" disabled={disabled}></Switch>
        </div>
        <input type="hidden"  {...register(keyName, Validators({ required: required }))} />
        <ErrorMessage as="aside" errors={errors} name={keyName} />
    </div>
};

SwitchFormComponent.defaultProps = {
    required: false,
    disabled: false,
};

export default SwitchFormComponent;