import { injectable } from "inversify";
import AIRepository from "../../repositories/AIRepository";

interface props {
    aiRepository: AIRepository;
}

@injectable()
export class DeleteAIMomentByIdUseCase {
    _aiRepository: AIRepository;

    constructor(_: props) {
        this._aiRepository = _.aiRepository;
    }

    public call = async (keyword_id: number): Promise<any> => {
        return await this._aiRepository.DeleteAIMoment(keyword_id);
    }
}

export const DeleteAIMomentByIdUseCaseName = "DeleteAIMomentByIdUseCaseName";