/********************************************************************************
 * File Header - AWS Update Password By Recovery API Implementation
 * Created By: Marlon Alejandro Mendez Castañeda
 * Contact: +57 3234686680
 * Date: August 9, 2023
 *
 * Description:
 * This file contains the implementation of the AWS Update Password By Recovery API.
 ********************************************************************************/

import { Auth } from "aws-amplify";
import { Either, left, right } from "fp-ts/lib/Either";
import ExceptionEntity from "../../../../../domain/entities/ExceptionEntity";
import KeyWordLocalization from "../../../../../ui/providers/localization/dictionaries/KeyWordLocalization";

const UpdatePasswordByRecoveryApiImpl = async (email:string, password: string, code: string): Promise<Either<ExceptionEntity, void>> => {
    try {
        await Auth.forgotPasswordSubmit(email, code, password);
        return right(undefined);
    }
    catch (error: any) {
        if(error.code === 'LimitExceededException') return left({ code:  KeyWordLocalization.AuthRepositoryErrorLimitResetExceeded, });
        if(error.code === 'CodeMismatchException') return left({ code:  KeyWordLocalization.AuthRepositoryErrorCodeRecoveryNoMatch, });
        if(error.code === 'ExpiredCodeException') return left({ code:  KeyWordLocalization.AuthRepositoryErrorCodeRecoveryExpired, });
        if(error.code === 'InvalidPasswordException') return left({ code:  KeyWordLocalization.AuthRepositoryErrorInvalidPasswordReset, });
        return left({});
    }
}

export default UpdatePasswordByRecoveryApiImpl;