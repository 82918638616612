import { Either, isLeft, left } from "fp-ts/lib/Either";
import ExceptionEntity from "../../entities/ExceptionEntity";
import ShiftEntity from "../../entities/ShiftEntity";
import UserEntity from "../../entities/UserEntity";
import ShiftRepository from "../../repositories/ShiftRepository";
import UserProvider from "../../providers/user/UserProvider";
import KeyWordLocalization from "../../../ui/providers/localization/dictionaries/KeyWordLocalization";
import { injectable } from "inversify";

interface props {
    shiftRepository: ShiftRepository;
    userProvider: UserProvider;
}

@injectable()
export default class CreateShiftUseCase {
    private _shiftRepository: ShiftRepository;
    private _userProvider: UserProvider;

    public constructor(_: props) {
        this._shiftRepository = _.shiftRepository;
        this._userProvider = _.userProvider;
    }

    public call = async (shift: ShiftEntity): Promise<Either<ExceptionEntity, ShiftEntity>> => {
        if(!this._userProvider.Actions.user?.permissions?.shift.calendar.create || !this._userProvider.Actions.user?.permissions.shift.asign) return left({ code: KeyWordLocalization.UserNotAuthorized});
        const shiftResponse = await this._shiftRepository.createShift(shift);
        return shiftResponse;
    }
}

export const CreateShiftUseCaseName = "CreateShiftUseCase";