import { ChangeEvent, FC, useEffect, useState } from "react";

import './ModulePermisionsEditableComponentStyles.scss';
import Icons from "../../../../assets/Icons";
import ModulePermisionsEditableComponentProps from "./ModulePermisionsEditableComponentProps";
import ModulePermision from "../../../../../domain/entities/ModulePermision";

const ModulePermisionsEditableComponent: FC<ModulePermisionsEditableComponentProps> = ({ moduleItem, handleUpdate, allPermissions }) => {

    const [permissions, setPermissions] = useState<any[]>(moduleItem.permissions);

    const [policy, setPolicy] = useState<any>();

    const handleChange = (permissionName: string, permissionType: keyof ModulePermision, value: any) => {
        setPolicy(value);
        
        setPermissions(prevPermissions => 
            prevPermissions.map((permission) =>
                permission.name === permissionName
                    ? { 
                        ...permission,
                        [permissionType]: !permission[permissionType],
                    }
                    : permission
            )
        );
    };

    useEffect(() => {
        handleUpdate(permissions, policy);
    }, [permissions]);

    useEffect(() => {

        if(allPermissions){

            let keyResource = moduleItem.name?.replaceAll(" ", "_");

            let permissionsToShow = moduleItem.permissions;
    
            allPermissions[keyResource]?.resources.map((resource: any) => {
                let hasResource = false;
                moduleItem.permissions.map((permission: any) => {
                    if(permission.name.toLowerCase() === resource.toLowerCase()){
                        hasResource = true;
                    }
                });
    
                if (!hasResource) {
                    let oldPermissions = permissions;
                    oldPermissions.push({
                        canAssign: false,
                        canCreate: false,
                        canDelete: false,
                        canEdit: false,
                        canView: false,
                        id: `${permissions.length}`,
                        name: resource
                    });
                    oldPermissions.sort((a, b) => {
                        return a.name.localeCompare(b.name);
                    });
    
                    permissionsToShow = oldPermissions;
                    
                }
            });
    
            permissionsToShow.sort((a, b) => {
                // Use localeCompare for string comparison
                return a.name.localeCompare(b.name);
            });
    
            setPermissions(permissionsToShow);
            
        }

        
    }, []);
    
    return <section className="role-edition-module-section">
        <div className="role-edition-module-title">
            <Icons.Calendar className='calendar-icon' />{(moduleItem.name  === 'User Management' || moduleItem.name  === 'User_Management') ? 'Roles And Permissions' : moduleItem.name.replaceAll("_", " ")}
        </div>
        <div>
            {moduleItem.description}
        </div>
        <div className="role-edition-module-permissions">
        {
            permissions.map((permission, idx) => {
                return <div className="role-edition-module-permissions-item" key={idx}>
                    <p className="role-edition-module-subtitle">{permission.name}</p>
                    <div>
                        <label className="permission-checkbox-container">
                            <input className="role-checkbox" type="checkbox" name="" id="checkbox1" checked={permission.canView} onChange={()=> handleChange(permission.name, 'canView', moduleItem.name)} />
                            View
                        </label>
                        <label className="permission-checkbox-container">
                            <input className="role-checkbox" type="checkbox" name="" id="checkbox2" checked={permission.canCreate} onChange={()=> handleChange(permission.name, 'canCreate', moduleItem.name)} />
                            Create
                        </label>
                    </div>
                    <div>
                        
                        <label className="permission-checkbox-container">
                            <input className="role-checkbox" type="checkbox" name="" id="checkbox1" checked={permission.canEdit} onChange={()=> handleChange(permission.name, 'canEdit', moduleItem.name)} />
                            Edit
                        </label>
                        <label className="permission-checkbox-container">
                            <input className="role-checkbox" type="checkbox" name="" id="checkbox2" checked={permission.canDelete} onChange={()=> handleChange(permission.name, 'canDelete', moduleItem.name)} />
                            Delete
                        </label>
                        
                        <label className="permission-checkbox-container">
                            <input className="role-checkbox" type="checkbox" name="" id="checkbox1" checked={permission.canAssign} onChange={()=> handleChange(permission.name, 'canAssign', moduleItem.name)} />
                            Assign
                        </label>
                    </div>
                </div>
            })
        }
        </div>
    </section>
}

export default ModulePermisionsEditableComponent;