import { right } from "fp-ts/lib/Either";
import Constants, { ConstantsName } from "../../../../../constants/Constants";
import di from "../../../../../di/DependencyInjection";
import callAPI from "../../../fetchCallImplementation";

//TODO implement
const DeleteAIMomentByIdApiImpl = async (keyword_id: number): Promise<any> => {

    const baseUrl = di.get<Constants>(ConstantsName).AI;

    const response = await callAPI(baseUrl + `/keywords/${keyword_id}`, 'DELETE', {});
    
    const result = await response.json();
    
    return right(result);
}

export default DeleteAIMomentByIdApiImpl;