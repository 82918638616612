import React from "react";
import TrackingTimeContextType from "./TrackingTimeContextType";
import { EventScheduleEntityStatus } from "../../entities/EventScheduleEntity";

const TrackingTimeContext = React.createContext<TrackingTimeContextType>({
    isOpen: false,
    text: "",
    setIsOpen: (isOpen: boolean) => {},
    todayCalendar: undefined,
    setTodayCalendar: () => {},
    status: EventScheduleEntityStatus.paused,
    setStatus: () => {},
    currentEvent: undefined,
    setCurrentEvent: () => {},
    nextEvent: undefined,
    currentProgress: 0,
    loaded: false,
    setLoaded: (loaded: boolean) => {},
});

export default TrackingTimeContext;