import { injectable } from "inversify";
import GroupRepository from "../../../domain/repositories/GroupRepository";
import GroupEntity from "../../../domain/entities/GroupEntity";
import SearchResultEntity from "../../../domain/entities/SearchResultEntity";
import UserEntity from "../../../domain/entities/UserEntity";
import { userTest } from "../user/UserRepositoryFake";
import Testing from "../../../ui/utils/Testing";
import { Either, right } from "fp-ts/lib/Either";
import ExceptionEntity from "../../../domain/entities/ExceptionEntity";
import SearchGroupApiImpl from "./api/impl/SearchGroupApiImpl";
import CreateGroupApiImpl from "./api/impl/CreateGroupApiImpl";
import DeleteGroupByIdApiImpl from "./api/impl/DeleteGroupByIdApiImpl";
import GetUsersOfGroupApiImpl from "./api/impl/GetUsersOfGroupApiImpl";

const testGroup: GroupEntity = {
    id: '1',
    name: 'Group A',
    description: '',
    users: Array(10).fill(userTest).map((user, index) => {
        return {
            ...user,
            id: index.toString()
        }
    }),
}
@injectable()
export default class GroupRepositoryDev implements GroupRepository {
    getUsersOfGroup = GetUsersOfGroupApiImpl;
    searchGroup = async (word: string, page: number, itemsPerPage: number): Promise<SearchResultEntity<GroupEntity>> => SearchGroupApiImpl(word, page, itemsPerPage);
    createGroup = async (name: string, description: string, users: UserEntity[]): Promise<Either<ExceptionEntity, GroupEntity>> => {
        const response = await CreateGroupApiImpl(name, description, users);
        return response;
    }
    deleteGroup = async (groupdId: string) => DeleteGroupByIdApiImpl(groupdId);
}