/********************************************************************************
 * File Header - AWS Update Password API Implementation
 * Created By: Marlon Alejandro Mendez Castañeda
 * Contact: +57 3234686680
 * Date: August 9, 2023
 *
 * Description:
 * This file contains the implementation of the AWS Update Password Update using old password and a new one, first check the users old password, then update it.
 ********************************************************************************/

import { Auth } from "aws-amplify";
import { Either, left, right } from "fp-ts/lib/Either";
import ExceptionEntity from "../../../../../domain/entities/ExceptionEntity";
import KeyWordLocalization from "../../../../../ui/providers/localization/dictionaries/KeyWordLocalization";

const UpdatePasswordApiImpl = async (password: string, newPassword: string, email: string): Promise<Either<ExceptionEntity, void>> => {
    try {
        Auth.signIn(email, password)
        .then(async user => {
            if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                await Auth.completeNewPassword(user, newPassword);
            }
        });
        
        return right(undefined);
    }
    catch (error: any) {
        if(error.code === 'LimitExceededException') return left({ code:  KeyWordLocalization.AuthRepositoryErrorLimitResetExceeded, });
        if(error.code === 'CodeMismatchException') return left({ code:  KeyWordLocalization.AuthRepositoryErrorCodeRecoveryNoMatch, });
        if(error.code === 'ExpiredCodeException') return left({ code:  KeyWordLocalization.AuthRepositoryErrorCodeRecoveryExpired, });
        if(error.code === 'InvalidPasswordException') return left({ code:  KeyWordLocalization.AuthRepositoryErrorInvalidPasswordReset, });
        return left({});
    }
}

export default UpdatePasswordApiImpl;