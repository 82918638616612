import { FC, useContext, useEffect, useRef, useState } from 'react';
import './UserManagementStyles.scss';
import di from '../../../../../../di/DependencyInjection';
import SearchAllUserUseCase, { SearchAllUserUseCaseName } from '../../../../../../domain/use_cases/user/SearchAllUserUseCase';
import { isRight } from 'fp-ts/lib/Either';
import SearchDropdown from './dropDownInput';
import GetAllRolesUseCase, { GetAllRolesUseCaseName } from '../../../../../../domain/use_cases/roles/GetAllRolesUseCase';
import SearchUserPositionsUseCase, { SearchUserPositionsUseCaseName } from '../../../../../../domain/use_cases/user/SearchUserPositionsUseCase';
import SearchUserBUnitsUseCase, { SearchUserBUnitsUseCaseName } from '../../../../../../domain/use_cases/user/SearchUserBUnitsUseCase';
import ButtonComponent from '../../../../../components/button/ButtonComponent';
import { ButtonType } from '../../../../../components/button/ButtonComponentProps';
import Icons from '../../../../../assets/Icons';
import SearchAlSupervisorsUseCase, { SearchAlSupervisorsUseCaseName } from '../../../../../../domain/use_cases/user/SearchAlSupervisorsUseCase';
import LoadingComponent from '../../../../../components/LoadingComponent/LoadingComponent';
import UpdateUserUseCase, { UpdateUserUseCaseName } from '../../../../../../domain/use_cases/user/UpdateUserUseCase';
import Papa from 'papaparse';
import DropDownComponent from '../../../../../components/dropDown/DropDownComponent';
import { DropDownType } from '../../../../../components/dropDown/DropDownComponentProps';
import { AutoCompleteItem } from '../../../../../components/forms/autocompleteWithCheck/AutoCompleteWithCheckProps';
import TypeEventScheduleEntity from '../../../../../../domain/entities/TypeEventScheduleEntity';
import ModalsContext from '../../../../../../domain/providers/modal/ModalsContext';
import ModalsContextType from '../../../../../../domain/providers/modal/ModalsContextType';

const timeZones: any =  [
    { label: "America/Belize", subLabel: "" },
    { label: "America/Bogota", subLabel: "" },
    { label: "America/Jamaica", subLabel: "" },
    { label: "America/Tegucigalpa", subLabel: "" },
    { label: "America/Guyana", subLabel: "" },
    { label: "America/St_Lucia", subLabel: "" },
    { label: "US/Eastern", subLabel: "" },
    { label: "US/Central", subLabel: "" },
    { label: "US/Mountain", subLabel: "" },
    { label: "US/Pacific", subLabel: "" },
    { label: "US/Alaska", subLabel: "" }
];

const locationsOptions = [
    { label: "Belize", subLabel: "" },
    { label: "Jamaica", subLabel: "" },
    { label: "Colombia", subLabel: "" },
    { label: "Guyana", subLabel: "" },
    { label: "Saint Lucia", subLabel: "" },
    { label: "US", subLabel: "" },
    { label: "Honduras", subLabel: "" },
    {label: "Training", subLabel: ""}
]

const UserUpdatePage: FC<{}> = () => {
    const [selectedUsers, setSelectedUsers] = useState<any>(
        {
            role: "",
            position: "",
            business_unit: "",
            timezone: "",
            location: "",
            client: "" ,
            supervisor: "",
            hr_id: ""
        }
    );
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const [ allUsersData, setAllUsersData ] = useState<any>([]);
    const [ allUsersHrIds, setAlllUsersHrIds] = useState<any>([]);
    const [ allRolesData, setAllRolesData ] = useState<any>([]);
    const [ allPositionsData, setAllPositionsData ] = useState<any>([]);
    const [ allBUnitsData, setAllBUnitsData ] = useState<any>([]);
    const [ allSupervisorsData, setAllSupervisorsData ] = useState<any>([]);
    
    const [ allHrIdData, setAllHrIdData ] = useState<any>([]);

    const [ loadingUpdate, setLoadingUpdate ] = useState(false);

    const [ showLocationField, setShowLocationField ] = useState(true);
    const [ showClientField, setShowClientField ] = useState(true);
    const [ showSupervisorField, setShowsupervisorField ] = useState(true);

    const [ openDropView, setOpenDropView ] = useState(false);

    const [ usersPreview, setUsersPreview ] = useState<any>([]);
    const [ userButtonSelected, setUserButtonSelected ] = useState<string>("");
    const [ userSelected, setUserSelected ] = useState<string>("");
    const [ allUsersInfo, setAllUsersInfo ] = useState<any[]>([]);
    
    const [ allUsersAllDataInfo, setAllUsersAllDataInfo ] = useState<any[]>([]);

    const dropdownRef = useRef<HTMLDivElement>(null);

    const [ emailBucket, setEmailBucket ] = useState<string>("");
    const [ roleBucket, setRoleBucket ] = useState<string>("");
    const [ positionBucket, setPositionBucket ] = useState<string>("");
    const [ businessUnitBucket, setBusinessUnit ] = useState<string>("");
    const [ timeZoneBucket, setTimeZoneBucket ] = useState<string>("");
    const [ locationBucket, setLocationBucket ] = useState<string>("");
    const [ clientBucket, setClientBucket ] = useState<string>("");
    const [ supervisorBucket, setSupervisorBucket ] = useState<string>("");
    
    const [ hrIdBucket, setHrIdBucket ] = useState<string>("");

    const _search = async () => (await di.get<SearchAllUserUseCase>(SearchAllUserUseCaseName).call(''));
    const _searchRole = async () => await di.get<GetAllRolesUseCase>(GetAllRolesUseCaseName).call(true);
    const _searchPositions = async () => await di.get<SearchUserPositionsUseCase>(SearchUserPositionsUseCaseName).call();
    const _searchBUnits = async () => await di.get<SearchUserBUnitsUseCase>(SearchUserBUnitsUseCaseName).call();
    const _searchSupervisors = async () => await di.get<SearchAlSupervisorsUseCase>(SearchAlSupervisorsUseCaseName).call();

    const { addToast } = useContext(ModalsContext) as ModalsContextType;

    const firstCall = async () => {
        setLoadingUpdate(true);
        const first = await _search();
        const firstRole = await _searchRole();
        const firstPositions = await _searchPositions();
        const firstBUnits = await _searchBUnits();
        const firstSupervisors = await _searchSupervisors();

        if (isRight(first)) {
            
            let userDataFormat = first.right.map((user: any) => {
                return {
                    label: user.firstname + " " + user.lastname,
                    subLabel: user.email
                }
            });
            setAllUsersAllDataInfo(first.right);
            setAllUsersData(userDataFormat);

            let userHrIdDataFormat = first.right.map((user: any) => {
                return {
                    label: user.hr_id + '',
                    subLabel: user.hr_id + ''
                }
            });

            userHrIdDataFormat = userHrIdDataFormat.filter((item: any) => item.label !== 'null');            

            setAlllUsersHrIds(userHrIdDataFormat);
        }
        if(isRight(firstRole)){
            let userDataFormat = firstRole.right.map((role: any) => {
                return {
                    label: role.name,
                    subLabel: ""
                }
            });
            setAllRolesData(userDataFormat);
        }

        if(isRight(firstPositions)) {
            let userDataFormat = firstPositions.right.map((position: any) => {
                return {
                    label: position.name,
                    subLabel: ""
                }
            });
            setAllPositionsData(userDataFormat);
        }
        if(isRight(firstBUnits)) {
            let userDataFormat = firstBUnits.right.map((BUnit: any) => {
                return {
                    label: BUnit.name,
                    subLabel: ""
                }
            });
            setAllBUnitsData(userDataFormat);
        }
        if (isRight(firstSupervisors)) {
            let userDataFormat = firstSupervisors.right.map((user: any) => {
                return {
                    label: user.firstname + " " + user.lastname,
                    subLabel: user.email
                }
            });
            setAllSupervisorsData(userDataFormat);
        }
        setLoadingUpdate(false);
    }

    let supervisorOptionsToSeeField = ['Agent'];
    let clientOptionsToSeeField = ['Senior Agent', 'Agent', 'Supervisor', 'Manager', 'Client Basic', 'Client Advanced'];
    let locationOptionsToSeeField = ['Senior Agent', 'Agent', 'Supervisor', 'Manager', 'Client Basic', 'Client Advanced'];


    const handleItemSelect = async (user: any, id: string) => {

        setDropdownOpen(false);

        if (allUsersInfo.length > 0) {

            if (id === 'role') setRoleBucket(user.label);
            if (id === 'position') setPositionBucket(user.label);
            if (id === 'timezone') setTimeZoneBucket(user.label);
            if (id === 'business_unit') setBusinessUnit(user.label);
            if (id === 'location') setLocationBucket(user.label);
            if (id === 'client') setClientBucket(user.label);
            if (id === 'supervisor') setSupervisorBucket(user.label);
            if (id === 'hr_id') setHrIdBucket(user.label);

            const userToChange = allUsersInfo.filter(user => user.email.toLowerCase() === userSelected.toLowerCase())[0];
            let newUserInfo = {
                ...userToChange,
                [id]: userToChange[id].toLowerCase().includes(user.label.toLowerCase()) ? userToChange[id] :  (userToChange[id] + "," + user.label).split(",").filter(item => item !== "").join(",")
            }

            setSelectedUsers(newUserInfo);
            
            const updatedArray = allUsersInfo.map(item =>
                item.email.toLowerCase() === newUserInfo.email.toLowerCase() ? newUserInfo : item
            );

            setAllUsersInfo(updatedArray);
                
        } else {
            let newUser = {
                name: user.label,
                subName: user.subLabel
            }
            
            let dataToShow = {
                ...selectedUsers,
                [id]: (
                    (id === "email") ? 
                    newUser.subName 
                    : 
                    (id === "location" || id === "client" || id === "supervisor") ? 
                        selectedUsers[id].includes(newUser.name) ? selectedUsers[id] : (selectedUsers[id] + "," + newUser.name).split(",").filter(item => item !== "").join(",") 
                        :
                        newUser.name
                )
            };
            setSelectedUsers(dataToShow);
            

            if (id === 'role') setRoleBucket(dataToShow['role']);
            if (id === 'position') setPositionBucket(dataToShow['position']);
            if (id === 'timezone') setTimeZoneBucket(dataToShow['timezone']);
            if (id === 'business_unit') setBusinessUnit(dataToShow['business_unit']);
            if (id === 'location') setLocationBucket(dataToShow['location']);
            if (id === 'client') setClientBucket(dataToShow['client']);
            if (id === 'supervisor') setSupervisorBucket(dataToShow['supervisor']);
            if (id === 'hr_id') setHrIdBucket(dataToShow['hr_id']);

            if (id === "email" && user.subLabel !== '') {

                let getUserFromEmail = allUsersAllDataInfo.filter((item: any) => item.email.toLowerCase().replaceAll(" ", "") === user.subLabel?.toLowerCase().replaceAll(" ", ""));

                const _search = async () => (await di.get<SearchAllUserUseCase>(SearchAllUserUseCaseName).call(getUserFromEmail[0].user_id));
                
                setLoadingUpdate(true);
                const getUserInfoById = await _search();
                
                if (getUserInfoById._tag === "Right") {
                    let response: any = getUserInfoById.right;
                    
                        setEmailBucket(response.email);
                        setRoleBucket(response.role_name.replaceAll("_", " "));
                        setPositionBucket(response.position ? response.position : '');
                        setTimeZoneBucket(response.timezone ? response.timezone : '');
                        setBusinessUnit(response.business_unit ? response.business_unit : '');
                        setLocationBucket(response.location ? response.location : '');
                        setClientBucket(response.client ? response.client : '');
                        setSupervisorBucket(response.supervisor ? response.supervisor : '');
                        setHrIdBucket(response.hr_id ? response.hr_id + '' : '');
                        setSelectedUsers({
                            'email': response.email,
                            'role': response.role_name.replaceAll("_", " "),
                            'position': response.position ? response.position : '',
                            'timezone': response.timezone ? response.timezone : '',
                            'business_unit': response.business_unit ? response.business_unit : '',
                            'location': response.location ? response.location : '',
                            'client': response.client ? response.client : '',
                            'supervisor': response.supervisor ? response.supervisor : '',
                            'hr_id': response.hr_id ? response.hr_id + '' : ''
                        });
                    
                }
                
                setLoadingUpdate(false);

            }

            if (id === "hr_id" && user.subLabel !== '') {

                let getUserFromEmail = allUsersAllDataInfo.filter((item: any) => (item.hr_id + '').toLowerCase().replaceAll(" ", "") === user.subLabel?.toLowerCase().replaceAll(" ", ""));
                
                if (getUserFromEmail[0]) {
                    const _search = async () => (await di.get<SearchAllUserUseCase>(SearchAllUserUseCaseName).call(getUserFromEmail[0].user_id));
                
                    setLoadingUpdate(true);
                    const getUserInfoById = await _search();
                    
                    if (getUserInfoById._tag === "Right") {
                        let response: any = getUserInfoById.right;
    
                            setEmailBucket(response.email);
                            setRoleBucket(response.role_name.replaceAll("_", " "));
                            setPositionBucket(response.position ? response.position : '');
                            setTimeZoneBucket(response.timezone ? response.timezone : '');
                            setBusinessUnit(response.business_unit ? response.business_unit : '');
                            setLocationBucket(response.location ? response.location : '');
                            setClientBucket(response.client ? response.client : '');
                            setSupervisorBucket(response.supervisor ? response.supervisor : '');
                            setHrIdBucket(response.hr_id ? response.hr_id + '' : '')
        
                            setSelectedUsers({
                                ...selectedUsers,
                                'email': response.email,
                                'role': response.role_name.replaceAll("_", " "),
                                'position': response.position ? response.position : '',
                                'timezone': response.timezone ? response.timezone : '',
                                'business_unit': response.business_unit ? response.business_unit : '',
                                'location': response.location ? response.location : '',
                                'client': response.client ? response.client : '',
                                'supervisor': response.supervisor ? response.supervisor : '',
                                'hr_id': response.hr_id ? response.hr_id + '' : ''
                            })
                        
                    }
                    
                    setLoadingUpdate(false);
                }

            }

    
            if (id === "role") {
                
                let isSuperLevel = supervisorOptionsToSeeField.filter(item => item.toLowerCase().replaceAll(" ", "") === user.label.toLowerCase().replaceAll(" ", "").replaceAll("_", ""));
                if (isSuperLevel.length > 0) {
                    setShowsupervisorField(true);
                } else {
                    setShowsupervisorField(false);
                    setSelectedUsers({
                        ...dataToShow,
                        'supervisor': ''
                    })
                }
                let isLocationLevel = locationOptionsToSeeField.filter(item => item.toLowerCase().replaceAll(" ", "") === user.label.toLowerCase().replaceAll(" ", "").replaceAll("_", ""));
                if (isLocationLevel.length > 0) {
                    setShowLocationField(true);
                } else {
                    setShowLocationField(false);
                    setSelectedUsers({
                        ...dataToShow,
                        'location': ''
                    })
                }
                let isClientLevel = clientOptionsToSeeField.filter(item => item.toLowerCase().replaceAll(" ", "") === user.label.toLowerCase().replaceAll(" ", "").replaceAll("_", ""));
                if (isClientLevel.length > 0) {
                    setShowClientField(true);
                } else {
                    setShowClientField(false);
                    setSelectedUsers({
                        ...dataToShow,
                        'client': ''
                    })
                }
                
            }
        }

    };

    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setDropdownOpen(false);
        }
    };

    const _handleUpdateUsers = async () => {
        
        setLoadingUpdate(true);

        let dataToSend = allUsersInfo.length > 0 ? allUsersInfo : selectedUsers;
        
        try {
            dataToSend = dataToSend.map((item: any) => {
                return {
                    ...item,
                    'hr_id': item['hr_id'] ? +item['hr_id'] : '',
                    'role': item['role'] ? item['role'].replaceAll(" ", "_") : '',
                }
            });
        } catch (error) {
            dataToSend = {
                ...dataToSend,
                'hr_id': dataToSend['hr_id'] ? +dataToSend['hr_id'] : '',
                'role': dataToSend['role'] ? dataToSend['role'].replaceAll(" ", "_") : '',
            }
        }
        
        const _updateUsers = async () => await di.get<UpdateUserUseCase>(UpdateUserUseCaseName).call(dataToSend);

        const response: any = await _updateUsers();

        if (response._tag === "Right") {
            const rightResponse = response.right;
            addToast(rightResponse.message, "success", undefined);
        } else {
            addToast(response.left.message, "error", undefined);
        }
        
        setLoadingUpdate(false);
        
    }

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        if (file) {
        handleFileUpload(file);
        }
    };

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
    };

    const _openDropView = () => {
        setOpenDropView(!openDropView);
    }

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files ? event.target.files[0] : null;
        if (file) {
            handleFileUpload(file);
        }
    };

    const handleFileUpload = (file: File) => {
        const reader = new FileReader();
    
    reader.onload = (event: ProgressEvent<FileReader>) => {
        if (event.target && typeof event.target.result === 'string') {
            Papa.parse(event.target.result, {
                complete: (result) => {
                    const rows = result.data as string[][];
                    
                    const data: string[][] = [];
                    for (let i = 0; i < rows.length; i++) {
                        const rowData: string[] = [];
                        for (let j = 0; j < Math.min(9, rows[i].length); j++) {
                            if (rows[i][j] === null) {
                                break;
                            }
                            rowData.push(rows[i][j]);
                        }
                        if (rowData.length > 0) {
                            data.push(rowData);
                        }
                    }
                    
                    const keys = data[0]; // First row as keys
                    const values = data.slice(1); // Rest of the rows as values

                    const newUsers = values.map(user => user[0]);
                    setUserButtonSelected(data[1][0]);
                    setUsersPreview(newUsers);
                    setOpenDropView(false);
                    setUserSelected(data[1][0]);


                    let newDataFormat = values.map(row => {
                        let obj: any = {
                            role: "",
                            position: "",
                            business_unit: "",
                            timezone: "",
                            location: "",
                            client: "" ,
                            supervisor: "",
                            hr_id: ""
                        };
                        keys.forEach((key, index) => {
                            if (key !== '') {
                                obj[key] = row[index];
                            }
                        });
                        return obj;
                    });

                    newDataFormat = newDataFormat.filter(item => item.email !== "");
                    
                    setAllUsersInfo(newDataFormat);

                    const dataFromUserSelected = newDataFormat.filter(item => item.email === data[1][0])[0];

                    setRoleBucket(dataFromUserSelected["role"]);
                    setPositionBucket(dataFromUserSelected["position"]);
                    setBusinessUnit(dataFromUserSelected["business_unit"]);
                    setTimeZoneBucket(dataFromUserSelected["timezone"]);
                    setLocationBucket(dataFromUserSelected["location"]);
                    setClientBucket(dataFromUserSelected["client"]);
                    setSupervisorBucket(dataFromUserSelected["supervisor"]);
                    setHrIdBucket(dataFromUserSelected["hr_id"]);
                    
                },
                error: (error: any) => {
                    console.error('Error parsing CSV:', error);
                    
                    setOpenDropView(false);
                }
            });
        }
    };

    reader.onerror = (error) => {
        console.error('Error reading file:', error);
    };

    reader.readAsText(file);
    
    };

    const usersToShow = usersPreview?.map((user: any) => {
        return {
            name: user,
            email: ''
        }
    })
    const _getTypeSchedule = async (word: string): Promise<AutoCompleteItem<TypeEventScheduleEntity>[]> => {
        
        const mapped = usersToShow.map((item: any) => {
            return {
                label: item.name,
                id: '',
                aditionalValue: '',
            }
        });
        return mapped;
    }

    const _handleClickIcon = (item: any) => {
        
        setUserSelected(item[0].label.toLowerCase());
        const userSelected = usersToShow.filter((user: any) => user.name.toLowerCase() === item[0].label.toLowerCase());
        
        setUserButtonSelected(userSelected[0].name);

        const dataFromUserSelected = allUsersInfo.filter(item => item.email === userSelected[0].name)[0];
        
        setRoleBucket(dataFromUserSelected["role"]);
        setPositionBucket(dataFromUserSelected["position"]);
        setBusinessUnit(dataFromUserSelected["business_unit"]);
        setTimeZoneBucket(dataFromUserSelected["timezone"]);
        setLocationBucket(dataFromUserSelected["location"]);
        setClientBucket(dataFromUserSelected["client"]);
        setSupervisorBucket(dataFromUserSelected["supervisor"]);
        setHrIdBucket(dataFromUserSelected["hr_id"]);
    }


    const _handelRemoveItem = (loc: string, item: string) => {
        
        let newValue = selectedUsers[loc].replaceAll(item, "").split(",").filter((item: string) => item !== "").join(",");

        let newfieldSelection = {
            ...selectedUsers,
            [loc]: newValue
        };
        setSelectedUsers(newfieldSelection);
        
        if (loc === 'location') setLocationBucket(newValue);
        if (loc === 'client') setClientBucket(newValue);
        if (loc === 'supervisor') setSupervisorBucket(newValue);
    }

    
    function downloadFile() {
        const fileUrl = 'https://itelligence.s3.amazonaws.com/settings/users_setup_template.csv';
        const fileName = 'bulk_import_template.csv';
        // Create an anchor element
        const anchor = document.createElement('a');
        anchor.href = fileUrl;
        anchor.setAttribute('download', fileName);
    
        // Programmatically trigger a click on the anchor
        document.body.appendChild(anchor);
        anchor.click();
    
        // Clean up
        document.body.removeChild(anchor);
    }

    useEffect(() => {
        firstCall();
        
        if (dropdownOpen) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdownOpen]);

    return (
        <>

        {
            loadingUpdate ?
            <LoadingComponent /> 
            :
            <div>
                <p className='update_title'>User Edition</p>
                <p className='title_information_user_container'>Bulk Data Import</p>
                <div className='bulk_user_import'>
                    
                    <div style={{justifyContent: "start",  marginBottom: "0px", paddingLeft: "20px", width: "fit-content", minWidth: "300px"}}>
                    <DropDownComponent
                                onSearch={_getTypeSchedule}
                                text={userButtonSelected ? userButtonSelected : "No data imported"}
                                icon={<Icons.PersonOutlined />}
                                onClickIcon={() => _handleClickIcon}
                                onSelected={(e) => _handleClickIcon(e)}
                                type={DropDownType.MAIN}
                                colorLine='#80ACFF' />
                    </div>
                    <div style={{width: "fit-content", display: "flex", marginRight: "20px"}}>
                        <div style={{width: "10px"}}></div>
                        <ButtonComponent text={"Download .csv"} type={ButtonType.MAIN} onClick={() => downloadFile()} icon={<Icons.Download />} />
                        <div style={{width: "10px"}}></div>
                        <ButtonComponent text={"Load .csv File"} type={ButtonType.MAIN} onClick={() => _openDropView()} icon={<Icons.Plus />} />
                    </div>
                </div>
                
                {
                    openDropView &&

                    <div>
            <div className='drop_loader_view' 
            
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                onClick={() => setOpenDropView(false)}
                        >
                            {
                                /**
                                 * 
                                 * <div onClick={() => setOpenDropView(!openDropView)}>close</div>
                                 */
                            }
                            
                            <div>
                            <label htmlFor="fileInput"
                                className='label_drop_content'
                            >
                                <p className='drop_message_bulk'>DROP YOUR CSV FILE HERE TO UPLOAD IT</p>
                                
                            </label>
                            </div>
                            <input 
                                id="fileInput" 
                                type="file" 
                                
                                onChange={handleInputChange}
                                accept=".xlsx,.xls" 
                                style={{ display: 'none' }} 
                            />
                        </div>
                    </div>
                }

                
                <p className='title_information_user_container'>User information</p>
                <p>Please enter the user's unique information. This data will customize the user's interaction with the intelligence modules.</p>

            <div className="container_update top">
            {
                    allUsersInfo.length === 0 &&
                    <div>
                        <div className='user_list_title'>User Selection:</div>
                        <SearchDropdown 
                            data={allUsersData}
                            onSelect={handleItemSelect}
                            selected={emailBucket}
                            id={"email"}
                            editable={false}
                        />
                    </div>
                }
            

            <div>
                <div className='user_list_title'>Select a Role:</div>
                <SearchDropdown 
                    data={allRolesData}
                    onSelect={handleItemSelect}
                    selected={roleBucket}
                    id={"role"}
                    editable={false}
                />
            </div>
            
            <div>
                <div className='user_list_title'>Select a Position:</div>
                <SearchDropdown 
                    data={allPositionsData}
                    onSelect={handleItemSelect}
                    selected={positionBucket}
                    id={"position"}
                    editable={true}
                />
            </div>

            <div>
                <div className='user_list_title'>Select a Business Unit:</div>
                <SearchDropdown 
                    data={allBUnitsData}
                    onSelect={handleItemSelect}
                    selected={businessUnitBucket}
                    id={"business_unit"}
                    editable={true}
                />
            </div>
            
            <div>
                <div className='user_list_title'>Select a Time Zone:</div>
                <SearchDropdown 
                    data={timeZones}
                    onSelect={handleItemSelect}
                    selected={timeZoneBucket}
                    id={"timezone"}
                    editable={false}
                />
            </div>

            <div>
                <div className='user_list_title'>Select a HR id:</div>
                <SearchDropdown 
                    data={allUsersHrIds}
                    onSelect={handleItemSelect}
                    selected={hrIdBucket}
                    id={"hr_id"}
                    editable={true}
                />
            </div>

            </div>
            <p className='title_information_user_container'>Team Information</p>
            <p>This section pertains to the team details visible to the user on the platform. The level of detail for these inputs varies based on the user's role. Each field can accept multiple entries, separated by commas, to represent users associated with various teams.</p>
            
            <div className="container_update bottom">
                
            {
                showLocationField &&
                <div>
                    <div className='user_list_title'>Select a Location:</div>
                    <SearchDropdown 
                        data={locationsOptions}
                        onSelect={handleItemSelect}
                        selected={locationBucket}
                        id={"location"}
                        editable={false}
                        needToBeFiltered={false}
                    />
                    {
                        selectedUsers["location"] !== "" &&
                        <div style={{display: "flex"}}>
                            {
                                selectedUsers["location"].split(",").map((location: string, index: string) => {
                                    return(
                                        <div key={index} className='item_option_selected' style={{display: 'flex'}}>
                                            {location}
                                            <div style={{cursor: "pointer"}} onClick={() => _handelRemoveItem("location", location)}>
                                                <Icons.Trash />
                                            </div>
                                        </div>
                                    )
                                })    
                            }
                        </div>
                    }
                    
                </div>
            }

            {
                showClientField &&
                <div>
                    <div className='user_list_title'>Select a Client:</div>
                    <SearchDropdown 
                        data={allBUnitsData}
                        onSelect={handleItemSelect}
                        selected={clientBucket}
                        id={"client"}
                        editable={true}
                        needToBeFiltered={false}
                    />
                    {
                        selectedUsers["client"] !== "" &&
                        <div style={{display: "flex"}}>
                            {
                                selectedUsers["client"].split(",").map((client: string, index: string) => {
                                    return(
                                        <div key={index} className='item_option_selected' style={{display: 'flex'}}>
                                            {client}
                                            <div style={{cursor: "pointer"}} onClick={() => _handelRemoveItem("client", client)}>
                                                <Icons.Trash />
                                            </div>
                                        </div>
                                    )
                                })    
                            }
                        </div>
                    }
                </div>
            }

{
                showSupervisorField &&
                <div>
                    <div className='user_list_title'>Select a Supervisor:</div>
                    <SearchDropdown 
                        data={allSupervisorsData}
                        onSelect={handleItemSelect}
                        selected={supervisorBucket}
                        id={"supervisor"}
                        editable={true}
                        needToBeFiltered={false}
                    />
                    {
                        selectedUsers["supervisor"] !== "" &&
                        <div style={{display: "flex"}}>
                            {
                                selectedUsers["supervisor"].split(",").map((supervisor: string, index: string) => {
                                    return(
                                        <div key={index} className='item_option_selected' style={{display: 'flex'}}>
                                            {supervisor}
                                            <div style={{cursor: "pointer"}} onClick={() => _handelRemoveItem("supervisor", supervisor)}>
                                                <Icons.Trash />
                                            </div>
                                        </div>
                                    )
                                })    
                            }
                        </div>
                    }
                </div>
            }
            
        </div>
        <div className="floating_button">
            <ButtonComponent text={"Update"} type={ButtonType.DANGER} onClick={_handleUpdateUsers} icon={<Icons.AddPeople />} />
        </div>
        </div>
        }
    </>
        
    );
};

export default UserUpdatePage;
