import { injectable } from "inversify";
import RoleRepository from "../../repositories/RoleRepository";
import ExceptionEntity from "../../entities/ExceptionEntity";
import { Either } from "fp-ts/lib/Either";
import UserRepository from "../../repositories/UserRepository";

interface props { userRepository: UserRepository }

@injectable()
export default class UpdateUserRoleIdUseCase {
    _userRepository: UserRepository;

    constructor(_: props) {
        this._userRepository = _.userRepository;
    }

    public call = (userId: number, roleId: number) => new Promise<Either<ExceptionEntity, string>>(async (resolve, reject) => {
        try {
            const response = await this._userRepository.updateUserRoleId(userId, roleId);
            
            return resolve(response);
        } catch (error) {
            return reject();
        }
    });
};

export const UpdateUserRoleIdUseCaseName = "UpdateUserRoleIdUseCase";