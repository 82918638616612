import { injectable } from "inversify";
import { Either, isLeft, isRight, left } from "fp-ts/lib/Either";
import ExceptionEntity from "../../entities/ExceptionEntity";
import UserProvider from "../../providers/user/UserProvider";
import TrackingTimeRepository, { GetSummaryAttendanceInTimeResponse } from "../../repositories/TrackingTimeRepository";
import di from "../../../di/DependencyInjection";
import GetAllTypeOfEventsUseCase, { GetAllTypeOfEventsUseCaseName } from "../calendar/GetAllTypeOfEventsUseCase";
import BusinessUnitEntity from "../../entities/BusinessUnitEntity";
import UserEntity from "../../entities/UserEntity";
import GroupEntity from "../../entities/GroupEntity";

interface props {
    userProvider: UserProvider;
    trackingTimeRepository: TrackingTimeRepository;
}

@injectable()
export default class GetSummaryAttendanceInTimeRangeUseCase {
    _userProvider: UserProvider;
    _trackingTimeRepository: TrackingTimeRepository;

    constructor(_: props) {
        this._trackingTimeRepository = _.trackingTimeRepository;
        this._userProvider = _.userProvider;
    }

    public call = async (businessUnits: BusinessUnitEntity[], supervisors: UserEntity[], employees: UserEntity[], groups: GroupEntity[], startDate: Date, endDate: Date): Promise<Either<ExceptionEntity, GetSummaryAttendanceInTimeResponse>> => {
        const tagTypes = await di.get<GetAllTypeOfEventsUseCase>(GetAllTypeOfEventsUseCaseName).call();
        if (isLeft(tagTypes)) return tagTypes;
        const response = await this._trackingTimeRepository.getSummaryAttendanceInTimeRange(businessUnits, supervisors, employees, groups, tagTypes.right, startDate, endDate);
        return response;
    }
}

export const GetSummaryAttendanceInTimeRangeUseCaseName = "GetSummaryAttendanceInTimeRangeUseCase";
