import { FC, useContext, useState } from "react";
import TrackingTimeEndedTaskComponentProps from "./TrackingTimeEndedTaskComponentProps";
import './TrackingTimeEndedTaskComponentStyles.scss';
import { isLeft } from "fp-ts/lib/Either";
import di from "../../../../../di/DependencyInjection";
import ModalsContext from "../../../../../domain/providers/modal/ModalsContext";
import ModalsContextType from "../../../../../domain/providers/modal/ModalsContextType";
import { StartClockTrackingUseCase, StartClockTrackingUseCaseName } from "../../../../../domain/use_cases/trackingTime/StartClockTrackingUseCase";
import ButtonComponent from "../../../button/ButtonComponent";
import CardEventScheduleComponent from "../../../calendar/cardEventSchedule/CardEventScheduleComponent";
import Icons from "../../../../assets/Icons";
import { EndEventClockUseCase, EndEventClockUseCaseName } from "../../../../../domain/use_cases/trackingTime/EndEventClockUseCase";
import { ButtonType } from "../../../button/ButtonComponentProps";

const TrackingTimeEndedTaskComponent: FC<TrackingTimeEndedTaskComponentProps> = ({ event }) => {
    const { closeModalCustom } = useContext(ModalsContext) as ModalsContextType;
    const [loading, setLoading] = useState(false);

    const _handleOnContinue = async () => {
        if (event === undefined || loading) return;
        setLoading(true);
        const response = await di.get<EndEventClockUseCase>(EndEventClockUseCaseName).call(event.shiftId ?? '');
        if (isLeft(response)) console.error(response.left.code ?? "Error ending task");
        else closeModalCustom();
        setLoading(false);
    }

    return <div className="tracking_time_ended_component">

        <div className="onhold_left_container">
            <div className=""><img src="/assets/images/overtime.png" className='img-fluid' alt="" /></div>
        </div>
        <div className="onhold_right_container">

            <p className="onhold_title">End Activity</p>
            <p className="onhold_subtitle">Your activity needs to be ended</p>
            <div className="tracking_card">
                <CardEventScheduleComponent event={event} />
            </div>

            <ButtonComponent icon={<Icons.Play height={20} width={20} />} isLoading={loading} type={ButtonType.DANGER} onClick={_handleOnContinue} text={`End task "${event?.name}"`}></ButtonComponent>
        </div>

    </div>
}

export default TrackingTimeEndedTaskComponent;