import { injectable } from "inversify";
import { Either, isRight, right } from "fp-ts/lib/Either";
import ExceptionEntity from "../../entities/ExceptionEntity";
import CalendarRepository from "../../repositories/CalendarRepository";
import ShiftEntity from "../../entities/ShiftEntity";

interface props {
    calendarRepository: CalendarRepository;
}

@injectable()
export default class GetUserCalendarOfDaysUseCase {
    _calendarRepository: CalendarRepository;

    constructor(_: props) {
        this._calendarRepository = _.calendarRepository;
    }

    public call = async (userId: string, startDate: Date, endDate: Date) : Promise<Either<ExceptionEntity, ShiftEntity[]>> => {
        const response = await this._calendarRepository.getEmployeeCalendar(userId, startDate, endDate);
        if(isRight(response)) return right(response.right);
        else return response;
    }
}

export const GetUserCalendarOfDaysUseCaseName = "GetUserCalendarOfDaysUseCaseName";
