import './ResetUserPasswordStyles.scss';
import { ErrorMessage } from "@hookform/error-message";
import { FC, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Validators from "../../../../utils/Validators";
import ModalsContext from '../../../../../domain/providers/modal/ModalsContext';
import ModalsContextType from '../../../../../domain/providers/modal/ModalsContextType';
import { routes } from '../../../../routes/RoutesComponent';
import di from '../../../../../di/DependencyInjection';
import LocalizationContext from '../../../../../domain/providers/localization/LocalizationContext';
import LocalizationContextType from '../../../../../domain/providers/localization/LocalizationContextType';
import KeyWordLocalization from '../../../../providers/localization/dictionaries/KeyWordLocalization';
import UpdatePasswordByRecoveryUseCase, { UpdatePasswordByRecoveryUseCaseName } from '../../../../../domain/use_cases/auth/UpdatePasswordByRecoveryUseCase';
import { isRight } from 'fp-ts/lib/Either';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
import UpdatePasswordUseCase, { UpdatePasswordUseCaseName } from '../../../../../domain/use_cases/auth/UpdatePasswordUseCase';

var interval: any = undefined;

const ResetUserPasswordPage: FC<{}> = () => {
    const { i18n } = useContext(LocalizationContext) as LocalizationContextType;
    const { addToast } = useContext(ModalsContext) as ModalsContextType;
    const navigate = useNavigate();
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const [showPassword, setShowPassword] = useState<boolean>(false);


    const location = useLocation();
    const data = location;
    const email = data.pathname.split('/').pop() + '';
    console.log(email);


    const onSubmit = async (data: any) => {
        console.log(data);
        

        const response = await di.get<UpdatePasswordUseCase>(UpdatePasswordUseCaseName).call(data.oldPassword, data.newPassword, email);
        if (isRight(response)) {
            addToast(i18n(KeyWordLocalization.UpdatePasswordRecoveryPageUpdatedPassword), 'success', null);
            interval = undefined;
            navigate(routes.signin.relativePath);
        } else {
            addToast(i18n(response.left.code ?? KeyWordLocalization.UpdatePasswordRecoveryPageErrorUpdatingPassword), 'error', null, true);
        }
        
    };


    return (
        <div className='update_password_recovery_page'>
            <form className="row my-3" onSubmit={handleSubmit(onSubmit)}>
                <div className={`col-12 my-2 form-group ${errors.password ? 'error' : ''}`}>
                    <label>{'Old Password'}</label>
                    <div className="password_input_wrapper">
                        <input type={showPassword ? 'text' : 'password'} className="form-control" placeholder={'Old Password'}
                            {...register('oldPassword', Validators({ required: true, isPassword: false, specialCharacterRequired: false }))} />
                        <div className="visibility_icon" onClick={() => setShowPassword(!showPassword)}>
                            {showPassword ? <MdVisibility /> : <MdVisibilityOff />}
                        </div>
                    </div>
                    <ErrorMessage as="aside" errors={errors} name="oldPassword" />
                </div>
                <div className={`col-12 my-2 form-group ${errors.confirm_password ? 'error' : ''}`}>
                    <label>{'New Password'}</label>
                    <div className="password_input_wrapper">
                        <input type={showPassword ? 'text' : 'password'} {...register("newPassword", Validators({
                            required: true, minLength: 6, isPassword: true
                        }))} className="form-control" placeholder={'New Password'} />
                        <div className="visibility_icon" onClick={() => setShowPassword(!showPassword)}>
                            {showPassword ? <MdVisibility /> : <MdVisibilityOff />}
                        </div>
                    </div>
                    <ErrorMessage as="aside" errors={errors} name="newPassword" />
                </div>

                <div className="col-12">
                    <button type="submit" className="btn btn_primary mb-4 w-100">{i18n(KeyWordLocalization.UpdatePasswordRecoveryPageSubmit)}</button>
                </div>
            </form>
        </div>
    );
};

export default ResetUserPasswordPage;