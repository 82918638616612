import { injectable } from "inversify";
import ShiftRepository from "../../repositories/ShiftRepository";
import SearchResultEntity from "../../entities/SearchResultEntity";
import StaffPerShiftEntity from "../../entities/StaffPerShiftEntity";
import BusinessUnitEntity from "../../entities/BusinessUnitEntity";
import UserEntity from "../../entities/UserEntity";
import GroupEntity from "../../entities/GroupEntity";

interface props {
  shiftRepository: ShiftRepository;
}

@injectable()
export default class SearchShiftsWithUsersGroupByStatusUseCase {
  private shiftRepository: ShiftRepository;

  public constructor(_: props) {
    this.shiftRepository = _.shiftRepository;
  }

  public async call(businessUnitId: BusinessUnitEntity[], supervisorId: UserEntity[], employeesIds: UserEntity[], groupsIds: GroupEntity[], days: Date[], page: number, itemsPerPage: number): Promise<SearchResultEntity<StaffPerShiftEntity>> {
    return this.shiftRepository.searchWithUserGroupStatus( businessUnitId, supervisorId, employeesIds, groupsIds, days, page, itemsPerPage);
  }
}

export const SearchShiftsWithUsersGroupByStatusUseCaseName = "SearchShiftsWithUsersGroupByStatusUseCase";