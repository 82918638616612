/********************************************************************************
 * File Header - Search Users Api Impl
 * Created By: Marlon Alejandro Mendez Castañeda
 * Contact: +57 3234686680
 * Date: January 25, 2024
 *
 * Description:
 * Search users and get the info
 * 
 * Comments: Api done, we need to check if the email attribute changes of name
 * 
 ********************************************************************************/

import { isLeft, isRight } from "fp-ts/lib/Either";
import SearchResultEntity from "../../../../../domain/entities/SearchResultEntity";
import UserEntity from "../../../../../domain/entities/UserEntity";
import UserHostDto from "../../../../dto/impl/UserHostDto";
import di from "../../../../../di/DependencyInjection";
import UserHostApi, { UserHostApiName } from "../../../../settings/UserHostApi";
import GetAllRolesUseCase, { GetAllRolesUseCaseName } from "../../../../../domain/use_cases/roles/GetAllRolesUseCase";
import SetPermissionOfUserApiImpl from "../../../auth/impl/api/SetPermissionOfUserApiImpl";

const SearchUsersApiImpl = async (word: string, page: number, itemsPerPage: number, filter: { [key: string]: any }): Promise<SearchResultEntity<UserEntity>> => {
    try {
        
        const relativeUrl = "/users";
        let params = `?keyword=${word}&page=${page}&per_page=${itemsPerPage}`;
        //convert filter to params
        const aditionalParams = UserHostDto.filterToJSON(filter);

        //convert object to url params if value is array convert to multiple instance of objects
        const parse = Object.entries(aditionalParams)?.reduce((acc, [key, value]) => {
            if (value === '' || value === undefined) return acc;
            else if (Array.isArray(value)) {
                return acc + `&${(value.map((v: any) => `${key}=${v}`).join('&'))}`;
            }
            return acc + `&${key}=${value}`;
        }, '')

        let pathR = params + parse;

        if (filter["email"]) {
            params = `?${parse.substring(1)}&page=${page}&per_page=${itemsPerPage}`;
            pathR = params;
        }
        const userList = await di.get<UserHostApi>(UserHostApiName).get(relativeUrl + pathR);

        if (isLeft(userList)) {
            return {
                items: [],
                total: 0,
                pages: 0,
            }
        }

        let users: UserEntity[] = userList.data.map((user: any) => UserHostDto.fromJson(user));

        const roles = await di.get<GetAllRolesUseCase>(GetAllRolesUseCaseName).call(false);

        if (isRight(roles)) {
            users.forEach((user: UserEntity) => {
                SetPermissionOfUserApiImpl(user); 
            });
        }

        return {
            items: users,
            total: users.length,
            pages: 1,
        }
    }
    catch (error: any) {
        return {
            items: [],
            pages: 0,
            total: 0,
        };
    }
}

export default SearchUsersApiImpl;
