import { FC, useContext, useEffect, useState } from "react";
import { Either, isRight, right } from "fp-ts/lib/Either";
import { FieldValues, UseFormReturn, set, useForm } from "react-hook-form";
import Icons from "../../../../../assets/Icons";
import LocalizationContext from "../../../../../../domain/providers/localization/LocalizationContext";
import LocalizationContextType from "../../../../../../domain/providers/localization/LocalizationContextType";
import MastersContext from "../../../../../../domain/providers/master/MastersContext";
import MastersContextType from "../../../../../../domain/providers/master/MastersContextType";
import ModalsContext from "../../../../../../domain/providers/modal/ModalsContext";
import ModalsContextType from "../../../../../../domain/providers/modal/ModalsContextType";
import KeyWordLocalization from "../../../../../providers/localization/dictionaries/KeyWordLocalization";
import DateParse, { getDayOfCalendar } from "../../../../../utils/DateParse";
import Validators from "../../../../../utils/Validators";
import CreateInteractionFormComponentProps from "./CreateInteractionFormComponentProps";
import { ErrorMessage } from "@hookform/error-message";
import SelectFormComponent from "../../../../../components/forms/select/SelectFormComponent";

const CreateInteractionFormComponent: FC<CreateInteractionFormComponentProps> = ({
    handleAddEvent,
    handleEditEvent,
    editInteraction,
    date,
}) => {
    const formFunctions = useForm();
    const { closeModalCustom } = useContext(ModalsContext) as ModalsContextType;
    const { register, handleSubmit, getValues, setValue, watch, formState: { errors } } = formFunctions;
    const { i18n } = useContext(LocalizationContext) as LocalizationContextType;
    const { typeSchedules } = useContext(MastersContext) as MastersContextType;
    const { addToast } = useContext(ModalsContext) as ModalsContextType;

    const [loading, setLoading] = useState(false);


    const _handleCreateEvent = async (data: any) => {
        if (handleAddEvent == undefined) return;
        setLoading(true);
        const hourInit = data[KeyWordLocalization.TrackingClockInteractionEntityClockInTime];
        const initDate = new Date(date!.getFullYear(), date!.getMonth(), date!.getDate(), hourInit.split(':')[0], hourInit.split(':')[1]);
        const hourEnd = data[KeyWordLocalization.TrackingClockInteractionEntityClockOutTime];
        const endDate = new Date(date!.getFullYear(), date!.getMonth(), date!.getDate(), hourEnd.split(':')[0], hourEnd.split(':')[1]);
        const response = await handleAddEvent(initDate, endDate, data[KeyWordLocalization.TrackingClockInteractionEntityComment], data[KeyWordLocalization.TrackingClockInteractionEntityType]);
        if (isRight(response)) {
            addToast(i18n(KeyWordLocalization.CreateInteractionFormComponentEventCreated), "success", undefined);
            closeModalCustom();
        } else {
            setLoading(false);
            addToast(i18n(response.left.code ?? KeyWordLocalization.UnknownError), "error", undefined);
        }
    }

    const _handleCancel = () => closeModalCustom();

    const _handleEditEvent = async (data: any) => {
        if (handleEditEvent == undefined || editInteraction == undefined) return;
        setLoading(true);
        const copyEvent = { ...editInteraction };
        const clockTimeData = data[KeyWordLocalization.TrackingClockInteractionEntityClockInTime];
        copyEvent.clockInTime = new Date(date!.getFullYear(), date!.getMonth(), date!.getDate(), clockTimeData.split(':')[0], clockTimeData.split(':')[1]);
        const clockOutTimeData = data[KeyWordLocalization.TrackingClockInteractionEntityClockOutTime];
        copyEvent.clockOutTime = new Date(date!.getFullYear(), date!.getMonth(), date!.getDate(), clockOutTimeData.split(':')[0], clockOutTimeData.split(':')[1]);
        const response = await handleEditEvent(copyEvent, data[KeyWordLocalization.TrackingClockInteractionEntityComment]);
        if (isRight(response)) {
            addToast(i18n(KeyWordLocalization.CreateInteractionFormComponentEventUpdated), "success", undefined);
            closeModalCustom();
        } else {
            setLoading(false);
            addToast(i18n(response.left.code ?? KeyWordLocalization.UnknownError), "error", undefined);
        }
    }

    const _loadDefaultData = () => {
        if (editInteraction != undefined) {
            setValue(KeyWordLocalization.TrackingClockInteractionEntityClockInTime, DateParse.getTimeForInput(editInteraction.clockInTime));
            setValue(KeyWordLocalization.TrackingClockInteractionEntityClockOutTime, editInteraction.clockOutTime != null ? DateParse.getTimeForInput(editInteraction.clockOutTime) : null);
            setValue(KeyWordLocalization.TrackingClockInteractionEntityType, editInteraction.tagType?.id);
        }
    }


    useEffect(() => {
        _loadDefaultData();
    }, [date, editInteraction])

    return <div className="create_event_component">
        <form onSubmit={editInteraction != undefined ? handleSubmit(_handleEditEvent) : handleSubmit(_handleCreateEvent)}>
            <div className="row">
                <div className="col-6 my-2 col-md-3">
                    <div className="form-group">
                        <label>{i18n(KeyWordLocalization.TrackingClockInteractionEntityClockInTime)}</label>
                        <input type="time" step={60} className="form-control" {...register(KeyWordLocalization.TrackingClockInteractionEntityClockInTime, Validators({ required: true, lessThan: watch(KeyWordLocalization.TrackingClockInteractionEntityClockOutTime) }))} />
                        <ErrorMessage as="aside" errors={errors} name={KeyWordLocalization.TrackingClockInteractionEntityClockInTime} />
                    </div>
                </div>
                <div className="col-6 my-2 col-md-3">
                    <div className="form-group">
                        <label>{i18n(KeyWordLocalization.TrackingClockInteractionEntityClockOutTime)}</label>
                        <input type="time" step={60} className="form-control" {...register(KeyWordLocalization.TrackingClockInteractionEntityClockOutTime, Validators({ required: true, greaterThan: watch(KeyWordLocalization.TrackingClockInteractionEntityClockInTime) }))} />
                        <ErrorMessage as="aside" errors={errors} name={KeyWordLocalization.TrackingClockInteractionEntityClockOutTime} />
                    </div>
                </div>
                {editInteraction == null && <div className="col-12">
                    <SelectFormComponent label={i18n(KeyWordLocalization.TrackingClockInteractionEntityType)} errors={errors} keyName={KeyWordLocalization.TrackingClockInteractionEntityType} {...formFunctions} onChange={setValue} required options={typeSchedules.map((typeSchedule) => {
                        return {
                            id: typeSchedule.id,
                            label: typeSchedule.name,
                        }
                    })} />
                </div>
                }
                <div className="col-12">
                    <label>{i18n(KeyWordLocalization.TrackingClockInteractionEntityComment)}</label>
                    <textarea className="form-control" {...register(KeyWordLocalization.TrackingClockInteractionEntityComment, Validators({
                        required: true,
                        minLength: 10,
                        maxLength: 255,
                    }))} />
                    <ErrorMessage as="aside" errors={errors} name={KeyWordLocalization.TrackingClockInteractionEntityComment} />
                </div>

                <div className="col-6 my-2">
                    <div onClick={_handleCancel} className="w-100 btn btn_light">{i18n(KeyWordLocalization.Cancel)}</div>
                </div>
                <div className="col-6 my-2">
                    <button type="submit" disabled={loading} className="w-100 btn btn_primary">{i18n(KeyWordLocalization.Create)}</button>
                </div>
            </div>
        </form>
    </div>
}

export default CreateInteractionFormComponent;