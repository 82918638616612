/********************************************************************************
 * File Header - Search Business Api Impl
 * Created By: Marlon Alejandro Mendez Castañeda
 * Contact: +57 3234686680
 * Date: February 15, 2024
 *
 * Description:
 * Search business paginated
 * 
 * Comments: The app is done, when the data is empty the error is a text, but the app return []
 * 
 ********************************************************************************/

import di from "../../../../../di/DependencyInjection";
import BusinessUnitEntity from "../../../../../domain/entities/BusinessUnitEntity";
import SearchResultEntity from "../../../../../domain/entities/SearchResultEntity";
import BusinessUnitHostDto from "../../../../dto/impl/BusinessUnitHostDto";
import UserHostApi, { UserHostApiName } from "../../../../settings/UserHostApi";

const SearchBusinessUnitApiImpl = async (word: string, page: number, itemsPerPage: number, filter: any): Promise<SearchResultEntity<BusinessUnitEntity>> => {
    try {
        const relativeUrl = "/businessunits";
        const params = `?keyword=${word}&page=${page}&per_page=${itemsPerPage} `
        const groupsList = await di.get<UserHostApi>(UserHostApiName).get(relativeUrl + params);
        
        const groups: BusinessUnitEntity[] = groupsList.data.map((group: any) => BusinessUnitHostDto.fromJson(group));
        return {
            items: groups,
            total: groups.length,
            pages: 1,
        }
    }
    catch (error: any) {
        return {
            items: [],
            pages: 0,
            total: 0,
        };
    }}

export default SearchBusinessUnitApiImpl;