import { injectable } from "inversify";
import ShiftRepository from "../../repositories/ShiftRepository";
import ExceptionEntity from "../../entities/ExceptionEntity";
import { Either } from "fp-ts/lib/Either";
import ShiftEntity from "../../entities/ShiftEntity";

interface props {
    shiftRepository: ShiftRepository;
}
@injectable()
export default class GetShiftCalendarOfDaysUseCase {
  private shiftRepository: ShiftRepository;

  public constructor(_:props) {
    this.shiftRepository = _.shiftRepository;
  }

  public async call(shiftId: string, startDate: Date, endDate: Date): Promise<Either<ExceptionEntity, ShiftEntity[]>> {
    return this.shiftRepository.getShiftCalendar(shiftId, startDate, endDate);
  }
}

export const GetShiftCalendarOfDaysUseCaseName = "GetShiftCalendarOfDaysUseCase";