import './CalendarPersonStyles.scss';
import { FC, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Either, isRight } from "fp-ts/lib/Either";
import di from "../../../../di/DependencyInjection";
import UserEntity from "../../../../domain/entities/UserEntity";
import { GetUserInfoByIdUseCase, GetUserInfoByIdUseCaseName } from "../../../../domain/use_cases/user/GetUserInfoByIdUseCase";
import LoadingComponent from "../../../components/LoadingComponent/LoadingComponent";
import NotFoundComponent from "../../../components/notFound/NotFoundComponent";
import { DateOperations } from "../../../utils/DateOperations";
import UserContext from "../../../../domain/providers/user/UserContext";
import UserContextType from "../../../../domain/providers/user/UserContextType";
import { routes } from "../../../routes/RoutesComponent";
import GetUserCalendarOfDaysUseCase, { GetUserCalendarOfDaysUseCaseName } from "../../../../domain/use_cases/calendar/GetUserCalendarOfDaysUseCase";
import { useForm } from 'react-hook-form';
import UserHeaderInfoComponent from '../../../components/user/userHeader/UserHeaderInfoComponent';
import CalendarWeekComponent from '../../../components/calendar/calendarWeek/CalendarWeekComponent';
import TypeEventScheduleEntity from '../../../../domain/entities/TypeEventScheduleEntity';
import EventScheduleEntity from '../../../../domain/entities/EventScheduleEntity';
import ModalsContext from '../../../../domain/providers/modal/ModalsContext';
import ModalsContextType from '../../../../domain/providers/modal/ModalsContextType';
import CreateEventFormComponent from '../../../components/calendar/createEventForm/CreateEventFormComponent';
import CreateEventInCalendarUseCase, { CreateEventInCalendarUseCaseName } from '../../../../domain/use_cases/calendar/CreateEventInCalendarUseCase';
import ExceptionEntity from '../../../../domain/entities/ExceptionEntity';
import LocalizationContext from '../../../../domain/providers/localization/LocalizationContext';
import LocalizationContextType from '../../../../domain/providers/localization/LocalizationContextType';
import KeyWordLocalization from '../../../providers/localization/dictionaries/KeyWordLocalization';
import ShiftEntity from '../../../../domain/entities/ShiftEntity';

const CalendarPersonPage: FC<{}> = () => {
    const { user } = useContext(UserContext) as UserContextType;
    const {i18n} = useContext(LocalizationContext) as LocalizationContextType;
    const { openModalCustom, addToast } = useContext(ModalsContext) as ModalsContextType;
    const params = useParams<{ userId: string }>();

    const formFunctions = useForm();
    const { register, handleSubmit, watch, formState: { errors }, setValue } = formFunctions
    const userId = routes.my_calendar.path === window.location.pathname ? user!.id : params.userId;

    const [userInfo, setUserInfo] = useState<UserEntity | undefined | null>(undefined); //undefined: loading, null: not found
    const [currentWeek, setCurrentWeek] = useState<Date[]>(DateOperations.getWeekDays(new Date())); //undefined: loading, null: not found
    const [calendar, setCalendar] = useState<ShiftEntity[] | undefined>(undefined); //undefined: loading, null: not found

    const _getUser = async () => {
        if (userId === undefined || userId === "") return setUserInfo(null);
        if(userId === user?.id) return setUserInfo(user!);
        const response = await di.get<GetUserInfoByIdUseCase>(GetUserInfoByIdUseCaseName).call(userId!);
        if (response === undefined) return setUserInfo(null);
        setUserInfo(response);
    }

    const _getWeekCalendar = async (dates: Date[]) => {
        //you can use DateOperations.getWeekDays to get the week days dat contains a day
        if (userId === undefined || userId === "") return setCalendar([]);
        
        const startDate = dates[0];
        const endDate = dates[dates.length - 1];
        const response = await di.get<GetUserCalendarOfDaysUseCase>(GetUserCalendarOfDaysUseCaseName).call(userId!, startDate, endDate);
        
        if (isRight(response)) return setCalendar(response.right);
        else return setCalendar([]);
    }

    // const _boolCanAddEvent = (userId != user?.id && user?.permissions?.calendar.others_calendar.create) || (userId == user?.id && user?.permissions?.calendar.my_calendar.create);
    // const _boolCanEditEvent = (userId != user?.id && user?.permissions?.calendar.others_calendar.update) || (userId == user?.id && user?.permissions?.calendar.my_calendar.update);
    const _boolCanAddEvent = (userId != user?.id && user?.permissions?.calendar.others_calendar.create);
    const _boolCanEditEvent = (userId != user?.id && user?.permissions?.calendar.others_calendar.update);

    const _handleAddEventToCalendar = async (eventType: TypeEventScheduleEntity | null, date: Date | undefined): Promise<void> => {
        const onEventCreated = async (event: EventScheduleEntity) => {
            //search day with same date and add event
            const copyCalendar = [...calendar!];
            const day = copyCalendar?.find(day => DateOperations.isSameDate(day.initHour, event.dateStart));
            day?.events.push(event);
            setCalendar(copyCalendar);
        };

        const _handleCreateEvent = async (event: EventScheduleEntity): Promise<Either<ExceptionEntity, EventScheduleEntity>> => {
            const response = await di.get<CreateEventInCalendarUseCase>(CreateEventInCalendarUseCaseName).call(event, userInfo!.id);
            if (isRight(response)) {
                onEventCreated(response.right);
            }
            return response;
        }
        openModalCustom("lg", i18n(KeyWordLocalization.CalendarPersonPageCreateEvent), <CreateEventFormComponent handleAddEvent={_handleCreateEvent} type={eventType} date={date} />);
    }

    const _handleEditEventToCalendar = async (event: EventScheduleEntity): Promise<void> => {
        const onEventEdited = async (event: EventScheduleEntity) => {
            //search day with same date and add event
            const copyCalendar = [...calendar!];
            const day = copyCalendar?.find(day => DateOperations.isSameDate(day.initHour, event.dateStart));
            let foundEvent = day?.events.find(e => e.id == event.id);
            foundEvent = event;
            setCalendar(copyCalendar);
        };

        const _handleEditEvent = async (event: EventScheduleEntity): Promise<Either<ExceptionEntity, EventScheduleEntity>> => {
            const response = await di.get<CreateEventInCalendarUseCase>(CreateEventInCalendarUseCaseName).call(event, userInfo!.id);
            if (isRight(response)) onEventEdited(response.right);
            return response;
        }
        openModalCustom("lg", i18n(KeyWordLocalization.CalendarPersonPageEditEvent), <CreateEventFormComponent handleEditEvent={_handleEditEvent} editEvent={event} />);
    }

    const _handleOnChangeWeek = (dates: Date[]) => {
        setCurrentWeek(dates);
        _getWeekCalendar?.(dates);
    }


    useEffect(() => {
        _getUser();
        _getWeekCalendar(currentWeek);
    }, [userId]);

    if (calendar === undefined) return <LoadingComponent />
    if (calendar === null) return <NotFoundComponent />
    return <div className="calendar_person_page">
        <div className="page_content">
            {userInfo && <UserHeaderInfoComponent user={userInfo} />}
            <CalendarWeekComponent calendar={calendar} handleEditEvent={_boolCanEditEvent ? _handleEditEventToCalendar : undefined} handleAddEvent={_boolCanAddEvent ? _handleAddEventToCalendar : undefined} fullClickeable={true} onWeekChange={_handleOnChangeWeek} currentWeek={currentWeek} />
        </div>
    </div>
}

export default CalendarPersonPage;