import { injectable } from "inversify";
import UserRepository from "../../repositories/UserRepository";
import UserEntity from "../../entities/UserEntity";

interface props {
    userRepository: UserRepository;
}

@injectable()
export class GetUserInfoByIdUseCase {
    _userRepository: UserRepository;

    constructor(_: props) {
        this._userRepository = _.userRepository;
    }

    public call = async (id: string): Promise<UserEntity | undefined> => {
        return await this._userRepository.getEmployeeById(id);
    }
}

export const GetUserInfoByIdUseCaseName = "GetUserInfoByIdUseCaseName";