import './UserNameWithPhotoComponent.scss';
import { FC } from "react";
import UserNameWithPhotoComponentProps from "./UserNameWithPhotoComponentProps";
import TextOperations from '../../../utils/TextOperations';

const UserNameWithPhotoComponent: FC<UserNameWithPhotoComponentProps> = ({ user, showRole, photoRadius }) => {
    return <div className="user_name_with_photo_component">
        <div className="d-flex">
            <img src={user.image || user.profile_picture || '/assets/no_photo_profile.svg'} alt="" className="img_rounded" style={photoRadius ? { width: photoRadius, height: photoRadius } : {}} />
            <div className="ms-2 d-flex flex-column justify-content-center">
                <strong style={{fontSize: "12px"}}>{user.name || TextOperations.capitalizeFirstLetter(`${user.firstname} ${user.lastname}`)}</strong>
                {showRole && <span className="ms-2">{user.role?.name}</span>}
            </div>
        </div>
    </div>
}

UserNameWithPhotoComponent.defaultProps = {
    showRole: false,
}

export default UserNameWithPhotoComponent;