import { FC, useContext, useEffect, useRef, useState } from 'react';
import di from '../../../../di/DependencyInjection';
import SearcherComponent from '../../../components/searcher/SearcherComponent';
import { useNavigate } from 'react-router-dom';
import KeyWordLocalization from '../../../providers/localization/dictionaries/KeyWordLocalization';
import LocalizationContextType from '../../../../domain/providers/localization/LocalizationContextType';
import LocalizationContext from '../../../../domain/providers/localization/LocalizationContext';
import { FilterOptionType } from '../../../../domain/entities/FilterOptionEntity';
import UserEntity from '../../../../domain/entities/UserEntity';
import SearchUserUseCase, { SearchUserUseCaseName } from '../../../../domain/use_cases/user/SearchUserUseCase';
import CardEmployeeComponent from './components/cardEmployee/CardEmployeeComponent';
import ButtonComponent from '../../../components/button/ButtonComponent';
import { ButtonType } from '../../../components/button/ButtonComponentProps';
import Icons from '../../../assets/Icons';
import './PeoplePageStyles.scss';
import { routes } from '../../../routes/RoutesComponent';
import UserContextType from '../../../../domain/providers/user/UserContextType';
import UserContext from '../../../../domain/providers/user/UserContext';

const PeoplePage: FC<{}> = () => {

    const navigate = useNavigate();

    const { i18n } = useContext(LocalizationContext) as LocalizationContextType;
    const { user } = useContext(UserContext) as UserContextType;

    const [ openUserModel, setOpenUserModal ] = useState(false);


    const modalRef = useRef<HTMLDivElement>(null);

    const _search = async (word: string, page: number, itemsPerPage: number, filter: { [key: string]: any }) => (await di.get<SearchUserUseCase>(SearchUserUseCaseName).call(word, page, itemsPerPage, filter)).items;


    const _handleGoToDetail = (trainingClass: UserEntity) => {
        //navigate(routes.user_calendar.relativePath(trainingClass.id));
    }

    const _handleAddUser = () => {
        setOpenUserModal(true);
    }

    const handleClickOutside = (event: MouseEvent) => {
        if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
            setOpenUserModal(false);
        }
    };

    const deleteItem = {
        id: "1",
        icon: Icons.Trash,
        content: "Delete Users",
        action: () => navigate(routes.employeesDelete.relativePath)
    };
    const edtiItem = {
        id: "2",
        icon: Icons.EditPencil,
        content: "Edit Users",
        action: () => navigate(routes.employeesUpdate.relativePath)
    }
    const createItem = {
        id: "3",
        icon: Icons.AddPeople,
        content: "Create Users",
        action: () => navigate(routes.employeesCreate.relativePath)
    }
    const finalItems: any[] = []; 
    if (user?.permissions?.user?.create) finalItems.push(createItem);
    if (user?.permissions?.user?.update) finalItems.push(edtiItem);
    if (user?.permissions?.user?.delete) finalItems.push(deleteItem);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    return <div className="container">
            {finalItems.length > 0 && (
                <div className="floating_button">
                    <ButtonComponent text={"User Management"} type={ButtonType.MAIN} onClick={_handleAddUser} icon={<Icons.Plus />} />
                </div>
            )}
        {openUserModel &&
            <div className='user_edition_container'>
                <div className='user_edition_modal' ref={modalRef}>
                    {finalItems.map((item, index) => {
                        return(
                            <div key={index} className='user_edition_item' onClick={() => item.action()}>{item.content}</div>
                        )
                    })}
                </div>
            </div>
        }
        
        <SearcherComponent onSearch={_search}
            sortOptions={[
                {
                    keyName: KeyWordLocalization.UserEntityId,
                    label: i18n(KeyWordLocalization.UserEntityId),
                },
                {
                    keyName: KeyWordLocalization.UserEntityName,
                    label: i18n(KeyWordLocalization.UserEntityName),
                }
            ]}
            filterOptions={[
                {
                    keyName: KeyWordLocalization.UserEntityName,
                    label: i18n(KeyWordLocalization.UserEntityName),
                    type: FilterOptionType.TEXT,
                },
            ]}
            itemsPerPage={20}
            render={(item: UserEntity, index: number) =>
                <div className="col-12 col-md-6 col-lg-4 col-xl-4 my-3" key={index}>
                    <CardEmployeeComponent employee={item} onClick={() => _handleGoToDetail(item)} />
                </div>
            }
            title='Employees'
            isInfinitive = {true}
        // floatingButtons={
        //     <Link className="btn btn_primary text_yellow d-flex align-items-center " to={routes.create_course.relativePath}>
        //         <MdAddCircle /> <span className='ms-2'>{i18n(KeyWordLocalization.Create)}</span>
        //     </Link>
        // } 
        />
    </div >
}

export default PeoplePage;