import { Either, isRight, left, right } from "fp-ts/lib/Either";
import ExceptionEntity from "../../../../domain/entities/ExceptionEntity";
import RoleEntity from "../../../../domain/entities/RoleEntity";
import GetAllRolesApiImpl from "./GetAllRolesApiImpl";

//TODO implement
const GetRoleByIdApiImpl = async (id: string): Promise<Either<ExceptionEntity, RoleEntity>>  => {
    try {
        const response = await GetAllRolesApiImpl();
        if(isRight(response)){
            const roleById = response.right.filter(role => role.id == id);
            return right(roleById[0]);
        } else {
            return response;
        }
        
    } catch (error) {
        return left({});
    }
    
}

export default GetRoleByIdApiImpl;
