import ExceptionEntity from "../../../../../domain/entities/ExceptionEntity";
import { Either, left, right } from "fp-ts/lib/Either";
import UserEntity from "../../../../../domain/entities/UserEntity";
import callAPI from "../../../fetchCallImplementation";
import Constants, { ConstantsName } from "../../../../../constants/Constants";
import di from "../../../../../di/DependencyInjection";

const SearchAllUsersApiImpl = async (): Promise<Either<ExceptionEntity, UserEntity[]>> => {
    try {
        const baseUrl = di.get<Constants>(ConstantsName).HOST_API_USERS_URL;
        const response = await callAPI(`${baseUrl}/users`, 'GET', {});
        
        if (!response.ok) {
            const value  = await response.body?.getReader().read();
            const result = new TextDecoder().decode(value?.value);
            throw new Error(result);
        }

        const reader = response.body?.getReader();

        if (!reader) {
            throw new Error("Readable stream not supported or body is empty.");
        }
        
        let result = '';
        const decoder = new TextDecoder();

        while (true) {
            const { done, value } = await reader.read();
            if (done) break;
            result += decoder.decode(value, { stream: true });
        }

        // Decoding the last chunk
        result += decoder.decode();
            
        return right(JSON.parse(result).data);
        
    } catch (error: any) {
        const exception: ExceptionEntity = {message: error.message};

        if(error.message === 'User is not valid or is unable to see other users.'){
            return right([]);
        }
        
        return left(exception);
    }
}

export default SearchAllUsersApiImpl;