import { FC, useContext } from "react";
import ConfirmModalComponentProps from "./ConfirmModalComponentProps";
import ModalsContext from "../../../../domain/providers/modal/ModalsContext";
import ModalsContextType from "../../../../domain/providers/modal/ModalsContextType";

const ConfirmModalComponent: FC<ConfirmModalComponentProps> = ({ text, onConfirm, onCancel }) => {
    const { closeModalCustom } = useContext(ModalsContext) as ModalsContextType;
    const onConfirmHandler = () => {
        onConfirm?.();
        closeModalCustom();
    }

    const onCancelHandler = () => {
        onCancel?.();
        closeModalCustom();
    }
    return <div className="confirm_modal_component">
        <div>{text}</div>
        <hr />
        <div className="w-100 row mt-2">
            <div className="col-md-6">
                <button className="btn btn-light w-100" onClick={onCancelHandler}>Cancel</button>
            </div>
            <div className="col-md-6">
                <button className="btn btn-danger w-100" onClick={onConfirmHandler}>Confirm</button>
            </div>
        </div>
    </div>
}

export default ConfirmModalComponent;