import LocalizationEntity from '../../../../domain/entities/LocalizationEntity';
import en from './en.json';
import es from './es.json';

const LocalizationList: LocalizationEntity[] = [
    {
        name: 'english',
        code: 'en',
        dictionary: en
    },
    {
        name: 'español',
        code: 'es',
        dictionary: es
    }
];

export default LocalizationList;