import GroupEntity from "../../../domain/entities/GroupEntity";
import UserEntity from "../../../domain/entities/UserEntity";
import UserHostDto from "./UserHostDto";

//TODO DTO check
const fromJson = (json: any): GroupEntity => {
    const temp: GroupEntity = {
        id: json.group_id,
        name: json.name,
        users: json.users?.map((group: any)=> UserHostDto.fromJson(group)) ?? [],
        description: json.description,
    }

    return temp;
}

const toJson = (group: GroupEntity):any => {
    return {
        group_id: group.id,
        name: group.name,
        users: group.users?.map((user: UserEntity)=> UserHostDto.toJson(user)) ?? [],
        description: group.description,
    }
}

export default {
    fromJson,
    toJson,
}