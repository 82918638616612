import { FC, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import RoleInformationComponent from "../detail/components/roleInformation/RoleInformationComponent";
import GetRoleInfoByIdUseCase, { GetRoleInfoByIdUseCaseName } from "../../../../domain/use_cases/roles/GetRoleInfoByIdUseCase";
import di from "../../../../di/DependencyInjection";
import LoadingComponent from "../../../components/LoadingComponent/LoadingComponent";

import './RoleEditPermissionsPageStyles.scss';
import Icons from "../../../assets/Icons";
import ModulePermisionsEditableComponent from "./components/ModulePermisionsEditableComponent";
import ModulePermision from "../../../../domain/entities/ModulePermision";
import { UpdateRolePermissionsUseCase, UpdateRolePermissionsUseCaseName } from "../../../../domain/use_cases/roles/UpdateRolePermissionsUseCase";
import { isRight } from "fp-ts/lib/Either";
import ModalsContext from "../../../../domain/providers/modal/ModalsContext";
import ModalsContextType from "../../../../domain/providers/modal/ModalsContextType";
import GetAllPermissionsUseCase, { GetAllPermissionsUseCaseName } from "../../../../domain/use_cases/roles/GetAllPermissionsUseCase";
import RoleEntity from "../../../../domain/entities/RoleEntity";
import ModuleEntity from "../../../../domain/entities/ModuleEntity";
import RolesContext from "../../../../domain/providers/roles/RolesContext";
import RolesContextType from "../../../../domain/providers/roles/RolesContextType";

import { isEmpty } from 'fp-ts/lib/ReadonlyRecord';
import GetAllRolesUseCase, { GetAllRolesUseCaseName } from "../../../../domain/use_cases/roles/GetAllRolesUseCase";
import LoadUseCase, { LoadUseCaseName } from "../../../../domain/use_cases/default/LoadUseCase";

interface RolePermissionsGroups {
    [key: string]: {
        permissions: Array<string>;
        resources: Array<string>;
    };
}

interface PolicyToUpdate {
    [key: string]: {
        policy_name: string;
        effect: string;
        policy: {
        resource: string;
        permissions: Array<string>;
        };
        policy_id: number;
    };
}

interface ModulesUpdate {
    policy_name: string;
    effect: string;
    policy: {
        resource: string;
        permissions: string[];
    };
    policy_id: string;
};

const RoleEditPermissionsPage: FC<{}> = () => {
    
    const roleParams = useParams<{ id: string }>();
    
    const {addToast} = useContext(ModalsContext) as ModalsContextType;
    
    const [ roleInfoById, setRoleInfoById ] = useState<RoleEntity>();
    const _roleById = async() => await di.get<GetRoleInfoByIdUseCase>(GetRoleInfoByIdUseCaseName).call(roleParams.id as string);
    
    const _search = async () => await di.get<GetAllRolesUseCase>(GetAllRolesUseCaseName).call(true);

    const _allPermissionsAPI = async() => await di.get<GetAllPermissionsUseCase>(GetAllPermissionsUseCaseName).call();
    
    const [ updatedData, setUpdatedData ] = useState<PolicyToUpdate>({});

    const [ allPermissions, setAllPermissions ] = useState<RolePermissionsGroups>({});

    const [ isLoading, setIsLoading ] = useState(false);
    const [isContentLoading, setisContentLoading] = useState(true);

    const { roles, setRoles, users, setUsers, permissions, setPermissions } = useContext(RolesContext) as RolesContextType;

    const _firstSearch = async () => {

        let _allRoles = roles;

        let _allPermissions: any = [];

        if(isEmpty(_allRoles)){
            
            const allRoles = await _search();
            if(isRight(allRoles)){
                setRoles(allRoles.right);
                _allRoles = allRoles.right;
                
                
            } else {
                setRoles([]);
                _allRoles = [];
            }
        }
    
            
            const allPermissionsResponse = await _allPermissionsAPI();
            
            if(isRight(allPermissionsResponse)){
                _allPermissions = [allPermissionsResponse.right];
                
                
            } else {
                _allPermissions = [];
            }
    
        

        const roleByIdAPI = _allRoles?.filter((role: RoleEntity) => role.id == roleParams.id);   
        
        
        setRoleInfoById(roleByIdAPI[0]);            
        

        const allPermissionsCast = _allPermissions[0] as RolePermissionsGroups;

        setAllPermissions(allPermissionsCast);
    
    }

    const handleUpdatePermissions = (value: ModulePermision[] = [], resource: string) => {

        
        if (resource !== undefined) {
            
            setRoles([]);
        }
        
        let permisions = value.map((el: ModulePermision) => {
            return [
                el.canAssign === true ? `Assign${el.name}`:'',
                el.canCreate === true ? `Create${el.name}`:'',
                el.canDelete === true ? `Delete${el.name}`:'',
                el.canView === true ? `View${el.name}`:'',
                el.canEdit === true ? `Edit${el.name}`:'',
            ]
        }).flat().filter((permission: string) => permission !== '');

        

        let modulesUpdate = roleInfoById?.modules.map((module: ModuleEntity) => {

            if (module.name.toLowerCase().replaceAll(" ", "_") === resource?.toLowerCase().replaceAll(" ", "_")) {
                let policiesCast = {
                    "resource": module.name === 'Roles And Permissions' ? 'User Management' : module.name,
                    "permissions": permisions,
                };
                let policyJSON = {
                    "policy_name": module.policyName,
                    "effect": "Allow",
                    "policy": policiesCast,
                    "policy_id": module.id
                };

                return policyJSON;
            }


        }).filter((item: any) => item !== undefined) as unknown as ModulesUpdate[];
        
        setUpdatedData({
            ...updatedData,
            [(modulesUpdate[0]?.policy_id as unknown as number)]: {
                ...modulesUpdate[0],
                policy_id: modulesUpdate[0]?.policy_id
            }
        }); 
        
        
    };

    const handleSaveUpdate = async () => {

        const dataToUpdate = updatedData;
        delete dataToUpdate['undefined'];

        for (let key in dataToUpdate){
            setIsLoading(true);

            if (dataToUpdate[key].policy_id !== undefined) {
                                
                let _rolePermissionsUpdate = async() => await di.get<UpdateRolePermissionsUseCase>(UpdateRolePermissionsUseCaseName).call(dataToUpdate[key].policy_name, dataToUpdate[key]);
                const responseFromUpdate = await _rolePermissionsUpdate();

                if(isRight(responseFromUpdate)){
                    
                    await _firstSearch();
                    
                    await di.get<LoadUseCase>(LoadUseCaseName).call();
                    
                    setIsLoading(false);
                    setisContentLoading(false);
                } else {
                    setIsLoading(false);
                    
                    setisContentLoading(false);

                    if(responseFromUpdate.left.message === 'Invalid role.'){
                        addToast('Insufficient update permission', 'error', null, responseFromUpdate.left.message?.toLocaleLowerCase().replaceAll(' ', '') === 'failedtofetch' ? false : true);
                    } else if(responseFromUpdate.left.message === '[] is too short'){
                            addToast('Assigning a permission is mandatory', 'error', null, responseFromUpdate.left.message?.toLocaleLowerCase().replaceAll(' ', '') === 'failedtofetch' ? false : true);
                    } else {
                        addToast(responseFromUpdate.left.message ? responseFromUpdate.left.message === 'Invalid role.' ? 'Insufficient update permission'  : responseFromUpdate.left.message : '', 'error', null, responseFromUpdate.left.message?.toLocaleLowerCase().replaceAll(' ', '') === 'failedtofetch' ? false : true);
                    }
                    
                }
            }
            
        }
        setIsLoading(false);

        
    }

    useEffect(() => {
        _firstSearch();
    }, [])

    return <div className="role_edit_permissions_page_props">
        {
            roleInfoById ? !isLoading ?
            <>
                <RoleInformationComponent role={roleInfoById} hasEdition={{}} hasButtonEdition={false} />
                <div style={{height: "20px"}}></div>
                <section className="role-modules-policies-container">
                <p style={{fontWeight: 'bold'}}>Permissions</p>
                <section>

                    {
                        roleInfoById?.modules.map((moduleItem: any, idx: any) => {
                            {
                                return <section key={idx}>
                                    <ModulePermisionsEditableComponent moduleItem={moduleItem} handleUpdate={handleUpdatePermissions} allPermissions={allPermissions} />
                                </section>
                            }
                        })
                    }

                </section>
                </section>
                <section className="role-edition-save-button-section">
                    <button onClick={() => handleSaveUpdate()} className="role-edition-save-button">
                        <Icons.Plus className="role-edition-save-button-icon"/>
                        Save
                    </button>
                </section>
                

            </> : <LoadingComponent />
            :
            isContentLoading ?
                <LoadingComponent />
                :
                <div className='no_data_retrieved'>
                    Policies for Role not found
                </div>
        }
        
    </div>
}

export default RoleEditPermissionsPage;