import { injectable } from "inversify";
import UserEntity, { UserEntityStatus } from "../../../domain/entities/UserEntity";
import AuthRepository from "../../../domain/repositories/AuthRepository";
import { Either, right, left } from "fp-ts/lib/Either";
import ExceptionEntity from "../../../domain/entities/ExceptionEntity";
import PermisionListEntity from "../../../domain/entities/PermisionListEntity";

const borned = {
  create: true,
  read: true,
  update: true,
  delete: true,
  assign: true,
}
const parsePermisionName: PermisionListEntity = {
  calendar: {
    my_calendar: borned,
    others_calendar: borned,
  },
  shift: {
    calendar: borned,
    asign: borned,
  },
  schedule: borned,
  role: borned,
  employee: borned,
  shiftTemplate: borned,
  timeTracking: borned,
  user: borned,
  policy: borned,
  permission: borned,
  resource: borned,
  attendance: borned,
  shiftSwap: borned,
  aiTranscription: borned,
  aiDashboard: borned,
  aiEvaluation: borned
}
@injectable()
class AuthRepositoryFake implements AuthRepository {
  public async signIn(email: string, password: string): Promise<Either<ExceptionEntity, UserEntity>> {
    return right({
      firstname: 'firstname',
      lastname: 'lastname',
      id: '1',
      image: 'https://images.ctfassets.net/h6goo9gw1hh6/2sNZtFAWOdP1lmQ33VwRN3/24e953b920a9cd0ff2e1d587742a2472/1-intro-photo-final.jpg?w=1200&h=992&fl=progressive&q=70&fm=jpg',
      position: 'Agent Supervisor',
      area: 'Information Technology',
      name: (new Date()).getMilliseconds().toString(),
      phone: 'phone',
      email: email,
      enabled: true,
      status: UserEntityStatus.active,
      permissions: parsePermisionName,
      roles: ['admin']
    });
  }

  public async signInWithActiveDirectory(): Promise<Either<ExceptionEntity, UserEntity>> {
    return right({
      image: 'https://images.ctfassets.net/h6goo9gw1hh6/2sNZtFAWOdP1lmQ33VwRN3/24e953b920a9cd0ff2e1d587742a2472/1-intro-photo-final.jpg?w=1200&h=992&fl=progressive&q=70&fm=jpg',
      position: 'Agent Supervisor',
      area: 'Information Technology',
      id: '1',
      firstname: 'firstname',
      lastname: 'lastname',
      name: (new Date()).getMilliseconds().toString(),
      phone: 'phone',
      email: 'email',
      enabled: true,
      status: UserEntityStatus.active,
      permissions: parsePermisionName,
      roles: ['admin']
    });
  }

  public async signUp(email: string, password: string): Promise<Either<ExceptionEntity, void>> {
    return right(undefined);
  }

  public async signOut(): Promise<Either<ExceptionEntity, void>> {
    return right(undefined);

  }

  public async confirmUser(email: string): Promise<Either<ExceptionEntity, void>> {
    return right(undefined);

  }

  public async sendRecoveryCode(email: string): Promise<Either<ExceptionEntity, void>> {
    return right(undefined);

  }

  public async sendConfirmCode(email: string): Promise<Either<ExceptionEntity, void>> {
    return right(undefined);

  }

  public async updatePasswordByRecovery(email: string, newPassword: string, code: string): Promise<Either<ExceptionEntity, void>> {
    return right(undefined);

  }

  public async getCurrentUser(): Promise<Either<ExceptionEntity, UserEntity>> {
    // return left({ code: '1' });
    return right({
      id: "1",
      name: 'Jhonny Commas',
      firstname: 'Jhonny',
      lastname: 'Commas',
      phone: '+1 305 123 4567',
      email: 'jhonny.commas@itelinternational.com',
      image: 'https://images.ctfassets.net/h6goo9gw1hh6/2sNZtFAWOdP1lmQ33VwRN3/24e953b920a9cd0ff2e1d587742a2472/1-intro-photo-final.jpg?w=1200&h=992&fl=progressive&q=70&fm=jpg',
      position: 'Agent',
      area: 'MIA',
      enabled: true,
      permissions: parsePermisionName,
      status: UserEntityStatus.active,
      roles: ['admin']
    });
  }

  public async addUser(user: UserEntity, password: string): Promise<Either<ExceptionEntity, void>> {
    return right(undefined);

  }

  public async deleteUser(): Promise<Either<ExceptionEntity, void>> {
    return right(undefined);

  }

  public async refreshToken(): Promise<Either<ExceptionEntity, void>> {
    return right(undefined);
  }

  public async updatePassword(): Promise<Either<ExceptionEntity, void>> {
    return right(undefined);
  }
}

export default AuthRepositoryFake;
