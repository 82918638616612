import { FC, useContext, useState } from "react";
import CreateGroupModalComponentProps from "./CreateGroupModalComponentProps";
import di from "../../../../di/DependencyInjection";
import SearchUserUseCase, { SearchUserUseCaseName } from "../../../../domain/use_cases/user/SearchUserUseCase";
import AutoCompleteWithCheckComponent from "../../../components/forms/autocompleteWithCheck/AutoCompleteWithCheckComponent";
import UserEntity from "../../../../domain/entities/UserEntity";
import { AutoCompleteItem } from "../../../components/forms/autocompleteWithCheck/AutoCompleteWithCheckProps";
import UserInListComponent from "../../../components/user/userInList/UserInListComponent";
import { UserInListAction } from "../../../components/user/userInList/UserInListComponentProps";
import KeyWordLocalization from "../../../providers/localization/dictionaries/KeyWordLocalization";
import { useForm } from "react-hook-form";
import CreateGroupUseCase, { CreateGroupUseCaseName } from "../../../../domain/use_cases/group/CreateGroupUseCase";
import Validators from "../../../utils/Validators";
import { ErrorMessage } from "@hookform/error-message";
import { isRight } from "fp-ts/lib/Either";
import ModalsContext from "../../../../domain/providers/modal/ModalsContext";
import ModalsContextType from "../../../../domain/providers/modal/ModalsContextType";
import LocalizationContext from "../../../../domain/providers/localization/LocalizationContext";
import LocalizationContextType from "../../../../domain/providers/localization/LocalizationContextType";
import LoadingComponent from "../../../components/LoadingComponent/LoadingComponent";

const CreateGroupModalComponent: FC<CreateGroupModalComponentProps> = ({ onDone }) => {
    const formFunctions = useForm();
    const { addToast } = useContext(ModalsContext) as ModalsContextType;
    const {i18n} = useContext(LocalizationContext) as LocalizationContextType;
    const [isLoading, setIsLoading] = useState(false);

    const { register, handleSubmit, watch, getValues, formState: { errors }, setValue } = formFunctions;
    const selectedusers = watch(KeyWordLocalization.GroupEntityUsers) == "" ? [] : watch(KeyWordLocalization.GroupEntityUsers) ?? [];
    const setSelectedUsers = (users: UserEntity[]) => setValue(KeyWordLocalization.GroupEntityUsers, users);

    const _handleSearchUsers = async (search: string): Promise<AutoCompleteItem<UserEntity>[]> => {
        const response = await di.get<SearchUserUseCase>(SearchUserUseCaseName).call(search, 1, 20, {[KeyWordLocalization.UserEntityRole]: "agent"});
        const mapped = response.items.map<AutoCompleteItem<UserEntity>>((user: UserEntity) => {
            return {
                label: user.name,
                image: user.image ?? '',
                id: user.id,
                aditionalValue: user,
            }
        });
        return mapped;
    }

    const _handleRemoveUser = (user: UserEntity) => {
        const users = getValues(KeyWordLocalization.GroupEntityUsers) ?? [];
        const filtered = users.filter((u: AutoCompleteItem<UserEntity>) => u.id != user.id);
        setValue(KeyWordLocalization.GroupEntityUsers, filtered);
        setSelectedUsers(filtered);
    }

    const _onSave = async (data: any) => {
        const userIds = data["GroupEntityUsers"].map((user: any) => user.id);
        setIsLoading(true);
        
        await di.get<CreateGroupUseCase>(CreateGroupUseCaseName).call(data[KeyWordLocalization.GroupEntityName], data["Description"], userIds);
        setIsLoading(false);
        onDone();
        
    }

    return <div className="create_group_modal_component">
        <form onSubmit={handleSubmit(_onSave)}>
            <div className="row">
                <div className="col-12 my-2">
                    <div className={`form-group ${errors[KeyWordLocalization.ShiftEntityName] && 'error'}`}>
                        <label>{i18n(KeyWordLocalization.GroupEntityName)}</label>
                        <input type="text" placeholder={i18n(KeyWordLocalization.GroupEntityName)} className="form-control" {...register(KeyWordLocalization.GroupEntityName, Validators({ required: true }))} />
                        <ErrorMessage errors={errors} as="aside" name={KeyWordLocalization.GroupEntityName} />
                    </div>
                </div>
                <div className="col-12 my-2">
                    <div className={`form-group ${errors[KeyWordLocalization.ShiftEntityName] && 'error'}`}>
                        <label>Description</label>
                        <input type="text" placeholder="Description" className="form-control" {...register("Description", Validators({ required: true }))} />
                        <ErrorMessage errors={errors} as="aside" name="Description" />
                    </div>
                </div>
                <div className="col-12 my-2">
                    <AutoCompleteWithCheckComponent label={i18n(KeyWordLocalization.CreateGroupModalComponentSearchUsers)}
                        onSearch={_handleSearchUsers}
                        keyName={KeyWordLocalization.GroupEntityUsers}
                        formInfo={formFunctions}
                        rounded
                    />
                    {selectedusers.length > 0 && <div className="row">
                        <div className="col-12 my-2"><strong>{i18n(KeyWordLocalization.CreateGroupModalComponentAddedUsers)}</strong></div>
                        {selectedusers.map((autocompleteItem: AutoCompleteItem<UserEntity>, index: number) => <div className="col-6 col-md-6 col-lg-3" style={{width: "fit-content"}} key={index} > <UserInListComponent user={autocompleteItem.aditionalValue} onClick={() => _handleRemoveUser(autocompleteItem.aditionalValue)} action={UserInListAction.REMOVE} /></div>)}
                    </div>}
                    <input type="hidden" {...register(KeyWordLocalization.GroupEntityUsers, Validators({ required: true }))} />
                    <ErrorMessage errors={errors} as="aside" name={KeyWordLocalization.GroupEntityUsers} />
                </div>
                <div className="d-flex justify-content-end w-100 mt-2">
                    {
                        !isLoading ?
                        <button type="submit" className="btn btn_primary">{i18n(KeyWordLocalization.Save)}</button>:
                        <LoadingComponent />

                    }
                    
                </div>
            </div>
        </form>
    </div>
}

export default CreateGroupModalComponent;