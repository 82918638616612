import { FC, useContext, useEffect, useState } from "react";
import TrackingTimeContext from "../../../../../domain/providers/trackingTime/TrackingTimeContext";
import TrackingTimeContextType from "../../../../../domain/providers/trackingTime/TrackingTimeContextType";
import CircularIndicatorComponent from "../../../circularIndicator/CircularIndicatorComponent";
import { EventScheduleEntityStatus } from "../../../../../domain/entities/EventScheduleEntity";
import Icons from "../../../../assets/Icons";
import TrackingTimeOnHoldComponent from "../trackingTimeOnHold/TrackingTimeOnHoldComponent";
import ModalsContext from "../../../../../domain/providers/modal/ModalsContext";
import ModalsContextType from "../../../../../domain/providers/modal/ModalsContextType";
import TrackingTimeOnHoldListComponent from "../trackingTimeOnHoldList/TrackingTimeOnHoldListComponent";
import di from "../../../../../di/DependencyInjection";
import { StartClockTrackingUseCase, StartClockTrackingUseCaseName } from "../../../../../domain/use_cases/trackingTime/StartClockTrackingUseCase";
import KeyWordLocalization from "../../../../providers/localization/dictionaries/KeyWordLocalization";

const reasons = {
    list: [
        KeyWordLocalization.TrackingRepositoryStopForRestroom,
        KeyWordLocalization.TrackingRepositoryTechnicalBreak,
        KeyWordLocalization.TrackingRepositoryTechnicalIssue,
        KeyWordLocalization.TrackingRepositoryOtherBreak,
    ]
}

const TrackingTimeClockComponent: FC<{}> = () => {
    const { currentEvent, text, currentProgress, setIsOpen } = useContext(TrackingTimeContext) as TrackingTimeContextType;
    const { openModalCustom, closeModalCustom, addToast } = useContext(ModalsContext) as ModalsContextType;

    const [loading, setLoading] = useState(false);

    const _handleResume = async () => {
        if(loading) return;
        setLoading(true);
        await di.get<StartClockTrackingUseCase>(StartClockTrackingUseCaseName).call(currentEvent!);
        setLoading(false);
    }

    const _handlePause = () => {
        if(loading) return;
        openModalCustom('sm', 'Pause Reason', <TrackingTimeOnHoldListComponent holdReasons={reasons} currentEvent={currentEvent} />)
        setIsOpen(false);
    }

   

    return <div className={`tracking_time_clock_component ${currentProgress > 100 ? 'text-danger' : 'text_blue'}`}>
        
        <CircularIndicatorComponent progress={currentProgress} minSize={200} color={currentProgress > 100 ? 'var(--color-red-soft)' : undefined}>
            <>
                {currentEvent?.status == EventScheduleEntityStatus.running && <div className="d-flex flex-column">
                    <h2>{text}</h2>
                    <div className="d-flex justify-content-center" onClick={() => _handlePause()}>
                        <div className="d-flex align-items-center hover">
                            <Icons.Pause height={16} /> <div className="ms-1">
                                Pause
                            </div>
                        </div>
                    </div>
                </div>}
                {currentEvent?.status == EventScheduleEntityStatus.paused && <div className="d-flex flex-column align-items-center justify-content-center mt-2 hover" onClick={_handleResume}>
                    <Icons.Play width={70} />
                    <h5>Resume</h5>
                </div>}
                {currentEvent?.status == EventScheduleEntityStatus.pending && <div className="d-flex flex-column align-items-center justify-content-center mt-2 hover" onClick={_handleResume}>
                    <Icons.Play width={70} />
                    <h5>Start</h5>
                </div>}
            </>
        </CircularIndicatorComponent>
    </div>
}

export default TrackingTimeClockComponent;