import './CircularIndicatorComponentStyles.scss';
import { FC } from "react";
import CircularIndicatorComponentProps from "./CircularIndicatorComponentProps";

const CircularIndicatorComponent: FC<CircularIndicatorComponentProps> = ({ minSize, children, color, progress }) => {
    const _getConicGradient = (): string => {
        let _progress = progress > 100 ? 100 : progress;
        _progress = _progress < 0 ? 0 : _progress;
        return `conic-gradient(${color} 0%, ${color} ${_progress}%, var(--color-blue) ${_progress}deg, var(--color-blue) 100%)`;
    }
    return <div className="circular_indicator_component">
        <div className="progress_indicator" style={{ width: minSize, height: minSize, background: _getConicGradient() }}>
            <div className="circle">
                {children}
            </div>
        </div>
    </div>
}

CircularIndicatorComponent.defaultProps = {
    minSize: 268,
    color: 'var(--color-yellow)',
}


export default CircularIndicatorComponent;