import { Either } from "fp-ts/lib/Either";
import EventScheduleEntity from "../../../../../domain/entities/EventScheduleEntity";
import ExceptionEntity from "../../../../../domain/entities/ExceptionEntity";

//TODO implement
const CreateEventForUserApiImpl = async (event: EventScheduleEntity, userId: string): Promise<Either<ExceptionEntity, EventScheduleEntity>> => {
    throw new Error("Not implemented");
}

export default CreateEventForUserApiImpl;
