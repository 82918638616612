import { FC, useState, useRef, useEffect } from "react";
import './ActionButtonRolesComponentStyles.scss';
import ActionButtonRolesComponentProps from "./ActionButtonRolesComponentProps";

const ActionButtonRolesComponent: FC<ActionButtonRolesComponentProps> = ({ onClick, text, disabled, icon: IconComponent, onClickIcon, colorLine, type, height, width, hasModal, modalItems }) => {
    const Icon = IconComponent || (() => null);
    const [openModal, setOpenModal] = useState(false);
    const modalContainerRef = useRef<HTMLDivElement>(null);

    const handleOpenModal = () => {
        setOpenModal(!openModal);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const handleClick = (event: MouseEvent) => {
        if (modalContainerRef.current && modalContainerRef.current.contains(event.target as Node)) {
            // Click inside the modal, do nothing
            return;
            
            
        }

        handleCloseModal();
    };

    useEffect(() => {
        if (openModal) {
            window.addEventListener("mousedown", handleClick);
        }

        return () => {
            window.removeEventListener("mousedown", handleClick);
        };
    }, [openModal]);

    return (
        <section className="action-button-container" ref={modalContainerRef}>
            <div className='edition-button' onClick={() => {
                
                if (typeof onClick === 'function') {
                    onClick();
                }
                handleOpenModal();
            }}>
                <div className='edition-button-icon'><Icon className='edit-pencil-icon' /></div>
                <div className='edition-button-name'>{text}</div>
                <div className='edition-button-line' style={{ backgroundColor: colorLine }}></div>
            </div>
            {hasModal && openModal &&
                <div className="modal-button-container">
                    {modalItems?.map((item, idx) => (
                        <div className="modal-button-item" onClick={(e) => { e.stopPropagation(); item.action(); }} key={idx}>
                            <item.icon className='edit-pencil-icon-modal modal-button-item-icon' />
                            <div className="modal-button-item-text">{item.content}</div>
                        </div>
                    ))}
                </div>
            }
        </section>
    );
}

ActionButtonRolesComponent.defaultProps = {
    disabled: false,
    icon: undefined,
    onClickIcon: undefined,
    colorLine: undefined,
    height: 'auto',
    width: 'auto',
    hasModal: false,
    modalItems: []
}

export default ActionButtonRolesComponent;
