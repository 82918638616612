import { injectable } from "inversify";
import TrackingTimeRepository from "../../repositories/TrackingTimeRepository";
import { isLeft } from "fp-ts/lib/Either";

interface props {
    trackingTimeRepository: TrackingTimeRepository;
}
@injectable()
export default class GetReasonsToStopTrackingUseCase {
    reasons: string[] = [];
    trackingTimeRepository: TrackingTimeRepository;

    constructor(_: props) {
        this.trackingTimeRepository = _.trackingTimeRepository;
    }

    public call = async (): Promise<string[]> => {
        if(this.reasons.length > 0) return this.reasons;
        const reasons = await this.trackingTimeRepository.getReasonsForPause();
        if(isLeft(reasons)) return [];
        this.reasons = reasons.right;
        return reasons.right;
    }
}

export const GetReasonsToStopTrackingUseCaseName = "GetReasonsToStopTrackingUseCase";