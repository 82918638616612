//import { Table } from "react-bootstrap";
import React, { useState } from "react";
import { useCollection } from '@cloudscape-design/collection-hooks';
import { Table, Header, Checkbox } from '@cloudscape-design/components';
import Icons from "../../assets/Icons";

import './ContactTableMomentsStyles.scss';

type ContactTableProps = {
    data: Array<any>;
    loading?: boolean;
    header?: React.ReactNode; // Adjust this type based on what `header` is supposed to be
    variant?: any; // Adjust the variants accordingly,
    tableTitle?: string;
    setModal?: any;
    modal?:any
  };


export const ContactTableMoments: React.FC<ContactTableProps> = ({ data = [], loading = false, header, variant='container', tableTitle, setModal, modal }) => {

  const preferences: any = {
      "pageSize": 30,
      "wrapLines": false,
      "stripedRows": false,
      "contentDensity": "comfortable",
      "visibleContent": [
          "bu_sequence",
          "business_unit",
          "description",
          "is_active",
          "is_autofail",
          "is_partial",
          "weight",
          "edit"

      ]
  }

  const { items, collectionProps } = useCollection(
    data,
    {
      propertyFiltering: {
        filteringProperties: [
          {
            key: "description",
            operators: ["=", "!=", ":", "!:"],
            propertyLabel: "description",
            groupValuesLabel: "description"
          }
        ],
        empty: (
          <div>No Item.</div>
        ),
        noMatch: (
          <div>No matches.</div>
        )
      },
      pagination: { pageSize: 100000000 },
      sorting: {},
      selection: {},
    });
    
    let activeChecked = items.map((item: any) => {
      return {
        [item.keyword_id]: item.is_active
      }
    });
    let autoFailChecked = items.map((item: any) => {
      return {
        [item.keyword_id]: item.autofail
      }
    });
    let partialChecked = items.map((item: any) => {
      return {
        [item.keyword_id]: item.partial
      }
    });
    const mergedObjectActive: any = activeChecked.reduce((acc, obj) => {
      return { ...acc, ...obj };
    }, {});
    const [ activeItems, setActiveItems ] = useState<any>(mergedObjectActive);

    const mergedObjectAutoFail: any = autoFailChecked.reduce((acc, obj) => {
      return { ...acc, ...obj };
    }, {});
    const [ autoFailItems, setAutoFailItems ] = useState<any>(mergedObjectAutoFail);


    const mergedObjectPartial: any = partialChecked.reduce((acc, obj) => {
      return { ...acc, ...obj };
    }, {});
    const [ partialItems, setPartialItems ] = useState<any>(mergedObjectPartial);

    const _handleUpdate = (item: any, type: string) => {
      if (type !== 'update') {
        let newValue = {
          ...activeItems,
          [item.keyword_id]: activeItems[item.keyword_id] === 0 ? 1 : 0
        }
        setActiveItems(newValue);
        const diffKeys = [];
  
        // Iterate over keys in the first object
        for (const key in newValue) {
          if (newValue[key] !== mergedObjectActive[key]) {
            diffKeys.push(key);
          }
        }
        setModal({ids: {
          ...modal?.ids,
          active: diffKeys,
          allKeys: {
            autofail: autoFailItems,
            partial: partialItems
          }
        }});
      } else {
        
        setModal({...modal, type: type, item})
      }
    }

    const _handleUpdateAutoFail = (item: any, type: string) => {
      console.log(partialItems[item.keyword_id]);
      console.log(!autoFailItems[item.keyword_id]);

      if (partialItems[item.keyword_id] !== !autoFailItems[item.keyword_id] || (partialItems[item.keyword_id] === false && !autoFailItems[item.keyword_id] === false)) {
        let newValue = {
          ...autoFailItems,
          [item.keyword_id]: !autoFailItems[item.keyword_id]
        }
        setAutoFailItems(newValue);
        const diffKeys = [];
  
        for (const key in newValue) {
          if (newValue[key] !== mergedObjectAutoFail[key]) {
            diffKeys.push({key, value: newValue[key] ? 1: 0});
          }
        }
        
        setModal({ids: {
          ...modal?.ids,
          autofail: diffKeys,
          allKeys: {
            autofail: newValue,
            partial: partialItems
          }
        }});
      }
      
      
    }

    const _handleUpdatePartial = (item: any, type: string) => {
      console.log(!partialItems[item.keyword_id]);
      console.log(autoFailItems[item.keyword_id]);

      if (!partialItems[item.keyword_id] !== autoFailItems[item.keyword_id] || (!partialItems[item.keyword_id] === false && autoFailItems[item.keyword_id] === false)) {
        let newValue = {
          ...partialItems,
          [item.keyword_id]: !partialItems[item.keyword_id]
        }
        setPartialItems(newValue);
        const diffKeys = [];
  
        for (const key in newValue) {
          if (newValue[key] !== mergedObjectPartial[key]) {
            
            diffKeys.push({key, value: newValue[key] ? 1: 0});
          }
        }
        setModal({ids: {
          ...modal?.ids,
          partial: diffKeys,
          allKeys: {
            partial: newValue,
            autofail: autoFailItems
          }
        }});
      }
      
    }
  
    const themesKeys: any = {
      '-1': 'Negative',
      '0': 'Neutral',
      '1': 'Positive'
    }
    const COLUMN_DEFINITIONS = [
      {
        id: "bu_sequence",
        header: "id",
        cell: (d: any) => {
          return(
            <div style={{display: 'flex', justifyContent: 'center'}}>
                {d.bu_sequence}
            </div>
            )
        },
        isRowHeader: true,
        sortingField: "bu_sequence",
        maxWidth: 150
      },
      {
        id: "description",
        header: "Description",
        cell: (d: any) => d.description,
        isRowHeader: true,
        sortingField: "description",
        maxWidth: 150
      },
      {
        id: "business_unit",
        header: "Customer",
        cell: (d: any) => {
          return(
          <div style={{display: 'flex', justifyContent: 'center'}}>
              {d.business_unit}
          </div>
          )
          
        },
          
        isRowHeader: true,
        sortingField: "business_unit",
        maxWidth: 150
      },
      {
        id: "keyword_id",
        header: "keyword_id",
        cell: (d: any) => d.keyword_id,
        isRowHeader: true,
        sortingField: "keyword_id",
        maxWidth: 150
      },
      {
        id: "is_active",
        header: "Active",
        cell: (d: any) => {
          
          return(
            <div
              onClick={() => _handleUpdate(d, 'active')}
              style={{display: 'flex', justifyContent: 'center'}}
            >
              <Checkbox  checked={activeItems[d.keyword_id] === 1 ? true : false} />
            </div>
          )
          
        },
        isRowHeader: true,
        sortingField: "is_active",
        maxWidth: 150
      },
      {
        id: "is_autofail",
        header: "AutoFail",
        cell: (d: any) => {
          
          return(
            <div
              onClick={() => {
                _handleUpdateAutoFail(d, 'autofail');
                }
              }
              style={{display: 'flex', justifyContent: 'center'}}
            >
              <Checkbox  checked={autoFailItems[d.keyword_id]} />
            </div>
          )
          
        },
        isRowHeader: true,
        sortingField: "is_autofail",
        maxWidth: 150
      },
      {
        id: "is_partial",
        header: "Partial",
        cell: (d: any) => {
          
          return(
            <div
              onClick={() => _handleUpdatePartial(d, 'partial')}
              style={{display: 'flex', justifyContent: 'center'}}
            >
              <Checkbox  checked={partialItems[d.keyword_id]} />
            </div>
          )
          
        },
        isRowHeader: true,
        sortingField: "is_partial",
        maxWidth: 150
      },
      {
        id: "weight",
        header: tableTitle === 'Moment' ? 'Theme' : 'Score',
        cell: (d: any) => tableTitle === 'Moment' ? `${themesKeys[d.weight]}` : d.weight,
        isRowHeader: true,
        sortingField: "weight",
        maxWidth: 150
      },
      {
        id: "edit",
        header: "Update",
        cell: (d: any) => {
          return(
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <div style={{cursor: 'pointer'}} onClick={() => _handleUpdate(d, 'update')}>
                <Icons.EditPencil color="green"/>
              </div>
            </div>
            
          )
          
        },
        isRowHeader: true,
        sortingField: "edit",
        minWidth: 200
      }
    ];
  

    return (
        <Table
              {...collectionProps}
              header={
                <Header
                >
                  {tableTitle}
                </Header>
              }
              variant={variant}
              columnDefinitions={COLUMN_DEFINITIONS}
              columnDisplay={preferences.contentDisplay}
              items={items}
              resizableColumns={true}
              loadingText="Loading Calls"
              stickyHeaderVerticalOffset={20}
              stickyColumns={{ first: 1, last: 0 }}
              visibleColumns={['jobName', ...preferences.visibleContent]}
          />
    
    );
};
