import { Either, right } from "fp-ts/lib/Either";
import ExceptionEntity from "../../../../../domain/entities/ExceptionEntity";
import KeyWordLocalization from "../../../../../ui/providers/localization/dictionaries/KeyWordLocalization";

//TODO implement
const GetReasonsForPauseTrackingClockApiImpl = async (): Promise<Either<ExceptionEntity, string[]>> => {
    return right(
        [
            KeyWordLocalization.TrackingRepositoryStopForRestroom,
            KeyWordLocalization.TrackingRepositoryTechnicalBreak,
            KeyWordLocalization.TrackingRepositoryOtherBreak,
            KeyWordLocalization.TrackingRepositoryTechnicalIssue,
        ]
    )
}

export default GetReasonsForPauseTrackingClockApiImpl;