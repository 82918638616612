import { injectable } from "inversify";
import GroupRepository from "../../repositories/GroupRepository";

interface props {
    groupRepository: GroupRepository;
}

@injectable()
export default class DeleteGroupByIdUseCase {
    _groupRepository: GroupRepository;

    constructor(_: props) {
        this._groupRepository = _.groupRepository;
    }
    public call = async (groupdId: string): Promise<boolean> => {
        return this._groupRepository.deleteGroup(groupdId);
    }
}

export const DeleteGroupByIdUseCaseName = "DeleteGroupByIdUseCaseName";
