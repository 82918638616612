import { injectable } from "inversify";
import UserEntity, { UserEntityStatus } from "../../../domain/entities/UserEntity";
import UserRepository from "../../../domain/repositories/UserRepository";
import Testing from "../../../ui/utils/Testing";
import { userTest } from "./UserRepositoryFake";
import SearchResultEntity from "../../../domain/entities/SearchResultEntity";
import ExceptionEntity from "../../../domain/entities/ExceptionEntity";
import { Either } from "fp-ts/lib/Either";
import UpdateUserRoleIdApiImpl from "./api/impl/UpdateUserRoleIdApiImpl";
import SearchUsersApiImpl from "./api/impl/SearchUsersApiImpl";
import KeyWordLocalization from "../../../ui/providers/localization/dictionaries/KeyWordLocalization";
import SearchAllUsersApiImpl from "./api/impl/SearchAllUsersApiImpl";
import DeleteUsersApiImpl from "./api/impl/DeleteUsersApiImpl";
import SearchAllPositionsApiImpl from "./api/impl/SearchAllPositionsApiImpl";
import SearchAllBUnitsApiImpl from "./api/impl/SearchAllBUnitsApiImpl";
import SearchAllSupervisorsApiImpl from "./api/impl/SearchAllSupervisorsApiImpl";
import UpdateUsersApiImpl from "./api/impl/UpdateUsersApiImpl";
import CreateUsersApiImpl from "./api/impl/CreateUsersApiImpl";

@injectable()
class UserRepositoryDev implements UserRepository {
    getEmployeeById = async (id: string): Promise<UserEntity | undefined> => {
        await Testing.sleeper(1000);
        return userTest;
    }
    search = async (word: string, page: number, itemsPerPage: number, filter: { [key: string]: any }): Promise<SearchResultEntity<UserEntity>> => SearchUsersApiImpl(word, page, itemsPerPage, filter);
    searchAgents = async (word: string, page: number, itemsPerPage: number, filter: { [key: string]: any }): Promise<SearchResultEntity<UserEntity>> => SearchUsersApiImpl(word, page, itemsPerPage, filter);
    searchSupervisor = async (word: string, page: number, itemsPerPage: number, filter: { [key: string]: any }): Promise<SearchResultEntity<UserEntity>> => SearchUsersApiImpl(word, page, itemsPerPage, {...filter, [KeyWordLocalization.UserEntityRole]: 'supervisor'});
    updateUserRoleId = async (userId: number, roleById: number): Promise<Either<ExceptionEntity, string>> => await UpdateUserRoleIdApiImpl(userId, roleById);
    searchAllUsers = async (id: string): Promise<Either<ExceptionEntity, UserEntity[]>> => SearchAllUsersApiImpl(id);
    deleteUsers = async (users: string[]): Promise<Either<ExceptionEntity, string>> => DeleteUsersApiImpl(users);
    searchUserPositions = async (): Promise<Either<ExceptionEntity, any[]>> => SearchAllPositionsApiImpl();
    searchUserBunits = async (): Promise<Either<ExceptionEntity, any[]>> => SearchAllBUnitsApiImpl();
    searchAllSupervisors = async (): Promise<Either<ExceptionEntity, any[]>> => SearchAllSupervisorsApiImpl();
    updateUsers = async (data: any[]): Promise<Either<ExceptionEntity, string>> => UpdateUsersApiImpl(data);
    createUsers = async (data: any[]): Promise<Either<ExceptionEntity, string>> => CreateUsersApiImpl(data);
}

export default UserRepositoryDev;