import RoleEntity from "../../../domain/entities/RoleEntity";
import TextOperations from "../../../ui/utils/TextOperations";
import ModuleHostDto from "./ModuleHostDto";

const fromJson = (json: any): RoleEntity => {
    return {
        id: json.role_id,
        name: TextOperations.capitalizeFirstLetter(json.role_name.replaceAll('_', ' ')),
        description: json.description,
        modules: json.policies.map((policy: any) => ModuleHostDto.fromJson(policy)),
    }
}

export default {
    fromJson,
}