import { FC, useContext, useEffect, useState } from 'react';
import di from '../../../../di/DependencyInjection';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../../routes/RoutesComponent';
import CardRolesComponent from './components/cardRoles/CardRolesComponent';
import './RolesPageStyles.scss';
import GetAllRolesUseCase, { GetAllRolesUseCaseName } from '../../../../domain/use_cases/roles/GetAllRolesUseCase';
import LoadingComponent from '../../../components/LoadingComponent/LoadingComponent';
import AutoCompleteComponent from '../../../components/forms/autocomplete/AutoCompleteComponent';
import { FieldValues, UseFormReturn, useForm } from 'react-hook-form';
import RoleEntity from '../../../../domain/entities/RoleEntity';
import RolesEntity from '../../../../domain/entities/RolesEntity';
import { isRight } from 'fp-ts/lib/Either';
import { AutoCompleteItem } from '../../../components/forms/autocompleteWithCheck/AutoCompleteWithCheckProps';
import Icons from '../../../assets/Icons';
import RolesContext from '../../../../domain/providers/roles/RolesContext';
import RolesContextType from '../../../../domain/providers/roles/RolesContextType';
import SearchAllUsersUseCase, { SearchAllUsersUseCaseName } from '../../../../domain/use_cases/allUsers/SearchAllUserUseCase';
import UserContext from '../../../../domain/providers/user/UserContext';
import UserContextType from '../../../../domain/providers/user/UserContextType';


const RolePage: FC<{}> = () => {

    const navigate = useNavigate();

    const { user } = useContext(UserContext) as UserContextType;

    const [isLoading, setIsLoading ] = useState<boolean>(true);

    const [ data, setData ] = useState<RolesEntity[]>([]);

    const _search = async () => await di.get<GetAllRolesUseCase>(GetAllRolesUseCaseName).call(true);

    const _searchAllUsers = async () => await di.get<SearchAllUsersUseCase>(SearchAllUsersUseCaseName).call();

    const _handleGoToDetail = (roleItem: RolesEntity) => {
        if(user?.permissions?.role.update){
            navigate(routes.rolesAndPermissionsDetail.relativePath + "/" + roleItem.id);
        }
        
    }

    const { roles, setRoles, setUsers, setPermissions, permissions } = useContext(RolesContext) as RolesContextType;
    
    const formInfo: UseFormReturn<FieldValues, any> = useForm({
        // Your useForm configuration options...
    });

    const _firstSearch = async () => {
        const responseRoles = await _search();
        if (isRight(responseRoles)) {
            setRoles(responseRoles.right);
        } else {
            setRoles([]);
        }
        const responseAllUsers = await _searchAllUsers();
        if (isRight(responseAllUsers)) {
            setUsers(responseAllUsers.right);
        } else {
            setUsers([]);
        }
    }

    const handleInputItems = async (word: string): Promise<AutoCompleteItem<RolesEntity>[]> => {
    
        setIsLoading(true);

        if (word !== "") {
            
            const newItemList = roles?.filter((item: RoleEntity) => {

                if (
                    word !== "" &&
                    item?.name.toLowerCase().includes(word.toLowerCase())
                ) {
                return item;
                }
            });


            const castFilterByRole = newItemList.map((item: RolesEntity) => {
                return {
                    id: item.id || "",
                    label: item.name || "",
                    image: "",
                    aditionalValue: item
                }
            });
            
            setIsLoading(false);
            
            setData(newItemList);
            setIsLoading(false);
            

            return castFilterByRole;
        } else {
            
            const newItemList = roles?.filter((item: RoleEntity) => {
                
                if (
                    item?.name.toLowerCase().includes(word.toLowerCase())
                ) {
                return item;
                }
            });
            
            const castFilterByRole = newItemList.map((item: RolesEntity) => {
                return {
                    id: item.id || "",
                    label: item.name || "",
                    image: "",
                    aditionalValue: item
                }
            });
            setData(roles);
            setIsLoading(false);

            return castFilterByRole;
        }
    };

    const handleSelected = (data: any = []) => {
        handleInputItems(data['name']);
        
    }

    useEffect(() => {
        _firstSearch();
    }, []);


    return <div className='container'>

    { roles?.length > 0 ?

        <>
            <div className="search_container_list">
                <AutoCompleteComponent
                    formInfo={formInfo} 
                    onSearch={handleInputItems}
                    keyName='Roles'
                    onSelect={handleSelected}
                />
            </div>
            <div className=" container_roles" id='scrollable_container_roles'>
                    { data.map((item, index) => (
                        <div className='col-12 col-md-6 col-lg-4 col-xl-4 my-3 container_roles_cards' key={index}>
                            <CardRolesComponent role={item} onClick={() => _handleGoToDetail(item)} />
                        </div>
                    )) }

                </div> 

                {
                    user?.permissions?.role.create &&
                        <section className="role-edition-save-button-section">
                            <button onClick={() => navigate(routes.rolesAndPermissionsCreation.relativePath + "/")} className="role-edition-save-button">
                                <Icons.Plus className="role-edition-save-button-icon"/>
                                Create
                            </button>
                        </section>

                }

                
        </>
    
    

        :

        isLoading ?
        <LoadingComponent />
        :
        <div className='no_data_retrieved'>
            Role not found
        </div>


        
        
    

}

</div>

}

export default RolePage;
