import { FC } from "react";
import ModulePermisionsEditableComponentProps from "./ModulePermisionsEditableComponentProps";
import Icons from "../../../assets/Icons";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const ModulePermisionsEditableComponent:FC<ModulePermisionsEditableComponentProps> = ({module, formFunctions, onChange, keyName, required }) => {

    const renderTooltip = (value: string) => (
        <Tooltip id="button-tooltip">
            {value}
        </Tooltip>
    );

    return <div className="module_permisions_editable_component">
        <div className="d-flex mt-3">
        
            <div style={{ width: 30 }}>
            
                <Icons.Calendar className='calendar-icon' />
            
                
            </div>
            
            <div className="modules-title">
                {module?.name === "User Management" ? "Roles And Permissions" : module?.name}
            </div>
        </div>
        { module?.permissions && module?.permissions.length > 0 ? (
            module?.permissions?.map((policy, idx) => {
                return <div className='d-flex' key={idx}>
                    {
                        (policy.canView || policy.canAssign || policy.canCreate || policy.canEdit || policy.canDelete) &&
                        <div className='modules-subtitle'>{policy.name}</div>
                        
                    }
                    
                    {
                        policy.canView &&
                        <OverlayTrigger
                            placement="top"
                            delay={{ show: 5, hide: 5 }}
                            overlay={renderTooltip('View')}
                        >
                            <div style={{ width: 25 }}>
                                <Icons.ViewEye className='view-eye-icon' />
                            </div>
                        </OverlayTrigger>
                        
                    }
                    {
                        policy.canEdit &&
                        <OverlayTrigger
                            placement="top"
                            delay={{ show: 5, hide: 5 }}
                            overlay={renderTooltip('Edit')}
                        >
                            <div style={{ width: 25 }}>
                                <Icons.EditPencil className='edit-pencil-icon' />
                            </div>
                        </OverlayTrigger>
                    }
                    {
                        policy.canDelete &&
                        
                        <OverlayTrigger
                            placement="top"
                            delay={{ show: 5, hide: 5 }}
                            overlay={renderTooltip('Delete')}
                        >
                            <div style={{ width: 25 }}>
                                <Icons.Trash className='trash-icon' />
                            </div>
                        </OverlayTrigger>
                    }
                    {
                        policy.canCreate &&
                        <OverlayTrigger
                            placement="top"
                            delay={{ show: 5, hide: 5 }}
                            overlay={renderTooltip('Create')}
                        >
                            <div style={{ width: 25 }}>
                                <Icons.Plus className='add-icon' />
                            </div>
                        </OverlayTrigger>
                    }
                    {
                        policy.canAssign &&
                        
                        <OverlayTrigger
                            placement="top"
                            delay={{ show: 5, hide: 5 }}
                            overlay={renderTooltip('Assign')}
                        >
                            <div style={{ width: 25 }}>
                                <Icons.AssignIcon className='assign-icon' />
                            </div>
                        </OverlayTrigger>
                    }
                    
                </div>
            })

        ) :

        <></>
        }

    </div>
}

ModulePermisionsEditableComponent.defaultProps = {
    required: false,
}

export default ModulePermisionsEditableComponent;