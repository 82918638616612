import StaffPerShiftEntity from "../../../domain/entities/StaffPerShiftEntity";
import StaffPerShiftDayHostDto from "./StaffPerShiftDayHostDto";

const fromJSON = (json: any): StaffPerShiftEntity => {
    const staffPerShiftEntity: StaffPerShiftEntity = {
        name: json.name,
        days: json.days.map((day: any) => StaffPerShiftDayHostDto.fromJSON(day)),
    }

    return staffPerShiftEntity;
}

export default {
    fromJSON,
}