import { injectable } from "inversify";
import TrackingTimeRepository, { GetSummaryAttendanceInTimeResponse } from "../../../domain/repositories/TrackingTimeRepository";
import { Either, right } from "fp-ts/lib/Either";
import ExceptionEntity from "../../../domain/entities/ExceptionEntity";
import Testing from "../../../ui/utils/Testing";
import KeyWordLocalization from "../../../ui/providers/localization/dictionaries/KeyWordLocalization";
import EventScheduleEntity from "../../../domain/entities/EventScheduleEntity";
import TrackingClockInteractionEntity from "../../../domain/entities/TrackingClockInteractionEntity";
import { testEventBreak, testEventSchedule, testEventSchedule2 } from "../calendar/CalendarRepositoryFake";
import { userTest } from "../user/UserRepositoryFake";
import di from "../../../di/DependencyInjection";
import MastersProvider, { MastersProviderName } from "../../../domain/providers/master/MastersProvider";
import TypeEventScheduleEntity from "../../../domain/entities/TypeEventScheduleEntity";
import UserEntity from "../../../domain/entities/UserEntity";
import GroupEntity from "../../../domain/entities/GroupEntity";
import BusinessUnitEntity from "../../../domain/entities/BusinessUnitEntity";

@injectable()
export default class TrackingTimeRepositoryFake implements TrackingTimeRepository {
    getSummaryAttendanceInTimeRange = async (businessUnits: BusinessUnitEntity[], supervisors: UserEntity[], employees: UserEntity[], groups: GroupEntity[], tagTypes: TypeEventScheduleEntity[], startDate: Date, endDate: Date): Promise<Either<ExceptionEntity, GetSummaryAttendanceInTimeResponse>> => {
        const contentDate = (_date: Date) => {
            return {
                date: _date,
                totalPlanned: Math.random() * 1000,
                totalReal: Math.random() * 1000,
            }
        }
        //date to date is a function that fill with contentDate(date) the array of days between startDate and endDate
        const dateToDate = Array.from({ length: (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24) + 1 }, (_, index) => {
            return contentDate(new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + 1 + index));
        });
        const types = di.get<MastersProvider>(MastersProviderName);
        const response: GetSummaryAttendanceInTimeResponse = {
            statusUsers: Array(5).fill(0).map((_, index) => {
                return {
                    user: userTest,
                    todayCalendar: { initHour: new Date(), events: [testEventSchedule, testEventBreak, testEventSchedule2], endHour: new Date(), id: '1', shiftGroup: '222', users: [] },
                }
            }),
            summary: Array(5).fill(0).map((_, index) => {
                return {
                    typeEvent: types.Actions.typeSchedules.length > 0 ? types.Actions.typeSchedules[types.Actions.typeSchedules.length % (index + 1)] : testEventBreak.type,
                    totalPlanned: Math.random() * 1000,
                    totalReal: Math.random() * 1000,
                    dateToDate: dateToDate,
                }
            }),
        };
        return right(response);
    }
    addTrackingInteractionAsSupervisor = async (clockIn: Date, clockOut: Date | null, comment: string, typeId: string, supervisorId: string, shiftId: string): Promise<Either<ExceptionEntity, void>> => {
        await Testing.sleeper(1000);
        const today = new Date();
        return right(undefined);
        // return right({
        //     id: '1',
        //     activityTypeId: 23,
        //     clockInTime: new Date(today.getFullYear(), today.getMonth(), today.getDate(), 8, 3, 0),
        //     clockOutTime: new Date(today.getFullYear(), today.getMonth(), today.getDate(), 10, 10, 0),
        //     details: 'working',
        //     shiftId: 1,
        //     tagTypeId: 1,
        //     editions: "",
        //     timeTrackingId: 1,
        //     tagType: {
        //         color: '#233746',
        //         id: "1",
        //         name: "Work time",
        //         description: "Working time",
        //         billable: true,
        //         payable: false,
        //         outstanding: false,
        //     },
        // },);
    }
    editTrackingInteractionAsSupervisor = async (trackingTime: TrackingClockInteractionEntity, commentId: string, supervisorId: string): Promise<Either<ExceptionEntity, void>> => {
        await Testing.sleeper(1000);
        return right(undefined);
    }
    getDayPlannedVsReal = async (userId: string, date: Date): Promise<Either<ExceptionEntity, { planned: EventScheduleEntity, real: TrackingClockInteractionEntity[] }[]>> => {
        await Testing.sleeper(1000);
        const today = new Date();
        const realListWorkA: TrackingClockInteractionEntity[] = [
            {
                id: '1',
                activityTypeId: 23,
                clockInTime: new Date(today.getFullYear(), today.getMonth(), today.getDate(), 8, 3, 0),
                clockOutTime: new Date(today.getFullYear(), today.getMonth(), today.getDate(), 10, 10, 0),
                details: 'working',
                shiftId: 1,
                tagTypeId: 1,
                editions: "",
                timeTrackingId: 1,
                tagType: {
                    color: '#233746',
                    id: "1",
                    name: "Work time",
                    description: "Working time",
                    billable: true,
                    payable: false,
                    outstanding: false,
                },
            },
            {
                id: '1',
                activityTypeId: 23,
                clockInTime: new Date(today.getFullYear(), today.getMonth(), today.getDate(), 10, 10, 0),
                clockOutTime: new Date(today.getFullYear(), today.getMonth(), today.getDate(), 10, 32, 0),
                details: 'pause',
                shiftId: 1,
                tagTypeId: 1,
                editions: "",
                timeTrackingId: 1,
                tagType: {
                    color: '#E6D7A3',
                    id: "1",
                    name: "Pause time",
                    billable: true,
                    payable: false,
                    outstanding: false,
                },
            },
            {
                id: '1',
                activityTypeId: 23,
                clockInTime: new Date(today.getFullYear(), today.getMonth(), today.getDate(), 10, 31, 0),
                clockOutTime: new Date(today.getFullYear(), today.getMonth(), today.getDate(), 11, 10, 0),
                details: 'working',
                shiftId: 1,
                tagTypeId: 1,
                editions: "",
                timeTrackingId: 1,
                tagType: {
                    color: '#233746',
                    id: "1",
                    name: "Work time",
                    description: "Working time",
                    billable: true,
                    payable: false,
                    outstanding: false,
                },
            },
            {
                id: '1',
                activityTypeId: 23,
                clockInTime: new Date(today.getFullYear(), today.getMonth(), today.getDate(), 11, 10, 0),
                clockOutTime: new Date(today.getFullYear(), today.getMonth(), today.getDate(), 11, 32, 0),
                details: 'pause',
                shiftId: 1,
                tagTypeId: 1,
                editions: "",
                timeTrackingId: 1,
                tagType: {
                    color: '#E6D7A3',
                    id: "1",
                    name: "Pause time",
                    billable: true,
                    payable: false,
                    outstanding: false,
                },
            },
            {
                id: '1',
                activityTypeId: 23,
                clockInTime: new Date(today.getFullYear(), today.getMonth(), today.getDate(), 11, 31, 0),
                clockOutTime: new Date(today.getFullYear(), today.getMonth(), today.getDate(), 12, 1, 0),
                details: 'working',
                shiftId: 1,
                tagTypeId: 1,
                editions: "",
                timeTrackingId: 1,
                tagType: {
                    color: '#233746',
                    id: "1",
                    name: "Work time",
                    description: "Working time",
                    billable: true,
                    payable: false,
                    outstanding: false,
                },
            },
        ];
        const realListBreak: TrackingClockInteractionEntity[] = [
            {
                id: '1',
                activityTypeId: 23,
                clockInTime: new Date(today.getFullYear(), today.getMonth(), today.getDate(), 12, 1, 0),
                clockOutTime: new Date(today.getFullYear(), today.getMonth(), today.getDate(), 13, 0, 0),
                details: 'break',
                shiftId: 1,
                tagTypeId: 1,
                editions: "",
                timeTrackingId: 1,
                tagType: {
                    color: '#E6D7A3',
                    id: "1",
                    name: "Break time",
                    billable: true,
                    payable: false,
                    outstanding: false,
                },
            },
        ]
        const realListWorkB: TrackingClockInteractionEntity[] = [
            {
                id: '1',
                activityTypeId: 23,
                clockInTime: new Date(today.getFullYear(), today.getMonth(), today.getDate(), 13, 0, 0),
                clockOutTime: new Date(today.getFullYear(), today.getMonth(), today.getDate(), 15, 10, 0),
                details: 'working',
                shiftId: 1,
                tagTypeId: 1,
                editions: "",
                timeTrackingId: 1,
                tagType: {
                    color: '#233746',
                    id: "1",
                    name: "Work time",
                    description: "Working time",
                    billable: true,
                    payable: false,
                    outstanding: false,
                },
            },
            {
                id: '1',
                activityTypeId: 23,
                clockInTime: new Date(today.getFullYear(), today.getMonth(), today.getDate(), 15, 10, 0),
                clockOutTime: new Date(today.getFullYear(), today.getMonth(), today.getDate(), 15, 32, 0),
                details: 'break',
                shiftId: 1,
                tagTypeId: 1,
                editions: "",
                timeTrackingId: 1,
                tagType: {
                    color: '#E6D7A3',
                    id: "1",
                    name: "Break time",
                    billable: true,
                    payable: false,
                    outstanding: false,
                },
            },
            {
                id: '1',
                activityTypeId: 23,
                clockInTime: new Date(today.getFullYear(), today.getMonth(), today.getDate(), 15, 32, 0),
                clockOutTime: new Date(today.getFullYear(), today.getMonth(), today.getDate(), 17, 10, 0),
                details: 'working',
                shiftId: 1,
                tagTypeId: 1,
                editions: "",
                timeTrackingId: 1,
                tagType: {
                    color: '#233746',
                    id: "1",
                    name: "Work time",
                    description: "Working time",
                    billable: true,
                    payable: false,
                    outstanding: false,
                },
            },
        ];
        return right([
            {
                planned: testEventSchedule,
                real: realListWorkA
            },
            {
                planned: testEventBreak,
                real: realListBreak
            },
            {
                planned: testEventSchedule2,
                real: realListWorkB
            }
        ])
    }
    startClock = async (eventId: string | undefined, tagType: string | undefined, shiftId: string, isAnyRunning: boolean): Promise<Either<ExceptionEntity, void>> => {
        await Testing.sleeper(1000);
        return right(undefined);
    }
    pauseClock = async (reason: string, eventId: string): Promise<Either<ExceptionEntity, void>> => {
        await Testing.sleeper(1000);
        return right(undefined);
    }
    getReasonsForPause = async (): Promise<Either<ExceptionEntity, string[]>> => {
        await Testing.sleeper(1000);
        return right(Array(4).fill(KeyWordLocalization.TrackingRepositoryStopForRestroom));
    }
    endEvent = async (eventId: string): Promise<Either<ExceptionEntity, void>> => {
        await Testing.sleeper(1000);
        return right(undefined);
    }
}