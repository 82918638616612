import { injectable } from "inversify";
import MastersProvider from "../../providers/master/MastersProvider";
import CalendarRepository from "../../repositories/CalendarRepository";
import { Either, isLeft, isRight, right } from "fp-ts/lib/Either";
import ExceptionEntity from "../../entities/ExceptionEntity";
import TypeEventScheduleEntity from "../../entities/TypeEventScheduleEntity";

interface props {
    calendarRepository: CalendarRepository;
    mastersProvider: MastersProvider;
}

@injectable()
export default class GetAllTypeOfEventsUseCase {
    _calendarRepository: CalendarRepository;
    _mastersProvider: MastersProvider;

    constructor(_: props) {
        this._calendarRepository = _.calendarRepository;
        this._mastersProvider = _.mastersProvider;
    }

    public call = async (): Promise<Either<ExceptionEntity, TypeEventScheduleEntity[]>> => {
        if (this._mastersProvider.Actions.typeSchedules.length > 0) return right(this._mastersProvider.Actions.typeSchedules);
        const response = await this._calendarRepository.getTypeEvents();
        if (isLeft(response)) return response;
        this._mastersProvider.Actions.setTypeSchedules(response.right.events);
        this._mastersProvider.Actions.setWorkTime(response.right.workTime);
        return right(response.right.events);
    };
}

export const GetAllTypeOfEventsUseCaseName = "GetAllTypeOfEventsUseCaseName";