import { injectable } from "inversify";
import EventScheduleEntity from "../../entities/EventScheduleEntity";
import { Either, left } from "fp-ts/lib/Either";
import ExceptionEntity from "../../entities/ExceptionEntity";
import ShiftRepository from "../../repositories/ShiftRepository";
import GetTodayTrackingCalendarUseCase from "../trackingTime/GetTodayTrackingCalendarUseCase";
import UserProvider from "../../providers/user/UserProvider";
import KeyWordLocalization from "../../../ui/providers/localization/dictionaries/KeyWordLocalization";

interface props {
    shiftRepository: ShiftRepository;
    getTodayTrackingCalendarUseCase: GetTodayTrackingCalendarUseCase;
    userProvider: UserProvider;
}
@injectable()
export default class CreateEventForShiftCalendarUseCase {
    private _shiftRepository: ShiftRepository;
    private _getTodayTrackingCalendarUseCase: GetTodayTrackingCalendarUseCase;
    private _userProvider: UserProvider;

    public constructor(_: props) {
        this._shiftRepository = _.shiftRepository;
        this._getTodayTrackingCalendarUseCase = _.getTodayTrackingCalendarUseCase;
        this._userProvider = _.userProvider;
    }

    public call = async (event: EventScheduleEntity, shiftId: string): Promise<Either<ExceptionEntity, EventScheduleEntity>> => {
        if(!this._userProvider.Actions.user?.permissions?.shift.calendar.create) return left({ code: KeyWordLocalization.UserNotAuthorized});
        const response = this._shiftRepository.createEventForShift(event, shiftId);
        this._getTodayTrackingCalendarUseCase.call();
        return response;
    }
}

export const CreateEventForShiftCalendarUseCaseName = "CreateEventForShiftCalendarUseCaseName";
