import { Either, left, right } from "fp-ts/lib/Either";
import ExceptionEntity from "../../../../../domain/entities/ExceptionEntity";
import UserEntity from "../../../../../domain/entities/UserEntity";
import di from "../../../../../di/DependencyInjection";
import UserHostApi, { UserHostApiName } from "../../../../settings/UserHostApi";
import UserHostDto from "../../../../dto/impl/UserHostDto";

const GetUsersOfGroupApiImpl = async (groupIds: string[]): Promise<Either<ExceptionEntity, UserEntity[]>> => {
    try {
        const paramsGroups = groupIds.map((groupId) => `groups=${groupId}`).join('&');
        const relativeUrl = `/groups/users?${paramsGroups}`;
        const groupsList = await di.get<UserHostApi>(UserHostApiName).get(relativeUrl);
        const mapped = groupsList.map((user: any) => UserHostDto.fromJson(user));
        return right(mapped);
    }
    catch (error: any) {
        return left({});
    }
}

export default GetUsersOfGroupApiImpl;
