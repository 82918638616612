/********************************************************************************
 * File Header - AWS cognito auth repository implementation
 * Created By: Marlon Alejandro Mendez Castañeda
 * Contact: +57 3234686680
 * Date: August 9, 2023
 *
 * Description:
 * This file contains the implementation for AuthRepository interface, using AWS cognito.
 ********************************************************************************/

import { injectable } from "inversify";
import AuthRepository from "../../../domain/repositories/AuthRepository";
import UserEntity from "../../../domain/entities/UserEntity";
import SignInApiImpl from "./impl/api/SignInApiImpl";
import SignUpApiImpl from "./impl/api/SignUpApiImpl";
import { Either } from "fp-ts/lib/Either";
import ExceptionEntity from "../../../domain/entities/ExceptionEntity";
import SignOutApiImpl from "./impl/api/SignOutApiImpl";
import SendRecoveryCodeApiImpl from "./impl/api/SendRecoveryCodeApiImpl";
import SendConfirmCodeApiImpl from "./impl/api/SendConfirmCodeApiImpl";
import ConfirmUserApiImpl from "./impl/api/ConfirmUserApiImpl";
import UpdatePasswordByRecoveryApiImpl from "./impl/api/UpdatePasswordByRecoveryApiImpl";
import GetCurrentUserApiImpl from "./impl/api/GetCurrentUserApiImpl";
import CreateUserAWSApiImpl from "./impl/api/CreateUserAWSApiImpl";
import DeleteUserApiImpl from "./impl/api/DeleteUserApiImpl";
import RefreshTokenApiImpl from "./impl/api/RefreshTokenApiImpl";
import UpdatePasswordApiImpl from "./impl/api/UpdatePasswordApiImpl";
import SignInWithActiveDirectoryApiImpl from "./impl/api/SignInWithActiveDirectoryApiImpl";

@injectable()
export default class AuthRepositoryImpl implements AuthRepository {
    signIn = (email: string, password: string): Promise<Either<ExceptionEntity, UserEntity>> => SignInApiImpl(email, password);
    signInWithActiveDirectory = (): Promise<Either<ExceptionEntity, UserEntity>> => SignInWithActiveDirectoryApiImpl();
    signUp = (email: string, password: string): Promise<Either<ExceptionEntity, void>> => SignUpApiImpl(email, password);
    signOut = (): Promise<Either<ExceptionEntity, void>> => SignOutApiImpl();
    sendRecoveryCode = (email: string): Promise<Either<ExceptionEntity, void>> => SendRecoveryCodeApiImpl(email);
    sendConfirmCode = (email: string): Promise<Either<ExceptionEntity, void>> => SendConfirmCodeApiImpl(email);
    confirmUser = (email: string, code: string): Promise<Either<ExceptionEntity, void>> => ConfirmUserApiImpl(email, code);
    updatePasswordByRecovery = (email: string, code: string, newPassword: string): Promise<Either<ExceptionEntity, void>> => UpdatePasswordByRecoveryApiImpl(email, code, newPassword);
    getCurrentUser = (): Promise<Either<ExceptionEntity, UserEntity>> => GetCurrentUserApiImpl();
    addUser = (user: UserEntity, password: string): Promise<Either<ExceptionEntity, void>> => CreateUserAWSApiImpl(user, password);
    deleteUser = (): Promise<Either<ExceptionEntity, void>> => DeleteUserApiImpl();
    refreshToken = (): Promise<Either<ExceptionEntity, void>> => RefreshTokenApiImpl();
    updatePassword = (oldPassword: string, newPassword: string, email: string): Promise<Either<ExceptionEntity, void>> => UpdatePasswordApiImpl(oldPassword, newPassword, email);
}