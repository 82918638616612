import React, { useEffect, useState } from 'react';
import { GetAIEmbedURLUseCase, GetAIEmbedURLUseCaseName } from '../../../domain/use_cases/ai/GetAIEmbedURLUseCase';
import di from '../../../di/DependencyInjection';

import "./AiPage.scss"

const AiPageDashEmbed: React.FC = () => {
    const [embededURL, setEmbededURL] = useState('');
    const [oldEmbededURL, setOldEmbededURL] = useState('');
    const [isUpdating, setIsUpdating] = useState(false); // State to track if iframe is updating

    const _updateUserRoleById = async () => await di.get<GetAIEmbedURLUseCase>(GetAIEmbedURLUseCaseName)._aiRepository.getAIEmbedURL();

    const _firstCall = async () => {
        setIsUpdating(true);
        const response = await _updateUserRoleById();
        if (response._tag === "Right") {
            let result = JSON.parse(response.right);
            if (result && result.data) {
                setEmbededURL(result.data); // Update to new URL after some delay
            }
        }
        setIsUpdating(false);
    };

    // Update URL on component mount and at intervals
    useEffect(() => {
        _firstCall();
    }, []);

    return (
        <div className='experience_container_body'>
            {isUpdating && (
                <div className="updating-message-container">
                    <p>Updating dashboard...</p>
                </div>
            )} {/* Show updating message when updating */}
            {!isUpdating && (
                <iframe
                src={embededURL}
                className="experience_container"
                style={{ width: '100%', height: '700px', display: isUpdating ? 'none' : 'block' }}
            ></iframe>
            )}
        </div>
    );
};

export default AiPageDashEmbed;
