import React from 'react';
import AiPageDashEmbed from './AiPageDashEmbed';

const AiPageDash: React.FC = () => {

    return (
            <AiPageDashEmbed />
    );
};


export default AiPageDash;
