export const formatDate = (date: Date) => {
    // date to dd/mm/yyyy hh:mm
    let dd = date?.getDate() < 10 ? ("0" + date?.getDate()) : date?.getDate();
    let mm = date?.getMonth() + 1 < 10 ? ("0" + (date?.getMonth() + 1)) : date?.getMonth() + 1;
    let yyyy = date?.getFullYear();
    let hh = date?.getHours() < 10 ? ("0" + date?.getHours()) : date?.getHours();
    let min = date?.getMinutes() < 10 ? ("0" + date?.getMinutes()) : date?.getMinutes();
    if (Number.isNaN(dd) || Number.isNaN(mm) || Number.isNaN(yyyy) || Number.isNaN(hh) || Number.isNaN(min)) return '--/--/---- --:--';
    return `${yyyy}-${mm}-${dd} ${hh}:${min}:00`;
}

export const formatDateForApi = (date: Date) => {
    let dd = date?.getDate() < 10 ? ("0" + date?.getDate()) : date?.getDate();
    let mm = date?.getMonth() + 1 < 10 ? ("0" + (date?.getMonth() + 1)) : date?.getMonth() + 1;
    let yyyy = date?.getFullYear();
    return `${yyyy}-${mm}-${dd}`;
}

export const getDateForInput = (date: Date) => {
    // date to dd-mm-yyyy
    let dd = date?.getDate() < 10 ? "0" + date?.getDate() : date?.getDate();
    let mm = date?.getMonth() < 10 ? "0" + (date?.getMonth() + 1) : date?.getMonth() + 1;
    let yyyy = date?.getFullYear();
    return `${yyyy}-${mm}-${dd}`;
}

export const getTimeForInput = (date: Date) => {
    let hh = date?.getHours() < 10 ? "0" + date?.getHours() : date?.getHours();
    let min = date?.getMinutes() < 10 ? "0" + date?.getMinutes() : date?.getMinutes();
    let ss = date?.getSeconds() < 10 ? "0" + date?.getSeconds() : date?.getSeconds();
    return `${hh}:${min}:${ss}`;
}

export const stringToDate = (dateString: string) => {
    // Split string and create array
    const arr: number[] = dateString.split(/[-\s:]/).map(val => parseInt(val, 10));

    // Ensure the array has at least 5 elements (year, month, day, hour, minute)
    while (arr.length < 5) arr.push(0);

    // Decrease month value by 1
    const [year, month, day, hour, minute, second = 0] = arr;

    // Create Date object
    return new Date(year, month - 1, day, hour, minute, second);
}


export const timeWithPmAm = (date: Date | undefined) => {
    if (date === undefined) return '--:-- --';
    let hh24 = date.getHours();
    let hh12 = hh24 % 12;
    let hh = hh12 < 10 ? "0" + hh12 : hh12;
    hh = hh == "00" ? "12" : hh;
    let min = date?.getMinutes() < 10 ? "0" + date?.getMinutes() : date?.getMinutes();
    return `${hh}:${min} ${date?.getHours() < 12 ? 'am' : 'pm'}`;
}

export const getDayOfCalendar = (date: Date) => {
    //TODO translate
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    return days[date?.getDay()];
}

export const getMonthOfCalendar = (date: Date): string => {
    //TODO translate
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    return months[date?.getMonth()];
}

export const getDateBeauty = (date: Date): string => {
    return `${getMonthOfCalendar(date)} ${date?.getDate()}, ${date?.getFullYear()}`;
}

export const getDateBeautyWithouthYear = (date: Date): string => {
    return `${getMonthOfCalendar(date)} ${date?.getDate()}`;
}

export const getHourAmPm = (dateOriginal: Date) => {

    /**
     * 
     * let hh = date?.getHours() < 12 ? date?.getHours() : date?.getHours() - 12;
    hh = hh == 0 ? 12 : hh;
    return `${hh} ${date?.getHours() < 12 ? 'AM' : 'PM'}`;
     */


    const date = new Date(dateOriginal);
    let hh = date?.getHours();
    let mm: any = date?.getMinutes();
    let ampm = hh < 12 ? 'AM' : 'PM';

    // Convert hours to 12-hour format
    hh = hh % 12 || 12;

    // Convert minutes to number and add leading zero if necessary
    mm = mm < 10 ? '0' + mm : mm;

    return `${hh}:${mm} ${ampm}`;

}

const fillArrayOfDates = (startDate: Date, endDate: Date) => {
    if(startDate === undefined || endDate === undefined) return [];
    if(startDate > endDate) return [];
    const arr = [];
    for (let dt = new Date(startDate); dt <= endDate; dt.setDate(dt.getDate() + 1)) {
        arr.push(new Date(dt));
    }
    return arr;
}

const DateParse = {
    formatDate,
    formatDateForApi,
    fillArrayOfDates,
    getDateForInput,
    getTimeForInput,
    getDayOfCalendar,
    getMonthOfCalendar,
    getDateBeauty,
    getDateBeautyWithouthYear,
    getHourAmPm,
    stringToDate,
    timeWithPmAm,
}

export default DateParse;
