import React from "react";
import LocalizationContextType from "./LocalizationContextType";
import LocalizationEntity from "../../entities/LocalizationEntity";

const LocalizationContext = React.createContext<LocalizationContextType>({
    localizations: [],
    localization: undefined,
    i18n: (keyWord: string, values?: any) => keyWord,
    setLocalization: (localization: LocalizationEntity) => {},
    setLocalizations: (localization: LocalizationEntity[]) => {}

});

export default LocalizationContext;