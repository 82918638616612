import { injectable } from "inversify";
import BusinessUnitRepository from "../../../domain/repositories/BusinessUnitRepository";
import BusinessUnitEntity from "../../../domain/entities/BusinessUnitEntity";
import SearchResultEntity from "../../../domain/entities/SearchResultEntity";
import SearchBusinessUnitApiImpl from "./api/impl/SearchBusinessUnitApiImpl";

const testBu: BusinessUnitEntity = {
    id: "1",
    name: "Walmart",
    logo: 'https://purepng.com/public/uploads/large/purepng.com-walmart-logologobrand-logoiconslogos-251519939045oqelv.png',
}

@injectable()
export default class BusinessUnitRepositoryDev implements BusinessUnitRepository {
    search = async (word: string, page: number, itemsPerPage: number, filter: any): Promise<SearchResultEntity<BusinessUnitEntity>> => SearchBusinessUnitApiImpl(word, page, itemsPerPage, filter);
}