import { Either } from "fp-ts/lib/Either";
import ExceptionEntity from "../../../domain/entities/ExceptionEntity";
import RoleEntity from "../../../domain/entities/RoleEntity";
import RoleRepository, { GetModuleWithAllRolesResponse } from "../../../domain/repositories/RoleRepository";
import UpdateRolPermisionsApiImpl from "./api/UpdateRolPermisionsApiImpl";
import DeleteRoleById from "./api/DeleteRoleByIdApiImpl";
import GetModuleWithRolesInteractionApiImpl from "./api/GetModuleWithRolesInteractionApiImpl";
import GetAllRoles from "./api/GetAllRolesApiImpl";
import UpdateInfo from './api/UpdateRoleInfoApiImpl'
import GetAllPermissions from "./api/GetAllPermissionsApiImpl";
import GetRoleById from "./api/GetRoleByIdApiImpl";
import { injectable } from "inversify";
import CreateRoleInfoApiImpl from "./api/CreateRoleInfoApiImpl";

@injectable()
export class RoleRepositoryImpl implements RoleRepository{
    getRoleById = async (id: string): Promise<Either<ExceptionEntity, RoleEntity>>  => GetRoleById(id);
    updateRoleInfo = async (id: string, name: string, description: string): Promise<Either<ExceptionEntity, string>> => UpdateInfo(id, name, description);
    updateRolePermissions = async (id: string, bodyContent: any): Promise<Either<ExceptionEntity, string>> => UpdateRolPermisionsApiImpl(id, bodyContent);
    deleteRole = async (id: string): Promise<Either<ExceptionEntity, string>> => DeleteRoleById(id);
    getModuleWithAllRoles = async (moduleId: string): Promise<Either<ExceptionEntity, GetModuleWithAllRolesResponse>> => GetModuleWithRolesInteractionApiImpl(moduleId);
    getAllRoles = async (): Promise<Either<ExceptionEntity, RoleEntity[]>> => GetAllRoles();
    getAllPermissions = async (): Promise<Either<ExceptionEntity, any[]>> => GetAllPermissions();
    createRole = async(name: string, description: string, permissions: any[]): Promise<Either<ExceptionEntity, string>> =>  CreateRoleInfoApiImpl(name, description, permissions);
    
}