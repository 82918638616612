import { injectable } from "inversify";
import ShiftRepository from "../../repositories/ShiftRepository";
import SearchResultEntity from "../../entities/SearchResultEntity";
import ShiftEntity from "../../entities/ShiftEntity";

interface props {
  shiftRepository: ShiftRepository;
}

@injectable()
export default class SearchShiftTemplateUseCase {
  private shiftRepository: ShiftRepository;

  public constructor(_: props) {
    this.shiftRepository = _.shiftRepository;
  }

public async call(businessUnitId: string,  days: Date[], word: string, page: number, itemsPerPage: number): Promise<SearchResultEntity<ShiftEntity>> {
    return this.shiftRepository.searchShiftTemplate(businessUnitId, days, word, page, itemsPerPage);
  }
}

export const SearchShiftTemplateUseCaseName = "SearchShiftTemplateUseCase";