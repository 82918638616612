import { Either } from "fp-ts/lib/Either";
import ExceptionEntity from "../../../../../domain/entities/ExceptionEntity";
import DateParse from "../../../../../ui/utils/DateParse";
import GraphApi from "../../../../settings/GraphApi";

const EndEventTrackingClockApiImpl = async (shiftId: string): Promise<Either<ExceptionEntity, void>> => {
    const body = {
        updateTime_trackingInput: {
            shift_id: parseInt(shiftId),
            clock_out_time: DateParse.formatDate(new Date()),
        }
    }
    const response = await GraphApi.mutation('updateTime_tracking', body, ['activity_type_id', 'clock_in_time', 'clock_out_time', 'details', 'shift_id', 'tag_type_id', 'time_tracking_id']);
    return response;
}

export default EndEventTrackingClockApiImpl;