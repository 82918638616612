import { FC } from "react";
import UserHeaderInfoComponentProps from "./UserHeaderInfoComponentProps";
import './UserHeaderInfoComponentStyles.scss';
import Icons from "../../../assets/Icons";

const UserHeaderInfoComponent: FC<UserHeaderInfoComponentProps> = ({ user }) => {
    return <div className="user_header_info_component px-3 pb-3">
        <div className="w-100 d-flex flex-wrap">
            <div className="circle-image-container">
                <img src={user.image ?? '/assets/no_photo_profile.svg'} alt="" className="img_rounded img-fluid" />
            </div>
            <div className="d-flex ms-4 flex-column">
                <strong>{user.name}</strong>
                <span>{user.position}</span>
                <span>{user.area}</span>
            </div>
            <div className="mt-md-4 ms-md-2 ms-lg-4">
                <div className="d-flex align-center w-100 hover">
                    <div style={{ width: 40 }}>
                        <Icons.Email />
                    </div>
                    <div className="flex-grow-1 text_ellipsis text-left">
                        {user.email}
                    </div>
                </div>
                {user.phone &&
                    <div className="d-flex align-center w-100 hover">
                        <div style={{ width: 40 }}>
                            <Icons.PhoneOutlined />
                        </div>
                        <div className="flex-grow-1 text_ellipsis text-left">
                            {user.phone}
                        </div>
                    </div>}
            </div>

        </div>
        <hr />
    </div >
}

export default UserHeaderInfoComponent;