import { injectable } from "inversify";
import ShiftEntity from "../../entities/ShiftEntity";
import MastersProvider from "../../providers/master/MastersProvider";
import EventScheduleEntity from "../../entities/EventScheduleEntity";

interface props {
    mastersProvider: MastersProvider;
}

@injectable()

class EventFromShiftUseCase {
    mastersProvider: MastersProvider;
    constructor(_: props) {
        this.mastersProvider = _.mastersProvider;
    }

    public call = (shift : ShiftEntity): EventScheduleEntity | undefined => {
        const workTime = this.mastersProvider.Actions.workTime;
        if(!workTime) return undefined;
        const temp:EventScheduleEntity = {
            id: '',
            dateStart: shift.initHour,
            dateEnd: shift.endHour,
            type: workTime!,
            name: 'Work Time',
        }

        return temp;
    }
}

export default EventFromShiftUseCase;

export const EventFromShiftUseCaseName = "EventFromShiftUseCase";