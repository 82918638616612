import { FC } from "react";
import SelectFormComponentProps from "./SelectFormComponentProps";
import { ErrorMessage } from "@hookform/error-message";
import KeyWordLocalization from "../../../providers/localization/dictionaries/KeyWordLocalization";
import Validators from "../../../utils/Validators";

const SelectFormComponent: FC<SelectFormComponentProps> = ({ label, keyName, disabled, register, onChange, watch, errors, validatorProps, options }) => {
    const value = watch(keyName);
    const _toggleOption = (optionId: number) => onChange(keyName, optionId);
    
    return <div className="w-100">
        <div className="form-group">
            <label>{label}</label>
            <div className="d-flex flex-wrap">
                {options.map((option, index) => <div key={index}
                    className={`chip m-2 hover ${value == option.id && 'active'}`}
                    onClick={() => _toggleOption(option.id)}
                >{option.label}</div>)}
            </div>
            <input type="hidden" {...register(keyName, Validators({
                required: true,
            }))} />
            <ErrorMessage as="aside" errors={errors} name={keyName} />
        </div>
    </div>
}

export default SelectFormComponent;