/********************************************************************************
 * File Header - Constants
 * Created By: Marlon Alejandro Mendez Castañeda
 * Contact: +57 3234686680
 * Date: March 14, 2024
 *
 * Description:
 * This file is the interface for the Constants object, which is used to store the
 * constants of the application, and used for dependency injection to switch between envs
 ********************************************************************************/

export default interface Constants {
    BASE_URL: string;
    GRAPH_API_URL: string;
    HOST_API_1_URL: string;
    HOST_API_2_URL: string;
    HOST_API_USERS_URL: string;
    GA4: string;
    AI: string;
}

export const ConstantsName = "Constants";