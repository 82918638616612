import TypeEventScheduleEntity from "../../../domain/entities/TypeEventScheduleEntity";

const fromJson = (json: any):TypeEventScheduleEntity => {
    const temp:TypeEventScheduleEntity = {
        id: json.tag_type_id,
        name: json.activity_tag,
        description: json.description,
        billable: json.billable ?? false, //its not part of type tag
        color: json.color,
        outstanding: json.outstanding ?? false, //its not part of type tag
        payable: json.payable ?? false, //its not part of type tag
    }

    return temp;
}

const toJson = (typeEventSchedule: TypeEventScheduleEntity): any => {
    return {
        tag_type_id: typeEventSchedule.id,
        activity_tag: typeEventSchedule.name,
        description: typeEventSchedule.description,
        billable: typeEventSchedule.billable,
        color: typeEventSchedule.color,
        outstanding: typeEventSchedule.outstanding,
        payable: typeEventSchedule.payable,
    }
}

export default {
    fromJson,
    toJson,
}