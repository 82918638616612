import { Either, left, right } from "fp-ts/lib/Either";
import ExceptionEntity from "../../../../../domain/entities/ExceptionEntity";
import { Auth } from "aws-amplify";
import callAPI from "../../../fetchCallImplementation";
import Constants, { ConstantsName } from "../../../../../constants/Constants";
import di from "../../../../../di/DependencyInjection";

const UpdateUserRoleIdApiImpl = async (userId: number, roleId: number): Promise<Either<ExceptionEntity, string>> => {
    
    try {
        const body = {
            "role_id": roleId
        }
        const baseUrl = di.get<Constants>(ConstantsName).HOST_API_USERS_URL;
        const response = await callAPI(`${baseUrl}/users/${userId}`, 'PUT', body);

        if (!response.ok) {
            const error = await response.json()
            throw new Error(error.message);
        }

        const value  = await response.body?.getReader().read();
        const result = new TextDecoder().decode(value?.value);
        
        return right(result);
        
    } catch (error: any) {
        
        const exception: ExceptionEntity = {message: error.message};
        
        return left(exception);
    }
}

export default UpdateUserRoleIdApiImpl;