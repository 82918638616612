import { injectable } from "inversify";
import CalendarRepository from "../../../domain/repositories/CalendarRepository";
import { Either, right } from "fp-ts/lib/Either";
import EventScheduleEntity from "../../../domain/entities/EventScheduleEntity";
import ExceptionEntity from "../../../domain/entities/ExceptionEntity";
import Testing from "../../../ui/utils/Testing";
import GetAllTypeEventsApiImpl from "./api/impl/GetAllTypeEventsApiImpl";
import SearchUsersWithShiftApiImpl from "../shift/api/impl/SearchUsersWithShiftApiImpl";
import GetEmployeeTodayCalendarApiImpl from "./api/impl/GetEmployeeTodayCalendarApiImpl";
import di from "../../../di/DependencyInjection";
import MastersProvider, { MastersProviderName } from "../../../domain/providers/master/MastersProvider";
import DateParse from "../../../ui/utils/DateParse";
import ShiftEntity from "../../../domain/entities/ShiftEntity";
import GetEmployeeCalendarApiImpl from "./api/impl/GetEmployeeCalendarApiImpl";

@injectable()
export default class CalendarRepositoryDev implements CalendarRepository {
    getEmployeeTodayCalendar = (employeeId: string): Promise<Either<ExceptionEntity, ShiftEntity>> => GetEmployeeTodayCalendarApiImpl(employeeId);
    getTypeEvents = GetAllTypeEventsApiImpl;
    getEmployeeCalendar = (employeeId: string, startDate: Date, endDate: Date): Promise<Either<ExceptionEntity, ShiftEntity[]>> => GetEmployeeCalendarApiImpl(employeeId, startDate, endDate);
    createEventForUser = async (event: EventScheduleEntity, userId: string): Promise<Either<ExceptionEntity, EventScheduleEntity>> => {
        await Testing.sleeper(1000);
        return right(event);
    }
    updateEvent = async (event: EventScheduleEntity): Promise<Either<ExceptionEntity, EventScheduleEntity>> => {
        await Testing.sleeper(1000);
        return right(event);
    }
    deleteEvent = async (eventId: string): Promise<Either<ExceptionEntity, void>> => {
        await Testing.sleeper(1000);
        return right(undefined);
    }

}