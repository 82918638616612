import './SendCodeRecoveryStyles.scss';
import { ErrorMessage } from "@hookform/error-message";
import { FC, useContext } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import Validators from "../../../../utils/Validators";
import ModalsContext from '../../../../../domain/providers/modal/ModalsContext';
import ModalsContextType from '../../../../../domain/providers/modal/ModalsContextType';
import { routes } from '../../../../routes/RoutesComponent';
import di from '../../../../../di/DependencyInjection';
import LocalizationContext from '../../../../../domain/providers/localization/LocalizationContext';
import LocalizationContextType from '../../../../../domain/providers/localization/LocalizationContextType';
import KeyWordLocalization from '../../../../providers/localization/dictionaries/KeyWordLocalization';
import SendRecoveryPasswordCodeUseCase, { SendRecoveryPasswordCodeUseCaseName } from '../../../../../domain/use_cases/auth/SendRecoveryPasswordCodeUseCase';
import { isRight } from 'fp-ts/lib/Either';

const SendCodeRecoveryPage: FC<{}> = () => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const { i18n } = useContext(LocalizationContext) as LocalizationContextType;
    const { addToast } = useContext(ModalsContext) as ModalsContextType;
    const navigate = useNavigate();

    const onSubmit = async (data: any) => {
        const response = await di.get<SendRecoveryPasswordCodeUseCase>(SendRecoveryPasswordCodeUseCaseName).call(data.email);
        if (isRight(response)) {
            addToast(i18n(KeyWordLocalization.SendCodeRecoveryPageCodeSent), 'success', null);
            navigate(routes.update_passowrd_recovery.relativePath + '/' + data.email);
        } else {
            addToast(i18n(response.left.code ?? KeyWordLocalization.SendCodeRecoveryPageEmailNotFound), 'error', null, true);
        }
    };

    return (
        <div className='send_code_recovery_page'>
            <span className=''>{i18n(KeyWordLocalization.SendCodeRecoveryPageTitle)}</span>
            <form className="row my-3" onSubmit={handleSubmit(onSubmit)}>
                <div className={`col-12 mb-3 form-group ${errors.email ? 'error' : ''}`}>
                    <label>{i18n(KeyWordLocalization.SendCodeRecoveryPageEmail)}</label>
                    <input type="text" className="form-control" placeholder={i18n(KeyWordLocalization.SendCodeRecoveryPageEmail)}
                        {...register('email', Validators({ required: true, email: true }))} />
                    <ErrorMessage as="aside" errors={errors} name="email" />
                </div>
                <div className="col-12">
                    <button type="submit" className="btn btn_primary mb-4 w-100">{i18n(KeyWordLocalization.SendCodeRecoveryPageSubmit)}</button>
                </div>
                <Link className="forget_password_link mt-0 small" to={routes.signin.relativePath}>{i18n(KeyWordLocalization.SendCodeRecoveryPageIRememberMyPassword)}</Link>
            </form>
        </div>
    );
};

export default SendCodeRecoveryPage;