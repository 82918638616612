import './UpdatePasswordRecoveryStyles.scss';
import { ErrorMessage } from "@hookform/error-message";
import { FC, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import Validators from "../../../../utils/Validators";
import ModalsContext from '../../../../../domain/providers/modal/ModalsContext';
import ModalsContextType from '../../../../../domain/providers/modal/ModalsContextType';
import { routes } from '../../../../routes/RoutesComponent';
import di from '../../../../../di/DependencyInjection';
import LocalizationContext from '../../../../../domain/providers/localization/LocalizationContext';
import LocalizationContextType from '../../../../../domain/providers/localization/LocalizationContextType';
import KeyWordLocalization from '../../../../providers/localization/dictionaries/KeyWordLocalization';
import UpdatePasswordByRecoveryUseCase, { UpdatePasswordByRecoveryUseCaseName } from '../../../../../domain/use_cases/auth/UpdatePasswordByRecoveryUseCase';
import { isRight } from 'fp-ts/lib/Either';
import SendRecoveryPasswordCodeUseCase, { SendRecoveryPasswordCodeUseCaseName } from '../../../../../domain/use_cases/auth/SendRecoveryPasswordCodeUseCase';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';

const _countTimer = 20;
var interval: any = undefined;

const UpdatePasswordRecoveryPage: FC<{}> = () => {
    const { i18n } = useContext(LocalizationContext) as LocalizationContextType;
    const { addToast } = useContext(ModalsContext) as ModalsContextType;
    const { email } = useParams();
    const navigate = useNavigate();
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const [showPassword, setShowPassword] = useState<boolean>(false);

    const [timer, setTimer] = useState(_countTimer);
    var _timer = _countTimer;

    const onSubmit = async (data: any) => {
        const response = await di.get<UpdatePasswordByRecoveryUseCase>(UpdatePasswordByRecoveryUseCaseName).call(email!, data.password, data.code);
        if (isRight(response)) {
            addToast(i18n(KeyWordLocalization.UpdatePasswordRecoveryPageUpdatedPassword), 'success', null);
            interval = undefined;
            navigate(routes.signin.relativePath);
        } else {
            addToast(i18n(response.left.code ?? KeyWordLocalization.UpdatePasswordRecoveryPageErrorUpdatingPassword), 'error', null, true);
        }
    };

    const _countdownFunc = () => {
        _timer = _timer - 1;
        if (_timer <= 0) {
            clearInterval(interval);
            interval = undefined;
            _timer = 0;
            setTimer(0);
            return;
        } else {
            setTimer(_timer);
        }
    }

    const _resendCode = async () => {
        if (timer > 0) return;
        const response = await di.get<SendRecoveryPasswordCodeUseCase>(SendRecoveryPasswordCodeUseCaseName).call(email!);
        if (isRight(response)) {
            addToast(i18n(KeyWordLocalization.ConfirmAccountPageResentCode), 'success', null);
        } else {
            addToast(i18n(response.left.code ?? KeyWordLocalization.UnknownError), 'error', null);
        }
        setTimer(_countTimer);
        _timer = _countTimer;
        interval = setInterval(_countdownFunc, 1000);
    }


    useEffect(() => {
        setTimer(_countTimer);
        _timer = _countTimer;
        if (interval == undefined) interval = setInterval(_countdownFunc, 1000);
    }, [email])

    return (
        <div className='update_password_recovery_page'>
            <span className=''>{i18n(KeyWordLocalization.UpdatePasswordRecoveryPageTitle)}</span>
            <form className="row my-3" onSubmit={handleSubmit(onSubmit)}>
                <div className={`col-12 my-2 form-group ${errors.code ? 'error' : ''}`}>
                    <label>{i18n(KeyWordLocalization.UpdatePasswordRecoveryPageCode)}</label>
                    <input type="text" className="form-control" placeholder={i18n(KeyWordLocalization.UpdatePasswordRecoveryPageCode)}
                        {...register('code', Validators({ required: true, minValue: 0, maxValue: 999999 }))} />
                    <ErrorMessage as="aside" errors={errors} name="code" />
                </div>
                <div className={`col-12 my-2 form-group ${errors.password ? 'error' : ''}`}>
                    <label>{i18n(KeyWordLocalization.UpdatePasswordRecoveryPagePassword)}</label>
                    <div className="password_input_wrapper">
                        <input type={showPassword ? 'text' : 'password'} className="form-control" placeholder={i18n(KeyWordLocalization.UpdatePasswordRecoveryPagePassword)}
                            {...register('password', Validators({ required: true, isPassword: true }))} />
                        <div className="visibility_icon" onClick={() => setShowPassword(!showPassword)}>
                            {showPassword ? <MdVisibility /> : <MdVisibilityOff />}
                        </div>
                    </div>
                    <ErrorMessage as="aside" errors={errors} name="password" />
                </div>
                <div className={`col-12 my-2 form-group ${errors.confirm_password ? 'error' : ''}`}>
                    <label>{i18n(KeyWordLocalization.UpdatePasswordRecoveryPagePasswordConfirm)}</label>
                    <div className="password_input_wrapper">
                        <input type={showPassword ? 'text' : 'password'} {...register("confirm_password", Validators({
                            required: true, minLength: 6, isPassword: true, validate: (val: string) => {
                                if (watch('password') != val) {
                                    return i18n(KeyWordLocalization.UpdatePasswordRecoveryPagePasswordNotMatch);
                                }
                            },
                        }))} className="form-control" placeholder={i18n(KeyWordLocalization.UpdatePasswordRecoveryPagePasswordConfirm)} />
                        <div className="visibility_icon" onClick={() => setShowPassword(!showPassword)}>
                            {showPassword ? <MdVisibility /> : <MdVisibilityOff />}
                        </div>
                    </div>
                    <ErrorMessage as="aside" errors={errors} name="confirm_password" />
                </div>

                <div className="col-12">
                    <button type="submit" className="btn btn_primary mb-4 w-100">{i18n(KeyWordLocalization.UpdatePasswordRecoveryPageSubmit)}</button>
                </div>
                <div onClick={_resendCode} className={`${timer > 0 ? 'disabled' : 'hover'} forget_password_link mt-0 small`} >{i18n(KeyWordLocalization.UpdatePasswordRecoveryPageResendCode, { timer: timer > 0 ? timer : '' })}</div>
            </form>
        </div>
    );
};

export default UpdatePasswordRecoveryPage;