import { injectable } from "inversify";
import EventScheduleEntity from "../../entities/EventScheduleEntity";
import { Either, isRight, left, right } from "fp-ts/lib/Either";
import ExceptionEntity from "../../entities/ExceptionEntity";
import CalendarRepository from "../../repositories/CalendarRepository";
import TrackingTimeProvider from "../../providers/trackingTime/TrackingTimeProvider";
import UserProvider from "../../providers/user/UserProvider";
import KeyWordLocalization from "../../../ui/providers/localization/dictionaries/KeyWordLocalization";

interface props {
    calendarRepository: CalendarRepository;
    userProvider: UserProvider;
    trackingTimeProvider: TrackingTimeProvider;
}

//TODO: verify
@injectable()
export default class GetTodayTrackingCalendarUseCase {
    _calendarRepository: CalendarRepository;
    _userProvider: UserProvider;
    _trackingTimeProvider: TrackingTimeProvider;

    constructor(_: props) {
        this._calendarRepository = _.calendarRepository;
        this._userProvider = _.userProvider;
        this._trackingTimeProvider = _.trackingTimeProvider;
    }

    public call = async (userId?: string | undefined): Promise<Either<ExceptionEntity, EventScheduleEntity[]>> => {
        if (this._userProvider.Actions.user == undefined && userId == undefined) return left({ code: KeyWordLocalization.UserNotFoundException, message: KeyWordLocalization.UserNotFoundException });
        const calendar = await this._calendarRepository.getEmployeeTodayCalendar(userId ?? this._userProvider.Actions.user!.id);
        this._trackingTimeProvider.Actions.setLoaded(true);
        if (isRight(calendar)) {
            this._trackingTimeProvider.Actions.setTodayCalendar(calendar.right);
            const todayEvents = calendar.right?.events ?? [];
            return right(todayEvents);
        }
        else return left(calendar.left);
    }
}

export const GetTodayTrackingCalendarUseCaseName = "GetTodayTrackingCalendarUseCaseName";
