import SearchResultEntity from "../../../../../domain/entities/SearchResultEntity";
import ShiftEntity from "../../../../../domain/entities/ShiftEntity";

const SearchShiftTemplateApiImpl = async (businessUnitId: string, days: Date[], word: string, page: number, itemsPerPage: number): Promise<SearchResultEntity<ShiftEntity>> => {
    return {
        items: [],
        pages: 1,
        total: 0
    };
}

export default SearchShiftTemplateApiImpl;