import { left } from "fp-ts/lib/EitherT";
import SearchResultEntity from "../../../../../domain/entities/SearchResultEntity";
import ShiftEntity from "../../../../../domain/entities/ShiftEntity";
import callAPI from "../../../fetchCallImplementation";
import Constants, { ConstantsName } from "../../../../../constants/Constants";
import di from "../../../../../di/DependencyInjection";
import StaffPerShiftEntity from "../../../../../domain/entities/StaffPerShiftEntity";
import Host2Api, { Host2ApiName } from "../../../../settings/Host2Api";
import StaffPerShiftHostDto from "../../../../dto/impl/StaffPerShiftHostDto";
import UserEntity from "../../../../../domain/entities/UserEntity";
import BusinessUnitEntity from "../../../../../domain/entities/BusinessUnitEntity";
import GroupEntity from "../../../../../domain/entities/GroupEntity";
import DateParse from "../../../../../ui/utils/DateParse";

//TODO implement
const SearchWithUserGroupStatusApiImpl = async (businessUnit: BusinessUnitEntity[], supervisors: UserEntity[], employees: UserEntity[], groups: GroupEntity[], days: Date[], page: number, itemsPerPage: number): Promise<SearchResultEntity<StaffPerShiftEntity>> => {

    let params = "";

    if (groups.length > 0) {
        params = groups.map((group) => 'groups=' + parseInt(group.id)).join('&');
    } else if (employees.length > 0) {
        params = employees.map((employee) => 'userid=' + parseInt(employee.id)).join('&');
    } else if (supervisors.length > 0) {
        params = supervisors.map((supervisor) => 'supervisorid=' + parseInt(supervisor.id)).join('&');
    } else if (businessUnit.length > 0) {
        params = businessUnit.map((businessUnit) => 'businessunits=' + businessUnit.id).join('&');
    }

    const startDate = days[0];
    const endDate = days[days.length - 1];

    let relativeUrl = `/staffpershift?${params}&starting_date=${DateParse.formatDateForApi(startDate)}&ending_date=${DateParse.formatDateForApi(endDate)}`;

    const hasAgentId = relativeUrl.replace('&&', '&');


    try {


        const response = await di.get<Host2Api>(Host2ApiName).get(hasAgentId);
        // const response = {
        //     "data": [
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "DSI",
        //             "category": 0,
        //             "name": "DSI - Others",
        //             "shift_date": "2023-05-15",
        //             "shift_end_time": null,
        //             "shift_id": 3150,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "DSI",
        //             "category": 0,
        //             "name": "DSI - Others",
        //             "shift_date": "2023-05-16",
        //             "shift_end_time": null,
        //             "shift_id": 3151,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "DSI",
        //             "category": 0,
        //             "name": "DSI - Others",
        //             "shift_date": "2023-05-17",
        //             "shift_end_time": null,
        //             "shift_id": 3152,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "DSI",
        //             "category": 0,
        //             "name": "DSI - Others",
        //             "shift_date": "2023-05-18",
        //             "shift_end_time": null,
        //             "shift_id": 3153,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "DSI",
        //             "category": 0,
        //             "name": "DSI - Others",
        //             "shift_date": "2023-05-21",
        //             "shift_end_time": null,
        //             "shift_id": 3154,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "DSI",
        //             "category": 0,
        //             "name": "DSI - Others",
        //             "shift_date": "2024-03-11",
        //             "shift_end_time": null,
        //             "shift_id": 64,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "DSI",
        //             "category": 0,
        //             "name": "DSI - Others",
        //             "shift_date": "2024-03-12",
        //             "shift_end_time": null,
        //             "shift_id": 19,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 4,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "DSI",
        //             "category": 0,
        //             "name": "DSI - Others",
        //             "shift_date": "2024-03-13",
        //             "shift_end_time": null,
        //             "shift_id": 44,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 4,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "DSI",
        //             "category": 0,
        //             "name": "DSI - Others",
        //             "shift_date": "2024-03-14",
        //             "shift_end_time": null,
        //             "shift_id": 45,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 2,
        //             "Training": 1,
        //             "business_unit": "DSI",
        //             "category": 0,
        //             "name": "DSI - Others",
        //             "shift_date": "2024-03-15",
        //             "shift_end_time": null,
        //             "shift_id": 52,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "DSI",
        //             "category": 0,
        //             "name": "DSI - Others",
        //             "shift_date": "2024-03-16",
        //             "shift_end_time": null,
        //             "shift_id": 49,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 8,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-03-18",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 107,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 1,
        //             "Training": 1,
        //             "business_unit": "DSI",
        //             "category": 0,
        //             "name": "DSI - Others",
        //             "shift_date": "2024-03-20",
        //             "shift_end_time": null,
        //             "shift_id": 104,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 8,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-03-20",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 117,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "DSI",
        //             "category": 0,
        //             "name": "DSI - Others",
        //             "shift_date": "2024-03-21",
        //             "shift_end_time": null,
        //             "shift_id": 55,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 8,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-03-21",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 121,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "DSI",
        //             "category": 0,
        //             "name": "DSI - Others",
        //             "shift_date": "2024-03-22",
        //             "shift_end_time": null,
        //             "shift_id": 57,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 6,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-03-22",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 130,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "DSI",
        //             "category": 0,
        //             "name": "DSI - Others",
        //             "shift_date": "2024-03-23",
        //             "shift_end_time": null,
        //             "shift_id": 59,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 8,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-03-23",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 138,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-03-25",
        //             "shift_end_time": null,
        //             "shift_id": 166,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "DSI",
        //             "category": 0,
        //             "name": "DSI - Others",
        //             "shift_date": "2024-03-25",
        //             "shift_end_time": null,
        //             "shift_id": 97,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 3,
        //             "name": "MIA Aesthetics - 08:00 AM to 05:00 PM",
        //             "shift_date": "2024-03-25",
        //             "shift_end_time": "17:00:00",
        //             "shift_id": 217,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 4,
        //             "name": "MIA Aesthetics - 09:00 AM to 06:00 PM",
        //             "shift_date": "2024-03-25",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 212,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-03-25",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 151,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-03-25",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 181,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-03-26",
        //             "shift_end_time": null,
        //             "shift_id": 162,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "DSI",
        //             "category": 0,
        //             "name": "DSI - Others",
        //             "shift_date": "2024-03-26",
        //             "shift_end_time": null,
        //             "shift_id": 98,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 3,
        //             "name": "MIA Aesthetics - 08:00 AM to 05:00 PM",
        //             "shift_date": "2024-03-26",
        //             "shift_end_time": "17:00:00",
        //             "shift_id": 218,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 3,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 4,
        //             "name": "MIA Aesthetics - 09:00 AM to 06:00 PM",
        //             "shift_date": "2024-03-26",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 223,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-03-26",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 152,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-03-26",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 176,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 1,
        //             "Training": 1,
        //             "business_unit": "DSI",
        //             "category": 0,
        //             "name": "DSI - Others",
        //             "shift_date": "2024-03-27",
        //             "shift_end_time": null,
        //             "shift_id": 235,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-03-27",
        //             "shift_end_time": null,
        //             "shift_id": 157,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 3,
        //             "name": "MIA Aesthetics - 08:00 AM to 05:00 PM",
        //             "shift_date": "2024-03-27",
        //             "shift_end_time": "17:00:00",
        //             "shift_id": 219,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 4,
        //             "name": "MIA Aesthetics - 09:00 AM to 06:00 PM",
        //             "shift_date": "2024-03-27",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 214,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-03-27",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 147,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-03-27",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 177,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-03-28",
        //             "shift_end_time": null,
        //             "shift_id": 169,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "DSI",
        //             "category": 0,
        //             "name": "DSI - Others",
        //             "shift_date": "2024-03-28",
        //             "shift_end_time": null,
        //             "shift_id": 100,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 3,
        //             "name": "MIA Aesthetics - 08:00 AM to 05:00 PM",
        //             "shift_date": "2024-03-28",
        //             "shift_end_time": "17:00:00",
        //             "shift_id": 220,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 4,
        //             "name": "MIA Aesthetics - 09:00 AM to 06:00 PM",
        //             "shift_date": "2024-03-28",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 210,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-03-28",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 173,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-03-28",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 183,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-03-29",
        //             "shift_end_time": null,
        //             "shift_id": 159,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "DSI",
        //             "category": 0,
        //             "name": "DSI - Others",
        //             "shift_date": "2024-03-29",
        //             "shift_end_time": null,
        //             "shift_id": 101,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 3,
        //             "name": "MIA Aesthetics - 08:00 AM to 05:00 PM",
        //             "shift_date": "2024-03-29",
        //             "shift_end_time": "17:00:00",
        //             "shift_id": 221,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 4,
        //             "name": "MIA Aesthetics - 09:00 AM to 06:00 PM",
        //             "shift_date": "2024-03-29",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 226,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-03-29",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 149,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-03-29",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 184,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-03-30",
        //             "shift_end_time": null,
        //             "shift_id": 160,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "DSI",
        //             "category": 0,
        //             "name": "DSI - Others",
        //             "shift_date": "2024-03-30",
        //             "shift_end_time": null,
        //             "shift_id": 102,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-03-30",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 150,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-03-30",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 180,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-03-31",
        //             "shift_end_time": null,
        //             "shift_id": 170,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "DSI",
        //             "category": 0,
        //             "name": "DSI - Others",
        //             "shift_date": "2024-03-31",
        //             "shift_end_time": null,
        //             "shift_id": 103,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-03-31",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 175,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 4,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-04-01",
        //             "shift_end_time": null,
        //             "shift_id": 301,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "DSI",
        //             "category": 0,
        //             "name": "DSI - Others",
        //             "shift_date": "2024-04-01",
        //             "shift_end_time": null,
        //             "shift_id": 186,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 3,
        //             "name": "MIA Aesthetics - 08:00 AM to 05:00 PM",
        //             "shift_date": "2024-04-01",
        //             "shift_end_time": "17:00:00",
        //             "shift_id": 236,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 4,
        //             "name": "MIA Aesthetics - 09:00 AM to 06:00 PM",
        //             "shift_date": "2024-04-01",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 256,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-04-01",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 291,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-04-01",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 323,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-04-02",
        //             "shift_end_time": null,
        //             "shift_id": 317,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "DSI",
        //             "category": 0,
        //             "name": "DSI - Others",
        //             "shift_date": "2024-04-02",
        //             "shift_end_time": null,
        //             "shift_id": 227,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 3,
        //             "name": "MIA Aesthetics - 08:00 AM to 05:00 PM",
        //             "shift_date": "2024-04-02",
        //             "shift_end_time": "17:00:00",
        //             "shift_id": 252,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 4,
        //             "name": "MIA Aesthetics - 09:00 AM to 06:00 PM",
        //             "shift_date": "2024-04-02",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 257,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-04-02",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 292,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-04-02",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 329,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-04-03",
        //             "shift_end_time": null,
        //             "shift_id": 297,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "DSI",
        //             "category": 0,
        //             "name": "DSI - Others",
        //             "shift_date": "2024-04-03",
        //             "shift_end_time": null,
        //             "shift_id": 188,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 3,
        //             "name": "MIA Aesthetics - 08:00 AM to 05:00 PM",
        //             "shift_date": "2024-04-03",
        //             "shift_end_time": "17:00:00",
        //             "shift_id": 243,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 4,
        //             "name": "MIA Aesthetics - 09:00 AM to 06:00 PM",
        //             "shift_date": "2024-04-03",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 258,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-04-03",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 287,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-04-03",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 324,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 4,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-04-04",
        //             "shift_end_time": null,
        //             "shift_id": 298,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "DSI",
        //             "category": 0,
        //             "name": "DSI - Others",
        //             "shift_date": "2024-04-04",
        //             "shift_end_time": null,
        //             "shift_id": 189,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 3,
        //             "name": "MIA Aesthetics - 08:00 AM to 05:00 PM",
        //             "shift_date": "2024-04-04",
        //             "shift_end_time": "17:00:00",
        //             "shift_id": 244,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 4,
        //             "name": "MIA Aesthetics - 09:00 AM to 06:00 PM",
        //             "shift_date": "2024-04-04",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 259,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-04-04",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 288,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-04-04",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 325,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "DSI",
        //             "category": 0,
        //             "name": "DSI - Others",
        //             "shift_date": "2024-04-05",
        //             "shift_end_time": null,
        //             "shift_id": 233,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-04-05",
        //             "shift_end_time": null,
        //             "shift_id": 299,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 3,
        //             "name": "MIA Aesthetics - 08:00 AM to 05:00 PM",
        //             "shift_date": "2024-04-05",
        //             "shift_end_time": "17:00:00",
        //             "shift_id": 240,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 4,
        //             "name": "MIA Aesthetics - 09:00 AM to 06:00 PM",
        //             "shift_date": "2024-04-05",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 260,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-04-05",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 289,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-04-05",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 326,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-04-06",
        //             "shift_end_time": null,
        //             "shift_id": 305,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-04-06",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 290,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-04-06",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 327,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-04-07",
        //             "shift_end_time": null,
        //             "shift_id": 322,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-04-07",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 315,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-04-07",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 333,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 3,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-04-08",
        //             "shift_end_time": null,
        //             "shift_id": 355,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 3,
        //             "name": "MIA Aesthetics - 08:00 AM to 05:00 PM",
        //             "shift_date": "2024-04-08",
        //             "shift_end_time": "17:00:00",
        //             "shift_id": 276,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 4,
        //             "name": "MIA Aesthetics - 09:00 AM to 06:00 PM",
        //             "shift_date": "2024-04-08",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 281,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-04-08",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 340,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-04-08",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 375,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-04-09",
        //             "shift_end_time": null,
        //             "shift_id": 356,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 3,
        //             "name": "MIA Aesthetics - 08:00 AM to 05:00 PM",
        //             "shift_date": "2024-04-09",
        //             "shift_end_time": "17:00:00",
        //             "shift_id": 267,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 4,
        //             "name": "MIA Aesthetics - 09:00 AM to 06:00 PM",
        //             "shift_date": "2024-04-09",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 282,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-04-09",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 341,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-04-09",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 370,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-04-10",
        //             "shift_end_time": null,
        //             "shift_id": 346,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 3,
        //             "name": "MIA Aesthetics - 08:00 AM to 05:00 PM",
        //             "shift_date": "2024-04-10",
        //             "shift_end_time": "17:00:00",
        //             "shift_id": 278,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 4,
        //             "name": "MIA Aesthetics - 09:00 AM to 06:00 PM",
        //             "shift_date": "2024-04-10",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 263,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-04-10",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 342,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-04-10",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 371,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "DSI",
        //             "category": 0,
        //             "name": "DSI - Others",
        //             "shift_date": "2024-04-11",
        //             "shift_end_time": null,
        //             "shift_id": 419,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 3,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-04-11",
        //             "shift_end_time": null,
        //             "shift_id": 362,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 3,
        //             "name": "MIA Aesthetics - 08:00 AM to 05:00 PM",
        //             "shift_date": "2024-04-11",
        //             "shift_end_time": "17:00:00",
        //             "shift_id": 279,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 4,
        //             "name": "MIA Aesthetics - 09:00 AM to 06:00 PM",
        //             "shift_date": "2024-04-11",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 264,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-04-11",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 343,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-04-11",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 372,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "DSI",
        //             "category": 0,
        //             "name": "DSI - Others",
        //             "shift_date": "2024-04-12",
        //             "shift_end_time": null,
        //             "shift_id": 420,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-04-12",
        //             "shift_end_time": null,
        //             "shift_id": 337,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 3,
        //             "name": "MIA Aesthetics - 08:00 AM to 05:00 PM",
        //             "shift_date": "2024-04-12",
        //             "shift_end_time": "17:00:00",
        //             "shift_id": 280,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 4,
        //             "name": "MIA Aesthetics - 09:00 AM to 06:00 PM",
        //             "shift_date": "2024-04-12",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 265,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-04-12",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 378,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "DSI",
        //             "category": 0,
        //             "name": "DSI - Others",
        //             "shift_date": "2024-04-13",
        //             "shift_end_time": null,
        //             "shift_id": 421,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-04-13",
        //             "shift_end_time": null,
        //             "shift_id": 338,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-04-13",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 374,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-04-14",
        //             "shift_end_time": null,
        //             "shift_id": 364,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-04-14",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 344,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 3,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-04-15",
        //             "shift_end_time": null,
        //             "shift_id": 405,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 4,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 3,
        //             "name": "MIA Aesthetics - 08:00 AM to 05:00 PM",
        //             "shift_date": "2024-04-15",
        //             "shift_end_time": "17:00:00",
        //             "shift_id": 424,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 4,
        //             "name": "MIA Aesthetics - 09:00 AM to 06:00 PM",
        //             "shift_date": "2024-04-15",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 447,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 6,
        //             "name": "Anyone Home - 09:00 AM to 07:00 PM",
        //             "shift_date": "2024-04-15",
        //             "shift_end_time": "19:00:00",
        //             "shift_id": 414,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-04-16",
        //             "shift_end_time": null,
        //             "shift_id": 381,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 3,
        //             "name": "MIA Aesthetics - 08:00 AM to 05:00 PM",
        //             "shift_date": "2024-04-16",
        //             "shift_end_time": "17:00:00",
        //             "shift_id": 427,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 4,
        //             "name": "MIA Aesthetics - 09:00 AM to 06:00 PM",
        //             "shift_date": "2024-04-16",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 449,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-04-16",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 410,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 6,
        //             "name": "Anyone Home - 09:00 AM to 07:00 PM",
        //             "shift_date": "2024-04-16",
        //             "shift_end_time": "19:00:00",
        //             "shift_id": 415,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-04-16",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 395,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-04-17",
        //             "shift_end_time": null,
        //             "shift_id": 382,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 4,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 3,
        //             "name": "MIA Aesthetics - 08:00 AM to 05:00 PM",
        //             "shift_date": "2024-04-17",
        //             "shift_end_time": "17:00:00",
        //             "shift_id": 433,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 4,
        //             "name": "MIA Aesthetics - 09:00 AM to 06:00 PM",
        //             "shift_date": "2024-04-17",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 452,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-04-17",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 411,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-04-17",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 396,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 3,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-04-18",
        //             "shift_end_time": null,
        //             "shift_id": 383,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 5,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 3,
        //             "name": "MIA Aesthetics - 08:00 AM to 05:00 PM",
        //             "shift_date": "2024-04-18",
        //             "shift_end_time": "17:00:00",
        //             "shift_id": 437,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 4,
        //             "name": "MIA Aesthetics - 09:00 AM to 06:00 PM",
        //             "shift_date": "2024-04-18",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 453,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-04-18",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 412,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 6,
        //             "name": "Anyone Home - 09:00 AM to 07:00 PM",
        //             "shift_date": "2024-04-18",
        //             "shift_end_time": "19:00:00",
        //             "shift_id": 416,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-04-18",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 397,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-04-19",
        //             "shift_end_time": null,
        //             "shift_id": 388,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 4,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 3,
        //             "name": "MIA Aesthetics - 08:00 AM to 05:00 PM",
        //             "shift_date": "2024-04-19",
        //             "shift_end_time": "17:00:00",
        //             "shift_id": 445,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 4,
        //             "name": "MIA Aesthetics - 09:00 AM to 06:00 PM",
        //             "shift_date": "2024-04-19",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 456,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 6,
        //             "name": "Anyone Home - 09:00 AM to 07:00 PM",
        //             "shift_date": "2024-04-19",
        //             "shift_end_time": "19:00:00",
        //             "shift_id": 417,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-04-19",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 398,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-04-20",
        //             "shift_end_time": null,
        //             "shift_id": 409,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 6,
        //             "name": "Anyone Home - 09:00 AM to 07:00 PM",
        //             "shift_date": "2024-04-20",
        //             "shift_end_time": "19:00:00",
        //             "shift_id": 418,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-04-20",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 399,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-04-21",
        //             "shift_end_time": null,
        //             "shift_id": 384,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-04-21",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 413,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 3,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-04-22",
        //             "shift_end_time": null,
        //             "shift_id": 486,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 4,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 3,
        //             "name": "MIA Aesthetics - 08:00 AM to 05:00 PM",
        //             "shift_date": "2024-04-22",
        //             "shift_end_time": "17:00:00",
        //             "shift_id": 496,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 4,
        //             "name": "MIA Aesthetics - 09:00 AM to 06:00 PM",
        //             "shift_date": "2024-04-22",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 520,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-04-22",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 471,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-04-22",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 476,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-04-23",
        //             "shift_end_time": null,
        //             "shift_id": 458,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 3,
        //             "name": "MIA Aesthetics - 08:00 AM to 05:00 PM",
        //             "shift_date": "2024-04-23",
        //             "shift_end_time": "17:00:00",
        //             "shift_id": 499,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 4,
        //             "name": "MIA Aesthetics - 09:00 AM to 06:00 PM",
        //             "shift_date": "2024-04-23",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 523,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-04-23",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 472,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-04-23",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 466,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-04-24",
        //             "shift_end_time": null,
        //             "shift_id": 482,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "DSI",
        //             "category": 0,
        //             "name": "DSI - Others",
        //             "shift_date": "2024-04-24",
        //             "shift_end_time": null,
        //             "shift_id": 535,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 3,
        //             "name": "MIA Aesthetics - 08:00 AM to 05:00 PM",
        //             "shift_date": "2024-04-24",
        //             "shift_end_time": "17:00:00",
        //             "shift_id": 514,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 4,
        //             "name": "MIA Aesthetics - 09:00 AM to 06:00 PM",
        //             "shift_date": "2024-04-24",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 526,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-04-24",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 473,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-04-24",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 467,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-04-25",
        //             "shift_end_time": null,
        //             "shift_id": 483,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 3,
        //             "name": "MIA Aesthetics - 08:00 AM to 05:00 PM",
        //             "shift_date": "2024-04-25",
        //             "shift_end_time": "17:00:00",
        //             "shift_id": 505,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 4,
        //             "name": "MIA Aesthetics - 09:00 AM to 06:00 PM",
        //             "shift_date": "2024-04-25",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 529,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-04-25",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 474,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-04-25",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 468,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-04-26",
        //             "shift_end_time": null,
        //             "shift_id": 464,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 4,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 3,
        //             "name": "MIA Aesthetics - 08:00 AM to 05:00 PM",
        //             "shift_date": "2024-04-26",
        //             "shift_end_time": "17:00:00",
        //             "shift_id": 518,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 4,
        //             "name": "MIA Aesthetics - 09:00 AM to 06:00 PM",
        //             "shift_date": "2024-04-26",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 532,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-04-26",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 469,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-04-27",
        //             "shift_end_time": null,
        //             "shift_id": 465,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-04-27",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 470,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-04-28",
        //             "shift_end_time": null,
        //             "shift_id": 460,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-04-28",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 475,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "DSI",
        //             "category": 0,
        //             "name": "DSI - Others",
        //             "shift_date": "2024-04-29",
        //             "shift_end_time": null,
        //             "shift_id": 576,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 26,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-04-29",
        //             "shift_end_time": null,
        //             "shift_id": 747,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 6,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 3,
        //             "name": "MIA Aesthetics - 08:00 AM to 05:00 PM",
        //             "shift_date": "2024-04-29",
        //             "shift_end_time": "17:00:00",
        //             "shift_id": 579,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-04-29",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 645,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 4,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 6,
        //             "name": "Anyone Home - 09:00 AM to 07:00 PM",
        //             "shift_date": "2024-04-29",
        //             "shift_end_time": "19:00:00",
        //             "shift_id": 630,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 7,
        //             "name": "Anyone Home - 10:00 AM to 08:00 PM",
        //             "shift_date": "2024-04-29",
        //             "shift_end_time": "20:00:00",
        //             "shift_id": 711,
        //             "shift_start_time": "10:00:00"
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-04-29",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 571,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "DSI",
        //             "category": 0,
        //             "name": "DSI - Others",
        //             "shift_date": "2024-04-30",
        //             "shift_end_time": null,
        //             "shift_id": 585,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 27,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-04-30",
        //             "shift_end_time": null,
        //             "shift_id": 556,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 5,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 3,
        //             "name": "MIA Aesthetics - 08:00 AM to 05:00 PM",
        //             "shift_date": "2024-04-30",
        //             "shift_end_time": "17:00:00",
        //             "shift_id": 592,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 4,
        //             "name": "MIA Aesthetics - 09:00 AM to 06:00 PM",
        //             "shift_date": "2024-04-30",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 621,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-04-30",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 726,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 5,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 6,
        //             "name": "Anyone Home - 09:00 AM to 07:00 PM",
        //             "shift_date": "2024-04-30",
        //             "shift_end_time": "19:00:00",
        //             "shift_id": 625,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 7,
        //             "name": "Anyone Home - 10:00 AM to 08:00 PM",
        //             "shift_date": "2024-04-30",
        //             "shift_end_time": "20:00:00",
        //             "shift_id": 712,
        //             "shift_start_time": "10:00:00"
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-04-30",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 793,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "DSI",
        //             "category": 0,
        //             "name": "DSI - Others",
        //             "shift_date": "2024-05-01",
        //             "shift_end_time": null,
        //             "shift_id": 594,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 22,
        //             "Active": 6,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-05-01",
        //             "shift_end_time": null,
        //             "shift_id": 764,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 6,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 3,
        //             "name": "MIA Aesthetics - 08:00 AM to 05:00 PM",
        //             "shift_date": "2024-05-01",
        //             "shift_end_time": "17:00:00",
        //             "shift_id": 598,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 4,
        //             "name": "MIA Aesthetics - 09:00 AM to 06:00 PM",
        //             "shift_date": "2024-05-01",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 622,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-05-01",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 727,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 4,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 6,
        //             "name": "Anyone Home - 09:00 AM to 07:00 PM",
        //             "shift_date": "2024-05-01",
        //             "shift_end_time": "19:00:00",
        //             "shift_id": 799,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 7,
        //             "name": "Anyone Home - 10:00 AM to 08:00 PM",
        //             "shift_date": "2024-05-01",
        //             "shift_end_time": "20:00:00",
        //             "shift_id": 808,
        //             "shift_start_time": "10:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-05-01",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 552,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "DSI",
        //             "category": 0,
        //             "name": "DSI - Others",
        //             "shift_date": "2024-05-02",
        //             "shift_end_time": null,
        //             "shift_id": 603,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 22,
        //             "Active": 8,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-05-02",
        //             "shift_end_time": null,
        //             "shift_id": 719,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 6,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 3,
        //             "name": "MIA Aesthetics - 08:00 AM to 05:00 PM",
        //             "shift_date": "2024-05-02",
        //             "shift_end_time": "17:00:00",
        //             "shift_id": 609,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 4,
        //             "name": "MIA Aesthetics - 09:00 AM to 06:00 PM",
        //             "shift_date": "2024-05-02",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 623,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-05-02",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 647,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 4,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 6,
        //             "name": "Anyone Home - 09:00 AM to 07:00 PM",
        //             "shift_date": "2024-05-02",
        //             "shift_end_time": "19:00:00",
        //             "shift_id": 819,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 7,
        //             "name": "Anyone Home - 10:00 AM to 08:00 PM",
        //             "shift_date": "2024-05-02",
        //             "shift_end_time": "20:00:00",
        //             "shift_id": 809,
        //             "shift_start_time": "10:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-05-02",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 573,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 13,
        //             "Active": 4,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-05-03",
        //             "shift_end_time": null,
        //             "shift_id": 830,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "DSI",
        //             "category": 0,
        //             "name": "DSI - Others",
        //             "shift_date": "2024-05-03",
        //             "shift_end_time": null,
        //             "shift_id": 612,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 6,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 3,
        //             "name": "MIA Aesthetics - 08:00 AM to 05:00 PM",
        //             "shift_date": "2024-05-03",
        //             "shift_end_time": "17:00:00",
        //             "shift_id": 616,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 4,
        //             "name": "MIA Aesthetics - 09:00 AM to 06:00 PM",
        //             "shift_date": "2024-05-03",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 624,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-05-03",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 648,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 6,
        //             "name": "Anyone Home - 09:00 AM to 07:00 PM",
        //             "shift_date": "2024-05-03",
        //             "shift_end_time": "19:00:00",
        //             "shift_id": 709,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 7,
        //             "name": "Anyone Home - 10:00 AM to 08:00 PM",
        //             "shift_date": "2024-05-03",
        //             "shift_end_time": "20:00:00",
        //             "shift_id": 810,
        //             "shift_start_time": "10:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-05-03",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 554,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 12,
        //             "Active": 7,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-05-04",
        //             "shift_end_time": null,
        //             "shift_id": 570,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-05-04",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 730,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 6,
        //             "name": "Anyone Home - 09:00 AM to 07:00 PM",
        //             "shift_date": "2024-05-04",
        //             "shift_end_time": "19:00:00",
        //             "shift_id": 629,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 7,
        //             "name": "Anyone Home - 10:00 AM to 08:00 PM",
        //             "shift_date": "2024-05-04",
        //             "shift_end_time": "20:00:00",
        //             "shift_id": 811,
        //             "shift_start_time": "10:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-05-04",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 575,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 15,
        //             "Active": 5,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-05-05",
        //             "shift_end_time": null,
        //             "shift_id": 1105,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 6,
        //             "name": "Anyone Home - 09:00 AM to 07:00 PM",
        //             "shift_date": "2024-05-05",
        //             "shift_end_time": "19:00:00",
        //             "shift_id": 634,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 7,
        //             "name": "Anyone Home - 10:00 AM to 08:00 PM",
        //             "shift_date": "2024-05-05",
        //             "shift_end_time": "20:00:00",
        //             "shift_id": 746,
        //             "shift_start_time": "10:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 0,
        //             "name": "MIA Aesthetics - Others",
        //             "shift_date": "2024-05-06",
        //             "shift_end_time": null,
        //             "shift_id": 1156,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 21,
        //             "Active": 15,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-05-06",
        //             "shift_end_time": null,
        //             "shift_id": 972,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 3,
        //             "name": "MIA Aesthetics - 08:00 AM to 05:00 PM",
        //             "shift_date": "2024-05-06",
        //             "shift_end_time": "17:00:00",
        //             "shift_id": 1129,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 4,
        //             "name": "MIA Aesthetics - 09:00 AM to 06:00 PM",
        //             "shift_date": "2024-05-06",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 1146,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-05-06",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 932,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 6,
        //             "name": "Anyone Home - 09:00 AM to 07:00 PM",
        //             "shift_date": "2024-05-06",
        //             "shift_end_time": "19:00:00",
        //             "shift_id": 872,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 7,
        //             "name": "Anyone Home - 10:00 AM to 08:00 PM",
        //             "shift_date": "2024-05-06",
        //             "shift_end_time": "20:00:00",
        //             "shift_id": 917,
        //             "shift_start_time": "10:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-05-06",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 862,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 0,
        //             "name": "MIA Aesthetics - Others",
        //             "shift_date": "2024-05-07",
        //             "shift_end_time": null,
        //             "shift_id": 1157,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 24,
        //             "Active": 11,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-05-07",
        //             "shift_end_time": null,
        //             "shift_id": 878,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 3,
        //             "name": "MIA Aesthetics - 08:00 AM to 05:00 PM",
        //             "shift_date": "2024-05-07",
        //             "shift_end_time": "17:00:00",
        //             "shift_id": 1130,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 4,
        //             "name": "MIA Aesthetics - 09:00 AM to 06:00 PM",
        //             "shift_date": "2024-05-07",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 1148,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-05-07",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 927,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 6,
        //             "name": "Anyone Home - 09:00 AM to 07:00 PM",
        //             "shift_date": "2024-05-07",
        //             "shift_end_time": "19:00:00",
        //             "shift_id": 948,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 7,
        //             "name": "Anyone Home - 10:00 AM to 08:00 PM",
        //             "shift_date": "2024-05-07",
        //             "shift_end_time": "20:00:00",
        //             "shift_id": 918,
        //             "shift_start_time": "10:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 3,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-05-07",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 1071,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 20,
        //             "Active": 14,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-05-08",
        //             "shift_end_time": null,
        //             "shift_id": 1057,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 0,
        //             "name": "MIA Aesthetics - Others",
        //             "shift_date": "2024-05-08",
        //             "shift_end_time": null,
        //             "shift_id": 1158,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 3,
        //             "name": "MIA Aesthetics - 08:00 AM to 05:00 PM",
        //             "shift_date": "2024-05-08",
        //             "shift_end_time": "17:00:00",
        //             "shift_id": 1137,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 4,
        //             "name": "MIA Aesthetics - 09:00 AM to 06:00 PM",
        //             "shift_date": "2024-05-08",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 1150,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-05-08",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 928,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 6,
        //             "name": "Anyone Home - 09:00 AM to 07:00 PM",
        //             "shift_date": "2024-05-08",
        //             "shift_end_time": "19:00:00",
        //             "shift_id": 949,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 7,
        //             "name": "Anyone Home - 10:00 AM to 08:00 PM",
        //             "shift_date": "2024-05-08",
        //             "shift_end_time": "20:00:00",
        //             "shift_id": 898,
        //             "shift_start_time": "10:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-05-08",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 1072,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 0,
        //             "name": "MIA Aesthetics - Others",
        //             "shift_date": "2024-05-09",
        //             "shift_end_time": null,
        //             "shift_id": 1159,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 22,
        //             "Active": 15,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-05-09",
        //             "shift_end_time": null,
        //             "shift_id": 1099,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 3,
        //             "name": "MIA Aesthetics - 08:00 AM to 05:00 PM",
        //             "shift_date": "2024-05-09",
        //             "shift_end_time": "17:00:00",
        //             "shift_id": 1138,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 4,
        //             "name": "MIA Aesthetics - 09:00 AM to 06:00 PM",
        //             "shift_date": "2024-05-09",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 1152,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-05-09",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 929,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 6,
        //             "name": "Anyone Home - 09:00 AM to 07:00 PM",
        //             "shift_date": "2024-05-09",
        //             "shift_end_time": "19:00:00",
        //             "shift_id": 950,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 7,
        //             "name": "Anyone Home - 10:00 AM to 08:00 PM",
        //             "shift_date": "2024-05-09",
        //             "shift_end_time": "20:00:00",
        //             "shift_id": 899,
        //             "shift_start_time": "10:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-05-09",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 854,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 0,
        //             "name": "MIA Aesthetics - Others",
        //             "shift_date": "2024-05-10",
        //             "shift_end_time": null,
        //             "shift_id": 1160,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "ePremium",
        //             "category": 0,
        //             "name": "ePremium - Others",
        //             "shift_date": "2024-05-10",
        //             "shift_end_time": null,
        //             "shift_id": 1347,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 15,
        //             "Active": 10,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-05-10",
        //             "shift_end_time": null,
        //             "shift_id": 1000,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 3,
        //             "name": "MIA Aesthetics - 08:00 AM to 05:00 PM",
        //             "shift_date": "2024-05-10",
        //             "shift_end_time": "17:00:00",
        //             "shift_id": 1142,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 4,
        //             "name": "MIA Aesthetics - 09:00 AM to 06:00 PM",
        //             "shift_date": "2024-05-10",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 1154,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-05-10",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 930,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 6,
        //             "name": "Anyone Home - 09:00 AM to 07:00 PM",
        //             "shift_date": "2024-05-10",
        //             "shift_end_time": "19:00:00",
        //             "shift_id": 875,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 7,
        //             "name": "Anyone Home - 10:00 AM to 08:00 PM",
        //             "shift_date": "2024-05-10",
        //             "shift_end_time": "20:00:00",
        //             "shift_id": 920,
        //             "shift_start_time": "10:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 3,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-05-10",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 905,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 11,
        //             "Active": 7,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-05-11",
        //             "shift_end_time": null,
        //             "shift_id": 1119,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-05-11",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 931,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 6,
        //             "name": "Anyone Home - 09:00 AM to 07:00 PM",
        //             "shift_date": "2024-05-11",
        //             "shift_end_time": "19:00:00",
        //             "shift_id": 876,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 7,
        //             "name": "Anyone Home - 10:00 AM to 08:00 PM",
        //             "shift_date": "2024-05-11",
        //             "shift_end_time": "20:00:00",
        //             "shift_id": 921,
        //             "shift_start_time": "10:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-05-11",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 1074,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 14,
        //             "Active": 7,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-05-12",
        //             "shift_end_time": null,
        //             "shift_id": 1025,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 6,
        //             "name": "Anyone Home - 09:00 AM to 07:00 PM",
        //             "shift_date": "2024-05-12",
        //             "shift_end_time": "19:00:00",
        //             "shift_id": 1048,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Henry Schein",
        //             "category": 0,
        //             "name": "Henry Schein - Others",
        //             "shift_date": "2024-05-13",
        //             "shift_end_time": null,
        //             "shift_id": 1391,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 19,
        //             "Active": 11,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-05-13",
        //             "shift_end_time": null,
        //             "shift_id": 1247,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "ePremium",
        //             "category": 0,
        //             "name": "ePremium - Others",
        //             "shift_date": "2024-05-13",
        //             "shift_end_time": null,
        //             "shift_id": 1421,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 3,
        //             "name": "MIA Aesthetics - 08:00 AM to 05:00 PM",
        //             "shift_date": "2024-05-13",
        //             "shift_end_time": "17:00:00",
        //             "shift_id": 1431,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 4,
        //             "name": "MIA Aesthetics - 09:00 AM to 06:00 PM",
        //             "shift_date": "2024-05-13",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 1451,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-05-13",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 1201,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 6,
        //             "name": "Anyone Home - 09:00 AM to 07:00 PM",
        //             "shift_date": "2024-05-13",
        //             "shift_end_time": "19:00:00",
        //             "shift_id": 1211,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 7,
        //             "name": "Anyone Home - 10:00 AM to 08:00 PM",
        //             "shift_date": "2024-05-13",
        //             "shift_end_time": "20:00:00",
        //             "shift_id": 1277,
        //             "shift_start_time": "10:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-05-13",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 1171,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "ePremium",
        //             "category": 0,
        //             "name": "ePremium - Others",
        //             "shift_date": "2024-05-14",
        //             "shift_end_time": null,
        //             "shift_id": 1423,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Henry Schein",
        //             "category": 0,
        //             "name": "Henry Schein - Others",
        //             "shift_date": "2024-05-14",
        //             "shift_end_time": null,
        //             "shift_id": 1392,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 19,
        //             "Active": 10,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-05-14",
        //             "shift_end_time": null,
        //             "shift_id": 1187,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 3,
        //             "name": "MIA Aesthetics - 08:00 AM to 05:00 PM",
        //             "shift_date": "2024-05-14",
        //             "shift_end_time": "17:00:00",
        //             "shift_id": 1437,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 4,
        //             "name": "MIA Aesthetics - 09:00 AM to 06:00 PM",
        //             "shift_date": "2024-05-14",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 1453,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-05-14",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 1202,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 4,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 6,
        //             "name": "Anyone Home - 09:00 AM to 07:00 PM",
        //             "shift_date": "2024-05-14",
        //             "shift_end_time": "19:00:00",
        //             "shift_id": 1212,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 7,
        //             "name": "Anyone Home - 10:00 AM to 08:00 PM",
        //             "shift_date": "2024-05-14",
        //             "shift_end_time": "20:00:00",
        //             "shift_id": 1381,
        //             "shift_start_time": "10:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-05-14",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 1313,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "ePremium",
        //             "category": 0,
        //             "name": "ePremium - Others",
        //             "shift_date": "2024-05-15",
        //             "shift_end_time": null,
        //             "shift_id": 1425,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Kroger",
        //             "category": 0,
        //             "name": "Kroger - Others",
        //             "shift_date": "2024-05-15",
        //             "shift_end_time": null,
        //             "shift_id": 1483,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Henry Schein",
        //             "category": 0,
        //             "name": "Henry Schein - Others",
        //             "shift_date": "2024-05-15",
        //             "shift_end_time": null,
        //             "shift_id": 1393,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 18,
        //             "Active": 10,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-05-15",
        //             "shift_end_time": null,
        //             "shift_id": 1365,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 3,
        //             "name": "MIA Aesthetics - 08:00 AM to 05:00 PM",
        //             "shift_date": "2024-05-15",
        //             "shift_end_time": "17:00:00",
        //             "shift_id": 1439,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 4,
        //             "name": "MIA Aesthetics - 09:00 AM to 06:00 PM",
        //             "shift_date": "2024-05-15",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 1455,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-05-15",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 1197,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 3,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 6,
        //             "name": "Anyone Home - 09:00 AM to 07:00 PM",
        //             "shift_date": "2024-05-15",
        //             "shift_end_time": "19:00:00",
        //             "shift_id": 1253,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 7,
        //             "name": "Anyone Home - 10:00 AM to 08:00 PM",
        //             "shift_date": "2024-05-15",
        //             "shift_end_time": "20:00:00",
        //             "shift_id": 1382,
        //             "shift_start_time": "10:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-05-15",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 1314,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "ePremium",
        //             "category": 0,
        //             "name": "ePremium - Others",
        //             "shift_date": "2024-05-16",
        //             "shift_end_time": null,
        //             "shift_id": 1427,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Kroger",
        //             "category": 0,
        //             "name": "Kroger - Others",
        //             "shift_date": "2024-05-16",
        //             "shift_end_time": null,
        //             "shift_id": 1484,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Henry Schein",
        //             "category": 0,
        //             "name": "Henry Schein - Others",
        //             "shift_date": "2024-05-16",
        //             "shift_end_time": null,
        //             "shift_id": 1394,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 19,
        //             "Active": 12,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-05-16",
        //             "shift_end_time": null,
        //             "shift_id": 1299,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 3,
        //             "name": "MIA Aesthetics - 08:00 AM to 05:00 PM",
        //             "shift_date": "2024-05-16",
        //             "shift_end_time": "17:00:00",
        //             "shift_id": 1443,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 4,
        //             "name": "MIA Aesthetics - 09:00 AM to 06:00 PM",
        //             "shift_date": "2024-05-16",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 1457,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-05-16",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 1198,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 5,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 6,
        //             "name": "Anyone Home - 09:00 AM to 07:00 PM",
        //             "shift_date": "2024-05-16",
        //             "shift_end_time": "19:00:00",
        //             "shift_id": 1214,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 7,
        //             "name": "Anyone Home - 10:00 AM to 08:00 PM",
        //             "shift_date": "2024-05-16",
        //             "shift_end_time": "20:00:00",
        //             "shift_id": 1279,
        //             "shift_start_time": "10:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-05-16",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 1163,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 10,
        //             "Active": 12,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-05-17",
        //             "shift_end_time": null,
        //             "shift_id": 1209,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "ePremium",
        //             "category": 0,
        //             "name": "ePremium - Others",
        //             "shift_date": "2024-05-17",
        //             "shift_end_time": null,
        //             "shift_id": 1429,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 3,
        //             "name": "MIA Aesthetics - 08:00 AM to 05:00 PM",
        //             "shift_date": "2024-05-17",
        //             "shift_end_time": "17:00:00",
        //             "shift_id": 1447,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 4,
        //             "name": "MIA Aesthetics - 09:00 AM to 06:00 PM",
        //             "shift_date": "2024-05-17",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 1459,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-05-17",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 1199,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 6,
        //             "name": "Anyone Home - 09:00 AM to 07:00 PM",
        //             "shift_date": "2024-05-17",
        //             "shift_end_time": "19:00:00",
        //             "shift_id": 1229,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 7,
        //             "name": "Anyone Home - 10:00 AM to 08:00 PM",
        //             "shift_date": "2024-05-17",
        //             "shift_end_time": "20:00:00",
        //             "shift_id": 1224,
        //             "shift_start_time": "10:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-05-17",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 1315,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 11,
        //             "Active": 9,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-05-18",
        //             "shift_end_time": null,
        //             "shift_id": 1399,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-05-18",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 1326,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 6,
        //             "name": "Anyone Home - 09:00 AM to 07:00 PM",
        //             "shift_date": "2024-05-18",
        //             "shift_end_time": "19:00:00",
        //             "shift_id": 1246,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 7,
        //             "name": "Anyone Home - 10:00 AM to 08:00 PM",
        //             "shift_date": "2024-05-18",
        //             "shift_end_time": "20:00:00",
        //             "shift_id": 1385,
        //             "shift_start_time": "10:00:00"
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-05-18",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 1316,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 14,
        //             "Active": 5,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-05-19",
        //             "shift_end_time": null,
        //             "shift_id": 1420,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 6,
        //             "name": "Anyone Home - 09:00 AM to 07:00 PM",
        //             "shift_date": "2024-05-19",
        //             "shift_end_time": "19:00:00",
        //             "shift_id": 1220,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "DSI",
        //             "category": 0,
        //             "name": "DSI - Others",
        //             "shift_date": "2024-05-20",
        //             "shift_end_time": null,
        //             "shift_id": 1466,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 147,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Walmart",
        //             "category": 0,
        //             "name": "Walmart - Others",
        //             "shift_date": "2024-05-20",
        //             "shift_end_time": null,
        //             "shift_id": 2794,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 42,
        //             "Active": 14,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-05-20",
        //             "shift_end_time": null,
        //             "shift_id": 1912,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 3,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 3,
        //             "name": "MIA Aesthetics - 08:00 AM to 05:00 PM",
        //             "shift_date": "2024-05-20",
        //             "shift_end_time": "17:00:00",
        //             "shift_id": 1953,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 4,
        //             "name": "MIA Aesthetics - 09:00 AM to 06:00 PM",
        //             "shift_date": "2024-05-20",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 1972,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-05-20",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 1679,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 3,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 6,
        //             "name": "Anyone Home - 09:00 AM to 07:00 PM",
        //             "shift_date": "2024-05-20",
        //             "shift_end_time": "19:00:00",
        //             "shift_id": 1784,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 7,
        //             "name": "Anyone Home - 10:00 AM to 08:00 PM",
        //             "shift_date": "2024-05-20",
        //             "shift_end_time": "20:00:00",
        //             "shift_id": 1789,
        //             "shift_start_time": "10:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-05-20",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 1669,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 45,
        //             "Active": 9,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-05-21",
        //             "shift_end_time": null,
        //             "shift_id": 1515,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "DSI",
        //             "category": 0,
        //             "name": "DSI - Others",
        //             "shift_date": "2024-05-21",
        //             "shift_end_time": null,
        //             "shift_id": 1467,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "GOALS",
        //             "category": 0,
        //             "name": "GOALS - Others",
        //             "shift_date": "2024-05-21",
        //             "shift_end_time": null,
        //             "shift_id": 3388,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 155,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Walmart",
        //             "category": 0,
        //             "name": "Walmart - Others",
        //             "shift_date": "2024-05-21",
        //             "shift_end_time": null,
        //             "shift_id": 2574,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 3,
        //             "name": "MIA Aesthetics - 08:00 AM to 05:00 PM",
        //             "shift_date": "2024-05-21",
        //             "shift_end_time": "17:00:00",
        //             "shift_id": 1956,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 4,
        //             "name": "MIA Aesthetics - 09:00 AM to 06:00 PM",
        //             "shift_date": "2024-05-21",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 1974,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-05-21",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 1680,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 3,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 6,
        //             "name": "Anyone Home - 09:00 AM to 07:00 PM",
        //             "shift_date": "2024-05-21",
        //             "shift_end_time": "19:00:00",
        //             "shift_id": 1765,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 7,
        //             "name": "Anyone Home - 10:00 AM to 08:00 PM",
        //             "shift_date": "2024-05-21",
        //             "shift_end_time": "20:00:00",
        //             "shift_id": 1531,
        //             "shift_start_time": "10:00:00"
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-05-21",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 1815,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 154,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Walmart",
        //             "category": 0,
        //             "name": "Walmart - Others",
        //             "shift_date": "2024-05-22",
        //             "shift_end_time": null,
        //             "shift_id": 2730,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 41,
        //             "Active": 12,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-05-22",
        //             "shift_end_time": null,
        //             "shift_id": 1936,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "DSI",
        //             "category": 0,
        //             "name": "DSI - Others",
        //             "shift_date": "2024-05-22",
        //             "shift_end_time": null,
        //             "shift_id": 1468,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "GOALS",
        //             "category": 0,
        //             "name": "GOALS - Others",
        //             "shift_date": "2024-05-22",
        //             "shift_end_time": null,
        //             "shift_id": 2003,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 3,
        //             "name": "MIA Aesthetics - 08:00 AM to 05:00 PM",
        //             "shift_date": "2024-05-22",
        //             "shift_end_time": "17:00:00",
        //             "shift_id": 1961,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 4,
        //             "name": "MIA Aesthetics - 09:00 AM to 06:00 PM",
        //             "shift_date": "2024-05-22",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 1976,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-05-22",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 1556,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 6,
        //             "name": "Anyone Home - 09:00 AM to 07:00 PM",
        //             "shift_date": "2024-05-22",
        //             "shift_end_time": "19:00:00",
        //             "shift_id": 1655,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 7,
        //             "name": "Anyone Home - 10:00 AM to 08:00 PM",
        //             "shift_date": "2024-05-22",
        //             "shift_end_time": "20:00:00",
        //             "shift_id": 1605,
        //             "shift_start_time": "10:00:00"
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-05-22",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 1671,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 142,
        //             "Active": 5,
        //             "Training": 0,
        //             "business_unit": "Walmart",
        //             "category": 0,
        //             "name": "Walmart - Others",
        //             "shift_date": "2024-05-23",
        //             "shift_end_time": null,
        //             "shift_id": 2321,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 32,
        //             "Active": 19,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-05-23",
        //             "shift_end_time": null,
        //             "shift_id": 1742,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "DSI",
        //             "category": 0,
        //             "name": "DSI - Others",
        //             "shift_date": "2024-05-23",
        //             "shift_end_time": null,
        //             "shift_id": 1475,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 3,
        //             "name": "MIA Aesthetics - 08:00 AM to 05:00 PM",
        //             "shift_date": "2024-05-23",
        //             "shift_end_time": "17:00:00",
        //             "shift_id": 1966,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 4,
        //             "name": "MIA Aesthetics - 09:00 AM to 06:00 PM",
        //             "shift_date": "2024-05-23",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 1985,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-05-23",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 1557,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 6,
        //             "name": "Anyone Home - 09:00 AM to 07:00 PM",
        //             "shift_date": "2024-05-23",
        //             "shift_end_time": "19:00:00",
        //             "shift_id": 1801,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 7,
        //             "name": "Anyone Home - 10:00 AM to 08:00 PM",
        //             "shift_date": "2024-05-23",
        //             "shift_end_time": "20:00:00",
        //             "shift_id": 1606,
        //             "shift_start_time": "10:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-05-23",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 1811,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "DSI",
        //             "category": 0,
        //             "name": "DSI - Others",
        //             "shift_date": "2024-05-24",
        //             "shift_end_time": null,
        //             "shift_id": 1476,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 141,
        //             "Active": 5,
        //             "Training": 0,
        //             "business_unit": "Walmart",
        //             "category": 0,
        //             "name": "Walmart - Others",
        //             "shift_date": "2024-05-24",
        //             "shift_end_time": null,
        //             "shift_id": 2351,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 32,
        //             "Active": 17,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-05-24",
        //             "shift_end_time": null,
        //             "shift_id": 1930,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 3,
        //             "name": "MIA Aesthetics - 08:00 AM to 05:00 PM",
        //             "shift_date": "2024-05-24",
        //             "shift_end_time": "17:00:00",
        //             "shift_id": 1968,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 4,
        //             "name": "MIA Aesthetics - 09:00 AM to 06:00 PM",
        //             "shift_date": "2024-05-24",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 1980,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-05-24",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 1682,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 6,
        //             "name": "Anyone Home - 09:00 AM to 07:00 PM",
        //             "shift_date": "2024-05-24",
        //             "shift_end_time": "19:00:00",
        //             "shift_id": 1657,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 7,
        //             "name": "Anyone Home - 10:00 AM to 08:00 PM",
        //             "shift_date": "2024-05-24",
        //             "shift_end_time": "20:00:00",
        //             "shift_id": 1607,
        //             "shift_start_time": "10:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-05-24",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 1672,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "DSI",
        //             "category": 0,
        //             "name": "DSI - Others",
        //             "shift_date": "2024-05-25",
        //             "shift_end_time": null,
        //             "shift_id": 1471,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 112,
        //             "Active": 5,
        //             "Training": 0,
        //             "business_unit": "Walmart",
        //             "category": 0,
        //             "name": "Walmart - Others",
        //             "shift_date": "2024-05-25",
        //             "shift_end_time": null,
        //             "shift_id": 2257,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 33,
        //             "Active": 13,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-05-25",
        //             "shift_end_time": null,
        //             "shift_id": 1884,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-05-25",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 1702,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 6,
        //             "name": "Anyone Home - 09:00 AM to 07:00 PM",
        //             "shift_date": "2024-05-25",
        //             "shift_end_time": "19:00:00",
        //             "shift_id": 1658,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 7,
        //             "name": "Anyone Home - 10:00 AM to 08:00 PM",
        //             "shift_date": "2024-05-25",
        //             "shift_end_time": "20:00:00",
        //             "shift_id": 1608,
        //             "shift_start_time": "10:00:00"
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-05-25",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 1673,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 28,
        //             "Active": 9,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-05-26",
        //             "shift_end_time": null,
        //             "shift_id": 1585,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 81,
        //             "Active": 11,
        //             "Training": 0,
        //             "business_unit": "Walmart",
        //             "category": 0,
        //             "name": "Walmart - Others",
        //             "shift_date": "2024-05-26",
        //             "shift_end_time": null,
        //             "shift_id": 2383,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-05-26",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 1703,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 6,
        //             "name": "Anyone Home - 09:00 AM to 07:00 PM",
        //             "shift_date": "2024-05-26",
        //             "shift_end_time": "19:00:00",
        //             "shift_id": 1788,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-05-26",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 1592,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 5,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Henry Schein",
        //             "category": 0,
        //             "name": "Henry Schein - Others",
        //             "shift_date": "2024-05-27",
        //             "shift_end_time": null,
        //             "shift_id": 4875,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 138,
        //             "Active": 34,
        //             "Training": 0,
        //             "business_unit": "Walmart",
        //             "category": 0,
        //             "name": "Walmart - Others",
        //             "shift_date": "2024-05-27",
        //             "shift_end_time": null,
        //             "shift_id": 6207,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 26,
        //             "Active": 20,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-05-27",
        //             "shift_end_time": null,
        //             "shift_id": 1829,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "DSI",
        //             "category": 0,
        //             "name": "DSI - Others",
        //             "shift_date": "2024-05-27",
        //             "shift_end_time": null,
        //             "shift_id": 5800,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 6,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 3,
        //             "name": "MIA Aesthetics - 08:00 AM to 05:00 PM",
        //             "shift_date": "2024-05-27",
        //             "shift_end_time": "17:00:00",
        //             "shift_id": 5027,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 4,
        //             "name": "MIA Aesthetics - 09:00 AM to 06:00 PM",
        //             "shift_date": "2024-05-27",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 5045,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 7,
        //             "name": "Anyone Home - 10:00 AM to 08:00 PM",
        //             "shift_date": "2024-05-27",
        //             "shift_end_time": "20:00:00",
        //             "shift_id": 3190,
        //             "shift_start_time": "10:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-05-27",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 3302,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 38,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Henry Schein",
        //             "category": 0,
        //             "name": "Henry Schein - Others",
        //             "shift_date": "2024-05-28",
        //             "shift_end_time": null,
        //             "shift_id": 5012,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 116,
        //             "Active": 57,
        //             "Training": 0,
        //             "business_unit": "Walmart",
        //             "category": 0,
        //             "name": "Walmart - Others",
        //             "shift_date": "2024-05-28",
        //             "shift_end_time": null,
        //             "shift_id": 6019,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 38,
        //             "Active": 24,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-05-28",
        //             "shift_end_time": null,
        //             "shift_id": 3105,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 15,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "GOALS",
        //             "category": 0,
        //             "name": "GOALS - Others",
        //             "shift_date": "2024-05-28",
        //             "shift_end_time": null,
        //             "shift_id": 3419,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 4,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "ePremium",
        //             "category": 0,
        //             "name": "ePremium - Others",
        //             "shift_date": "2024-05-28",
        //             "shift_end_time": null,
        //             "shift_id": 5809,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 5,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 3,
        //             "name": "MIA Aesthetics - 08:00 AM to 05:00 PM",
        //             "shift_date": "2024-05-28",
        //             "shift_end_time": "17:00:00",
        //             "shift_id": 5030,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 4,
        //             "name": "MIA Aesthetics - 09:00 AM to 06:00 PM",
        //             "shift_date": "2024-05-28",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 5047,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-05-28",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 3312,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 4,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 6,
        //             "name": "Anyone Home - 09:00 AM to 07:00 PM",
        //             "shift_date": "2024-05-28",
        //             "shift_end_time": "19:00:00",
        //             "shift_id": 3200,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 7,
        //             "name": "Anyone Home - 10:00 AM to 08:00 PM",
        //             "shift_date": "2024-05-28",
        //             "shift_end_time": "20:00:00",
        //             "shift_id": 3191,
        //             "shift_start_time": "10:00:00"
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-05-28",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 3369,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 15,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "GOALS",
        //             "category": 0,
        //             "name": "GOALS - Others",
        //             "shift_date": "2024-05-29",
        //             "shift_end_time": null,
        //             "shift_id": 3424,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "ePremium",
        //             "category": 0,
        //             "name": "ePremium - Others",
        //             "shift_date": "2024-05-29",
        //             "shift_end_time": null,
        //             "shift_id": 5806,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 36,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "Henry Schein",
        //             "category": 0,
        //             "name": "Henry Schein - Others",
        //             "shift_date": "2024-05-29",
        //             "shift_end_time": null,
        //             "shift_id": 4893,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 97,
        //             "Active": 66,
        //             "Training": 0,
        //             "business_unit": "Walmart",
        //             "category": 0,
        //             "name": "Walmart - Others",
        //             "shift_date": "2024-05-29",
        //             "shift_end_time": null,
        //             "shift_id": 5717,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 36,
        //             "Active": 22,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-05-29",
        //             "shift_end_time": null,
        //             "shift_id": 3001,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 4,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 3,
        //             "name": "MIA Aesthetics - 08:00 AM to 05:00 PM",
        //             "shift_date": "2024-05-29",
        //             "shift_end_time": "17:00:00",
        //             "shift_id": 5036,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 4,
        //             "name": "MIA Aesthetics - 09:00 AM to 06:00 PM",
        //             "shift_date": "2024-05-29",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 5049,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-05-29",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 3313,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 6,
        //             "name": "Anyone Home - 09:00 AM to 07:00 PM",
        //             "shift_date": "2024-05-29",
        //             "shift_end_time": "19:00:00",
        //             "shift_id": 3293,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 7,
        //             "name": "Anyone Home - 10:00 AM to 08:00 PM",
        //             "shift_date": "2024-05-29",
        //             "shift_end_time": "20:00:00",
        //             "shift_id": 3263,
        //             "shift_start_time": "10:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-05-29",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 3304,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 13,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "GOALS",
        //             "category": 0,
        //             "name": "GOALS - Others",
        //             "shift_date": "2024-05-30",
        //             "shift_end_time": null,
        //             "shift_id": 3433,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 4,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "ePremium",
        //             "category": 0,
        //             "name": "ePremium - Others",
        //             "shift_date": "2024-05-30",
        //             "shift_end_time": null,
        //             "shift_id": 5803,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 37,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Henry Schein",
        //             "category": 0,
        //             "name": "Henry Schein - Others",
        //             "shift_date": "2024-05-30",
        //             "shift_end_time": null,
        //             "shift_id": 4957,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 105,
        //             "Active": 67,
        //             "Training": 0,
        //             "business_unit": "Walmart",
        //             "category": 0,
        //             "name": "Walmart - Others",
        //             "shift_date": "2024-05-30",
        //             "shift_end_time": null,
        //             "shift_id": 5431,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 33,
        //             "Active": 23,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-05-30",
        //             "shift_end_time": null,
        //             "shift_id": 3278,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 4,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 3,
        //             "name": "MIA Aesthetics - 08:00 AM to 05:00 PM",
        //             "shift_date": "2024-05-30",
        //             "shift_end_time": "17:00:00",
        //             "shift_id": 5040,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 4,
        //             "name": "MIA Aesthetics - 09:00 AM to 06:00 PM",
        //             "shift_date": "2024-05-30",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 5051,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-05-30",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 3289,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 6,
        //             "name": "Anyone Home - 09:00 AM to 07:00 PM",
        //             "shift_date": "2024-05-30",
        //             "shift_end_time": "19:00:00",
        //             "shift_id": 3294,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 7,
        //             "name": "Anyone Home - 10:00 AM to 08:00 PM",
        //             "shift_date": "2024-05-30",
        //             "shift_end_time": "20:00:00",
        //             "shift_id": 3264,
        //             "shift_start_time": "10:00:00"
        //         },
        //         {
        //             "Absent": 4,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-05-30",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 3365,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 102,
        //             "Active": 69,
        //             "Training": 0,
        //             "business_unit": "Walmart",
        //             "category": 0,
        //             "name": "Walmart - Others",
        //             "shift_date": "2024-05-31",
        //             "shift_end_time": null,
        //             "shift_id": 5163,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 32,
        //             "Active": 21,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-05-31",
        //             "shift_end_time": null,
        //             "shift_id": 3023,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 14,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "GOALS",
        //             "category": 0,
        //             "name": "GOALS - Others",
        //             "shift_date": "2024-05-31",
        //             "shift_end_time": null,
        //             "shift_id": 3451,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "ePremium",
        //             "category": 0,
        //             "name": "ePremium - Others",
        //             "shift_date": "2024-05-31",
        //             "shift_end_time": null,
        //             "shift_id": 5812,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 39,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Henry Schein",
        //             "category": 0,
        //             "name": "Henry Schein - Others",
        //             "shift_date": "2024-05-31",
        //             "shift_end_time": null,
        //             "shift_id": 4925,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 4,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 3,
        //             "name": "MIA Aesthetics - 08:00 AM to 05:00 PM",
        //             "shift_date": "2024-05-31",
        //             "shift_end_time": "17:00:00",
        //             "shift_id": 5059,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 4,
        //             "name": "MIA Aesthetics - 09:00 AM to 06:00 PM",
        //             "shift_date": "2024-05-31",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 5053,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 4,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-05-31",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 3213,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 6,
        //             "name": "Anyone Home - 09:00 AM to 07:00 PM",
        //             "shift_date": "2024-05-31",
        //             "shift_end_time": "19:00:00",
        //             "shift_id": 3300,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 7,
        //             "name": "Anyone Home - 10:00 AM to 08:00 PM",
        //             "shift_date": "2024-05-31",
        //             "shift_end_time": "20:00:00",
        //             "shift_id": 3193,
        //             "shift_start_time": "10:00:00"
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-05-31",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 3224,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 115,
        //             "Active": 46,
        //             "Training": 0,
        //             "business_unit": "Walmart",
        //             "category": 0,
        //             "name": "Walmart - Others",
        //             "shift_date": "2024-06-01",
        //             "shift_end_time": null,
        //             "shift_id": 5313,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 28,
        //             "Active": 20,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-06-01",
        //             "shift_end_time": null,
        //             "shift_id": 3209,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 6,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "GOALS",
        //             "category": 0,
        //             "name": "GOALS - Others",
        //             "shift_date": "2024-06-01",
        //             "shift_end_time": null,
        //             "shift_id": 3447,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 4,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-06-01",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 3316,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 6,
        //             "name": "Anyone Home - 09:00 AM to 07:00 PM",
        //             "shift_date": "2024-06-01",
        //             "shift_end_time": "19:00:00",
        //             "shift_id": 3296,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 7,
        //             "name": "Anyone Home - 10:00 AM to 08:00 PM",
        //             "shift_date": "2024-06-01",
        //             "shift_end_time": "20:00:00",
        //             "shift_id": 3194,
        //             "shift_start_time": "10:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-06-01",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 3306,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 97,
        //             "Active": 43,
        //             "Training": 0,
        //             "business_unit": "Walmart",
        //             "category": 0,
        //             "name": "Walmart - Others",
        //             "shift_date": "2024-06-02",
        //             "shift_end_time": null,
        //             "shift_id": 5449,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 26,
        //             "Active": 13,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-06-02",
        //             "shift_end_time": null,
        //             "shift_id": 3301,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "DSI",
        //             "category": 0,
        //             "name": "DSI - Others",
        //             "shift_date": "2024-06-02",
        //             "shift_end_time": null,
        //             "shift_id": 1992,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-06-02",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 3321,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 6,
        //             "name": "Anyone Home - 09:00 AM to 07:00 PM",
        //             "shift_date": "2024-06-02",
        //             "shift_end_time": "19:00:00",
        //             "shift_id": 3204,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-06-02",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 3250,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 48,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Henry Schein",
        //             "category": 0,
        //             "name": "Henry Schein - Others",
        //             "shift_date": "2024-06-03",
        //             "shift_end_time": null,
        //             "shift_id": 8020,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 136,
        //             "Active": 53,
        //             "Training": 0,
        //             "business_unit": "Walmart",
        //             "category": 0,
        //             "name": "Walmart - Others",
        //             "shift_date": "2024-06-03",
        //             "shift_end_time": null,
        //             "shift_id": 7160,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 39,
        //             "Active": 23,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-06-03",
        //             "shift_end_time": null,
        //             "shift_id": 6618,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "DSI",
        //             "category": 0,
        //             "name": "DSI - Others",
        //             "shift_date": "2024-06-03",
        //             "shift_end_time": null,
        //             "shift_id": 1995,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 12,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "GOALS",
        //             "category": 0,
        //             "name": "GOALS - Others",
        //             "shift_date": "2024-06-03",
        //             "shift_end_time": null,
        //             "shift_id": 8205,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 8,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 3,
        //             "name": "MIA Aesthetics - 08:00 AM to 05:00 PM",
        //             "shift_date": "2024-06-03",
        //             "shift_end_time": "17:00:00",
        //             "shift_id": 7867,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-06-03",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 6534,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 6,
        //             "name": "Anyone Home - 09:00 AM to 07:00 PM",
        //             "shift_date": "2024-06-03",
        //             "shift_end_time": "19:00:00",
        //             "shift_id": 6673,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 7,
        //             "name": "Anyone Home - 10:00 AM to 08:00 PM",
        //             "shift_date": "2024-06-03",
        //             "shift_end_time": "20:00:00",
        //             "shift_id": 6392,
        //             "shift_start_time": "10:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-06-03",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 6573,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 35,
        //             "Active": 21,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-06-04",
        //             "shift_end_time": null,
        //             "shift_id": 6403,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 12,
        //             "Active": 3,
        //             "Training": 0,
        //             "business_unit": "GOALS",
        //             "category": 0,
        //             "name": "GOALS - Others",
        //             "shift_date": "2024-06-04",
        //             "shift_end_time": null,
        //             "shift_id": 8196,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 48,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Henry Schein",
        //             "category": 0,
        //             "name": "Henry Schein - Others",
        //             "shift_date": "2024-06-04",
        //             "shift_end_time": null,
        //             "shift_id": 7956,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 129,
        //             "Active": 48,
        //             "Training": 0,
        //             "business_unit": "Walmart",
        //             "category": 0,
        //             "name": "Walmart - Others",
        //             "shift_date": "2024-06-04",
        //             "shift_end_time": null,
        //             "shift_id": 7000,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 8,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 3,
        //             "name": "MIA Aesthetics - 08:00 AM to 05:00 PM",
        //             "shift_date": "2024-06-04",
        //             "shift_end_time": "17:00:00",
        //             "shift_id": 7875,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-06-04",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 6412,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 6,
        //             "name": "Anyone Home - 09:00 AM to 07:00 PM",
        //             "shift_date": "2024-06-04",
        //             "shift_end_time": "19:00:00",
        //             "shift_id": 6674,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 7,
        //             "name": "Anyone Home - 10:00 AM to 08:00 PM",
        //             "shift_date": "2024-06-04",
        //             "shift_end_time": "20:00:00",
        //             "shift_id": 6393,
        //             "shift_start_time": "10:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-06-04",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 6438,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 115,
        //             "Active": 56,
        //             "Training": 0,
        //             "business_unit": "Walmart",
        //             "category": 0,
        //             "name": "Walmart - Others",
        //             "shift_date": "2024-06-05",
        //             "shift_end_time": null,
        //             "shift_id": 7462,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 32,
        //             "Active": 24,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-06-05",
        //             "shift_end_time": null,
        //             "shift_id": 6443,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 13,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "GOALS",
        //             "category": 0,
        //             "name": "GOALS - Others",
        //             "shift_date": "2024-06-05",
        //             "shift_end_time": null,
        //             "shift_id": 8187,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 48,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Henry Schein",
        //             "category": 0,
        //             "name": "Henry Schein - Others",
        //             "shift_date": "2024-06-05",
        //             "shift_end_time": null,
        //             "shift_id": 8052,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 8,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 3,
        //             "name": "MIA Aesthetics - 08:00 AM to 05:00 PM",
        //             "shift_date": "2024-06-05",
        //             "shift_end_time": "17:00:00",
        //             "shift_id": 7887,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-06-05",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 6413,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 6,
        //             "name": "Anyone Home - 09:00 AM to 07:00 PM",
        //             "shift_date": "2024-06-05",
        //             "shift_end_time": "19:00:00",
        //             "shift_id": 6557,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 7,
        //             "name": "Anyone Home - 10:00 AM to 08:00 PM",
        //             "shift_date": "2024-06-05",
        //             "shift_end_time": "20:00:00",
        //             "shift_id": 6485,
        //             "shift_start_time": "10:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-06-05",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 6575,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 119,
        //             "Active": 58,
        //             "Training": 0,
        //             "business_unit": "Walmart",
        //             "category": 0,
        //             "name": "Walmart - Others",
        //             "shift_date": "2024-06-06",
        //             "shift_end_time": null,
        //             "shift_id": 7508,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 41,
        //             "Active": 13,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-06-06",
        //             "shift_end_time": null,
        //             "shift_id": 6670,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 12,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "GOALS",
        //             "category": 0,
        //             "name": "GOALS - Others",
        //             "shift_date": "2024-06-06",
        //             "shift_end_time": null,
        //             "shift_id": 8173,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 48,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Henry Schein",
        //             "category": 0,
        //             "name": "Henry Schein - Others",
        //             "shift_date": "2024-06-06",
        //             "shift_end_time": null,
        //             "shift_id": 8148,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 8,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 3,
        //             "name": "MIA Aesthetics - 08:00 AM to 05:00 PM",
        //             "shift_date": "2024-06-06",
        //             "shift_end_time": "17:00:00",
        //             "shift_id": 7894,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-06-06",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 6609,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 6,
        //             "name": "Anyone Home - 09:00 AM to 07:00 PM",
        //             "shift_date": "2024-06-06",
        //             "shift_end_time": "19:00:00",
        //             "shift_id": 6676,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 7,
        //             "name": "Anyone Home - 10:00 AM to 08:00 PM",
        //             "shift_date": "2024-06-06",
        //             "shift_end_time": "20:00:00",
        //             "shift_id": 6486,
        //             "shift_start_time": "10:00:00"
        //         },
        //         {
        //             "Absent": 48,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Henry Schein",
        //             "category": 0,
        //             "name": "Henry Schein - Others",
        //             "shift_date": "2024-06-07",
        //             "shift_end_time": null,
        //             "shift_id": 7924,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 96,
        //             "Active": 33,
        //             "Training": 0,
        //             "business_unit": "Walmart",
        //             "category": 0,
        //             "name": "Walmart - Others",
        //             "shift_date": "2024-06-07",
        //             "shift_end_time": null,
        //             "shift_id": 8353,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 32,
        //             "Active": 25,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-06-07",
        //             "shift_end_time": null,
        //             "shift_id": 6605,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 13,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "GOALS",
        //             "category": 0,
        //             "name": "GOALS - Others",
        //             "shift_date": "2024-06-07",
        //             "shift_end_time": null,
        //             "shift_id": 8224,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 8,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 3,
        //             "name": "MIA Aesthetics - 08:00 AM to 05:00 PM",
        //             "shift_date": "2024-06-07",
        //             "shift_end_time": "17:00:00",
        //             "shift_id": 7901,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-06-07",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 6610,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 6,
        //             "name": "Anyone Home - 09:00 AM to 07:00 PM",
        //             "shift_date": "2024-06-07",
        //             "shift_end_time": "19:00:00",
        //             "shift_id": 6542,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 7,
        //             "name": "Anyone Home - 10:00 AM to 08:00 PM",
        //             "shift_date": "2024-06-07",
        //             "shift_end_time": "20:00:00",
        //             "shift_id": 6487,
        //             "shift_start_time": "10:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-06-07",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 6576,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 5,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "GOALS",
        //             "category": 0,
        //             "name": "GOALS - Others",
        //             "shift_date": "2024-06-08",
        //             "shift_end_time": null,
        //             "shift_id": 8219,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 119,
        //             "Active": 43,
        //             "Training": 0,
        //             "business_unit": "Walmart",
        //             "category": 0,
        //             "name": "Walmart - Others",
        //             "shift_date": "2024-06-08",
        //             "shift_end_time": null,
        //             "shift_id": 8321,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 35,
        //             "Active": 20,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-06-08",
        //             "shift_end_time": null,
        //             "shift_id": 6472,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-06-08",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 6538,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 6,
        //             "name": "Anyone Home - 09:00 AM to 07:00 PM",
        //             "shift_date": "2024-06-08",
        //             "shift_end_time": "19:00:00",
        //             "shift_id": 6543,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 7,
        //             "name": "Anyone Home - 10:00 AM to 08:00 PM",
        //             "shift_date": "2024-06-08",
        //             "shift_end_time": "20:00:00",
        //             "shift_id": 6396,
        //             "shift_start_time": "10:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-06-08",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 6577,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 105,
        //             "Active": 37,
        //             "Training": 0,
        //             "business_unit": "Walmart",
        //             "category": 0,
        //             "name": "Walmart - Others",
        //             "shift_date": "2024-06-09",
        //             "shift_end_time": null,
        //             "shift_id": 6887,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 33,
        //             "Active": 11,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-06-09",
        //             "shift_end_time": null,
        //             "shift_id": 6366,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-06-09",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 6612,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 6,
        //             "name": "Anyone Home - 09:00 AM to 07:00 PM",
        //             "shift_date": "2024-06-09",
        //             "shift_end_time": "19:00:00",
        //             "shift_id": 6677,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "DSI",
        //             "category": 0,
        //             "name": "DSI - Others",
        //             "shift_date": "2024-06-10",
        //             "shift_end_time": null,
        //             "shift_id": 2009,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 127,
        //             "Active": 45,
        //             "Training": 0,
        //             "business_unit": "Walmart",
        //             "category": 0,
        //             "name": "Walmart - Others",
        //             "shift_date": "2024-06-10",
        //             "shift_end_time": null,
        //             "shift_id": 9906,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 13,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "GOALS",
        //             "category": 0,
        //             "name": "GOALS - Others",
        //             "shift_date": "2024-06-10",
        //             "shift_end_time": null,
        //             "shift_id": 10001,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 50,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Henry Schein",
        //             "category": 0,
        //             "name": "Henry Schein - Others",
        //             "shift_date": "2024-06-10",
        //             "shift_end_time": null,
        //             "shift_id": 10102,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 34,
        //             "Active": 25,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-06-10",
        //             "shift_end_time": null,
        //             "shift_id": 8702,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 4,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "ePremium",
        //             "category": 0,
        //             "name": "ePremium - Others",
        //             "shift_date": "2024-06-10",
        //             "shift_end_time": null,
        //             "shift_id": 10052,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Kroger",
        //             "category": 0,
        //             "name": "Kroger - Others",
        //             "shift_date": "2024-06-10",
        //             "shift_end_time": null,
        //             "shift_id": 11971,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-06-10",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 8607,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 6,
        //             "name": "Anyone Home - 09:00 AM to 07:00 PM",
        //             "shift_date": "2024-06-10",
        //             "shift_end_time": "19:00:00",
        //             "shift_id": 8662,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 7,
        //             "name": "Anyone Home - 10:00 AM to 08:00 PM",
        //             "shift_date": "2024-06-10",
        //             "shift_end_time": "20:00:00",
        //             "shift_id": 8520,
        //             "shift_start_time": "10:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-06-10",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 8555,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Kroger",
        //             "category": 0,
        //             "name": "Kroger - Others",
        //             "shift_date": "2024-06-11",
        //             "shift_end_time": null,
        //             "shift_id": 11972,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "DSI",
        //             "category": 0,
        //             "name": "DSI - Others",
        //             "shift_date": "2024-06-11",
        //             "shift_end_time": null,
        //             "shift_id": 2011,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 98,
        //             "Active": 50,
        //             "Training": 0,
        //             "business_unit": "Walmart",
        //             "category": 0,
        //             "name": "Walmart - Others",
        //             "shift_date": "2024-06-11",
        //             "shift_end_time": null,
        //             "shift_id": 9367,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 15,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "GOALS",
        //             "category": 0,
        //             "name": "GOALS - Others",
        //             "shift_date": "2024-06-11",
        //             "shift_end_time": null,
        //             "shift_id": 10038,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 50,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Henry Schein",
        //             "category": 0,
        //             "name": "Henry Schein - Others",
        //             "shift_date": "2024-06-11",
        //             "shift_end_time": null,
        //             "shift_id": 10198,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 35,
        //             "Active": 24,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-06-11",
        //             "shift_end_time": null,
        //             "shift_id": 8849,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 4,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "ePremium",
        //             "category": 0,
        //             "name": "ePremium - Others",
        //             "shift_date": "2024-06-11",
        //             "shift_end_time": null,
        //             "shift_id": 10043,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-06-11",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 8608,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 6,
        //             "name": "Anyone Home - 09:00 AM to 07:00 PM",
        //             "shift_date": "2024-06-11",
        //             "shift_end_time": "19:00:00",
        //             "shift_id": 8663,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 7,
        //             "name": "Anyone Home - 10:00 AM to 08:00 PM",
        //             "shift_date": "2024-06-11",
        //             "shift_end_time": "20:00:00",
        //             "shift_id": 8526,
        //             "shift_start_time": "10:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-06-11",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 8623,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 4,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "ePremium",
        //             "category": 0,
        //             "name": "ePremium - Others",
        //             "shift_date": "2024-06-12",
        //             "shift_end_time": null,
        //             "shift_id": 10044,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "DSI",
        //             "category": 0,
        //             "name": "DSI - Others",
        //             "shift_date": "2024-06-12",
        //             "shift_end_time": null,
        //             "shift_id": 2013,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 95,
        //             "Active": 50,
        //             "Training": 0,
        //             "business_unit": "Walmart",
        //             "category": 0,
        //             "name": "Walmart - Others",
        //             "shift_date": "2024-06-12",
        //             "shift_end_time": null,
        //             "shift_id": 9762,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 15,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "GOALS",
        //             "category": 0,
        //             "name": "GOALS - Others",
        //             "shift_date": "2024-06-12",
        //             "shift_end_time": null,
        //             "shift_id": 9978,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 50,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Henry Schein",
        //             "category": 0,
        //             "name": "Henry Schein - Others",
        //             "shift_date": "2024-06-12",
        //             "shift_end_time": null,
        //             "shift_id": 10294,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 36,
        //             "Active": 19,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-06-12",
        //             "shift_end_time": null,
        //             "shift_id": 8938,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-06-12",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 8541,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 6,
        //             "name": "Anyone Home - 09:00 AM to 07:00 PM",
        //             "shift_date": "2024-06-12",
        //             "shift_end_time": "19:00:00",
        //             "shift_id": 8664,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 7,
        //             "name": "Anyone Home - 10:00 AM to 08:00 PM",
        //             "shift_date": "2024-06-12",
        //             "shift_end_time": "20:00:00",
        //             "shift_id": 8527,
        //             "shift_start_time": "10:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-06-12",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 8624,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 36,
        //             "Active": 18,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-06-13",
        //             "shift_end_time": null,
        //             "shift_id": 8957,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 4,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "ePremium",
        //             "category": 0,
        //             "name": "ePremium - Others",
        //             "shift_date": "2024-06-13",
        //             "shift_end_time": null,
        //             "shift_id": 10045,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Tuners",
        //             "category": 0,
        //             "name": "Tuners - Others",
        //             "shift_date": "2024-06-13",
        //             "shift_end_time": null,
        //             "shift_id": 12174,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "DSI",
        //             "category": 0,
        //             "name": "DSI - Others",
        //             "shift_date": "2024-06-13",
        //             "shift_end_time": null,
        //             "shift_id": 2015,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 92,
        //             "Active": 50,
        //             "Training": 0,
        //             "business_unit": "Walmart",
        //             "category": 0,
        //             "name": "Walmart - Others",
        //             "shift_date": "2024-06-13",
        //             "shift_end_time": null,
        //             "shift_id": 9417,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 15,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "GOALS",
        //             "category": 0,
        //             "name": "GOALS - Others",
        //             "shift_date": "2024-06-13",
        //             "shift_end_time": null,
        //             "shift_id": 9983,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 49,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Henry Schein",
        //             "category": 0,
        //             "name": "Henry Schein - Others",
        //             "shift_date": "2024-06-13",
        //             "shift_end_time": null,
        //             "shift_id": 10070,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-06-13",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 8542,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 6,
        //             "name": "Anyone Home - 09:00 AM to 07:00 PM",
        //             "shift_date": "2024-06-13",
        //             "shift_end_time": "19:00:00",
        //             "shift_id": 8665,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 7,
        //             "name": "Anyone Home - 10:00 AM to 08:00 PM",
        //             "shift_date": "2024-06-13",
        //             "shift_end_time": "20:00:00",
        //             "shift_id": 8599,
        //             "shift_start_time": "10:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "DSI",
        //             "category": 0,
        //             "name": "DSI - Others",
        //             "shift_date": "2024-06-14",
        //             "shift_end_time": null,
        //             "shift_id": 2017,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 102,
        //             "Active": 44,
        //             "Training": 0,
        //             "business_unit": "Walmart",
        //             "category": 0,
        //             "name": "Walmart - Others",
        //             "shift_date": "2024-06-14",
        //             "shift_end_time": null,
        //             "shift_id": 9138,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 15,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "GOALS",
        //             "category": 0,
        //             "name": "GOALS - Others",
        //             "shift_date": "2024-06-14",
        //             "shift_end_time": null,
        //             "shift_id": 10010,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 49,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Henry Schein",
        //             "category": 0,
        //             "name": "Henry Schein - Others",
        //             "shift_date": "2024-06-14",
        //             "shift_end_time": null,
        //             "shift_id": 10166,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 32,
        //             "Active": 27,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-06-14",
        //             "shift_end_time": null,
        //             "shift_id": 8994,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Kroger",
        //             "category": 0,
        //             "name": "Kroger - Others",
        //             "shift_date": "2024-06-14",
        //             "shift_end_time": null,
        //             "shift_id": 11973,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 4,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "ePremium",
        //             "category": 0,
        //             "name": "ePremium - Others",
        //             "shift_date": "2024-06-14",
        //             "shift_end_time": null,
        //             "shift_id": 10061,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Tuners",
        //             "category": 0,
        //             "name": "Tuners - Others",
        //             "shift_date": "2024-06-14",
        //             "shift_end_time": null,
        //             "shift_id": 12175,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-06-14",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 8644,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 7,
        //             "name": "Anyone Home - 10:00 AM to 08:00 PM",
        //             "shift_date": "2024-06-14",
        //             "shift_end_time": "20:00:00",
        //             "shift_id": 8528,
        //             "shift_start_time": "10:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-06-14",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 8558,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 92,
        //             "Active": 39,
        //             "Training": 0,
        //             "business_unit": "Walmart",
        //             "category": 0,
        //             "name": "Walmart - Others",
        //             "shift_date": "2024-06-15",
        //             "shift_end_time": null,
        //             "shift_id": 9169,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "GOALS",
        //             "category": 0,
        //             "name": "GOALS - Others",
        //             "shift_date": "2024-06-15",
        //             "shift_end_time": null,
        //             "shift_id": 9970,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 32,
        //             "Active": 22,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-06-15",
        //             "shift_end_time": null,
        //             "shift_id": 8760,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Kroger",
        //             "category": 0,
        //             "name": "Kroger - Others",
        //             "shift_date": "2024-06-15",
        //             "shift_end_time": null,
        //             "shift_id": 11974,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-06-15",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 8645,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 7,
        //             "name": "Anyone Home - 10:00 AM to 08:00 PM",
        //             "shift_date": "2024-06-15",
        //             "shift_end_time": "20:00:00",
        //             "shift_id": 8529,
        //             "shift_start_time": "10:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-06-15",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 8626,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "DSI",
        //             "category": 0,
        //             "name": "DSI - Others",
        //             "shift_date": "2024-06-16",
        //             "shift_end_time": null,
        //             "shift_id": 2018,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 82,
        //             "Active": 25,
        //             "Training": 0,
        //             "business_unit": "Walmart",
        //             "category": 0,
        //             "name": "Walmart - Others",
        //             "shift_date": "2024-06-16",
        //             "shift_end_time": null,
        //             "shift_id": 9490,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 30,
        //             "Active": 12,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-06-16",
        //             "shift_end_time": null,
        //             "shift_id": 8554,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 5,
        //             "Active": 7,
        //             "Training": 0,
        //             "business_unit": "Kroger",
        //             "category": 0,
        //             "name": "Kroger - Others",
        //             "shift_date": "2024-06-16",
        //             "shift_end_time": null,
        //             "shift_id": 11970,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-06-16",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 8646,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 6,
        //             "name": "Anyone Home - 09:00 AM to 07:00 PM",
        //             "shift_date": "2024-06-16",
        //             "shift_end_time": "19:00:00",
        //             "shift_id": 8666,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 20,
        //             "Active": 6,
        //             "Training": 0,
        //             "business_unit": "Kroger",
        //             "category": 0,
        //             "name": "Kroger - Others",
        //             "shift_date": "2024-06-17",
        //             "shift_end_time": null,
        //             "shift_id": 15259,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 8,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Active Engage",
        //             "category": 0,
        //             "name": "Active Engage - Others",
        //             "shift_date": "2024-06-17",
        //             "shift_end_time": null,
        //             "shift_id": 10458,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 52,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Henry Schein",
        //             "category": 0,
        //             "name": "Henry Schein - Others",
        //             "shift_date": "2024-06-17",
        //             "shift_end_time": null,
        //             "shift_id": 12401,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 90,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Office Depot",
        //             "category": 0,
        //             "name": "Office Depot - Others",
        //             "shift_date": "2024-06-17",
        //             "shift_end_time": null,
        //             "shift_id": 14752,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 4,
        //             "Active": 17,
        //             "Training": 0,
        //             "business_unit": "GOALS",
        //             "category": 0,
        //             "name": "GOALS - Others",
        //             "shift_date": "2024-06-17",
        //             "shift_end_time": null,
        //             "shift_id": 12116,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 112,
        //             "Active": 50,
        //             "Training": 0,
        //             "business_unit": "Walmart",
        //             "category": 0,
        //             "name": "Walmart - Others",
        //             "shift_date": "2024-06-17",
        //             "shift_end_time": null,
        //             "shift_id": 11364,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 4,
        //             "Training": 0,
        //             "business_unit": "ePremium",
        //             "category": 0,
        //             "name": "ePremium - Others",
        //             "shift_date": "2024-06-17",
        //             "shift_end_time": null,
        //             "shift_id": 12446,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "Tuners",
        //             "category": 0,
        //             "name": "Tuners - Others",
        //             "shift_date": "2024-06-17",
        //             "shift_end_time": null,
        //             "shift_id": 12176,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 35,
        //             "Active": 27,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-06-17",
        //             "shift_end_time": null,
        //             "shift_id": 8791,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 3,
        //             "name": "MIA Aesthetics - 08:00 AM to 05:00 PM",
        //             "shift_date": "2024-06-17",
        //             "shift_end_time": "17:00:00",
        //             "shift_id": 10337,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 4,
        //             "name": "MIA Aesthetics - 09:00 AM to 06:00 PM",
        //             "shift_date": "2024-06-17",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 10322,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-06-17",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 10685,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 6,
        //             "name": "Anyone Home - 09:00 AM to 07:00 PM",
        //             "shift_date": "2024-06-17",
        //             "shift_end_time": "19:00:00",
        //             "shift_id": 10549,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 7,
        //             "name": "Anyone Home - 10:00 AM to 08:00 PM",
        //             "shift_date": "2024-06-17",
        //             "shift_end_time": "20:00:00",
        //             "shift_id": 10513,
        //             "shift_start_time": "10:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-06-17",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 10518,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 4,
        //             "Training": 0,
        //             "business_unit": "ePremium",
        //             "category": 0,
        //             "name": "ePremium - Others",
        //             "shift_date": "2024-06-18",
        //             "shift_end_time": null,
        //             "shift_id": 12462,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "Tuners",
        //             "category": 0,
        //             "name": "Tuners - Others",
        //             "shift_date": "2024-06-18",
        //             "shift_end_time": null,
        //             "shift_id": 12177,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 30,
        //             "Active": 31,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-06-18",
        //             "shift_end_time": null,
        //             "shift_id": 10727,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 18,
        //             "Active": 8,
        //             "Training": 0,
        //             "business_unit": "Kroger",
        //             "category": 0,
        //             "name": "Kroger - Others",
        //             "shift_date": "2024-06-18",
        //             "shift_end_time": null,
        //             "shift_id": 15250,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 10,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Active Engage",
        //             "category": 0,
        //             "name": "Active Engage - Others",
        //             "shift_date": "2024-06-18",
        //             "shift_end_time": null,
        //             "shift_id": 10468,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 45,
        //             "Active": 8,
        //             "Training": 0,
        //             "business_unit": "Henry Schein",
        //             "category": 0,
        //             "name": "Henry Schein - Others",
        //             "shift_date": "2024-06-18",
        //             "shift_end_time": null,
        //             "shift_id": 12242,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 91,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Office Depot",
        //             "category": 0,
        //             "name": "Office Depot - Others",
        //             "shift_date": "2024-06-18",
        //             "shift_end_time": null,
        //             "shift_id": 15004,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 17,
        //             "Training": 0,
        //             "business_unit": "GOALS",
        //             "category": 0,
        //             "name": "GOALS - Others",
        //             "shift_date": "2024-06-18",
        //             "shift_end_time": null,
        //             "shift_id": 12106,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 106,
        //             "Active": 52,
        //             "Training": 0,
        //             "business_unit": "Walmart",
        //             "category": 0,
        //             "name": "Walmart - Others",
        //             "shift_date": "2024-06-18",
        //             "shift_end_time": null,
        //             "shift_id": 11020,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 3,
        //             "name": "MIA Aesthetics - 08:00 AM to 05:00 PM",
        //             "shift_date": "2024-06-18",
        //             "shift_end_time": "17:00:00",
        //             "shift_id": 10338,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 4,
        //             "name": "MIA Aesthetics - 09:00 AM to 06:00 PM",
        //             "shift_date": "2024-06-18",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 10323,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-06-18",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 10686,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 6,
        //             "name": "Anyone Home - 09:00 AM to 07:00 PM",
        //             "shift_date": "2024-06-18",
        //             "shift_end_time": "19:00:00",
        //             "shift_id": 10550,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 7,
        //             "name": "Anyone Home - 10:00 AM to 08:00 PM",
        //             "shift_date": "2024-06-18",
        //             "shift_end_time": "20:00:00",
        //             "shift_id": 10514,
        //             "shift_start_time": "10:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-06-18",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 10616,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 18,
        //             "Training": 0,
        //             "business_unit": "GOALS",
        //             "category": 0,
        //             "name": "GOALS - Others",
        //             "shift_date": "2024-06-19",
        //             "shift_end_time": null,
        //             "shift_id": 12128,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 109,
        //             "Active": 45,
        //             "Training": 0,
        //             "business_unit": "Walmart",
        //             "category": 0,
        //             "name": "Walmart - Others",
        //             "shift_date": "2024-06-19",
        //             "shift_end_time": null,
        //             "shift_id": 11651,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 4,
        //             "Training": 0,
        //             "business_unit": "ePremium",
        //             "category": 0,
        //             "name": "ePremium - Others",
        //             "shift_date": "2024-06-19",
        //             "shift_end_time": null,
        //             "shift_id": 12453,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "Tuners",
        //             "category": 0,
        //             "name": "Tuners - Others",
        //             "shift_date": "2024-06-19",
        //             "shift_end_time": null,
        //             "shift_id": 12178,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 27,
        //             "Active": 37,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-06-19",
        //             "shift_end_time": null,
        //             "shift_id": 10411,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 14,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Kroger",
        //             "category": 0,
        //             "name": "Kroger - Others",
        //             "shift_date": "2024-06-19",
        //             "shift_end_time": null,
        //             "shift_id": 15291,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 9,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Active Engage",
        //             "category": 0,
        //             "name": "Active Engage - Others",
        //             "shift_date": "2024-06-19",
        //             "shift_end_time": null,
        //             "shift_id": 10499,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 49,
        //             "Active": 4,
        //             "Training": 0,
        //             "business_unit": "Henry Schein",
        //             "category": 0,
        //             "name": "Henry Schein - Others",
        //             "shift_date": "2024-06-19",
        //             "shift_end_time": null,
        //             "shift_id": 12433,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 68,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Office Depot",
        //             "category": 0,
        //             "name": "Office Depot - Others",
        //             "shift_date": "2024-06-19",
        //             "shift_end_time": null,
        //             "shift_id": 14816,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 3,
        //             "name": "MIA Aesthetics - 08:00 AM to 05:00 PM",
        //             "shift_date": "2024-06-19",
        //             "shift_end_time": "17:00:00",
        //             "shift_id": 10314,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 4,
        //             "name": "MIA Aesthetics - 09:00 AM to 06:00 PM",
        //             "shift_date": "2024-06-19",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 10334,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-06-19",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 10575,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 6,
        //             "name": "Anyone Home - 09:00 AM to 07:00 PM",
        //             "shift_date": "2024-06-19",
        //             "shift_end_time": "19:00:00",
        //             "shift_id": 10551,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 7,
        //             "name": "Anyone Home - 10:00 AM to 08:00 PM",
        //             "shift_date": "2024-06-19",
        //             "shift_end_time": "20:00:00",
        //             "shift_id": 10515,
        //             "shift_start_time": "10:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-06-19",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 10520,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 50,
        //             "Active": 3,
        //             "Training": 0,
        //             "business_unit": "Henry Schein",
        //             "category": 0,
        //             "name": "Henry Schein - Others",
        //             "shift_date": "2024-06-20",
        //             "shift_end_time": null,
        //             "shift_id": 12274,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 90,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Office Depot",
        //             "category": 0,
        //             "name": "Office Depot - Others",
        //             "shift_date": "2024-06-20",
        //             "shift_end_time": null,
        //             "shift_id": 14972,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 19,
        //             "Training": 0,
        //             "business_unit": "GOALS",
        //             "category": 0,
        //             "name": "GOALS - Others",
        //             "shift_date": "2024-06-20",
        //             "shift_end_time": null,
        //             "shift_id": 12048,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 112,
        //             "Active": 42,
        //             "Training": 0,
        //             "business_unit": "Walmart",
        //             "category": 0,
        //             "name": "Walmart - Others",
        //             "shift_date": "2024-06-20",
        //             "shift_end_time": null,
        //             "shift_id": 11821,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 4,
        //             "Training": 0,
        //             "business_unit": "ePremium",
        //             "category": 0,
        //             "name": "ePremium - Others",
        //             "shift_date": "2024-06-20",
        //             "shift_end_time": null,
        //             "shift_id": 12454,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "Tuners",
        //             "category": 0,
        //             "name": "Tuners - Others",
        //             "shift_date": "2024-06-20",
        //             "shift_end_time": null,
        //             "shift_id": 12179,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 22,
        //             "Active": 38,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-06-20",
        //             "shift_end_time": null,
        //             "shift_id": 10737,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 10,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Active Engage",
        //             "category": 0,
        //             "name": "Active Engage - Others",
        //             "shift_date": "2024-06-20",
        //             "shift_end_time": null,
        //             "shift_id": 10485,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 4,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 3,
        //             "name": "MIA Aesthetics - 08:00 AM to 05:00 PM",
        //             "shift_date": "2024-06-20",
        //             "shift_end_time": "17:00:00",
        //             "shift_id": 10315,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 4,
        //             "name": "MIA Aesthetics - 09:00 AM to 06:00 PM",
        //             "shift_date": "2024-06-20",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 10335,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-06-20",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 10576,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 6,
        //             "name": "Anyone Home - 09:00 AM to 07:00 PM",
        //             "shift_date": "2024-06-20",
        //             "shift_end_time": "19:00:00",
        //             "shift_id": 10542,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 10,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Active Engage",
        //             "category": 0,
        //             "name": "Active Engage - Others",
        //             "shift_date": "2024-06-21",
        //             "shift_end_time": null,
        //             "shift_id": 10467,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 51,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "Henry Schein",
        //             "category": 0,
        //             "name": "Henry Schein - Others",
        //             "shift_date": "2024-06-21",
        //             "shift_end_time": null,
        //             "shift_id": 12305,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 93,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Office Depot",
        //             "category": 0,
        //             "name": "Office Depot - Others",
        //             "shift_date": "2024-06-21",
        //             "shift_end_time": null,
        //             "shift_id": 15036,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 5,
        //             "Active": 17,
        //             "Training": 0,
        //             "business_unit": "GOALS",
        //             "category": 0,
        //             "name": "GOALS - Others",
        //             "shift_date": "2024-06-21",
        //             "shift_end_time": null,
        //             "shift_id": 12038,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 115,
        //             "Active": 41,
        //             "Training": 0,
        //             "business_unit": "Walmart",
        //             "category": 0,
        //             "name": "Walmart - Others",
        //             "shift_date": "2024-06-21",
        //             "shift_end_time": null,
        //             "shift_id": 11538,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 19,
        //             "Active": 7,
        //             "Training": 0,
        //             "business_unit": "Kroger",
        //             "category": 0,
        //             "name": "Kroger - Others",
        //             "shift_date": "2024-06-21",
        //             "shift_end_time": null,
        //             "shift_id": 12012,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 4,
        //             "Training": 0,
        //             "business_unit": "ePremium",
        //             "category": 0,
        //             "name": "ePremium - Others",
        //             "shift_date": "2024-06-21",
        //             "shift_end_time": null,
        //             "shift_id": 12450,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "Tuners",
        //             "category": 0,
        //             "name": "Tuners - Others",
        //             "shift_date": "2024-06-21",
        //             "shift_end_time": null,
        //             "shift_id": 12180,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 21,
        //             "Active": 39,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-06-21",
        //             "shift_end_time": null,
        //             "shift_id": 10862,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 3,
        //             "name": "MIA Aesthetics - 08:00 AM to 05:00 PM",
        //             "shift_date": "2024-06-21",
        //             "shift_end_time": "17:00:00",
        //             "shift_id": 10341,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 4,
        //             "name": "MIA Aesthetics - 09:00 AM to 06:00 PM",
        //             "shift_date": "2024-06-21",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 10331,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-06-21",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 10577,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 6,
        //             "name": "Anyone Home - 09:00 AM to 07:00 PM",
        //             "shift_date": "2024-06-21",
        //             "shift_end_time": "19:00:00",
        //             "shift_id": 10613,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 7,
        //             "name": "Anyone Home - 10:00 AM to 08:00 PM",
        //             "shift_date": "2024-06-21",
        //             "shift_end_time": "20:00:00",
        //             "shift_id": 10516,
        //             "shift_start_time": "10:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-06-21",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 10618,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 22,
        //             "Active": 40,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-06-22",
        //             "shift_end_time": null,
        //             "shift_id": 10526,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 8,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Active Engage",
        //             "category": 0,
        //             "name": "Active Engage - Others",
        //             "shift_date": "2024-06-22",
        //             "shift_end_time": null,
        //             "shift_id": 10476,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 13,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Office Depot",
        //             "category": 0,
        //             "name": "Office Depot - Others",
        //             "shift_date": "2024-06-22",
        //             "shift_end_time": null,
        //             "shift_id": 15059,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 11,
        //             "Training": 0,
        //             "business_unit": "GOALS",
        //             "category": 0,
        //             "name": "GOALS - Others",
        //             "shift_date": "2024-06-22",
        //             "shift_end_time": null,
        //             "shift_id": 12074,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 107,
        //             "Active": 36,
        //             "Training": 0,
        //             "business_unit": "Walmart",
        //             "category": 0,
        //             "name": "Walmart - Others",
        //             "shift_date": "2024-06-22",
        //             "shift_end_time": null,
        //             "shift_id": 11217,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 20,
        //             "Active": 6,
        //             "Training": 0,
        //             "business_unit": "Kroger",
        //             "category": 0,
        //             "name": "Kroger - Others",
        //             "shift_date": "2024-06-22",
        //             "shift_end_time": null,
        //             "shift_id": 11983,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-06-22",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 10547,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 6,
        //             "name": "Anyone Home - 09:00 AM to 07:00 PM",
        //             "shift_date": "2024-06-22",
        //             "shift_end_time": "19:00:00",
        //             "shift_id": 10614,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 7,
        //             "name": "Anyone Home - 10:00 AM to 08:00 PM",
        //             "shift_date": "2024-06-22",
        //             "shift_end_time": "20:00:00",
        //             "shift_id": 10517,
        //             "shift_start_time": "10:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-06-22",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 10521,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 88,
        //             "Active": 32,
        //             "Training": 0,
        //             "business_unit": "Walmart",
        //             "category": 0,
        //             "name": "Walmart - Others",
        //             "shift_date": "2024-06-23",
        //             "shift_end_time": null,
        //             "shift_id": 11083,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 9,
        //             "Active": 3,
        //             "Training": 0,
        //             "business_unit": "Kroger",
        //             "category": 0,
        //             "name": "Kroger - Others",
        //             "shift_date": "2024-06-23",
        //             "shift_end_time": null,
        //             "shift_id": 12034,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 19,
        //             "Active": 29,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-06-23",
        //             "shift_end_time": null,
        //             "shift_id": 10558,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 5,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Active Engage",
        //             "category": 0,
        //             "name": "Active Engage - Others",
        //             "shift_date": "2024-06-23",
        //             "shift_end_time": null,
        //             "shift_id": 10477,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-06-23",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 10548,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 6,
        //             "name": "Anyone Home - 09:00 AM to 07:00 PM",
        //             "shift_date": "2024-06-23",
        //             "shift_end_time": "19:00:00",
        //             "shift_id": 10553,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-06-23",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 10522,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 135,
        //             "Active": 48,
        //             "Training": 0,
        //             "business_unit": "Walmart",
        //             "category": 0,
        //             "name": "Walmart - Others",
        //             "shift_date": "2024-06-24",
        //             "shift_end_time": null,
        //             "shift_id": 13729,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 16,
        //             "Active": 7,
        //             "Training": 0,
        //             "business_unit": "Kroger",
        //             "category": 0,
        //             "name": "Kroger - Others",
        //             "shift_date": "2024-06-24",
        //             "shift_end_time": null,
        //             "shift_id": 15194,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 22,
        //             "Active": 37,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-06-24",
        //             "shift_end_time": null,
        //             "shift_id": 12841,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 50,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "Henry Schein",
        //             "category": 0,
        //             "name": "Henry Schein - Others",
        //             "shift_date": "2024-06-24",
        //             "shift_end_time": null,
        //             "shift_id": 13006,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 8,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Active Engage",
        //             "category": 0,
        //             "name": "Active Engage - Others",
        //             "shift_date": "2024-06-24",
        //             "shift_end_time": null,
        //             "shift_id": 13384,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 23,
        //             "Training": 0,
        //             "business_unit": "GOALS",
        //             "category": 0,
        //             "name": "GOALS - Others",
        //             "shift_date": "2024-06-24",
        //             "shift_end_time": null,
        //             "shift_id": 15334,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 4,
        //             "Training": 0,
        //             "business_unit": "ePremium",
        //             "category": 0,
        //             "name": "ePremium - Others",
        //             "shift_date": "2024-06-24",
        //             "shift_end_time": null,
        //             "shift_id": 13051,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 3,
        //             "Training": 0,
        //             "business_unit": "Tuners",
        //             "category": 0,
        //             "name": "Tuners - Others",
        //             "shift_date": "2024-06-24",
        //             "shift_end_time": null,
        //             "shift_id": 15339,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 5,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 3,
        //             "name": "MIA Aesthetics - 08:00 AM to 05:00 PM",
        //             "shift_date": "2024-06-24",
        //             "shift_end_time": "17:00:00",
        //             "shift_id": 15169,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 4,
        //             "name": "MIA Aesthetics - 09:00 AM to 06:00 PM",
        //             "shift_date": "2024-06-24",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 15149,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-06-24",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 12683,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 6,
        //             "name": "Anyone Home - 09:00 AM to 07:00 PM",
        //             "shift_date": "2024-06-24",
        //             "shift_end_time": "19:00:00",
        //             "shift_id": 13308,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 7,
        //             "name": "Anyone Home - 10:00 AM to 08:00 PM",
        //             "shift_date": "2024-06-24",
        //             "shift_end_time": "20:00:00",
        //             "shift_id": 12581,
        //             "shift_start_time": "10:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-06-24",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 12606,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 22,
        //             "Training": 0,
        //             "business_unit": "GOALS",
        //             "category": 0,
        //             "name": "GOALS - Others",
        //             "shift_date": "2024-06-25",
        //             "shift_end_time": null,
        //             "shift_id": 13289,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 4,
        //             "Training": 0,
        //             "business_unit": "ePremium",
        //             "category": 0,
        //             "name": "ePremium - Others",
        //             "shift_date": "2024-06-25",
        //             "shift_end_time": null,
        //             "shift_id": 13062,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 3,
        //             "Training": 0,
        //             "business_unit": "Tuners",
        //             "category": 0,
        //             "name": "Tuners - Others",
        //             "shift_date": "2024-06-25",
        //             "shift_end_time": null,
        //             "shift_id": 15305,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 139,
        //             "Active": 39,
        //             "Training": 0,
        //             "business_unit": "Walmart",
        //             "category": 0,
        //             "name": "Walmart - Others",
        //             "shift_date": "2024-06-25",
        //             "shift_end_time": null,
        //             "shift_id": 13470,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 8,
        //             "Active": 15,
        //             "Training": 0,
        //             "business_unit": "Kroger",
        //             "category": 0,
        //             "name": "Kroger - Others",
        //             "shift_date": "2024-06-25",
        //             "shift_end_time": null,
        //             "shift_id": 15220,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 21,
        //             "Active": 37,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-06-25",
        //             "shift_end_time": null,
        //             "shift_id": 12591,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 45,
        //             "Active": 7,
        //             "Training": 0,
        //             "business_unit": "Henry Schein",
        //             "category": 0,
        //             "name": "Henry Schein - Others",
        //             "shift_date": "2024-06-25",
        //             "shift_end_time": null,
        //             "shift_id": 12542,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 10,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Active Engage",
        //             "category": 0,
        //             "name": "Active Engage - Others",
        //             "shift_date": "2024-06-25",
        //             "shift_end_time": null,
        //             "shift_id": 13389,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 4,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 3,
        //             "name": "MIA Aesthetics - 08:00 AM to 05:00 PM",
        //             "shift_date": "2024-06-25",
        //             "shift_end_time": "17:00:00",
        //             "shift_id": 15175,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 4,
        //             "name": "MIA Aesthetics - 09:00 AM to 06:00 PM",
        //             "shift_date": "2024-06-25",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 15150,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-06-25",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 12597,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 6,
        //             "name": "Anyone Home - 09:00 AM to 07:00 PM",
        //             "shift_date": "2024-06-25",
        //             "shift_end_time": "19:00:00",
        //             "shift_id": 13309,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 7,
        //             "name": "Anyone Home - 10:00 AM to 08:00 PM",
        //             "shift_date": "2024-06-25",
        //             "shift_end_time": "20:00:00",
        //             "shift_id": 12582,
        //             "shift_start_time": "10:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-06-25",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 12693,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 9,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Active Engage",
        //             "category": 0,
        //             "name": "Active Engage - Others",
        //             "shift_date": "2024-06-26",
        //             "shift_end_time": null,
        //             "shift_id": 13375,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 22,
        //             "Training": 0,
        //             "business_unit": "GOALS",
        //             "category": 0,
        //             "name": "GOALS - Others",
        //             "shift_date": "2024-06-26",
        //             "shift_end_time": null,
        //             "shift_id": 13250,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 4,
        //             "Training": 0,
        //             "business_unit": "ePremium",
        //             "category": 0,
        //             "name": "ePremium - Others",
        //             "shift_date": "2024-06-26",
        //             "shift_end_time": null,
        //             "shift_id": 13063,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 3,
        //             "Training": 0,
        //             "business_unit": "Tuners",
        //             "category": 0,
        //             "name": "Tuners - Others",
        //             "shift_date": "2024-06-26",
        //             "shift_end_time": null,
        //             "shift_id": 15306,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 137,
        //             "Active": 36,
        //             "Training": 0,
        //             "business_unit": "Walmart",
        //             "category": 0,
        //             "name": "Walmart - Others",
        //             "shift_date": "2024-06-26",
        //             "shift_end_time": null,
        //             "shift_id": 13851,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 6,
        //             "Active": 8,
        //             "Training": 0,
        //             "business_unit": "Kroger",
        //             "category": 0,
        //             "name": "Kroger - Others",
        //             "shift_date": "2024-06-26",
        //             "shift_end_time": null,
        //             "shift_id": 15351,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 21,
        //             "Active": 39,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-06-26",
        //             "shift_end_time": null,
        //             "shift_id": 12708,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 46,
        //             "Active": 6,
        //             "Training": 0,
        //             "business_unit": "Henry Schein",
        //             "category": 0,
        //             "name": "Henry Schein - Others",
        //             "shift_date": "2024-06-26",
        //             "shift_end_time": null,
        //             "shift_id": 12943,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 4,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 3,
        //             "name": "MIA Aesthetics - 08:00 AM to 05:00 PM",
        //             "shift_date": "2024-06-26",
        //             "shift_end_time": "17:00:00",
        //             "shift_id": 15166,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 4,
        //             "name": "MIA Aesthetics - 09:00 AM to 06:00 PM",
        //             "shift_date": "2024-06-26",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 15181,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-06-26",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 12598,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 6,
        //             "name": "Anyone Home - 09:00 AM to 07:00 PM",
        //             "shift_date": "2024-06-26",
        //             "shift_end_time": "19:00:00",
        //             "shift_id": 13310,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 7,
        //             "name": "Anyone Home - 10:00 AM to 08:00 PM",
        //             "shift_date": "2024-06-26",
        //             "shift_end_time": "20:00:00",
        //             "shift_id": 12583,
        //             "shift_start_time": "10:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-06-26",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 12694,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 4,
        //             "Active": 5,
        //             "Training": 0,
        //             "business_unit": "Kroger",
        //             "category": 0,
        //             "name": "Kroger - Others",
        //             "shift_date": "2024-06-27",
        //             "shift_end_time": null,
        //             "shift_id": 15191,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 18,
        //             "Active": 36,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-06-27",
        //             "shift_end_time": null,
        //             "shift_id": 13139,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 42,
        //             "Active": 10,
        //             "Training": 0,
        //             "business_unit": "Henry Schein",
        //             "category": 0,
        //             "name": "Henry Schein - Others",
        //             "shift_date": "2024-06-27",
        //             "shift_end_time": null,
        //             "shift_id": 12519,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 10,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Active Engage",
        //             "category": 0,
        //             "name": "Active Engage - Others",
        //             "shift_date": "2024-06-27",
        //             "shift_end_time": null,
        //             "shift_id": 13371,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 22,
        //             "Training": 0,
        //             "business_unit": "GOALS",
        //             "category": 0,
        //             "name": "GOALS - Others",
        //             "shift_date": "2024-06-27",
        //             "shift_end_time": null,
        //             "shift_id": 13316,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 4,
        //             "Training": 0,
        //             "business_unit": "ePremium",
        //             "category": 0,
        //             "name": "ePremium - Others",
        //             "shift_date": "2024-06-27",
        //             "shift_end_time": null,
        //             "shift_id": 13059,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 3,
        //             "Training": 0,
        //             "business_unit": "Tuners",
        //             "category": 0,
        //             "name": "Tuners - Others",
        //             "shift_date": "2024-06-27",
        //             "shift_end_time": null,
        //             "shift_id": 15342,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 131,
        //             "Active": 40,
        //             "Training": 0,
        //             "business_unit": "Walmart",
        //             "category": 0,
        //             "name": "Walmart - Others",
        //             "shift_date": "2024-06-27",
        //             "shift_end_time": null,
        //             "shift_id": 13906,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 4,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 3,
        //             "name": "MIA Aesthetics - 08:00 AM to 05:00 PM",
        //             "shift_date": "2024-06-27",
        //             "shift_end_time": "17:00:00",
        //             "shift_id": 15177,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 4,
        //             "name": "MIA Aesthetics - 09:00 AM to 06:00 PM",
        //             "shift_date": "2024-06-27",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 15182,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-06-27",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 12685,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 6,
        //             "name": "Anyone Home - 09:00 AM to 07:00 PM",
        //             "shift_date": "2024-06-27",
        //             "shift_end_time": "19:00:00",
        //             "shift_id": 13311,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-06-27",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 12695,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 134,
        //             "Active": 35,
        //             "Training": 0,
        //             "business_unit": "Walmart",
        //             "category": 0,
        //             "name": "Walmart - Others",
        //             "shift_date": "2024-06-28",
        //             "shift_end_time": null,
        //             "shift_id": 13956,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 15,
        //             "Active": 8,
        //             "Training": 0,
        //             "business_unit": "Kroger",
        //             "category": 0,
        //             "name": "Kroger - Others",
        //             "shift_date": "2024-06-28",
        //             "shift_end_time": null,
        //             "shift_id": 15202,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 18,
        //             "Active": 38,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-06-28",
        //             "shift_end_time": null,
        //             "shift_id": 12751,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 42,
        //             "Active": 10,
        //             "Training": 0,
        //             "business_unit": "Henry Schein",
        //             "category": 0,
        //             "name": "Henry Schein - Others",
        //             "shift_date": "2024-06-28",
        //             "shift_end_time": null,
        //             "shift_id": 12975,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 10,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Active Engage",
        //             "category": 0,
        //             "name": "Active Engage - Others",
        //             "shift_date": "2024-06-28",
        //             "shift_end_time": null,
        //             "shift_id": 13352,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 24,
        //             "Training": 0,
        //             "business_unit": "GOALS",
        //             "category": 0,
        //             "name": "GOALS - Others",
        //             "shift_date": "2024-06-28",
        //             "shift_end_time": null,
        //             "shift_id": 13322,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 4,
        //             "Training": 0,
        //             "business_unit": "ePremium",
        //             "category": 0,
        //             "name": "ePremium - Others",
        //             "shift_date": "2024-06-28",
        //             "shift_end_time": null,
        //             "shift_id": 13060,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 3,
        //             "Training": 0,
        //             "business_unit": "Tuners",
        //             "category": 0,
        //             "name": "Tuners - Others",
        //             "shift_date": "2024-06-28",
        //             "shift_end_time": null,
        //             "shift_id": 15343,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 5,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 3,
        //             "name": "MIA Aesthetics - 08:00 AM to 05:00 PM",
        //             "shift_date": "2024-06-28",
        //             "shift_end_time": "17:00:00",
        //             "shift_id": 15178,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 4,
        //             "name": "MIA Aesthetics - 09:00 AM to 06:00 PM",
        //             "shift_date": "2024-06-28",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 15153,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-06-28",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 12599,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 7,
        //             "name": "Anyone Home - 10:00 AM to 08:00 PM",
        //             "shift_date": "2024-06-28",
        //             "shift_end_time": "20:00:00",
        //             "shift_id": 12584,
        //             "shift_start_time": "10:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-06-28",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 12609,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 129,
        //             "Active": 26,
        //             "Training": 0,
        //             "business_unit": "Walmart",
        //             "category": 0,
        //             "name": "Walmart - Others",
        //             "shift_date": "2024-06-29",
        //             "shift_end_time": null,
        //             "shift_id": 14042,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 8,
        //             "Active": 6,
        //             "Training": 0,
        //             "business_unit": "Kroger",
        //             "category": 0,
        //             "name": "Kroger - Others",
        //             "shift_date": "2024-06-29",
        //             "shift_end_time": null,
        //             "shift_id": 15383,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 17,
        //             "Active": 35,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-06-29",
        //             "shift_end_time": null,
        //             "shift_id": 12630,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 8,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Active Engage",
        //             "category": 0,
        //             "name": "Active Engage - Others",
        //             "shift_date": "2024-06-29",
        //             "shift_end_time": null,
        //             "shift_id": 13357,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 10,
        //             "Training": 0,
        //             "business_unit": "GOALS",
        //             "category": 0,
        //             "name": "GOALS - Others",
        //             "shift_date": "2024-06-29",
        //             "shift_end_time": null,
        //             "shift_id": 13237,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-06-29",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 12600,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 7,
        //             "name": "Anyone Home - 10:00 AM to 08:00 PM",
        //             "shift_date": "2024-06-29",
        //             "shift_end_time": "20:00:00",
        //             "shift_id": 12585,
        //             "shift_start_time": "10:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-06-29",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 12610,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 115,
        //             "Active": 20,
        //             "Training": 0,
        //             "business_unit": "Walmart",
        //             "category": 0,
        //             "name": "Walmart - Others",
        //             "shift_date": "2024-06-30",
        //             "shift_end_time": null,
        //             "shift_id": 13933,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 5,
        //             "Active": 4,
        //             "Training": 0,
        //             "business_unit": "Kroger",
        //             "category": 0,
        //             "name": "Kroger - Others",
        //             "shift_date": "2024-06-30",
        //             "shift_end_time": null,
        //             "shift_id": 15218,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 14,
        //             "Active": 30,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-06-30",
        //             "shift_end_time": null,
        //             "shift_id": 12722,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 5,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Active Engage",
        //             "category": 0,
        //             "name": "Active Engage - Others",
        //             "shift_date": "2024-06-30",
        //             "shift_end_time": null,
        //             "shift_id": 13343,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-06-30",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 12647,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 6,
        //             "name": "Anyone Home - 09:00 AM to 07:00 PM",
        //             "shift_date": "2024-06-30",
        //             "shift_end_time": "19:00:00",
        //             "shift_id": 12657,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 4,
        //             "Training": 0,
        //             "business_unit": "ePremium",
        //             "category": 0,
        //             "name": "ePremium - Others",
        //             "shift_date": "2024-07-01",
        //             "shift_end_time": null,
        //             "shift_id": 15678,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "Tuners",
        //             "category": 0,
        //             "name": "Tuners - Others",
        //             "shift_date": "2024-07-01",
        //             "shift_end_time": null,
        //             "shift_id": 16886,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 9,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Active Engage",
        //             "category": 0,
        //             "name": "Active Engage - Others",
        //             "shift_date": "2024-07-01",
        //             "shift_end_time": null,
        //             "shift_id": 16209,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 22,
        //             "Training": 0,
        //             "business_unit": "GOALS",
        //             "category": 0,
        //             "name": "GOALS - Others",
        //             "shift_date": "2024-07-01",
        //             "shift_end_time": null,
        //             "shift_id": 15736,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 24,
        //             "Active": 25,
        //             "Training": 0,
        //             "business_unit": "Henry Schein",
        //             "category": 0,
        //             "name": "Henry Schein - Others",
        //             "shift_date": "2024-07-01",
        //             "shift_end_time": null,
        //             "shift_id": 15664,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 123,
        //             "Active": 19,
        //             "Training": 0,
        //             "business_unit": "Walmart",
        //             "category": 0,
        //             "name": "Walmart - Others",
        //             "shift_date": "2024-07-01",
        //             "shift_end_time": null,
        //             "shift_id": 16743,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 10,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Kroger",
        //             "category": 0,
        //             "name": "Kroger - Others",
        //             "shift_date": "2024-07-01",
        //             "shift_end_time": null,
        //             "shift_id": 16977,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 20,
        //             "Active": 37,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-07-01",
        //             "shift_end_time": null,
        //             "shift_id": 15452,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 5,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 3,
        //             "name": "MIA Aesthetics - 08:00 AM to 05:00 PM",
        //             "shift_date": "2024-07-01",
        //             "shift_end_time": "17:00:00",
        //             "shift_id": 16269,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 4,
        //             "name": "MIA Aesthetics - 09:00 AM to 06:00 PM",
        //             "shift_date": "2024-07-01",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 16254,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-07-01",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 17037,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 6,
        //             "name": "Anyone Home - 09:00 AM to 07:00 PM",
        //             "shift_date": "2024-07-01",
        //             "shift_end_time": "19:00:00",
        //             "shift_id": 15825,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 7,
        //             "name": "Anyone Home - 10:00 AM to 08:00 PM",
        //             "shift_date": "2024-07-01",
        //             "shift_end_time": "20:00:00",
        //             "shift_id": 17017,
        //             "shift_start_time": "10:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-07-01",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 17032,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 117,
        //             "Active": 23,
        //             "Training": 0,
        //             "business_unit": "Walmart",
        //             "category": 0,
        //             "name": "Walmart - Others",
        //             "shift_date": "2024-07-02",
        //             "shift_end_time": null,
        //             "shift_id": 16415,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 8,
        //             "Active": 11,
        //             "Training": 0,
        //             "business_unit": "Kroger",
        //             "category": 0,
        //             "name": "Kroger - Others",
        //             "shift_date": "2024-07-02",
        //             "shift_end_time": null,
        //             "shift_id": 16921,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 15,
        //             "Active": 39,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-07-02",
        //             "shift_end_time": null,
        //             "shift_id": 17023,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 4,
        //             "Training": 0,
        //             "business_unit": "ePremium",
        //             "category": 0,
        //             "name": "ePremium - Others",
        //             "shift_date": "2024-07-02",
        //             "shift_end_time": null,
        //             "shift_id": 15669,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "Tuners",
        //             "category": 0,
        //             "name": "Tuners - Others",
        //             "shift_date": "2024-07-02",
        //             "shift_end_time": null,
        //             "shift_id": 16887,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 10,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Active Engage",
        //             "category": 0,
        //             "name": "Active Engage - Others",
        //             "shift_date": "2024-07-02",
        //             "shift_end_time": null,
        //             "shift_id": 16195,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 21,
        //             "Training": 0,
        //             "business_unit": "GOALS",
        //             "category": 0,
        //             "name": "GOALS - Others",
        //             "shift_date": "2024-07-02",
        //             "shift_end_time": null,
        //             "shift_id": 15708,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 22,
        //             "Active": 27,
        //             "Training": 0,
        //             "business_unit": "Henry Schein",
        //             "category": 0,
        //             "name": "Henry Schein - Others",
        //             "shift_date": "2024-07-02",
        //             "shift_end_time": null,
        //             "shift_id": 15535,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 5,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 3,
        //             "name": "MIA Aesthetics - 08:00 AM to 05:00 PM",
        //             "shift_date": "2024-07-02",
        //             "shift_end_time": "17:00:00",
        //             "shift_id": 16245,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 4,
        //             "name": "MIA Aesthetics - 09:00 AM to 06:00 PM",
        //             "shift_date": "2024-07-02",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 16260,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-07-02",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 17027,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 6,
        //             "name": "Anyone Home - 09:00 AM to 07:00 PM",
        //             "shift_date": "2024-07-02",
        //             "shift_end_time": "19:00:00",
        //             "shift_id": 15826,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 7,
        //             "name": "Anyone Home - 10:00 AM to 08:00 PM",
        //             "shift_date": "2024-07-02",
        //             "shift_end_time": "20:00:00",
        //             "shift_id": 17018,
        //             "shift_start_time": "10:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-07-02",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 15876,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 16,
        //             "Active": 33,
        //             "Training": 0,
        //             "business_unit": "Henry Schein",
        //             "category": 0,
        //             "name": "Henry Schein - Others",
        //             "shift_date": "2024-07-03",
        //             "shift_end_time": null,
        //             "shift_id": 15652,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 114,
        //             "Active": 27,
        //             "Training": 0,
        //             "business_unit": "Walmart",
        //             "category": 0,
        //             "name": "Walmart - Others",
        //             "shift_date": "2024-07-03",
        //             "shift_end_time": null,
        //             "shift_id": 16561,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 9,
        //             "Active": 10,
        //             "Training": 0,
        //             "business_unit": "Kroger",
        //             "category": 0,
        //             "name": "Kroger - Others",
        //             "shift_date": "2024-07-03",
        //             "shift_end_time": null,
        //             "shift_id": 16934,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 18,
        //             "Active": 46,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-07-03",
        //             "shift_end_time": null,
        //             "shift_id": 16095,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 4,
        //             "Training": 0,
        //             "business_unit": "ePremium",
        //             "category": 0,
        //             "name": "ePremium - Others",
        //             "shift_date": "2024-07-03",
        //             "shift_end_time": null,
        //             "shift_id": 15680,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Tuners",
        //             "category": 0,
        //             "name": "Tuners - Others",
        //             "shift_date": "2024-07-03",
        //             "shift_end_time": null,
        //             "shift_id": 16898,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 9,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Active Engage",
        //             "category": 0,
        //             "name": "Active Engage - Others",
        //             "shift_date": "2024-07-03",
        //             "shift_end_time": null,
        //             "shift_id": 16186,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 21,
        //             "Training": 0,
        //             "business_unit": "GOALS",
        //             "category": 0,
        //             "name": "GOALS - Others",
        //             "shift_date": "2024-07-03",
        //             "shift_end_time": null,
        //             "shift_id": 15746,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 5,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 3,
        //             "name": "MIA Aesthetics - 08:00 AM to 05:00 PM",
        //             "shift_date": "2024-07-03",
        //             "shift_end_time": "17:00:00",
        //             "shift_id": 16246,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 4,
        //             "name": "MIA Aesthetics - 09:00 AM to 06:00 PM",
        //             "shift_date": "2024-07-03",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 16256,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-07-03",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 17028,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 6,
        //             "name": "Anyone Home - 09:00 AM to 07:00 PM",
        //             "shift_date": "2024-07-03",
        //             "shift_end_time": "19:00:00",
        //             "shift_id": 15832,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 7,
        //             "name": "Anyone Home - 10:00 AM to 08:00 PM",
        //             "shift_date": "2024-07-03",
        //             "shift_end_time": "20:00:00",
        //             "shift_id": 17019,
        //             "shift_start_time": "10:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-07-03",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 17034,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 4,
        //             "Active": 6,
        //             "Training": 0,
        //             "business_unit": "Henry Schein",
        //             "category": 0,
        //             "name": "Henry Schein - Others",
        //             "shift_date": "2024-07-04",
        //             "shift_end_time": null,
        //             "shift_id": 15469,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 74,
        //             "Active": 14,
        //             "Training": 0,
        //             "business_unit": "Walmart",
        //             "category": 0,
        //             "name": "Walmart - Others",
        //             "shift_date": "2024-07-04",
        //             "shift_end_time": null,
        //             "shift_id": 16875,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 14,
        //             "Active": 42,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-07-04",
        //             "shift_end_time": null,
        //             "shift_id": 16151,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 4,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "ePremium",
        //             "category": 0,
        //             "name": "ePremium - Others",
        //             "shift_date": "2024-07-04",
        //             "shift_end_time": null,
        //             "shift_id": 15681,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "Tuners",
        //             "category": 0,
        //             "name": "Tuners - Others",
        //             "shift_date": "2024-07-04",
        //             "shift_end_time": null,
        //             "shift_id": 16889,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 10,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Active Engage",
        //             "category": 0,
        //             "name": "Active Engage - Others",
        //             "shift_date": "2024-07-04",
        //             "shift_end_time": null,
        //             "shift_id": 16232,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 3,
        //             "name": "MIA Aesthetics - 08:00 AM to 05:00 PM",
        //             "shift_date": "2024-07-04",
        //             "shift_end_time": "17:00:00",
        //             "shift_id": 16282,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-07-04",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 17039,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 6,
        //             "name": "Anyone Home - 09:00 AM to 07:00 PM",
        //             "shift_date": "2024-07-04",
        //             "shift_end_time": "19:00:00",
        //             "shift_id": 15828,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 10,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Active Engage",
        //             "category": 0,
        //             "name": "Active Engage - Others",
        //             "shift_date": "2024-07-05",
        //             "shift_end_time": null,
        //             "shift_id": 16227,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 23,
        //             "Active": 26,
        //             "Training": 0,
        //             "business_unit": "Henry Schein",
        //             "category": 0,
        //             "name": "Henry Schein - Others",
        //             "shift_date": "2024-07-05",
        //             "shift_end_time": null,
        //             "shift_id": 15517,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 12,
        //             "Active": 7,
        //             "Training": 0,
        //             "business_unit": "Kroger",
        //             "category": 0,
        //             "name": "Kroger - Others",
        //             "shift_date": "2024-07-05",
        //             "shift_end_time": null,
        //             "shift_id": 16907,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 94,
        //             "Active": 21,
        //             "Training": 0,
        //             "business_unit": "Walmart",
        //             "category": 0,
        //             "name": "Walmart - Others",
        //             "shift_date": "2024-07-05",
        //             "shift_end_time": null,
        //             "shift_id": 17305,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 16,
        //             "Active": 40,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-07-05",
        //             "shift_end_time": null,
        //             "shift_id": 15852,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 20,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "GOALS",
        //             "category": 0,
        //             "name": "GOALS - Others",
        //             "shift_date": "2024-07-05",
        //             "shift_end_time": null,
        //             "shift_id": 15690,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 3,
        //             "Training": 0,
        //             "business_unit": "ePremium",
        //             "category": 0,
        //             "name": "ePremium - Others",
        //             "shift_date": "2024-07-05",
        //             "shift_end_time": null,
        //             "shift_id": 15687,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "Tuners",
        //             "category": 0,
        //             "name": "Tuners - Others",
        //             "shift_date": "2024-07-05",
        //             "shift_end_time": null,
        //             "shift_id": 16890,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 5,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 3,
        //             "name": "MIA Aesthetics - 08:00 AM to 05:00 PM",
        //             "shift_date": "2024-07-05",
        //             "shift_end_time": "17:00:00",
        //             "shift_id": 16283,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 4,
        //             "name": "MIA Aesthetics - 09:00 AM to 06:00 PM",
        //             "shift_date": "2024-07-05",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 16257,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-07-05",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 17040,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 7,
        //             "name": "Anyone Home - 10:00 AM to 08:00 PM",
        //             "shift_date": "2024-07-05",
        //             "shift_end_time": "20:00:00",
        //             "shift_id": 17020,
        //             "shift_start_time": "10:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-07-05",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 15878,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 19,
        //             "Active": 32,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-07-06",
        //             "shift_end_time": null,
        //             "shift_id": 15938,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 13,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "GOALS",
        //             "category": 0,
        //             "name": "GOALS - Others",
        //             "shift_date": "2024-07-06",
        //             "shift_end_time": null,
        //             "shift_id": 15731,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 7,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Active Engage",
        //             "category": 0,
        //             "name": "Active Engage - Others",
        //             "shift_date": "2024-07-06",
        //             "shift_end_time": null,
        //             "shift_id": 16223,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 10,
        //             "Active": 9,
        //             "Training": 0,
        //             "business_unit": "Kroger",
        //             "category": 0,
        //             "name": "Kroger - Others",
        //             "shift_date": "2024-07-06",
        //             "shift_end_time": null,
        //             "shift_id": 17006,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 98,
        //             "Active": 30,
        //             "Training": 0,
        //             "business_unit": "Walmart",
        //             "category": 0,
        //             "name": "Walmart - Others",
        //             "shift_date": "2024-07-06",
        //             "shift_end_time": null,
        //             "shift_id": 16587,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 4,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 3,
        //             "name": "MIA Aesthetics - 08:00 AM to 05:00 PM",
        //             "shift_date": "2024-07-06",
        //             "shift_end_time": "17:00:00",
        //             "shift_id": 16278,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 4,
        //             "name": "MIA Aesthetics - 09:00 AM to 06:00 PM",
        //             "shift_date": "2024-07-06",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 16258,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-07-06",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 17031,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 7,
        //             "name": "Anyone Home - 10:00 AM to 08:00 PM",
        //             "shift_date": "2024-07-06",
        //             "shift_end_time": "20:00:00",
        //             "shift_id": 17021,
        //             "shift_start_time": "10:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-07-06",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 15879,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 136,
        //             "Active": 18,
        //             "Training": 0,
        //             "business_unit": "Walmart",
        //             "category": 0,
        //             "name": "Walmart - Others",
        //             "shift_date": "2024-07-07",
        //             "shift_end_time": null,
        //             "shift_id": 16383,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 14,
        //             "Active": 35,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-07-07",
        //             "shift_end_time": null,
        //             "shift_id": 15839,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 5,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Active Engage",
        //             "category": 0,
        //             "name": "Active Engage - Others",
        //             "shift_date": "2024-07-07",
        //             "shift_end_time": null,
        //             "shift_id": 16238,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 6,
        //             "name": "Anyone Home - 09:00 AM to 07:00 PM",
        //             "shift_date": "2024-07-07",
        //             "shift_end_time": "19:00:00",
        //             "shift_id": 15834,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 4,
        //             "Active": 5,
        //             "Training": 0,
        //             "business_unit": "Kroger",
        //             "category": 0,
        //             "name": "Kroger - Others",
        //             "shift_date": "2024-07-08",
        //             "shift_end_time": null,
        //             "shift_id": 18457,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 4,
        //             "Training": 0,
        //             "business_unit": "ePremium",
        //             "category": 0,
        //             "name": "ePremium - Others",
        //             "shift_date": "2024-07-08",
        //             "shift_end_time": null,
        //             "shift_id": 17721,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 3,
        //             "Training": 0,
        //             "business_unit": "Tuners",
        //             "category": 0,
        //             "name": "Tuners - Others",
        //             "shift_date": "2024-07-08",
        //             "shift_end_time": null,
        //             "shift_id": 18477,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 4,
        //             "Active": 19,
        //             "Training": 0,
        //             "business_unit": "GOALS",
        //             "category": 0,
        //             "name": "GOALS - Others",
        //             "shift_date": "2024-07-08",
        //             "shift_end_time": null,
        //             "shift_id": 17992,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 119,
        //             "Active": 15,
        //             "Training": 0,
        //             "business_unit": "Walmart",
        //             "category": 0,
        //             "name": "Walmart - Others",
        //             "shift_date": "2024-07-08",
        //             "shift_end_time": null,
        //             "shift_id": 18287,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 37,
        //             "Active": 25,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-07-08",
        //             "shift_end_time": null,
        //             "shift_id": 18667,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 22,
        //             "Active": 26,
        //             "Training": 0,
        //             "business_unit": "Henry Schein",
        //             "category": 0,
        //             "name": "Henry Schein - Others",
        //             "shift_date": "2024-07-08",
        //             "shift_end_time": null,
        //             "shift_id": 17466,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 9,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Active Engage",
        //             "category": 0,
        //             "name": "Active Engage - Others",
        //             "shift_date": "2024-07-08",
        //             "shift_end_time": null,
        //             "shift_id": 18512,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 5,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Office Depot",
        //             "category": 0,
        //             "name": "Office Depot - Others",
        //             "shift_date": "2024-07-08",
        //             "shift_end_time": null,
        //             "shift_id": 20419,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Workforce Management and Planning",
        //             "category": 0,
        //             "name": "Workforce Management and Planning - Others",
        //             "shift_date": "2024-07-08",
        //             "shift_end_time": null,
        //             "shift_id": 19230,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 6,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "IT Infrastructure",
        //             "category": 0,
        //             "name": "IT Infrastructure - Others",
        //             "shift_date": "2024-07-08",
        //             "shift_end_time": null,
        //             "shift_id": 20874,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 4,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 3,
        //             "name": "MIA Aesthetics - 08:00 AM to 05:00 PM",
        //             "shift_date": "2024-07-08",
        //             "shift_end_time": "17:00:00",
        //             "shift_id": 18367,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 4,
        //             "name": "MIA Aesthetics - 09:00 AM to 06:00 PM",
        //             "shift_date": "2024-07-08",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 18362,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-07-08",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 17421,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 6,
        //             "name": "Anyone Home - 09:00 AM to 07:00 PM",
        //             "shift_date": "2024-07-08",
        //             "shift_end_time": "19:00:00",
        //             "shift_id": 18804,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 7,
        //             "name": "Anyone Home - 10:00 AM to 08:00 PM",
        //             "shift_date": "2024-07-08",
        //             "shift_end_time": "20:00:00",
        //             "shift_id": 17396,
        //             "shift_start_time": "10:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-07-08",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 17411,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 6,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Office Depot",
        //             "category": 0,
        //             "name": "Office Depot - Others",
        //             "shift_date": "2024-07-09",
        //             "shift_end_time": null,
        //             "shift_id": 20415,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Workforce Management and Planning",
        //             "category": 0,
        //             "name": "Workforce Management and Planning - Others",
        //             "shift_date": "2024-07-09",
        //             "shift_end_time": null,
        //             "shift_id": 19231,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 6,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "IT Infrastructure",
        //             "category": 0,
        //             "name": "IT Infrastructure - Others",
        //             "shift_date": "2024-07-09",
        //             "shift_end_time": null,
        //             "shift_id": 20879,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 14,
        //             "Active": 6,
        //             "Training": 0,
        //             "business_unit": "Kroger",
        //             "category": 0,
        //             "name": "Kroger - Others",
        //             "shift_date": "2024-07-09",
        //             "shift_end_time": null,
        //             "shift_id": 18450,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 4,
        //             "Training": 0,
        //             "business_unit": "ePremium",
        //             "category": 0,
        //             "name": "ePremium - Others",
        //             "shift_date": "2024-07-09",
        //             "shift_end_time": null,
        //             "shift_id": 17707,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 3,
        //             "Training": 0,
        //             "business_unit": "Tuners",
        //             "category": 0,
        //             "name": "Tuners - Others",
        //             "shift_date": "2024-07-09",
        //             "shift_end_time": null,
        //             "shift_id": 18478,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 21,
        //             "Training": 0,
        //             "business_unit": "GOALS",
        //             "category": 0,
        //             "name": "GOALS - Others",
        //             "shift_date": "2024-07-09",
        //             "shift_end_time": null,
        //             "shift_id": 17902,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 103,
        //             "Active": 18,
        //             "Training": 0,
        //             "business_unit": "Walmart",
        //             "category": 0,
        //             "name": "Walmart - Others",
        //             "shift_date": "2024-07-09",
        //             "shift_end_time": null,
        //             "shift_id": 18138,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 23,
        //             "Active": 35,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-07-09",
        //             "shift_end_time": null,
        //             "shift_id": 18735,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 20,
        //             "Active": 28,
        //             "Training": 0,
        //             "business_unit": "Henry Schein",
        //             "category": 0,
        //             "name": "Henry Schein - Others",
        //             "shift_date": "2024-07-09",
        //             "shift_end_time": null,
        //             "shift_id": 17557,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 10,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Active Engage",
        //             "category": 0,
        //             "name": "Active Engage - Others",
        //             "shift_date": "2024-07-09",
        //             "shift_end_time": null,
        //             "shift_id": 18503,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 4,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 3,
        //             "name": "MIA Aesthetics - 08:00 AM to 05:00 PM",
        //             "shift_date": "2024-07-09",
        //             "shift_end_time": "17:00:00",
        //             "shift_id": 18383,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 4,
        //             "name": "MIA Aesthetics - 09:00 AM to 06:00 PM",
        //             "shift_date": "2024-07-09",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 18388,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-07-09",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 17406,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 6,
        //             "name": "Anyone Home - 09:00 AM to 07:00 PM",
        //             "shift_date": "2024-07-09",
        //             "shift_end_time": "19:00:00",
        //             "shift_id": 18805,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 7,
        //             "name": "Anyone Home - 10:00 AM to 08:00 PM",
        //             "shift_date": "2024-07-09",
        //             "shift_end_time": "20:00:00",
        //             "shift_id": 17397,
        //             "shift_start_time": "10:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-07-09",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 17427,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 12,
        //             "Active": 36,
        //             "Training": 0,
        //             "business_unit": "Henry Schein",
        //             "category": 0,
        //             "name": "Henry Schein - Others",
        //             "shift_date": "2024-07-10",
        //             "shift_end_time": null,
        //             "shift_id": 17688,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 9,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Active Engage",
        //             "category": 0,
        //             "name": "Active Engage - Others",
        //             "shift_date": "2024-07-10",
        //             "shift_end_time": null,
        //             "shift_id": 18494,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 6,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Office Depot",
        //             "category": 0,
        //             "name": "Office Depot - Others",
        //             "shift_date": "2024-07-10",
        //             "shift_end_time": null,
        //             "shift_id": 20410,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Workforce Management and Planning",
        //             "category": 0,
        //             "name": "Workforce Management and Planning - Others",
        //             "shift_date": "2024-07-10",
        //             "shift_end_time": null,
        //             "shift_id": 19232,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 7,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "IT Infrastructure",
        //             "category": 0,
        //             "name": "IT Infrastructure - Others",
        //             "shift_date": "2024-07-10",
        //             "shift_end_time": null,
        //             "shift_id": 20896,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 7,
        //             "Active": 4,
        //             "Training": 0,
        //             "business_unit": "Kroger",
        //             "category": 0,
        //             "name": "Kroger - Others",
        //             "shift_date": "2024-07-10",
        //             "shift_end_time": null,
        //             "shift_id": 18426,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 4,
        //             "Training": 0,
        //             "business_unit": "ePremium",
        //             "category": 0,
        //             "name": "ePremium - Others",
        //             "shift_date": "2024-07-10",
        //             "shift_end_time": null,
        //             "shift_id": 17708,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 3,
        //             "Training": 0,
        //             "business_unit": "Tuners",
        //             "category": 0,
        //             "name": "Tuners - Others",
        //             "shift_date": "2024-07-10",
        //             "shift_end_time": null,
        //             "shift_id": 18479,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 20,
        //             "Training": 0,
        //             "business_unit": "GOALS",
        //             "category": 0,
        //             "name": "GOALS - Others",
        //             "shift_date": "2024-07-10",
        //             "shift_end_time": null,
        //             "shift_id": 17968,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 105,
        //             "Active": 21,
        //             "Training": 0,
        //             "business_unit": "Walmart",
        //             "category": 0,
        //             "name": "Walmart - Others",
        //             "shift_date": "2024-07-10",
        //             "shift_end_time": null,
        //             "shift_id": 19140,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 24,
        //             "Active": 45,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-07-10",
        //             "shift_end_time": null,
        //             "shift_id": 18837,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 4,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 3,
        //             "name": "MIA Aesthetics - 08:00 AM to 05:00 PM",
        //             "shift_date": "2024-07-10",
        //             "shift_end_time": "17:00:00",
        //             "shift_id": 18374,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 4,
        //             "name": "MIA Aesthetics - 09:00 AM to 06:00 PM",
        //             "shift_date": "2024-07-10",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 18389,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-07-10",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 17407,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 6,
        //             "name": "Anyone Home - 09:00 AM to 07:00 PM",
        //             "shift_date": "2024-07-10",
        //             "shift_end_time": "19:00:00",
        //             "shift_id": 18806,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 7,
        //             "name": "Anyone Home - 10:00 AM to 08:00 PM",
        //             "shift_date": "2024-07-10",
        //             "shift_end_time": "20:00:00",
        //             "shift_id": 17398,
        //             "shift_start_time": "10:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-07-10",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 17428,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 104,
        //             "Active": 13,
        //             "Training": 0,
        //             "business_unit": "Walmart",
        //             "category": 0,
        //             "name": "Walmart - Others",
        //             "shift_date": "2024-07-11",
        //             "shift_end_time": null,
        //             "shift_id": 18339,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 19,
        //             "Active": 47,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-07-11",
        //             "shift_end_time": null,
        //             "shift_id": 18559,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 14,
        //             "Active": 35,
        //             "Training": 0,
        //             "business_unit": "Henry Schein",
        //             "category": 0,
        //             "name": "Henry Schein - Others",
        //             "shift_date": "2024-07-11",
        //             "shift_end_time": null,
        //             "shift_id": 17604,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 10,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Active Engage",
        //             "category": 0,
        //             "name": "Active Engage - Others",
        //             "shift_date": "2024-07-11",
        //             "shift_end_time": null,
        //             "shift_id": 18534,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 6,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Office Depot",
        //             "category": 0,
        //             "name": "Office Depot - Others",
        //             "shift_date": "2024-07-11",
        //             "shift_end_time": null,
        //             "shift_id": 20411,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Workforce Management and Planning",
        //             "category": 0,
        //             "name": "Workforce Management and Planning - Others",
        //             "shift_date": "2024-07-11",
        //             "shift_end_time": null,
        //             "shift_id": 19233,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 6,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "IT Infrastructure",
        //             "category": 0,
        //             "name": "IT Infrastructure - Others",
        //             "shift_date": "2024-07-11",
        //             "shift_end_time": null,
        //             "shift_id": 20883,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 6,
        //             "Active": 3,
        //             "Training": 0,
        //             "business_unit": "Kroger",
        //             "category": 0,
        //             "name": "Kroger - Others",
        //             "shift_date": "2024-07-11",
        //             "shift_end_time": null,
        //             "shift_id": 18447,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 4,
        //             "Training": 0,
        //             "business_unit": "ePremium",
        //             "category": 0,
        //             "name": "ePremium - Others",
        //             "shift_date": "2024-07-11",
        //             "shift_end_time": null,
        //             "shift_id": 17724,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 3,
        //             "Training": 0,
        //             "business_unit": "Tuners",
        //             "category": 0,
        //             "name": "Tuners - Others",
        //             "shift_date": "2024-07-11",
        //             "shift_end_time": null,
        //             "shift_id": 18485,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 20,
        //             "Training": 0,
        //             "business_unit": "GOALS",
        //             "category": 0,
        //             "name": "GOALS - Others",
        //             "shift_date": "2024-07-11",
        //             "shift_end_time": null,
        //             "shift_id": 18015,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 4,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 3,
        //             "name": "MIA Aesthetics - 08:00 AM to 05:00 PM",
        //             "shift_date": "2024-07-11",
        //             "shift_end_time": "17:00:00",
        //             "shift_id": 18375,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 4,
        //             "name": "MIA Aesthetics - 09:00 AM to 06:00 PM",
        //             "shift_date": "2024-07-11",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 18365,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-07-11",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 17423,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 6,
        //             "name": "Anyone Home - 09:00 AM to 07:00 PM",
        //             "shift_date": "2024-07-11",
        //             "shift_end_time": "19:00:00",
        //             "shift_id": 18807,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 3,
        //             "Training": 0,
        //             "business_unit": "ePremium",
        //             "category": 0,
        //             "name": "ePremium - Others",
        //             "shift_date": "2024-07-12",
        //             "shift_end_time": null,
        //             "shift_id": 17720,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "Tuners",
        //             "category": 0,
        //             "name": "Tuners - Others",
        //             "shift_date": "2024-07-12",
        //             "shift_end_time": null,
        //             "shift_id": 18481,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 21,
        //             "Training": 0,
        //             "business_unit": "GOALS",
        //             "category": 0,
        //             "name": "GOALS - Others",
        //             "shift_date": "2024-07-12",
        //             "shift_end_time": null,
        //             "shift_id": 17980,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 85,
        //             "Active": 19,
        //             "Training": 0,
        //             "business_unit": "Walmart",
        //             "category": 0,
        //             "name": "Walmart - Others",
        //             "shift_date": "2024-07-12",
        //             "shift_end_time": null,
        //             "shift_id": 17744,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 23,
        //             "Active": 42,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-07-12",
        //             "shift_end_time": null,
        //             "shift_id": 18635,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "DSI",
        //             "category": 0,
        //             "name": "DSI - Others",
        //             "shift_date": "2024-07-12",
        //             "shift_end_time": null,
        //             "shift_id": 19990,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 10,
        //             "Active": 39,
        //             "Training": 0,
        //             "business_unit": "Henry Schein",
        //             "category": 0,
        //             "name": "Henry Schein - Others",
        //             "shift_date": "2024-07-12",
        //             "shift_end_time": null,
        //             "shift_id": 17505,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 10,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Active Engage",
        //             "category": 0,
        //             "name": "Active Engage - Others",
        //             "shift_date": "2024-07-12",
        //             "shift_end_time": null,
        //             "shift_id": 18499,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 5,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Office Depot",
        //             "category": 0,
        //             "name": "Office Depot - Others",
        //             "shift_date": "2024-07-12",
        //             "shift_end_time": null,
        //             "shift_id": 20433,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Workforce Management and Planning",
        //             "category": 0,
        //             "name": "Workforce Management and Planning - Others",
        //             "shift_date": "2024-07-12",
        //             "shift_end_time": null,
        //             "shift_id": 19234,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 6,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "IT Infrastructure",
        //             "category": 0,
        //             "name": "IT Infrastructure - Others",
        //             "shift_date": "2024-07-12",
        //             "shift_end_time": null,
        //             "shift_id": 20892,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 6,
        //             "Active": 5,
        //             "Training": 0,
        //             "business_unit": "Kroger",
        //             "category": 0,
        //             "name": "Kroger - Others",
        //             "shift_date": "2024-07-12",
        //             "shift_end_time": null,
        //             "shift_id": 18411,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 3,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 3,
        //             "name": "MIA Aesthetics - 08:00 AM to 05:00 PM",
        //             "shift_date": "2024-07-12",
        //             "shift_end_time": "17:00:00",
        //             "shift_id": 18376,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 4,
        //             "name": "MIA Aesthetics - 09:00 AM to 06:00 PM",
        //             "shift_date": "2024-07-12",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 18391,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-07-12",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 17409,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 7,
        //             "name": "Anyone Home - 10:00 AM to 08:00 PM",
        //             "shift_date": "2024-07-12",
        //             "shift_end_time": "20:00:00",
        //             "shift_id": 17399,
        //             "shift_start_time": "10:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-07-12",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 17414,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "IT Infrastructure",
        //             "category": 0,
        //             "name": "IT Infrastructure - Others",
        //             "shift_date": "2024-07-13",
        //             "shift_end_time": null,
        //             "shift_id": 20887,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 10,
        //             "Active": 10,
        //             "Training": 0,
        //             "business_unit": "Kroger",
        //             "category": 0,
        //             "name": "Kroger - Others",
        //             "shift_date": "2024-07-13",
        //             "shift_end_time": null,
        //             "shift_id": 18400,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 13,
        //             "Training": 0,
        //             "business_unit": "GOALS",
        //             "category": 0,
        //             "name": "GOALS - Others",
        //             "shift_date": "2024-07-13",
        //             "shift_end_time": null,
        //             "shift_id": 17965,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 77,
        //             "Active": 18,
        //             "Training": 0,
        //             "business_unit": "Walmart",
        //             "category": 0,
        //             "name": "Walmart - Others",
        //             "shift_date": "2024-07-13",
        //             "shift_end_time": null,
        //             "shift_id": 18170,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 14,
        //             "Active": 43,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-07-13",
        //             "shift_end_time": null,
        //             "shift_id": 18616,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 6,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Active Engage",
        //             "category": 0,
        //             "name": "Active Engage - Others",
        //             "shift_date": "2024-07-13",
        //             "shift_end_time": null,
        //             "shift_id": 18500,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Office Depot",
        //             "category": 0,
        //             "name": "Office Depot - Others",
        //             "shift_date": "2024-07-13",
        //             "shift_end_time": null,
        //             "shift_id": 20413,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-07-13",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 17410,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 7,
        //             "name": "Anyone Home - 10:00 AM to 08:00 PM",
        //             "shift_date": "2024-07-13",
        //             "shift_end_time": "20:00:00",
        //             "shift_id": 17400,
        //             "shift_start_time": "10:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-07-13",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 17415,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 5,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Active Engage",
        //             "category": 0,
        //             "name": "Active Engage - Others",
        //             "shift_date": "2024-07-14",
        //             "shift_end_time": null,
        //             "shift_id": 18516,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Office Depot",
        //             "category": 0,
        //             "name": "Office Depot - Others",
        //             "shift_date": "2024-07-14",
        //             "shift_end_time": null,
        //             "shift_id": 20418,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "IT Infrastructure",
        //             "category": 0,
        //             "name": "IT Infrastructure - Others",
        //             "shift_date": "2024-07-14",
        //             "shift_end_time": null,
        //             "shift_id": 20878,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 70,
        //             "Active": 12,
        //             "Training": 0,
        //             "business_unit": "Walmart",
        //             "category": 0,
        //             "name": "Walmart - Others",
        //             "shift_date": "2024-07-14",
        //             "shift_end_time": null,
        //             "shift_id": 18999,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 17,
        //             "Active": 39,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-07-14",
        //             "shift_end_time": null,
        //             "shift_id": 18854,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 24,
        //             "Active": 42,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-07-15",
        //             "shift_end_time": null,
        //             "shift_id": 19759,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 3,
        //             "Training": 0,
        //             "business_unit": "ePremium",
        //             "category": 0,
        //             "name": "ePremium - Others",
        //             "shift_date": "2024-07-15",
        //             "shift_end_time": null,
        //             "shift_id": 20689,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 3,
        //             "Training": 0,
        //             "business_unit": "Tuners",
        //             "category": 0,
        //             "name": "Tuners - Others",
        //             "shift_date": "2024-07-15",
        //             "shift_end_time": null,
        //             "shift_id": 21769,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 8,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Active Engage",
        //             "category": 0,
        //             "name": "Active Engage - Others",
        //             "shift_date": "2024-07-15",
        //             "shift_end_time": null,
        //             "shift_id": 19980,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 7,
        //             "Active": 12,
        //             "Training": 0,
        //             "business_unit": "Kroger",
        //             "category": 0,
        //             "name": "Kroger - Others",
        //             "shift_date": "2024-07-15",
        //             "shift_end_time": null,
        //             "shift_id": 20749,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 62,
        //             "Active": 22,
        //             "Training": 0,
        //             "business_unit": "Office Depot",
        //             "category": 0,
        //             "name": "Office Depot - Others",
        //             "shift_date": "2024-07-15",
        //             "shift_end_time": null,
        //             "shift_id": 20073,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 15,
        //             "Active": 6,
        //             "Training": 0,
        //             "business_unit": "IT Infrastructure",
        //             "category": 0,
        //             "name": "IT Infrastructure - Others",
        //             "shift_date": "2024-07-15",
        //             "shift_end_time": null,
        //             "shift_id": 22758,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 24,
        //             "Training": 0,
        //             "business_unit": "GOALS",
        //             "category": 0,
        //             "name": "GOALS - Others",
        //             "shift_date": "2024-07-15",
        //             "shift_end_time": null,
        //             "shift_id": 19824,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 8,
        //             "Active": 40,
        //             "Training": 0,
        //             "business_unit": "Henry Schein",
        //             "category": 0,
        //             "name": "Henry Schein - Others",
        //             "shift_date": "2024-07-15",
        //             "shift_end_time": null,
        //             "shift_id": 20624,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 123,
        //             "Active": 53,
        //             "Training": 0,
        //             "business_unit": "Walmart",
        //             "category": 0,
        //             "name": "Walmart - Others",
        //             "shift_date": "2024-07-15",
        //             "shift_end_time": null,
        //             "shift_id": 22134,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 3,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 3,
        //             "name": "MIA Aesthetics - 08:00 AM to 05:00 PM",
        //             "shift_date": "2024-07-15",
        //             "shift_end_time": "17:00:00",
        //             "shift_id": 20839,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 4,
        //             "name": "MIA Aesthetics - 09:00 AM to 06:00 PM",
        //             "shift_date": "2024-07-15",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 20849,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-07-15",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 19389,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 6,
        //             "name": "Anyone Home - 09:00 AM to 07:00 PM",
        //             "shift_date": "2024-07-15",
        //             "shift_end_time": "19:00:00",
        //             "shift_id": 19324,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 7,
        //             "name": "Anyone Home - 10:00 AM to 08:00 PM",
        //             "shift_date": "2024-07-15",
        //             "shift_end_time": "20:00:00",
        //             "shift_id": 19267,
        //             "shift_start_time": "10:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-07-15",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 19287,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 100,
        //             "Active": 77,
        //             "Training": 0,
        //             "business_unit": "Walmart",
        //             "category": 0,
        //             "name": "Walmart - Others",
        //             "shift_date": "2024-07-16",
        //             "shift_end_time": null,
        //             "shift_id": 21634,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 19,
        //             "Active": 44,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-07-16",
        //             "shift_end_time": null,
        //             "shift_id": 19594,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 4,
        //             "Training": 0,
        //             "business_unit": "ePremium",
        //             "category": 0,
        //             "name": "ePremium - Others",
        //             "shift_date": "2024-07-16",
        //             "shift_end_time": null,
        //             "shift_id": 20685,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 3,
        //             "Training": 0,
        //             "business_unit": "Tuners",
        //             "category": 0,
        //             "name": "Tuners - Others",
        //             "shift_date": "2024-07-16",
        //             "shift_end_time": null,
        //             "shift_id": 21770,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 8,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Active Engage",
        //             "category": 0,
        //             "name": "Active Engage - Others",
        //             "shift_date": "2024-07-16",
        //             "shift_end_time": null,
        //             "shift_id": 19976,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 11,
        //             "Active": 8,
        //             "Training": 0,
        //             "business_unit": "Kroger",
        //             "category": 0,
        //             "name": "Kroger - Others",
        //             "shift_date": "2024-07-16",
        //             "shift_end_time": null,
        //             "shift_id": 20770,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 40,
        //             "Active": 47,
        //             "Training": 0,
        //             "business_unit": "Office Depot",
        //             "category": 0,
        //             "name": "Office Depot - Others",
        //             "shift_date": "2024-07-16",
        //             "shift_end_time": null,
        //             "shift_id": 20355,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 14,
        //             "Active": 8,
        //             "Training": 0,
        //             "business_unit": "IT Infrastructure",
        //             "category": 0,
        //             "name": "IT Infrastructure - Others",
        //             "shift_date": "2024-07-16",
        //             "shift_end_time": null,
        //             "shift_id": 24138,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 23,
        //             "Training": 0,
        //             "business_unit": "GOALS",
        //             "category": 0,
        //             "name": "GOALS - Others",
        //             "shift_date": "2024-07-16",
        //             "shift_end_time": null,
        //             "shift_id": 19810,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 6,
        //             "Active": 42,
        //             "Training": 0,
        //             "business_unit": "Henry Schein",
        //             "category": 0,
        //             "name": "Henry Schein - Others",
        //             "shift_date": "2024-07-16",
        //             "shift_end_time": null,
        //             "shift_id": 20550,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 4,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 3,
        //             "name": "MIA Aesthetics - 08:00 AM to 05:00 PM",
        //             "shift_date": "2024-07-16",
        //             "shift_end_time": "17:00:00",
        //             "shift_id": 20860,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 4,
        //             "name": "MIA Aesthetics - 09:00 AM to 06:00 PM",
        //             "shift_date": "2024-07-16",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 20855,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-07-16",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 19390,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 6,
        //             "name": "Anyone Home - 09:00 AM to 07:00 PM",
        //             "shift_date": "2024-07-16",
        //             "shift_end_time": "19:00:00",
        //             "shift_id": 19325,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 7,
        //             "name": "Anyone Home - 10:00 AM to 08:00 PM",
        //             "shift_date": "2024-07-16",
        //             "shift_end_time": "20:00:00",
        //             "shift_id": 19268,
        //             "shift_start_time": "10:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-07-16",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 19400,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 23,
        //             "Training": 0,
        //             "business_unit": "GOALS",
        //             "category": 0,
        //             "name": "GOALS - Others",
        //             "shift_date": "2024-07-17",
        //             "shift_end_time": null,
        //             "shift_id": 19835,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 9,
        //             "Active": 39,
        //             "Training": 0,
        //             "business_unit": "Henry Schein",
        //             "category": 0,
        //             "name": "Henry Schein - Others",
        //             "shift_date": "2024-07-17",
        //             "shift_end_time": null,
        //             "shift_id": 20611,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 123,
        //             "Active": 54,
        //             "Training": 0,
        //             "business_unit": "Walmart",
        //             "category": 0,
        //             "name": "Walmart - Others",
        //             "shift_date": "2024-07-17",
        //             "shift_end_time": null,
        //             "shift_id": 21596,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 33,
        //             "Active": 39,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-07-17",
        //             "shift_end_time": null,
        //             "shift_id": 19711,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 3,
        //             "Training": 0,
        //             "business_unit": "ePremium",
        //             "category": 0,
        //             "name": "ePremium - Others",
        //             "shift_date": "2024-07-17",
        //             "shift_end_time": null,
        //             "shift_id": 20691,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Tuners",
        //             "category": 0,
        //             "name": "Tuners - Others",
        //             "shift_date": "2024-07-17",
        //             "shift_end_time": null,
        //             "shift_id": 21776,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "DSI",
        //             "category": 0,
        //             "name": "DSI - Others",
        //             "shift_date": "2024-07-17",
        //             "shift_end_time": null,
        //             "shift_id": 22227,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 9,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Active Engage",
        //             "category": 0,
        //             "name": "Active Engage - Others",
        //             "shift_date": "2024-07-17",
        //             "shift_end_time": null,
        //             "shift_id": 19971,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 14,
        //             "Active": 5,
        //             "Training": 0,
        //             "business_unit": "Kroger",
        //             "category": 0,
        //             "name": "Kroger - Others",
        //             "shift_date": "2024-07-17",
        //             "shift_end_time": null,
        //             "shift_id": 20736,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 68,
        //             "Active": 17,
        //             "Training": 0,
        //             "business_unit": "Office Depot",
        //             "category": 0,
        //             "name": "Office Depot - Others",
        //             "shift_date": "2024-07-17",
        //             "shift_end_time": null,
        //             "shift_id": 20160,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 19,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "IT Infrastructure",
        //             "category": 0,
        //             "name": "IT Infrastructure - Others",
        //             "shift_date": "2024-07-17",
        //             "shift_end_time": null,
        //             "shift_id": 20816,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 3,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 3,
        //             "name": "MIA Aesthetics - 08:00 AM to 05:00 PM",
        //             "shift_date": "2024-07-17",
        //             "shift_end_time": "17:00:00",
        //             "shift_id": 20841,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 4,
        //             "name": "MIA Aesthetics - 09:00 AM to 06:00 PM",
        //             "shift_date": "2024-07-17",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 20856,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-07-17",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 19283,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 6,
        //             "name": "Anyone Home - 09:00 AM to 07:00 PM",
        //             "shift_date": "2024-07-17",
        //             "shift_end_time": "19:00:00",
        //             "shift_id": 19346,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 7,
        //             "name": "Anyone Home - 10:00 AM to 08:00 PM",
        //             "shift_date": "2024-07-17",
        //             "shift_end_time": "20:00:00",
        //             "shift_id": 19269,
        //             "shift_start_time": "10:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-07-17",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 19289,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 59,
        //             "Active": 24,
        //             "Training": 0,
        //             "business_unit": "Office Depot",
        //             "category": 0,
        //             "name": "Office Depot - Others",
        //             "shift_date": "2024-07-18",
        //             "shift_end_time": null,
        //             "shift_id": 20110,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 9,
        //             "Active": 13,
        //             "Training": 0,
        //             "business_unit": "IT Infrastructure",
        //             "category": 0,
        //             "name": "IT Infrastructure - Others",
        //             "shift_date": "2024-07-18",
        //             "shift_end_time": null,
        //             "shift_id": 22221,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 22,
        //             "Training": 0,
        //             "business_unit": "GOALS",
        //             "category": 0,
        //             "name": "GOALS - Others",
        //             "shift_date": "2024-07-18",
        //             "shift_end_time": null,
        //             "shift_id": 19807,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 5,
        //             "Active": 43,
        //             "Training": 0,
        //             "business_unit": "Henry Schein",
        //             "category": 0,
        //             "name": "Henry Schein - Others",
        //             "shift_date": "2024-07-18",
        //             "shift_end_time": null,
        //             "shift_id": 20512,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 105,
        //             "Active": 69,
        //             "Training": 0,
        //             "business_unit": "Walmart",
        //             "category": 0,
        //             "name": "Walmart - Others",
        //             "shift_date": "2024-07-18",
        //             "shift_end_time": null,
        //             "shift_id": 21457,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 19,
        //             "Active": 52,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-07-18",
        //             "shift_end_time": null,
        //             "shift_id": 19300,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 4,
        //             "Training": 0,
        //             "business_unit": "ePremium",
        //             "category": 0,
        //             "name": "ePremium - Others",
        //             "shift_date": "2024-07-18",
        //             "shift_end_time": null,
        //             "shift_id": 20687,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 3,
        //             "Training": 0,
        //             "business_unit": "Tuners",
        //             "category": 0,
        //             "name": "Tuners - Others",
        //             "shift_date": "2024-07-18",
        //             "shift_end_time": null,
        //             "shift_id": 21772,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "DSI",
        //             "category": 0,
        //             "name": "DSI - Others",
        //             "shift_date": "2024-07-18",
        //             "shift_end_time": null,
        //             "shift_id": 22189,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 10,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Active Engage",
        //             "category": 0,
        //             "name": "Active Engage - Others",
        //             "shift_date": "2024-07-18",
        //             "shift_end_time": null,
        //             "shift_id": 19967,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 3,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 3,
        //             "name": "MIA Aesthetics - 08:00 AM to 05:00 PM",
        //             "shift_date": "2024-07-18",
        //             "shift_end_time": "17:00:00",
        //             "shift_id": 20862,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 4,
        //             "name": "MIA Aesthetics - 09:00 AM to 06:00 PM",
        //             "shift_date": "2024-07-18",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 20852,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-07-18",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 19284,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 6,
        //             "name": "Anyone Home - 09:00 AM to 07:00 PM",
        //             "shift_date": "2024-07-18",
        //             "shift_end_time": "19:00:00",
        //             "shift_id": 19347,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 4,
        //             "Training": 0,
        //             "business_unit": "ePremium",
        //             "category": 0,
        //             "name": "ePremium - Others",
        //             "shift_date": "2024-07-19",
        //             "shift_end_time": null,
        //             "shift_id": 20693,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 3,
        //             "Training": 0,
        //             "business_unit": "Tuners",
        //             "category": 0,
        //             "name": "Tuners - Others",
        //             "shift_date": "2024-07-19",
        //             "shift_end_time": null,
        //             "shift_id": 21773,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "DSI",
        //             "category": 0,
        //             "name": "DSI - Others",
        //             "shift_date": "2024-07-19",
        //             "shift_end_time": null,
        //             "shift_id": 22228,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 10,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Active Engage",
        //             "category": 0,
        //             "name": "Active Engage - Others",
        //             "shift_date": "2024-07-19",
        //             "shift_end_time": null,
        //             "shift_id": 19944,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 51,
        //             "Active": 25,
        //             "Training": 0,
        //             "business_unit": "Office Depot",
        //             "category": 0,
        //             "name": "Office Depot - Others",
        //             "shift_date": "2024-07-19",
        //             "shift_end_time": null,
        //             "shift_id": 20378,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 8,
        //             "Active": 13,
        //             "Training": 0,
        //             "business_unit": "IT Infrastructure",
        //             "category": 0,
        //             "name": "IT Infrastructure - Others",
        //             "shift_date": "2024-07-19",
        //             "shift_end_time": null,
        //             "shift_id": 22781,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 20,
        //             "Training": 0,
        //             "business_unit": "GOALS",
        //             "category": 0,
        //             "name": "GOALS - Others",
        //             "shift_date": "2024-07-19",
        //             "shift_end_time": null,
        //             "shift_id": 19924,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 7,
        //             "Active": 41,
        //             "Training": 0,
        //             "business_unit": "Henry Schein",
        //             "category": 0,
        //             "name": "Henry Schein - Others",
        //             "shift_date": "2024-07-19",
        //             "shift_end_time": null,
        //             "shift_id": 20528,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 116,
        //             "Active": 51,
        //             "Training": 0,
        //             "business_unit": "Walmart",
        //             "category": 0,
        //             "name": "Walmart - Others",
        //             "shift_date": "2024-07-19",
        //             "shift_end_time": null,
        //             "shift_id": 21137,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 25,
        //             "Active": 43,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-07-19",
        //             "shift_end_time": null,
        //             "shift_id": 19442,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 11,
        //             "Active": 8,
        //             "Training": 0,
        //             "business_unit": "Kroger",
        //             "category": 0,
        //             "name": "Kroger - Others",
        //             "shift_date": "2024-07-19",
        //             "shift_end_time": null,
        //             "shift_id": 20727,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 3,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 3,
        //             "name": "MIA Aesthetics - 08:00 AM to 05:00 PM",
        //             "shift_date": "2024-07-19",
        //             "shift_end_time": "17:00:00",
        //             "shift_id": 20848,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "MIA Aesthetics",
        //             "category": 4,
        //             "name": "MIA Aesthetics - 09:00 AM to 06:00 PM",
        //             "shift_date": "2024-07-19",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 20858,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-07-19",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 19392,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 1,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 7,
        //             "name": "Anyone Home - 10:00 AM to 08:00 PM",
        //             "shift_date": "2024-07-19",
        //             "shift_end_time": "20:00:00",
        //             "shift_id": 19270,
        //             "shift_start_time": "10:00:00"
        //         },
        //         {
        //             "Absent": 0,
        //             "Active": 2,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-07-19",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 19290,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 59,
        //             "Active": 3,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-07-20",
        //             "shift_end_time": null,
        //             "shift_id": 19688,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 19,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Kroger",
        //             "category": 0,
        //             "name": "Kroger - Others",
        //             "shift_date": "2024-07-20",
        //             "shift_end_time": null,
        //             "shift_id": 20718,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "DSI",
        //             "category": 0,
        //             "name": "DSI - Others",
        //             "shift_date": "2024-07-20",
        //             "shift_end_time": null,
        //             "shift_id": 22727,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 9,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Active Engage",
        //             "category": 0,
        //             "name": "Active Engage - Others",
        //             "shift_date": "2024-07-20",
        //             "shift_end_time": null,
        //             "shift_id": 19948,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 9,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Office Depot",
        //             "category": 0,
        //             "name": "Office Depot - Others",
        //             "shift_date": "2024-07-20",
        //             "shift_end_time": null,
        //             "shift_id": 20137,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 7,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "IT Infrastructure",
        //             "category": 0,
        //             "name": "IT Infrastructure - Others",
        //             "shift_date": "2024-07-20",
        //             "shift_end_time": null,
        //             "shift_id": 22226,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 15,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "GOALS",
        //             "category": 0,
        //             "name": "GOALS - Others",
        //             "shift_date": "2024-07-20",
        //             "shift_end_time": null,
        //             "shift_id": 19883,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 152,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Walmart",
        //             "category": 0,
        //             "name": "Walmart - Others",
        //             "shift_date": "2024-07-20",
        //             "shift_end_time": null,
        //             "shift_id": 20952,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-07-20",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 19286,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 7,
        //             "name": "Anyone Home - 10:00 AM to 08:00 PM",
        //             "shift_date": "2024-07-20",
        //             "shift_end_time": "20:00:00",
        //             "shift_id": 19271,
        //             "shift_start_time": "10:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-07-20",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 19403,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 129,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Walmart",
        //             "category": 0,
        //             "name": "Walmart - Others",
        //             "shift_date": "2024-07-21",
        //             "shift_end_time": null,
        //             "shift_id": 21298,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 57,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-07-21",
        //             "shift_end_time": null,
        //             "shift_id": 19423,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 6,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Active Engage",
        //             "category": 0,
        //             "name": "Active Engage - Others",
        //             "shift_date": "2024-07-21",
        //             "shift_end_time": null,
        //             "shift_id": 19939,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 9,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Office Depot",
        //             "category": 0,
        //             "name": "Office Depot - Others",
        //             "shift_date": "2024-07-21",
        //             "shift_end_time": null,
        //             "shift_id": 20328,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 4,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "IT Infrastructure",
        //             "category": 0,
        //             "name": "IT Infrastructure - Others",
        //             "shift_date": "2024-07-21",
        //             "shift_end_time": null,
        //             "shift_id": 22742,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 6,
        //             "name": "Anyone Home - 09:00 AM to 07:00 PM",
        //             "shift_date": "2024-07-21",
        //             "shift_end_time": "19:00:00",
        //             "shift_id": 19348,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 7,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "IT Infrastructure",
        //             "category": 0,
        //             "name": "IT Infrastructure - Others",
        //             "shift_date": "2024-07-22",
        //             "shift_end_time": null,
        //             "shift_id": 24353,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 4,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "ePremium",
        //             "category": 0,
        //             "name": "ePremium - Others",
        //             "shift_date": "2024-07-22",
        //             "shift_end_time": null,
        //             "shift_id": 25453,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Tuners",
        //             "category": 0,
        //             "name": "Tuners - Others",
        //             "shift_date": "2024-07-22",
        //             "shift_end_time": null,
        //             "shift_id": 23255,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 125,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Walmart",
        //             "category": 0,
        //             "name": "Walmart - Others",
        //             "shift_date": "2024-07-22",
        //             "shift_end_time": null,
        //             "shift_id": 24943,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 60,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-07-22",
        //             "shift_end_time": null,
        //             "shift_id": 22571,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 8,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Active Engage",
        //             "category": 0,
        //             "name": "Active Engage - Others",
        //             "shift_date": "2024-07-22",
        //             "shift_end_time": null,
        //             "shift_id": 24413,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 48,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Henry Schein",
        //             "category": 0,
        //             "name": "Henry Schein - Others",
        //             "shift_date": "2024-07-22",
        //             "shift_end_time": null,
        //             "shift_id": 25103,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 6,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Office Depot",
        //             "category": 0,
        //             "name": "Office Depot - Others",
        //             "shift_date": "2024-07-22",
        //             "shift_end_time": null,
        //             "shift_id": 24458,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 24,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "GOALS",
        //             "category": 0,
        //             "name": "GOALS - Others",
        //             "shift_date": "2024-07-22",
        //             "shift_end_time": null,
        //             "shift_id": 24298,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-07-22",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 22481,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 6,
        //             "name": "Anyone Home - 09:00 AM to 07:00 PM",
        //             "shift_date": "2024-07-22",
        //             "shift_end_time": "19:00:00",
        //             "shift_id": 22436,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 7,
        //             "name": "Anyone Home - 10:00 AM to 08:00 PM",
        //             "shift_date": "2024-07-22",
        //             "shift_end_time": "20:00:00",
        //             "shift_id": 22359,
        //             "shift_start_time": "10:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-07-22",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 22379,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 9,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Active Engage",
        //             "category": 0,
        //             "name": "Active Engage - Others",
        //             "shift_date": "2024-07-23",
        //             "shift_end_time": null,
        //             "shift_id": 24403,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 48,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Henry Schein",
        //             "category": 0,
        //             "name": "Henry Schein - Others",
        //             "shift_date": "2024-07-23",
        //             "shift_end_time": null,
        //             "shift_id": 25199,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 7,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Office Depot",
        //             "category": 0,
        //             "name": "Office Depot - Others",
        //             "shift_date": "2024-07-23",
        //             "shift_end_time": null,
        //             "shift_id": 24449,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 23,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "GOALS",
        //             "category": 0,
        //             "name": "GOALS - Others",
        //             "shift_date": "2024-07-23",
        //             "shift_end_time": null,
        //             "shift_id": 24243,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 7,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "IT Infrastructure",
        //             "category": 0,
        //             "name": "IT Infrastructure - Others",
        //             "shift_date": "2024-07-23",
        //             "shift_end_time": null,
        //             "shift_id": 24339,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 4,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "ePremium",
        //             "category": 0,
        //             "name": "ePremium - Others",
        //             "shift_date": "2024-07-23",
        //             "shift_end_time": null,
        //             "shift_id": 25454,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Tuners",
        //             "category": 0,
        //             "name": "Tuners - Others",
        //             "shift_date": "2024-07-23",
        //             "shift_end_time": null,
        //             "shift_id": 23250,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 112,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Walmart",
        //             "category": 0,
        //             "name": "Walmart - Others",
        //             "shift_date": "2024-07-23",
        //             "shift_end_time": null,
        //             "shift_id": 24719,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 55,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-07-23",
        //             "shift_end_time": null,
        //             "shift_id": 22349,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-07-23",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 22374,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 6,
        //             "name": "Anyone Home - 09:00 AM to 07:00 PM",
        //             "shift_date": "2024-07-23",
        //             "shift_end_time": "19:00:00",
        //             "shift_id": 22437,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 7,
        //             "name": "Anyone Home - 10:00 AM to 08:00 PM",
        //             "shift_date": "2024-07-23",
        //             "shift_end_time": "20:00:00",
        //             "shift_id": 22360,
        //             "shift_start_time": "10:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-07-23",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 22380,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 67,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-07-24",
        //             "shift_end_time": null,
        //             "shift_id": 22253,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 10,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Active Engage",
        //             "category": 0,
        //             "name": "Active Engage - Others",
        //             "shift_date": "2024-07-24",
        //             "shift_end_time": null,
        //             "shift_id": 24394,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 48,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Henry Schein",
        //             "category": 0,
        //             "name": "Henry Schein - Others",
        //             "shift_date": "2024-07-24",
        //             "shift_end_time": null,
        //             "shift_id": 25135,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 7,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Office Depot",
        //             "category": 0,
        //             "name": "Office Depot - Others",
        //             "shift_date": "2024-07-24",
        //             "shift_end_time": null,
        //             "shift_id": 24435,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 23,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "GOALS",
        //             "category": 0,
        //             "name": "GOALS - Others",
        //             "shift_date": "2024-07-24",
        //             "shift_end_time": null,
        //             "shift_id": 24234,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 7,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "IT Infrastructure",
        //             "category": 0,
        //             "name": "IT Infrastructure - Others",
        //             "shift_date": "2024-07-24",
        //             "shift_end_time": null,
        //             "shift_id": 24330,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 4,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "ePremium",
        //             "category": 0,
        //             "name": "ePremium - Others",
        //             "shift_date": "2024-07-24",
        //             "shift_end_time": null,
        //             "shift_id": 25465,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Tuners",
        //             "category": 0,
        //             "name": "Tuners - Others",
        //             "shift_date": "2024-07-24",
        //             "shift_end_time": null,
        //             "shift_id": 23245,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 117,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Walmart",
        //             "category": 0,
        //             "name": "Walmart - Others",
        //             "shift_date": "2024-07-24",
        //             "shift_end_time": null,
        //             "shift_id": 24499,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-07-24",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 22375,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 6,
        //             "name": "Anyone Home - 09:00 AM to 07:00 PM",
        //             "shift_date": "2024-07-24",
        //             "shift_end_time": "19:00:00",
        //             "shift_id": 22438,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 7,
        //             "name": "Anyone Home - 10:00 AM to 08:00 PM",
        //             "shift_date": "2024-07-24",
        //             "shift_end_time": "20:00:00",
        //             "shift_id": 22361,
        //             "shift_start_time": "10:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-07-24",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 22381,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 106,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Walmart",
        //             "category": 0,
        //             "name": "Walmart - Others",
        //             "shift_date": "2024-07-25",
        //             "shift_end_time": null,
        //             "shift_id": 24710,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 63,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-07-25",
        //             "shift_end_time": null,
        //             "shift_id": 22396,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 11,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Active Engage",
        //             "category": 0,
        //             "name": "Active Engage - Others",
        //             "shift_date": "2024-07-25",
        //             "shift_end_time": null,
        //             "shift_id": 24371,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 48,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Henry Schein",
        //             "category": 0,
        //             "name": "Henry Schein - Others",
        //             "shift_date": "2024-07-25",
        //             "shift_end_time": null,
        //             "shift_id": 25451,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 7,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Office Depot",
        //             "category": 0,
        //             "name": "Office Depot - Others",
        //             "shift_date": "2024-07-25",
        //             "shift_end_time": null,
        //             "shift_id": 24440,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 23,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "GOALS",
        //             "category": 0,
        //             "name": "GOALS - Others",
        //             "shift_date": "2024-07-25",
        //             "shift_end_time": null,
        //             "shift_id": 24321,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 7,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "IT Infrastructure",
        //             "category": 0,
        //             "name": "IT Infrastructure - Others",
        //             "shift_date": "2024-07-25",
        //             "shift_end_time": null,
        //             "shift_id": 24326,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 4,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "ePremium",
        //             "category": 0,
        //             "name": "ePremium - Others",
        //             "shift_date": "2024-07-25",
        //             "shift_end_time": null,
        //             "shift_id": 25456,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Tuners",
        //             "category": 0,
        //             "name": "Tuners - Others",
        //             "shift_date": "2024-07-25",
        //             "shift_end_time": null,
        //             "shift_id": 23246,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-07-25",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 22376,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 6,
        //             "name": "Anyone Home - 09:00 AM to 07:00 PM",
        //             "shift_date": "2024-07-25",
        //             "shift_end_time": "19:00:00",
        //             "shift_id": 22439,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 4,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "ePremium",
        //             "category": 0,
        //             "name": "ePremium - Others",
        //             "shift_date": "2024-07-26",
        //             "shift_end_time": null,
        //             "shift_id": 25457,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Tuners",
        //             "category": 0,
        //             "name": "Tuners - Others",
        //             "shift_date": "2024-07-26",
        //             "shift_end_time": null,
        //             "shift_id": 23259,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 107,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Walmart",
        //             "category": 0,
        //             "name": "Walmart - Others",
        //             "shift_date": "2024-07-26",
        //             "shift_end_time": null,
        //             "shift_id": 24531,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 62,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-07-26",
        //             "shift_end_time": null,
        //             "shift_id": 22635,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 9,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Active Engage",
        //             "category": 0,
        //             "name": "Active Engage - Others",
        //             "shift_date": "2024-07-26",
        //             "shift_end_time": null,
        //             "shift_id": 24385,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 48,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Henry Schein",
        //             "category": 0,
        //             "name": "Henry Schein - Others",
        //             "shift_date": "2024-07-26",
        //             "shift_end_time": null,
        //             "shift_id": 25327,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 6,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Office Depot",
        //             "category": 0,
        //             "name": "Office Depot - Others",
        //             "shift_date": "2024-07-26",
        //             "shift_end_time": null,
        //             "shift_id": 24467,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 23,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "GOALS",
        //             "category": 0,
        //             "name": "GOALS - Others",
        //             "shift_date": "2024-07-26",
        //             "shift_end_time": null,
        //             "shift_id": 24275,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 7,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "IT Infrastructure",
        //             "category": 0,
        //             "name": "IT Infrastructure - Others",
        //             "shift_date": "2024-07-26",
        //             "shift_end_time": null,
        //             "shift_id": 24362,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-07-26",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 22377,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 7,
        //             "name": "Anyone Home - 10:00 AM to 08:00 PM",
        //             "shift_date": "2024-07-26",
        //             "shift_end_time": "20:00:00",
        //             "shift_id": 22362,
        //             "shift_start_time": "10:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-07-26",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 22382,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 15,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "GOALS",
        //             "category": 0,
        //             "name": "GOALS - Others",
        //             "shift_date": "2024-07-27",
        //             "shift_end_time": null,
        //             "shift_id": 24307,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 3,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "IT Infrastructure",
        //             "category": 0,
        //             "name": "IT Infrastructure - Others",
        //             "shift_date": "2024-07-27",
        //             "shift_end_time": null,
        //             "shift_id": 24336,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Tuners",
        //             "category": 0,
        //             "name": "Tuners - Others",
        //             "shift_date": "2024-07-27",
        //             "shift_end_time": null,
        //             "shift_id": 23254,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 102,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Walmart",
        //             "category": 0,
        //             "name": "Walmart - Others",
        //             "shift_date": "2024-07-27",
        //             "shift_end_time": null,
        //             "shift_id": 24911,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 57,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-07-27",
        //             "shift_end_time": null,
        //             "shift_id": 22317,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 7,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Active Engage",
        //             "category": 0,
        //             "name": "Active Engage - Others",
        //             "shift_date": "2024-07-27",
        //             "shift_end_time": null,
        //             "shift_id": 24376,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Office Depot",
        //             "category": 0,
        //             "name": "Office Depot - Others",
        //             "shift_date": "2024-07-27",
        //             "shift_end_time": null,
        //             "shift_id": 24442,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 5,
        //             "name": "Anyone Home - 08:00 AM to 06:00 PM",
        //             "shift_date": "2024-07-27",
        //             "shift_end_time": "18:00:00",
        //             "shift_id": 22485,
        //             "shift_start_time": "08:00:00"
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 7,
        //             "name": "Anyone Home - 10:00 AM to 08:00 PM",
        //             "shift_date": "2024-07-27",
        //             "shift_end_time": "20:00:00",
        //             "shift_id": 22363,
        //             "shift_start_time": "10:00:00"
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 8,
        //             "name": "Anyone Home - 11:00 AM to 09:00 PM",
        //             "shift_date": "2024-07-27",
        //             "shift_end_time": "21:00:00",
        //             "shift_id": 22383,
        //             "shift_start_time": "11:00:00"
        //         },
        //         {
        //             "Absent": 6,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Active Engage",
        //             "category": 0,
        //             "name": "Active Engage - Others",
        //             "shift_date": "2024-07-28",
        //             "shift_end_time": null,
        //             "shift_id": 24372,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Office Depot",
        //             "category": 0,
        //             "name": "Office Depot - Others",
        //             "shift_date": "2024-07-28",
        //             "shift_end_time": null,
        //             "shift_id": 24447,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 2,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "IT Infrastructure",
        //             "category": 0,
        //             "name": "IT Infrastructure - Others",
        //             "shift_date": "2024-07-28",
        //             "shift_end_time": null,
        //             "shift_id": 24367,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 78,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Walmart",
        //             "category": 0,
        //             "name": "Walmart - Others",
        //             "shift_date": "2024-07-28",
        //             "shift_end_time": null,
        //             "shift_id": 25007,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 57,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-07-28",
        //             "shift_end_time": null,
        //             "shift_id": 22465,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 6,
        //             "name": "Anyone Home - 09:00 AM to 07:00 PM",
        //             "shift_date": "2024-07-28",
        //             "shift_end_time": "19:00:00",
        //             "shift_id": 22440,
        //             "shift_start_time": "09:00:00"
        //         },
        //         {
        //             "Absent": 8,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Anyone Home",
        //             "category": 0,
        //             "name": "Anyone Home - Others",
        //             "shift_date": "2024-07-29",
        //             "shift_end_time": null,
        //             "shift_id": 22258,
        //             "shift_start_time": null
        //         },
        //         {
        //             "Absent": 1,
        //             "Active": 0,
        //             "Training": 0,
        //             "business_unit": "Active Engage",
        //             "category": 0,
        //             "name": "Active Engage - Others",
        //             "shift_date": "2024-07-29",
        //             "shift_end_time": null,
        //             "shift_id": 24426,
        //             "shift_start_time": null
        //         }
        //     ]
        // };
        const objectMap: { [key: string]: any } = {};

        response.data.forEach((item: any) => {
            if (!objectMap[item.name]) {
                objectMap[item.name] = {
                    name: item.name,
                    days: [item],
                }
            } else {
                objectMap[item.name].days.push(item);
            }
        });

        const daysCasted = Object.values(objectMap).map((item) => StaffPerShiftHostDto.fromJSON(item));

        return {
            items: daysCasted,
            pages: 100,
            total: daysCasted.length
        }

    } catch (error) {
        return {
            items: [],
            pages: 0,
            total: 0,
        }
    }



}

export default SearchWithUserGroupStatusApiImpl;