import './TrackingCompareLineComponentStyles.scss';
import { FC, useContext, useEffect, useState } from "react";
import TrackingCompareLineComponentProps from "./TrackingCompareLineComponentProps";
import KeyWordLocalization from '../../../../../providers/localization/dictionaries/KeyWordLocalization';
import LocalizationContext from '../../../../../../domain/providers/localization/LocalizationContext';
import LocalizationContextType from '../../../../../../domain/providers/localization/LocalizationContextType';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import DateParse from '../../../../../utils/DateParse';
import { DateOperations } from '../../../../../utils/DateOperations';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    scalable: true,
    aspectRatio: 1.6,
    plugins: {
        legend: {
            position: 'top' as const,
        },
    },
};
const TrackingCompareLineComponent: FC<TrackingCompareLineComponentProps> = ({ summary }) => {
    const { i18n } = useContext(LocalizationContext) as LocalizationContextType;

    const [data, setData] = useState<any>();

    const _getAllDatesAsList = () => {
        // get all dates in summary.dateToDate.date join all dates and then remove duplicates and sort
        const allDates: Date[] = summary.flatMap(item => item.dateToDate.map(date => date.date));
        //remove duplicates
        const removedDuplicates:Date[] = [];
        allDates.forEach(date => {
            if (!removedDuplicates.find(dateToRemove => DateOperations.isSameDate(dateToRemove, date))) {
                removedDuplicates.push(date);
            }
        });
        return removedDuplicates;
    }
    const parseData = () => {
        const labelsAsDate = _getAllDatesAsList();
        const data = summary.flatMap(item => {
            const plannedDataSet = {
                label: item.typeEvent.name + ' Planned',
                borderDash: [5, 5],
                borderColor: item.typeEvent.color,
                backgroundColor: 'transparent',
                data: [] as any[],
            }
            const realDataSet = {
                label: item.typeEvent.name + ' Real',
                borderColor: item.typeEvent.color,
                backgroundColor: item.typeEvent.color,
                data: [] as any[],
            }
            labelsAsDate.forEach((date: Date) => {
                const datoToGetData = item.dateToDate.find(dateToDate => DateOperations.isSameDate(dateToDate.date, date));
                if (datoToGetData) {
                    plannedDataSet.data.push(datoToGetData.totalPlanned);
                    realDataSet.data.push(datoToGetData.totalReal);
                } else {
                    plannedDataSet.data.push(100);
                    realDataSet.data.push(100);
                }
            });
            return [plannedDataSet, realDataSet];
        });
        const _parsedData = {
            labels: labelsAsDate.map((date: Date) => `${date.getDate()} ${DateParse.getMonthOfCalendar(date)} ${date.getFullYear()}`),
            datasets: data,
        }
        setData(_parsedData);
    }

    useEffect(() => {
        parseData();
    }, [summary]);

    return <div className="tracking_compare_line_component h-100">
        <div className="card h-100">
            <div className="px-3 pt-3">
                {i18n(KeyWordLocalization.TrackingCompareLineComponentTitle)}
            </div>
            <hr />
            <div className="p-2 h-100">
                {data && <Line  data={data} height={'100%'} options={options} />}
            </div>
        </div>
    </div>

}

export default TrackingCompareLineComponent;