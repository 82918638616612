import React from "react";

import RolesContextType from "./RolesContextType";
const RolesContext = React.createContext<RolesContextType>({
    users: [],
    setUsers: () => {},
    roles: [],
    setRoles: () => {},
    permissions: [],
    setPermissions: () => {},
});

export default RolesContext;