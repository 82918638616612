import { FC, useContext, useEffect, useRef, useState } from 'react';
import './UserManagementStyles.scss';
import di from '../../../../../../di/DependencyInjection';
import SearchAllUserUseCase, { SearchAllUserUseCaseName } from '../../../../../../domain/use_cases/user/SearchAllUserUseCase';
import { isRight } from 'fp-ts/lib/Either';
import CardEmployeeComponent from '../cardEmployee/CardEmployeeComponent';
import Icons from '../../../../../assets/Icons';
import DeleteUsersUseCase, { DeleteUsersUseCaseName } from '../../../../../../domain/use_cases/user/DeleteUserUseCase';
import ButtonComponent from '../../../../../components/button/ButtonComponent';
import { ButtonType } from '../../../../../components/button/ButtonComponentProps';
import LoadingComponent from '../../../../../components/LoadingComponent/LoadingComponent';
import ModalsContext from '../../../../../../domain/providers/modal/ModalsContext';
import ModalsContextType from '../../../../../../domain/providers/modal/ModalsContextType';

function capitalizeFirstLetterOfEachWord(name: string) {
    return name.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
}

const UserDeletePage: FC<{}> = () => {

    const { addToast } = useContext(ModalsContext) as ModalsContextType;

    const modalRef = useRef<HTMLDivElement>(null);

    const [selectedUsers, setSelectedUsers] = useState<any>([]);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");

    const [ allUsersData, setAllUsersData ] = useState<any>([]);

    const [ loadingDelete, setLoadingDelete ] = useState(false);

    const [ openDeleteModal, setOpenDeleteModal ] = useState(false);


    const dropdownRef = useRef<HTMLDivElement>(null);

    const _search = async () => (await di.get<SearchAllUserUseCase>(SearchAllUserUseCaseName).call(''));

    const firstCall = async () => {
        const first = await _search();
        if (isRight(first)) {
            setAllUsersData(first.right);
        }
    }


    const handleUserSelect = (user: any) => {
        setSelectedUsers((prevSelectedUsers: any) => {
            if (prevSelectedUsers.find((selectedUser: any) => selectedUser.email === user.email)) {
                return prevSelectedUsers;
            }
            let newUser = {
                ...user,
                name: user.firstname + " " + user.lastname,
                image: user.profile_picture
            }
            return [...prevSelectedUsers, newUser];
        });
        setDropdownOpen(false);
        setSearchTerm("");
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setDropdownOpen(false);
        }
        if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
            setOpenDeleteModal(false);
        }
    };

    const _handleRemoveUserFromList = (user: any) => {
        setSelectedUsers((prevSelectedUsers: any) => 
            prevSelectedUsers.filter((selectedUser: any) => selectedUser.email !== user.email)
        );
    }

    const _handleDeleteUsers = async () => {
        setLoadingDelete(true);
        const usersToDelete = selectedUsers.map((user: any) => user.email);
        
        const _delete = async () => await di.get<DeleteUsersUseCase>(DeleteUsersUseCaseName).call(usersToDelete);

        const response: any = await _delete();

        if (response._tag === "Right") {
            
            setAllUsersData([]);
            setSelectedUsers([]);
            firstCall();
            setLoadingDelete(false);
            const rightResponse = JSON.parse(response.right);
            addToast(rightResponse.message, "success", undefined);
        } else {
            addToast(response.left.message, "error", undefined);
        }
    }

    const _handleOpenDelete = (value: boolean) => {
        if (selectedUsers.length > 0) {
            setOpenDeleteModal(value);
        }
    }
    

    useEffect(() => {
        firstCall();
        
        if (dropdownOpen || openDeleteModal) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdownOpen, openDeleteModal]);

    return (
        <div className="container">
            {!loadingDelete ?   <>
                <div className='user_list_title'>Search for Users:</div>
                    <div className="dropdown">
                        <input
                            type="text"
                            className="dropdown-input"
                            placeholder="Type and find a user"
                            onChange={(e) => setSearchTerm(e.target.value)}
                            onFocus={() => setDropdownOpen(true)}
                            value={searchTerm}
                        />
                        
                            
                    </div>
                    {dropdownOpen &&
                        <div className="dropdown_user_menu" ref={dropdownRef}>

                            {allUsersData
                                ?.filter((user: any) => user.firstname.toLowerCase().includes(searchTerm.toLowerCase()) || user.lastname.toLowerCase().includes(searchTerm.toLowerCase()) || user.email.toLowerCase().includes(searchTerm.toLowerCase()))
                                .map((user: any, index: string) => (
                                    <div
                                        key={index}
                                        className="dropdown_user_item"
                                        onClick={() => handleUserSelect(user)}
                                    >
                                        <strong>{capitalizeFirstLetterOfEachWord(user.firstname)} {capitalizeFirstLetterOfEachWord(user.lastname)} </strong>{`(${user.email})`}
                                    </div>
                            ))}

                            {
                                allUsersData
                                ?.filter((user: any) => user.firstname.toLowerCase().includes(searchTerm.toLowerCase()) || user.lastname.toLowerCase().includes(searchTerm.toLowerCase()) || user.email.toLowerCase().includes(searchTerm.toLowerCase())).length < 1 ?
                                <>No users found</>:<></>
                                
                            }
                        </div>
                    }

                    <div className="selected_users">
                        {selectedUsers.length > 0 && <div className='user_list_title'>List of Users to delete:</div>}

                        <div className='selected_user_list'>
                            {selectedUsers.length > 0 && selectedUsers?.map((user: any, index: any) => (
                                <div key={index} className='user_selected_item'>
                                    <div className='user_remove_list_container'>
                                        <div className='user_remove_list' onClick={() => _handleRemoveUserFromList(user)}>
                                            <Icons.Clear />
                                        </div>
                                    </div>
                                    <div>
                                        <CardEmployeeComponent employee={user} onClick={() => console.log()} />
                                    </div>
                                </div>
                                
                            ))}
                        </div>
                    </div>
                    <div className="floating_button">
                        <ButtonComponent text={"Delete"} type={ButtonType.DANGER} onClick={() => _handleOpenDelete(true)} icon={<Icons.Trash />} />
                    </div>

                    {
                        openDeleteModal && selectedUsers.length > 0 &&
                            <div className='user_edition_container'>
                                <div className='user_edition_modal delete' ref={modalRef}>
                                    <center><p>Disabled users lose their access to the platform. <br/>Do you want to proceed?</p></center>
                                    <div className='delete_modal_buttons'>
                                        <ButtonComponent text={"Cancel"} type={ButtonType.MAIN} onClick={() => _handleOpenDelete(false)} icon={<Icons.Trash />} />
                                        <ButtonComponent text={"Delete"} type={ButtonType.DANGER} onClick={_handleDeleteUsers} icon={<Icons.Trash />} />
                                    </div>
                                </div>
                            </div>
                    }
                    
                </>
                : <LoadingComponent/>
            }
            
            
        </div>
    );
};

export default UserDeletePage;
