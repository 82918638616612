import { FC } from "react";
import DateFormComponentProps from "./DateFormComponentProps";
import Validators from "../../../utils/Validators";
import { ErrorMessage } from "@hookform/error-message";

const DateFormComponent: FC<DateFormComponentProps> = ({ label, required, keyName, disabled, register, onChange, watch, errors, validatorProps }) => {

    return <div className="w-100">
        <div className="row">
            <div className="col-12">
                <div className="form-group">
                    <label>{label}</label>
                    <input type="date" className="form-control" {...register(keyName, Validators({ required: required }))} />
                    <ErrorMessage as="aside" errors={errors} name={keyName} />
                </div>
            </div>
        </div>
    </div>
}

export default DateFormComponent;