import Button from '@cloudscape-design/components/button';
import Container from '@cloudscape-design/components/container';
import ContentLayout from '@cloudscape-design/components/content-layout';
import Form from '@cloudscape-design/components/form';
import FormField from '@cloudscape-design/components/form-field';
import SpaceBetween from '@cloudscape-design/components/space-between';
import { useEffect, useState } from 'react';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css'
import { MultiSelect } from './components/MultiSelect';
import Select from 'react-select';
import di from '../../../di/DependencyInjection';
import { SearchAISummariesUseCase, SearchAISummariesUseCaseName } from '../../../domain/use_cases/ai/SearchAISummariesUseCase';
import Grid from '@cloudscape-design/components/grid';
import { ContactTable } from './ContactTable';
import { isRight } from 'fp-ts/lib/Either';
import { SearchAISummariesLanguagesUseCase, SearchAISummariesLanguagesUseCaseName } from '../../../domain/use_cases/ai/SearchAISummariesLanguagesUseCase';
import { SearchAISummariesEntitiesUseCase, SearchAISummariesEntitiesUseCaseName } from '../../../domain/use_cases/ai/SearchAISummariesEntitiesUseCase';

const entities = [
    "Mary Wittenham",
    "Bella Kloberman",
    "12,004 90.40",
    "$9,887.61",
    "Alza",
    "chrisparkerson.brown",
    "NNECE",
    "two weekends",
    "Memphis",
    "Waterloo square",
    "First week",
    "G-I-R-K-S-A-M-O-",
    "Acoba Krikas"
]

const sentimentWhat = [
    { value: "average", label: "Average" },
    { value: "trend", label: "Trending" },
];

const sentimentWho = [
    { value: "caller", label: "Customer" },
    { value: "agent", label: "Agent" },
];

const sentimentDirection = [
    { value: "positive", label: "Positive" },
    { value: "negative", label: "Negative" },
];

interface HomePageProps {
}

const SearchCalls: React.FC<HomePageProps> = () => {

    const [query, setQuery] = useState<any>({});
    const [ languageCodes, setLanguageCodes ] = useState([]);
    const [ entitiesData, setEntitiesData ] = useState([]);
    const [editing, setEditing] = useState(true);
    const [shouldSearch, setShouldSearch] = useState(true);
    const [ data, setData ] = useState<any[]>([]);
    const [ loading, setLoading ] = useState(true);

    const _updateUserRoleById = async () => await di.get<SearchAISummariesUseCase>(SearchAISummariesUseCaseName)._aiRepository.searchAISummaries(query);

    const _getLanguages = async () => await di.get<SearchAISummariesLanguagesUseCase>(SearchAISummariesLanguagesUseCaseName)._aiRepository.searchAISummariesLanguages();

    //SearchAISummariesUseCase

    const filterEmptyKeys = (obj: any) => {
        const shouldKeep = (v: any) => (Array.isArray(v) ? v.length > 0 : v !== null);
    
        return Object.fromEntries(
            Object.entries(obj).filter(([_, v]) => shouldKeep(v))
        );
    };
    
    const handleQueryInput = (input: any, field: any) => {
        setQuery((q: any) => filterEmptyKeys({ ...q, [field]: input }));
    }
    
    const handleDates = (dates: any) => {
        const [start, end] = dates;
    
        handleQueryInput(start, "timestampFrom");
        handleQueryInput(end, "timestampTo");
    };

    
    const onClick = () => {
        setEditing(false);
        callingAPI();
    };

    const callingAPI = async () => {
        const apiGet = await _updateUserRoleById();
        if (isRight(apiGet)) {
            let value = JSON.parse(apiGet.right as string);
            setData(value.data);
        }
    }

    const callingAPIFields = async () => {
        const languages = await _getLanguages();
        if (isRight(languages)) {
            let value = JSON.parse(languages.right as string);
            setLanguageCodes(value.data);
        }
    }

    /**
     * 
     * 
    useEffect(() => {
        (query.timestampTo && query.timestampTo) ||
        (!query.timestampTo && !query.timestampFrom)
            ? setShouldSearch(true)
            : setShouldSearch(false);
    }, [query.timestampTo, query.timestampFrom]);

    useEffect(() => {
        if (shouldSearch) {
            callingAPI();
        }
    }, [query])
     * 
     */


    useEffect(() => {
        callingAPIFields();
    }, [])

    return (
        <div style={{padding: "20px"}}>
                <ContentLayout 
                >
                    
                <Container>
                    <div className='aisearch_title_filter'>
                        <>Call List Search</>
                    </div>
                    <Form>
                    <SpaceBetween direction="vertical" size="l">
                        <FormField label="Language Code">
                        <SpaceBetween direction="horizontal" size="l">
                            <Select
                                onChange={(event: any) => handleQueryInput(event.value, "language")}
                                options={(languageCodes || []).map((code, i) => ({
                                    label: code,
                                    value: code,
                                }))}
                                isLoading={!languageCodes}
                                value={
                                query.language
                                    ? { label: query.language, value: query.language }
                                    : null
                                }
                                inputValue=""
                                onInputChange={() => {}}
                                onMenuOpen={() => {}}
                                onMenuClose={() => {}}
                            />
                            <Button
                                className="mt-2"
                                variant="normal"
                                onClick={() => {
                                    handleQueryInput(null, "language");
                                }}
                                >
                                Clear
                            </Button>
                        </SpaceBetween>
                        </FormField>
                        <FormField label="Date Range">
                        <SpaceBetween direction="horizontal" size="l">
                            <DatePicker
                                selectsRange
                                startDate={query.timestampFrom}
                                endDate={query.timestampTo}
                                dateFormat="yyyy-MM-dd"
                                onChange={(e) => {
                                    handleDates(e)
                                    
                                }}
                                maxDate={new Date()}
                                placeholderText="Select a start and end date"
                                />
                                <Button
                                    className="mt-2"
                                    variant="normal"
                                    onClick={() => {
                                        handleQueryInput(null, "timestampTo");
                                        handleQueryInput(null, "timestampFrom");
                                    }}
                                    >
                                    Clear
                                </Button>
                        </SpaceBetween>
                    </FormField>
                    <FormField label="Sentiment">
                        <SpaceBetween direction="horizontal" size="l">
                        <p className="align-self-end mb-0">The</p>
                        <Select
                            className="flex-grow-1"
                            options={sentimentWhat}
                            onChange={(event: any) =>
                            handleQueryInput(event.value, "sentimentWhat")
                            }
                            value={
                                sentimentWhat.find((o) => o.value === query.sentimentWhat) ||
                                null
                            }
                            
                            inputValue=""
                            onInputChange={() => {}}
                            onMenuOpen={() => {}}
                            onMenuClose={() => {}}
                        />
                        <p className="align-self-end mb-0"> sentiment of the</p>
                        <Select
                            className="flex-grow-1"
                            options={sentimentWho}
                            onChange={(event: any) =>
                            handleQueryInput(event.value, "sentimentWho")
                            }
                            value={
                                sentimentWho.find((o) => o.value === query.sentimentWho) || null
                            }
                            
                            inputValue=""
                            onInputChange={() => {}}
                            onMenuOpen={() => {}}
                            onMenuClose={() => {}}
                        />
                        <p className="align-self-end mb-0">is</p>
                        <Select
                            className="flex-grow-1"
                            options={sentimentDirection}
                            onChange={(event: any) =>
                            handleQueryInput(event.value, "sentimentDirection")
                            }
                            value={
                                sentimentDirection.find(
                                    (o) => o.value === query.sentimentDirection
                                ) || null
                            }
                            
                            inputValue=""
                            onInputChange={() => {}}
                            onMenuOpen={() => {}}
                            onMenuClose={() => {}}
                        />
                        <Button
                            className="mt-2"
                            variant="normal"
                            onClick={() => {
                                handleQueryInput(null, "sentimentWhat");
                                handleQueryInput(null, "sentimentWho");
                                handleQueryInput(null, "sentimentDirection");
                            }}
                        >
                            Clear
                        </Button>
                        </SpaceBetween>
                    </FormField>

                {
                    true &&
                    <Button 
                        variant="primary" onClick={onClick}>
                        Search
                    </Button>
                }
                
                    
                <hr/>
                </SpaceBetween>
            </Form>
            
            </Container>
            <br />
            <Grid
                    gridDefinition={[
                        {colspan: { default:12} },
                        {colspan: { default:12} }
                    ]}
                >
                    <ContactTable
                        data={data as any}
                    />
                </Grid>
            </ContentLayout>
        </div>
    );
};

export default SearchCalls;
