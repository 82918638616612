import { injectable } from "inversify";
import AIRepository from "../../repositories/AIRepository";

interface props {
    aiRepository: AIRepository;
}

@injectable()
export class GetAIPersonUseCase {
    _aiRepository: AIRepository;

    constructor(_: props) {
        this._aiRepository = _.aiRepository;
    }

    public call = async (person: string, type: string, params: string): Promise<any> => {
        return await this._aiRepository.getAIPerson(person, type, params);
    }
}

export const GetAIPersonUseCaseName = "GetAIPersonUseCaseName";