import { FC } from "react";
import MultiSelectFormComponentProps from "./MultiSelectFormComponentProps";
import { ErrorMessage } from "@hookform/error-message";
import Validators from "../../../utils/Validators";

const MultiSelectFormComponent: FC<MultiSelectFormComponentProps> = ({ label, keyName, disabled, register, onChange, watch, errors, validatorProps, options }) => {
    const value = watch(keyName);
    const _toggleOption = (optionId: number) => {
        if(value == undefined) onChange(keyName, [optionId]);
        else if (value?.includes(optionId)) {
            onChange(keyName, value.filter((v: number) => v != optionId));
        } else {
            onChange(keyName, [...value, optionId]);
        }
    }
    return <div className="w-100">
        <div className={`form-group ${errors[keyName] && 'error'}`}>
            <label>{label}</label>
            <div className="d-flex flex-wrap">
                {options.map((option, index) => <div  key={index}
                className={`chip m-2 hover ${value?.includes(option.id) && 'active'}`}
                onClick={() => _toggleOption(option.id)}
                >{option.label}</div>)}
            </div>
            <input type="hidden" {...register(keyName, Validators(validatorProps ?? {}))} />
            <ErrorMessage as="aside" errors={errors} name={keyName} />
        </div>
    </div>
}

export default MultiSelectFormComponent;