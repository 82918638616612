import './TrackingTimeComponentStyles.scss';
import { FC, useContext } from "react";
import TrackingTimeContext from "../../../domain/providers/trackingTime/TrackingTimeContext";
import TrackingTimeContextType from "../../../domain/providers/trackingTime/TrackingTimeContextType";
import Icons from "../../assets/Icons";
import TrackingTimeClosedComponent from './components/trackingTimeClosed/TrackingTimeClosedComponent';
import DateParse from '../../utils/DateParse';
import CardEventScheduleComponent from '../calendar/cardEventSchedule/CardEventScheduleComponent';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../routes/RoutesComponent';
import { EventScheduleEntityStatus } from '../../../domain/entities/EventScheduleEntity';
import TrackingTimeOverTimeComponent from './components/trackingTimeOvertime/TrackingTimeOverTimeComponent';
import TrackingTimeNoTasksComponent from './components/trackingTimeNoTasks/TrackingTimeNoTasksComponent';
import TrackingTimeClockComponent from './components/trackingTimeClockComponent/TrackingTimeClockComponent';

const TrackingTimeComponent: FC<{}> = () => {
    const { isOpen, setIsOpen, currentEvent, nextEvent, currentProgress, loaded } = useContext(TrackingTimeContext) as TrackingTimeContextType;
    const navigate = useNavigate();

    const _handleSeeMore = () => {
        setIsOpen(false);
        navigate(routes.my_calendar.path);
    }
    
    if(!loaded) return <>Loading...</>

    return (
        <div className="tracking_time_component">
            <TrackingTimeClosedComponent />
            {/*currentEvent?.status == EventScheduleEntityStatus.paused && <TrackingTimeOnHoldComponent currentEvent={currentEvent} />*/}
            {isOpen && <div className="card_time_tracking">
                <div className="position-fixed">
                    {currentEvent != undefined && <div className="w-100 d-flex justify-content-between">
                        <span>Start: {DateParse.timeWithPmAm(currentEvent.dateStart).toLowerCase()}</span>
                        <span>End: {DateParse.timeWithPmAm(currentEvent.dateEnd).toLowerCase()}</span>
                    </div>}

                    {currentEvent == undefined && nextEvent == undefined && <TrackingTimeNoTasksComponent />}

                    {currentEvent != undefined && currentEvent.status == EventScheduleEntityStatus.running && currentProgress > 100 && <TrackingTimeOverTimeComponent />}

                    {currentEvent != undefined && ((currentEvent.status == EventScheduleEntityStatus.running && currentProgress <= 100) || currentEvent.status == EventScheduleEntityStatus.paused || currentEvent.status == EventScheduleEntityStatus.pending ) && <div className="my-3"><TrackingTimeClockComponent /></div>}

                    {currentEvent != undefined && <div className={`d-flex flex-column align-items-center text_bold ${currentProgress > 100 ? 'text-danger' : ''}`}>
                        <div className="d-flex align-items-center justify-content-center">
                            <Icons.WorkBag height={16} /> <span>{currentEvent.name}</span>
                        </div>
                        {/* <div className="text-center my-2">
                        {currentEvent.description}
                    </div> */}
                    </div>}


                    {nextEvent != undefined && <div className="w-100 mb-3 mt-2">
                        Next Tasks:
                        <CardEventScheduleComponent event={nextEvent!} />
                    </div>}
                    <div className="w-100 text-center">
                        <div className='hover' onClick={_handleSeeMore} >See More</div>
                    </div>
                </div>
            </div>}
            {/* {isOpen && currentEvent == undefined  && } */}
        </div>
    )
}

export default TrackingTimeComponent;