/********************************************************************************
 * File Header - AsignShiftApiImpl.tsx
 * Created By: Marlon Alejandro Mendez Castañeda
 * Contact: +57 3234686680
 * Date: Febrary 27 2024
 *
 * Description:
 * Connect the asign shift api to the graphql server
 * 
 * Comments: We need to send an array of shifts to the server
 * Example: [{employee_id: [1, 2], end_time: "2024-02-01 17:00:00", start_time: "2024-02-01 8:00:00"}, {employee_id: [2], end_time: "2024-02-02 17:00:00", start_time: "2024-02-02 8:00:00"}]
 ********************************************************************************/

import { Either, isRight, left, right } from "fp-ts/lib/Either";
import UserEntity from "../../../../../domain/entities/UserEntity";
import ExceptionEntity from "../../../../../domain/entities/ExceptionEntity";
import GraphApi from "../../../../settings/GraphApi";
import DateParse from "../../../../../ui/utils/DateParse";
import KeyWordLocalization from "../../../../../ui/providers/localization/dictionaries/KeyWordLocalization";
import ShiftEntity from "../../../../../domain/entities/ShiftEntity";
import CreateMultipleEventsForShiftApiImpl from "./CreateMultipleEventsForShiftApiImpl";

const AsignShiftApiImpl = async (users: UserEntity[], shifts: ShiftEntity[]): Promise<Either<ExceptionEntity, void>> => {
    let mappedQuery: any[] = []

    mappedQuery = shifts.map(shift => {
        const initDate = new Date(shift.initHour);
        initDate.setHours(shift.initHour.getHours());
        initDate.setMinutes(shift.initHour.getMinutes());
        initDate.setSeconds(0);

        const endDate = new Date(shift.endHour);
        endDate.setHours(shift.endHour.getHours());
        endDate.setMinutes(shift.endHour.getMinutes());
        endDate.setSeconds(0);
        return {
            email: users.map(user => user.email),
            end_time: DateParse.formatDate(endDate),
            start_time: DateParse.formatDate(initDate),
        }
    });

    const response = await GraphApi.mutation('assignBulkShift', {
        assignShiftInput: mappedQuery,
    }, ['employee_id', 'end_time', 'start_time', 'shift_id', 'shift_group']);
    if (isRight(response)) {
        const shiftResponse = response.right.data?.assignBulkShift?.[0]?.shift_group;
        if (shiftResponse === undefined) return left({ code: KeyWordLocalization.ErrorHostShiftNotCreated });
        console.log('shift groups id, ', shiftResponse, typeof shiftResponse);
        const mappedEvents = shifts.flatMap(shift => shift.events);
        if (mappedEvents.length === 0) return right(undefined);
        const responseEvents = await CreateMultipleEventsForShiftApiImpl(mappedEvents, users, shiftResponse);
        return responseEvents;
    }
    return response;

}

export default AsignShiftApiImpl;