import { injectable } from "inversify";
import { Either } from "fp-ts/lib/Either";
import ExceptionEntity from "../../entities/ExceptionEntity";
import CalendarRepository from "../../repositories/CalendarRepository";
import ShiftEntity from "../../entities/ShiftEntity";

interface props {
    calendarRepository: CalendarRepository;
}

@injectable()
export default class GetUserTodayCalendarUseCase {
    _calendarRepository: CalendarRepository;

    constructor(_: props) {
        this._calendarRepository = _.calendarRepository;
    }

    public call = async (userId: string) : Promise<Either<ExceptionEntity, ShiftEntity>> => {
        const response = await this._calendarRepository.getEmployeeTodayCalendar(userId, );
        return response;
    }
}

export const GetUserTodayCalendarUseCaseName = "GetUserTodayCalendarUseCaseName";
