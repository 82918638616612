import di from "../../../di/DependencyInjection";
import EventScheduleEntity from "../../../domain/entities/EventScheduleEntity"
import TypeEventScheduleEntity from "../../../domain/entities/TypeEventScheduleEntity";
import MastersProvider, { MastersProviderName } from "../../../domain/providers/master/MastersProvider";
import DateParse from "../../../ui/utils/DateParse";

const fromJson = (json: any): EventScheduleEntity => {
    const mastersProvider = di.get<MastersProvider>(MastersProviderName);
    const fakeEvent = {
        color: '#233746',
        id: "1",
        name: "Other",
        description: "Other",
        billable: true,
        payable: false,
        outstanding: false,
    };
    const typeEvent = mastersProvider.Actions.typeSchedules.find((item: TypeEventScheduleEntity) => item.id === json.tag_type_id) ?? fakeEvent;
    return {
        id: json.shift_modifier_id + "",
        dateStart: DateParse.stringToDate(json.start_time),
        dateEnd: DateParse.stringToDate(json.end_time),
        type: typeEvent,
        name: typeEvent.name,
        description: typeEvent.description,
        shiftId: json.shift_id,
    }
}

export default {
    fromJson
}