import { injectable } from "inversify";
import GroupEntity from "../../entities/GroupEntity";
import GroupRepository from "../../repositories/GroupRepository";
import SearchResultEntity from "../../entities/SearchResultEntity";

interface props {
    groupRepository: GroupRepository;
}

@injectable()
export default class SearchAllMyGroupsUseCase {
    _groupRepository: GroupRepository;

    constructor(_: props) {
        this._groupRepository = _.groupRepository;
    }

    public call = async (word: string, page: number, itemsPerPage: number) : Promise<SearchResultEntity<GroupEntity>> => {
        return this._groupRepository.searchGroup(word, page, itemsPerPage);
    }
}

export const SearchAllMyGroupsUseCaseName = "SearchAllMyGroupsUseCaseName";
