import { Either } from "fp-ts/lib/Either";
import ExceptionEntity from "../../../../../domain/entities/ExceptionEntity";
import GraphApi from "../../../../settings/GraphApi";

const CreateTimeTrackingSwapApiImpl = async (description: string, original_time_tracking_id: string | null, requestor_id: string, responder_id: string, swapped_time_tracking_id: string): Promise<Either<ExceptionEntity, void>> => {
    const response = await GraphApi.mutation('createTime_tracking_swap', {
        createTime_tracking_swapInput: { description, original_time_tracking_id: original_time_tracking_id != null ? parseInt(original_time_tracking_id) : null, requestor_id: parseInt(requestor_id), responder_id: parseInt(responder_id), swapped_time_tracking_id: parseInt(swapped_time_tracking_id) }
    }, ['description', 'modified_at', 'original_time_tracking_id', 'requestor_id', 'responder_id', 'status', 'swapped_time_tracking_id', 'time_tracking_swap_id']);
    return response;

}

export default CreateTimeTrackingSwapApiImpl;