import { FC, useContext, useEffect, useState } from "react";
import KeyWordLocalization from '../../../../../providers/localization/dictionaries/KeyWordLocalization';
import LocalizationContext from '../../../../../../domain/providers/localization/LocalizationContext';
import LocalizationContextType from '../../../../../../domain/providers/localization/LocalizationContextType';
import { Pie } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { SummaryTypeAttendance } from '../../../../../../domain/repositories/TrackingTimeRepository';
import PieChartDashboardComponentProps from './PieChartDashboardComponentProps';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    scalable: true,
    aspectRatio: 1,
    plugins: {
        legend: {
            position: 'top' as const,
        },
    },
};
const PieChartDashboardComponent: FC<PieChartDashboardComponentProps> = ({ summary, title, showReal }) => {
    const { i18n } = useContext(LocalizationContext) as LocalizationContextType;

    const [data, setData] = useState<any>();

    const parseData = () => {
        const labelsAsDate = summary.map((item: SummaryTypeAttendance) => item.typeEvent.name);
        const dataSet = {
            labels: labelsAsDate,
            datasets: [{
                label: title,
                data: summary.map((item: SummaryTypeAttendance) => showReal ? item.totalReal : item.totalPlanned),
                border: [1, 1, 1, 1],
                backgroundColor: summary.map((item: SummaryTypeAttendance) => item.typeEvent.color),
            }],
        }
        setData(dataSet);
    }

    useEffect(() => {
        parseData();
    }, [summary]);

    if(summary.reduce((acc: number, item: SummaryTypeAttendance) => acc + (showReal ? item.totalReal : item.totalPlanned), 0) === 0) return <></>;

    return <div className="tracking_compare_line_component">
        <div className="card h-100">
            <div className="px-3 pt-3">
                {title}
            </div>
            <hr />
            <div className="p-2 h-100">
                {data && <Pie data={data} height={'100%'} options={options} />}
            </div>
        </div>
    </div>

}

export default PieChartDashboardComponent;