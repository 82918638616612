import { FC, useContext, useState } from "react";
import CircularIndicatorComponent from "../../../circularIndicator/CircularIndicatorComponent";
import TrackingTimeContext from "../../../../../domain/providers/trackingTime/TrackingTimeContext";
import TrackingTimeContextType from "../../../../../domain/providers/trackingTime/TrackingTimeContextType";
import di from "../../../../../di/DependencyInjection";
import { EndEventClockUseCase, EndEventClockUseCaseName } from "../../../../../domain/use_cases/trackingTime/EndEventClockUseCase";
import ModalsContext from "../../../../../domain/providers/modal/ModalsContext";
import ModalsContextType from "../../../../../domain/providers/modal/ModalsContextType";
import { isRight } from "fp-ts/lib/Either";
import Icons from "../../../../assets/Icons";

const TrackingTimeOverTimeComponent: FC<{}> = () => {
    const { currentEvent, text, currentProgress } = useContext(TrackingTimeContext) as TrackingTimeContextType;
    const { addToast } = useContext(ModalsContext) as ModalsContextType;
    const [loading, setLoading] = useState(false);

    const _handleEndTask = async () => {
        if (currentEvent == undefined) return;
        setLoading(true);
        const response = await di.get<EndEventClockUseCase>(EndEventClockUseCaseName).call(currentEvent!.shiftId!);
        if (isRight(response)) addToast("Task ended", "success", undefined);
        else addToast(response.left.code ?? "Error ending task", "error", undefined, true);
        setLoading(false);
    }

    return <div className="circle_timer_container my-3">
        
        <CircularIndicatorComponent minSize={200} color={currentProgress > 100 ? 'var(--color-red-soft)' : undefined} progress={currentProgress}>
            {currentProgress > 100 ? <div className="overtime_content mt-2">
                <h2 className='text-danger'>+ {text}</h2>
                <div className="d-flex justify-content-center">
                    <div onClick={() => _handleEndTask()} className='text-danger m-auto d-flex align-items-center justify-content-center hover'>
                        <Icons.Pause height={16} /> <div className="ms-1">
                            End task
                        </div>
                    </div>
                </div>
            </div> : ''}
        </CircularIndicatorComponent>
    </div>
}

export default TrackingTimeOverTimeComponent;