import { Either } from "fp-ts/lib/Either";
import ShiftEntity from "../entities/ShiftEntity";
import ExceptionEntity from "../entities/ExceptionEntity";
import UserEntity from "../entities/UserEntity";
import SearchResultEntity from "../entities/SearchResultEntity";
import EventScheduleEntity from "../entities/EventScheduleEntity";
import StaffPerShiftEntity from "../entities/StaffPerShiftEntity";
import BusinessUnitEntity from "../entities/BusinessUnitEntity";
import GroupEntity from "../entities/GroupEntity";

export interface ChangesOfCalendarWeekParam {
    eventsChanged: EventScheduleEntity[],
    shiftsChanged: ShiftEntity[],
    eventsDeleted: EventScheduleEntity[],
    shiftsDeleted: ShiftEntity[],
    eventsCreated: EventScheduleEntity[],
    shiftsCreated: ShiftEntity[],
}
export interface GetUsersWithShiftResponse {
    shifts: ShiftEntity[],
    user: UserEntity,
}

export interface AsingShiftBulkImportResponse {
    doneEmails: string[],
    failedEmails: {
        email: string,
        error: string,
    }[],
}

export default interface ShiftRepository {
    asignShfit: (users: UserEntity[], shifts: ShiftEntity[]) => Promise<Either<ExceptionEntity, void>>;
    asignShiftBulkImport: (data: { email: string, shifts: ShiftEntity[], outEvents: EventScheduleEntity[] }[], onCallBack: (data :AsingShiftBulkImportResponse) => void ) => Promise<Either<ExceptionEntity, AsingShiftBulkImportResponse>>;
    createShift: (shift: ShiftEntity) => Promise<Either<ExceptionEntity, ShiftEntity>>;
    createEventForShift: (event: EventScheduleEntity, shiftId: string) => Promise<Either<ExceptionEntity, EventScheduleEntity>>;
    createMultipleEventsForShift: (events: EventScheduleEntity[], employees: UserEntity[], shiftGroupId: string, type: string) => Promise<Either<ExceptionEntity, void>>;
    updateEventForShift: (event: EventScheduleEntity, shiftId: string) => Promise<Either<ExceptionEntity, EventScheduleEntity>>;
    getShiftCalendar: (shiftId: string, startDate: Date, endDate: Date) => Promise<Either<ExceptionEntity, ShiftEntity[]>>;
    searchShiftTemplate: (businessUnitId: string, days: Date[], word: string, page: number, itemsPerPage: number) => Promise<SearchResultEntity<ShiftEntity>>;
    searchUsersWithShift: (businessUnits: BusinessUnitEntity[], supervisorsIds: UserEntity[], employeesIds: UserEntity[], groups: GroupEntity[], startDate: Date, endDate: Date, page: number, itemsPerPage: number) => Promise<SearchResultEntity<GetUsersWithShiftResponse>>;
    searchUsersOfShift: (shiftId: string, word: string, page: number, itemsPerPage: number) => Promise<SearchResultEntity<UserEntity>>;
    searchWithUserGroupStatus: (businessUnitId: BusinessUnitEntity[], supervisorId: UserEntity[], employeesIds: UserEntity[], groupsIds: GroupEntity[], date: Date[], page: number, itemsPerPage: number) => Promise<SearchResultEntity<StaffPerShiftEntity>>;
    getShiftById: (shiftId: string) => Promise<Either<ExceptionEntity, ShiftEntity>>;
    searchShifts: (businessUnitId: string[], supervisorId: string[], employeesIds: string[], groupId: string | undefined, date: Date[], word: string, page: number, itemsPerPage: number) => Promise<SearchResultEntity<ShiftEntity>>;
    updateUserWeek: (email: string, changes: ChangesOfCalendarWeekParam) => Promise<Either<ExceptionEntity, void>>;
}
export const ShiftRepositoryName = "ShiftRepository";