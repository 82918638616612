const load = () => {
    const originalError = console.error;

    console.error = (message?: any, ...optionalParams: any[]): void => {
        originalError(message, ...optionalParams);
        // call gtag and send error
        (window as any).gtag?.('event', 'exception', {
            'description': message,
            'fatal': false
        });
    }

}

export default {
    load
}
