/********************************************************************************
 * File Header - AWS Confirm User API Implementation
 * Created By: Marlon Alejandro Mendez Castañeda
 * Contact: +57 3234686680
 * Date: August 9, 2023
 *
 * Description:
 * This file contains the implementation of the AWS Confirm User API.
 * Confirm the user with the code sent to the email.
 ********************************************************************************/

import { Auth } from "aws-amplify";
import { Either, left, right } from "fp-ts/lib/Either";
import ExceptionEntity from "../../../../../domain/entities/ExceptionEntity";
import KeyWordLocalization from "../../../../../ui/providers/localization/dictionaries/KeyWordLocalization";

const ConfirmUserApiImpl = async (email:string, code: string): Promise<Either<ExceptionEntity, void>> => {
    try {
        await Auth.confirmSignUp(email, code);
        return right(undefined);
    }
    catch (error: any) {
        if(error.code === 'LimitExceededException') return left({ code:  KeyWordLocalization.AuthRepositoryErrorLimitResetExceeded, });
        if(error.code === 'CodeMismatchException') return left({ code:  KeyWordLocalization.AuthRepositoryErrorCodeRecoveryNoMatch, });
        if(error.code === 'ExpiredCodeException') return left({ code:  KeyWordLocalization.AuthRepositoryErrorCodeRecoveryExpired, });
        return left({});
    }
}

export default ConfirmUserApiImpl;