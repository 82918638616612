import { injectable } from "inversify";
import FilterOptionEntity from "../../entities/FilterOptionEntity";
import UserEntity from "../../entities/UserEntity";
import UserRepository from "../../repositories/UserRepository";
import SearchResultEntity from "../../entities/SearchResultEntity";

interface props { userRepository: UserRepository }

@injectable()
export default class SearchUserUseCase {
    _userRepository: UserRepository;

    constructor(_: props) {
        this._userRepository = _.userRepository;
    }

    public call = async (word: string, page: number, itemsPerPage: number, filter: {[key: string]:any}) => new Promise<SearchResultEntity<UserEntity>>(async (resolve, reject) => {
        try {
            const response = await this._userRepository.search(word, page, itemsPerPage, filter);
            return resolve(response);
        } catch (error) {
            return reject();
        }
    });
}

export const SearchUserUseCaseName = "SearchUserUseCaseName";