import { ChangeEvent, FC, useContext, useEffect, useRef, useState } from "react";
import DeleteModalComponentProps from "./DeleteModalComponentProps";
import ModalsContext from "../../../../../domain/providers/modal/ModalsContext";
import ModalsContextType from "../../../../../domain/providers/modal/ModalsContextType";
import './DeleteModalComponentStyles.scss';
import Icons from "../../../../assets/Icons";

const DeleteModalComponent:FC<DeleteModalComponentProps> = ({}) => {
    const { isOpenModalDelete, closeModalDelete, deleteModal } = useContext(ModalsContext) as ModalsContextType;

    const [ inputConfirmation, setInputConfirmation ] = useState('');

    const modalContainerRef = useRef<HTMLDivElement>(null);

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        setInputConfirmation(event.target.value);
    }

    const handleSave = () => {
        if (deleteModal.keyMessage === inputConfirmation) {
            deleteModal.onConfirm();
            closeModalDelete();
            setInputConfirmation('');
        }
    }

    const handleCancel = () => {
        closeModalDelete();
        setInputConfirmation('');
    }

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (modalContainerRef.current && !modalContainerRef.current.contains(event.target as Node)) {
            closeModalDelete();
            }
        };

        if (isOpenModalDelete) {
            // Add event listener when the modal is open
            window.addEventListener("mousedown", handleClickOutside);
        }

        return () => {
            // Remove event listener when the component unmounts
            window.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isOpenModalDelete, closeModalDelete]);

    

    if (deleteModal === undefined) return <></>
    
    return <section className="delete-back-container">
        <div className="delete_modal_component" ref={modalContainerRef}>
                <div className="delete_modal_component_header">
                    <div>{deleteModal.title}</div>
                    <div onClick={() => closeModalDelete()}>
                        <Icons.CloseX className="delete_modal_component_header_icon"/>
                    </div>
                    
                </div>
                <div className="delete_modal_component_content">{deleteModal.content}</div>
                <input className="delete_modal_component_content_input" onChange={(e) => handleInputChange(e) } value={inputConfirmation} type="text" name="" id="" placeholder={deleteModal.warningMessage}/>
                <div className="delete_modal_component_footer">
                    <button className="delete_modal_component_cancel" onClick={() => handleCancel()}>Cancel</button>
                    <button className="delete_modal_component_delete" onClick={() => handleSave()}>Delete</button>
                </div>
        </div>
    </section>
}

export default DeleteModalComponent;
