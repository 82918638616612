import { Either, left, right } from "fp-ts/lib/Either";
import ExceptionEntity from "../../../../domain/entities/ExceptionEntity";
import { Auth } from "aws-amplify";
import callAPI from "../../fetchCallImplementation";
import Constants, { ConstantsName } from "../../../../constants/Constants";
import di from "../../../../di/DependencyInjection";

const UpdateRolPermisionsApiImpl  = async (id: string, bodyContent: any): Promise<Either<ExceptionEntity, string>> => {


    try {
        const bodyToSend = bodyContent.policy_id;
        delete bodyContent['policy_id'];

        const body = {
            "effect": bodyContent.effect,
            "policy_name": bodyContent.policy_name,
            "policy": bodyContent.policy
        }

        const baseUrl = di.get<Constants>(ConstantsName).HOST_API_1_URL;
        const response = await callAPI(`${baseUrl}/policies/${bodyToSend}`, 'PUT', body);

        if (!response.ok) {
            const error = await response.json()
            throw new Error(error.message);
        }

        const value  = await response.body?.getReader().read();
        const result = new TextDecoder().decode(value?.value);

        return right(result);
        
    } catch (error: any) {

        const exception: ExceptionEntity = {message: error.message};
        
        return left(exception);
    }
}

export default UpdateRolPermisionsApiImpl;