import './ModulePermisionsDetailComponentStyles.scss';
import { FC } from "react";
import ModulePermisionsEditableComponent from '../../../../../components/roles/modulePermisionsEditable/ModulePermisionsEditableComponent';
import ModulePermisionsDetailComponentProps from './ModulePermisionsDetailComponentProps';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../../../../routes/RoutesComponent';
import Icons from '../../../../../assets/Icons';
import ModuleEntity from '../../../../../../domain/entities/ModuleEntity';
import ActionButtonRolesComponent from '../../../../../components/actionButton/ActionButtonRolesComponent';

const ModulePermisionsDetailComponent: FC<ModulePermisionsDetailComponentProps> = ({ role, hasEdition }) => {

    const navigate = useNavigate();


    const _handleGoToEdition = () => {
        navigate(routes.rolesAndPermissionsEdition.relativePath + "/" + role.id);
    }

    return <div className="card-role-component">
        <div className="card-body">
            <div className="role-title-detail">
                {
                    hasEdition?.update && 
                        <div style={{display: "flex", justifyContent: "space-between"}}>
                        <div>Permissions</div>
                        <ActionButtonRolesComponent text='Edit Permissions' onClick={() => _handleGoToEdition()} icon={Icons.EditPencil} colorLine="#E6C860"/>
                </div>
                }
                
                <div className="d-flex modules-container">
                    {
                        role?.modules.map((module: ModuleEntity, idx: number) => {
                            
                            return <section key={idx}  style={{marginRight: "40px"}}>
                                <ModulePermisionsEditableComponent module={module} />
                            </section> 
                            
                        })
                    }
                </div>
                
            </div>
        </div>
    </div>
}

export default ModulePermisionsDetailComponent;