import { right } from "fp-ts/lib/Either";
import Constants, { ConstantsName } from "../../../../../constants/Constants";
import di from "../../../../../di/DependencyInjection";
import callAPI from "../../../fetchCallImplementation";

//TODO implement
const CreateAIMomentByIdApiImpl = async (customer: string, type: string, description: string, weight: number): Promise<any> => {

    let body = {
        customer,
        type,
        description,
        weight
    }

    const baseUrl = di.get<Constants>(ConstantsName).AI;

    const response = await callAPI(baseUrl + `/keywords`, 'POST', body);
    
    const result = await response.json();
    
    return right(result);
}

export default CreateAIMomentByIdApiImpl;