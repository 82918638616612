import { Either, isRight, left, right } from "fp-ts/lib/Either";
import ExceptionEntity from "../../../../../domain/entities/ExceptionEntity";
import ShiftEntity from "../../../../../domain/entities/ShiftEntity";
import { AsingShiftBulkImportResponse } from "../../../../../domain/repositories/ShiftRepository";
import DateParse from "../../../../../ui/utils/DateParse";
import GraphApi from "../../../../settings/GraphApi";
import KeyWordLocalization from "../../../../../ui/providers/localization/dictionaries/KeyWordLocalization";
import EventScheduleEntity from "../../../../../domain/entities/EventScheduleEntity";

const _asignEventsToShift = async (email: string, events: EventScheduleEntity[], shiftGroupId: string): Promise<Either<ExceptionEntity, void>> => {
    try {
        const body = events.map(event => {
            return {
                activity_type_id: 7,
                end_time: DateParse.formatDate(event.dateEnd),
                start_time: DateParse.formatDate(event.dateStart),
                tag_type_id: event.type.id,
                shift_group: shiftGroupId,
                email: email,
            }
        });

        const response = await GraphApi.mutation('createBulkShift_modifiers', { createShift_modifiersInput: body }, ['activity_type_id', 'end_time', 'shift_id', 'shift_modifier_id', 'start_time', 'tag_type_id']);
        return response;
    } catch (error) {
        return left({ code: KeyWordLocalization.UnknownError, message: KeyWordLocalization.UnknownError });
    }
}
const _asignShiftToEmail = async (email: string, shifts: ShiftEntity[]): Promise<Either<ExceptionEntity, void>> => {
    try {
        let mappedQuery: any[] = []

        mappedQuery = shifts.map(shift => {
            const initDate = new Date(shift.initHour);
            initDate.setHours(shift.initHour.getHours());
            initDate.setMinutes(shift.initHour.getMinutes());
            initDate.setSeconds(0);

            const endDate = new Date(shift.endHour);
            endDate.setHours(shift.endHour.getHours());
            endDate.setMinutes(shift.endHour.getMinutes());
            endDate.setSeconds(0);
            return {
                email: [email],
                end_time: DateParse.formatDate(endDate),
                start_time: DateParse.formatDate(initDate),
            }
        });

        const response = await GraphApi.mutation('assignBulkShift', {
            assignShiftInput: mappedQuery,
        }, ['employee_id', 'end_time', 'start_time', 'shift_id', 'shift_group']);
        if (isRight(response)) {
            const shiftResponse = response.right.data?.assignBulkShift?.[0]?.shift_group;
            if (shiftResponse === undefined) return left({ code: KeyWordLocalization.ErrorHostShiftNotCreated });
            const groupedShifts = shifts.flatMap(shift => shift.events);
            if (groupedShifts.length == 0) return right(undefined);
            const responseCreateEvents = await _asignEventsToShift(email, groupedShifts, shiftResponse);
            return responseCreateEvents;
        }
        const keyWord = response.left.message?.split(': ')[0];
        response.left.code = 'ErrorHost' + keyWord;
        return response;
    } catch (e) {
        return left({ code: KeyWordLocalization.UnknownError });
    }
}

const AsignShiftBulkImportApiImpl = async (data: { email: string, shifts: ShiftEntity[], outEvents: EventScheduleEntity[] }[], onCallBack: (data: AsingShiftBulkImportResponse) => void): Promise<Either<ExceptionEntity, AsingShiftBulkImportResponse>> => {
    const responses: AsingShiftBulkImportResponse = {
        doneEmails: [],
        failedEmails: [],
    }

    const apiCall = async (email: string, shifts: ShiftEntity[], outEvents: EventScheduleEntity[]) => {
        if (outEvents.length > 0) {
            responses.failedEmails.push({ email: email, error: KeyWordLocalization.ErrorHostEventOutoOfShift });
        } else {
            const response = await _asignShiftToEmail(email, shifts);
            if (isRight(response)) {
                responses.doneEmails.push(email);
            } else {
                responses.failedEmails.push({ email: email, error: response.left.code ?? KeyWordLocalization.UnknownError });
            }
        }

    }

    // await Promise.all([...data.map((d) => apiCall(d.email, d.shifts))]);

    for (const d of data) {
        await apiCall(d.email, d.shifts, d.outEvents);
        onCallBack(responses);
    }

    return right(responses);
}

export default AsignShiftBulkImportApiImpl;