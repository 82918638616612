import { Either } from "fp-ts/lib/Either";
import ExceptionEntity from "../entities/ExceptionEntity";
import GroupEntity from "../entities/GroupEntity";
import SearchResultEntity from "../entities/SearchResultEntity";
import UserEntity from "../entities/UserEntity";
import DeleteGroupByIdApiImpl from "../../data/repositories/group/api/impl/DeleteGroupByIdApiImpl";

export default interface GroupRepository {
    searchGroup: (word: string, page: number, itemsPerPage: number) => Promise<SearchResultEntity<GroupEntity>>;
    getUsersOfGroup: (groupIds: string[]) => Promise<Either<ExceptionEntity, UserEntity[]>>;
    createGroup: (name: string, description: string, users: UserEntity[]) => Promise<Either<ExceptionEntity, GroupEntity>>;
    deleteGroup: (groupdId: string) => Promise<boolean>;
}

export const GroupRepositoryName = "GroupRepository";