import UserEntity, { UserEntityStatus } from "../../../domain/entities/UserEntity";
import KeyWordLocalization from "../../../ui/providers/localization/dictionaries/KeyWordLocalization";

const fromJson = (json: any): UserEntity => {
    return {
        id: json.user_id ?? json.employee_id,
        name: json.firstname + ' ' + json.lastname,
        email: json.email,
        phone: json.telephone_number,
        status: UserEntityStatus.active, //When user is inactive user is not getted
        image: json.profile_picture,
        firstname: json.firstname,
        lastname: json.lastname,
        profile_picture: json.profile_picture,
        role_id: json.role_id,
        systemid: json.systemid,
        user_id: json.user_id,
        cognito_user: json.cognito_user,
        permissions: undefined,
        // permissions: json.permissions, //TODO
        area: json.business_unit,
        position: json.position,
        role: json.role, //TODO

        business_unit: json.business_unit,
    }
}

const toJson = (user: UserEntity): any => {
    return {
        id: user.id,
        name: user.name,
        email: user.email,
        phone: user.phone,
        permissions: user.permissions,
        status: user.status,
        image: user.image,
        position: user.position,
        area: user.area,

        // createdAt: user.createdAt,
        // updatedAt: user.updatedAt,
    }
}

const filterToJSON = (filter: { [key: string]: any }): any => {
    if (filter == null) return {};
    return {
        'name': filter[KeyWordLocalization.UserEntityName],
        'role': filter[KeyWordLocalization.UserEntityRole],
        'businessunits': filter[KeyWordLocalization.UserEntityBUAssociated] != '' ? filter[KeyWordLocalization.UserEntityBUAssociated]?.map((bu: any) => bu) : '',
        'supervisorid': filter[KeyWordLocalization.UserEntitySupervisor] != '' ? filter[KeyWordLocalization.UserEntitySupervisor]?.map((sup: any) => sup) : '',
        'email': filter["email"] != '' ? filter['email']?.map((email: string) => email) : '',
    }
}

export default {
    toJson,
    fromJson,
    filterToJSON,
}
