import { injectable } from "inversify";
import ShiftRepository, { GetUsersWithShiftResponse } from "../../repositories/ShiftRepository";
import SearchResultEntity from "../../entities/SearchResultEntity";
import BusinessUnitEntity from "../../entities/BusinessUnitEntity";
import UserEntity from "../../entities/UserEntity";
import GroupEntity from "../../entities/GroupEntity";

interface props {
  shiftRepository: ShiftRepository;
}
@injectable()
export default class SearchUsersWithShiftUseCase {
  private shiftRepository: ShiftRepository;

  public constructor(_: props) {
    this.shiftRepository = _.shiftRepository;
  }

  public async call(businessUnits: BusinessUnitEntity[], supervisorsIds: UserEntity[], employeesIds: UserEntity[], groups: GroupEntity[], startDate: Date, endDate: Date, page: number, itemsPerPage: number): Promise<SearchResultEntity<GetUsersWithShiftResponse>> {
    return this.shiftRepository.searchUsersWithShift(businessUnits, supervisorsIds, employeesIds, groups, startDate, endDate, page, itemsPerPage);
  }
}

export const SearchUsersWithShiftUseCaseName = "SearchUsersWithShiftUseCase";