import { Either, isRight, right } from "fp-ts/lib/Either";
import ExceptionEntity from "../../../../../domain/entities/ExceptionEntity";
import TypeEventScheduleEntity from "../../../../../domain/entities/TypeEventScheduleEntity";
import GraphApi from "../../../../settings/GraphApi";
import TypeEventScheduleEntityHostDto from "../../../../dto/impl/TypeEventScheduleEntityHostDto";

const GetAllTypeEventsApiImpl = async (): Promise<Either<ExceptionEntity, {
    events: TypeEventScheduleEntity[],
    workTime: TypeEventScheduleEntity | undefined
}>> => {
    const call = await GraphApi.query('listTag_types', {}, ['tag_type_id', 'activity_tag', 'color', 'description']);
    if (isRight(call)) {
        const parsed: TypeEventScheduleEntity[] = call.right.data.listTag_types.map((item: any) => TypeEventScheduleEntityHostDto.fromJson(item)).filter((item: TypeEventScheduleEntity) => item.id != '26');
        //outstand the first 3
        for (let i = 0; i < 3 && i < parsed.length; i++) {
            parsed[i].outstanding = true;
        }
        let workTime: TypeEventScheduleEntity | undefined;
        if (parsed.length > 0) {
            workTime = parsed.find((item: TypeEventScheduleEntity) => item.id == '1' || item.id == '17' || item.name.toLowerCase() == 'Work Time'.toLowerCase());
        }
        return right({
            events: parsed,
            workTime: workTime
        });
    } else {
        return call;
    }
}

export default GetAllTypeEventsApiImpl;