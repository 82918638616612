import { injectable } from "inversify";
import CalendarRepository from "../../../domain/repositories/CalendarRepository";
import { Either } from "fp-ts/lib/Either";
import EventScheduleEntity from "../../../domain/entities/EventScheduleEntity";
import ExceptionEntity from "../../../domain/entities/ExceptionEntity";
import CreateEventForUserApiImpl from "./api/impl/CreateEventForUserApiImpl";
import GetEmployeeCalendarApiImpl from "./api/impl/GetEmployeeCalendarApiImpl";
import DeleteEventInCalendarApiImpl from "./api/impl/DeleteEventInCalendarApiImpl";
import UpdateEventCalendarApiImpl from "./api/impl/UpdateEventCalendarApiImpl";
import GetAllTypeEventsApiImpl from "./api/impl/GetAllTypeEventsApiImpl";
import GetEmployeeTodayCalendarApiImpl from "./api/impl/GetEmployeeTodayCalendarApiImpl";
import ShiftEntity from "../../../domain/entities/ShiftEntity";

@injectable()
export default class CalendarRepositoryImpl implements CalendarRepository {
    getTypeEvents = GetAllTypeEventsApiImpl;
    createEventForUser = (event: EventScheduleEntity, userId: string): Promise<Either<ExceptionEntity, EventScheduleEntity>> => CreateEventForUserApiImpl(event, userId);
    updateEvent = (event: EventScheduleEntity): Promise<Either<ExceptionEntity, EventScheduleEntity>> => UpdateEventCalendarApiImpl(event);
    deleteEvent = (eventId: string): Promise<Either<ExceptionEntity, void>> => DeleteEventInCalendarApiImpl(eventId);
    getEmployeeCalendar = (employeeId: string, startDate: Date, endDate: Date): Promise<Either<ExceptionEntity, ShiftEntity[]>> => GetEmployeeCalendarApiImpl(employeeId, startDate, endDate);
    getEmployeeTodayCalendar = (employeeId: string):Promise<Either<ExceptionEntity, ShiftEntity>> => GetEmployeeTodayCalendarApiImpl(employeeId);
}