import { Either, isLeft, left, right } from "fp-ts/lib/Either";
import di from "../../../../../di/DependencyInjection";
import ExceptionEntity from "../../../../../domain/entities/ExceptionEntity";
import ShiftEntity from "../../../../../domain/entities/ShiftEntity";
import MastersProvider, { MastersProviderName } from "../../../../../domain/providers/master/MastersProvider";
import DateParse from "../../../../../ui/utils/DateParse";
import EventScheduleEntityHostDto from "../../../../dto/impl/EventScheduleEntityHostDto";
import Host2Api, { Host2ApiName } from "../../../../settings/Host2Api";
import EventScheduleEntity from "../../../../../domain/entities/EventScheduleEntity";


//TODO implement
const GetAllModifiersApiImpl = async (employeesId: string, startDate: Date, endDate: Date, page: number, itemsPerPage: number): Promise<Either<ExceptionEntity, EventScheduleEntity[]>> => {
    try {
        const mappedIds = `userid=${parseInt(employeesId)}`
        let relativeUrl = `/modifiers?${mappedIds}&starting_date=${DateParse.formatDateForApi(startDate)}&ending_date=${DateParse.formatDateForApi(endDate)}`;

        const finalParam = relativeUrl.replace('&&', '&');


        const response = await di.get<Host2Api>(Host2ApiName).get(finalParam);

        const responseCasted = response.data.map((item: any, index: number) => EventScheduleEntityHostDto.fromJson(item));
        return right(responseCasted);

    } catch (e) {
        return left({})
    }
}

export default GetAllModifiersApiImpl;