import { FC, useContext, useEffect, useState } from "react";
import ModalsContext from "../../../../domain/providers/modal/ModalsContext";
import ModalsContextType from "../../../../domain/providers/modal/ModalsContextType";
import { isRight } from "fp-ts/lib/Either";
import { FieldValues, UseFormReturn, useForm } from "react-hook-form";
import KeyWordLocalization from "../../../providers/localization/dictionaries/KeyWordLocalization";
import LocalizationContext from "../../../../domain/providers/localization/LocalizationContext";
import LocalizationContextType from "../../../../domain/providers/localization/LocalizationContextType";
import { ErrorMessage } from "@hookform/error-message";
import Validators from "../../../utils/Validators";
import DateParse from "../../../utils/DateParse";
import CreateShiftFormComponentProps from "./CreateShiftFormComponentProps";
import ShiftEntity from "../../../../domain/entities/ShiftEntity";
import { DateOperations } from "../../../utils/DateOperations";

const CreateShiftFormComponent: FC<CreateShiftFormComponentProps> = ({
    handleAddShift,
    handleEditShift,
    date,
    shift,
}) => {
    const formFunctions = useForm();
    const { closeModalCustom } = useContext(ModalsContext) as ModalsContextType;
    const { register, handleSubmit, getValues, setValue, watch, formState: { errors } } = formFunctions;
    const { i18n } = useContext(LocalizationContext) as LocalizationContextType;
    const { addToast } = useContext(ModalsContext) as ModalsContextType;
    const dayShift = (date ?? shift?.initHour)!;
    const canEdit = (dateEdit: Date | undefined) => {
        if (dateEdit == undefined) return true;
        return dateEdit.getTime() >= (new Date()).getTime();
    }

    const minEditabledDate = () => {
        const now = new Date();
        const currentTime = `${now.getHours()}:${now.getMinutes()}`;
        const isToday = (d: Date) => DateOperations.isSameDate(d, new Date());

        if (date && isToday(date)) return currentTime;
        if (shift && isToday(shift.initHour)) return currentTime;
        return undefined;
    };

    const _handleCreateShift = async (data: any) => {
        if (handleAddShift == undefined) return;
        const initHourField = data[KeyWordLocalization.ShiftEntityInitHour];
        const initHour = new Date(dayShift);
        initHour.setHours(Number(initHourField.split(":")[0]));
        initHour.setMinutes(Number(initHourField.split(":")[1]));

        const endHourField = data[KeyWordLocalization.ShiftEntityEndHour];
        const endHour = new Date(dayShift);
        endHour.setHours(Number(endHourField.split(":")[0]));
        endHour.setMinutes(Number(endHourField.split(":")[1]));

        const tempShift: ShiftEntity = {
            id: '',
            events: [],
            shiftGroup: '',
            users: [],
            endHour: endHour,
            initHour: initHour,
        }

        const response = await handleAddShift(tempShift);
        if (isRight(response)) {
            addToast(i18n(KeyWordLocalization.CreateShiftFormComponentShiftCreated), "success", undefined);
            closeModalCustom();
        } else {
            addToast(i18n(response.left.code ?? KeyWordLocalization.UnknownError), "error", undefined);
        }

    }

    const _handleCancel = () => closeModalCustom();

    const _handleEditShift = async (data: any) => {
        if (handleEditShift == undefined || shift == undefined) return;
        const copyShift = { ...shift };
        const initHourField = data[KeyWordLocalization.ShiftEntityInitHour];
        copyShift.initHour.setHours(Number(initHourField.split(":")[0]));
        copyShift.initHour.setMinutes(Number(initHourField.split(":")[1]));

        const endHourField = data[KeyWordLocalization.ShiftEntityEndHour];
        copyShift.endHour.setHours(Number(endHourField.split(":")[0]));
        copyShift.endHour.setMinutes(Number(endHourField.split(":")[1]));

        const response = await handleEditShift(copyShift);
        if (isRight(response)) {
            addToast(i18n(KeyWordLocalization.CreateShiftFormComponentShiftUpdated), "success", undefined);
            closeModalCustom();
        } else {
            addToast(i18n(response.left.code ?? KeyWordLocalization.UnknownError), "error", undefined);
        }
    }
    const _loadDefaultData = () => {
        if (shift != undefined) {
            setValue(KeyWordLocalization.ShiftEntityInitHour, DateParse.getTimeForInput(shift.initHour));
            setValue(KeyWordLocalization.ShiftEntityEndHour, DateParse.getTimeForInput(shift.endHour));
        }
    }

    useEffect(() => {
        _loadDefaultData();
    }, [shift])

    const formInfo: UseFormReturn<FieldValues, any> = useForm({
        // Your useForm configuration options...
    });

    return <div className="create_shift_component">
        <form onSubmit={shift != undefined ? handleSubmit(_handleEditShift) : handleSubmit(_handleCreateShift)}>
            <div className="row">
                <div className="col-6 my-2">
                    <div className="form-group">
                        <label>Start</label>
                        <input type="time" disabled={!canEdit(shift?.initHour)} className="form-control" {...register(KeyWordLocalization.ShiftEntityInitHour, Validators({
                            required: true, lessThan: watch(KeyWordLocalization.ShiftEntityEndHour),
                            minTime: canEdit(shift?.initHour) ? minEditabledDate() : undefined,
                        }))} />
                        <ErrorMessage as="aside" errors={errors} name={KeyWordLocalization.ShiftEntityInitHour} />
                    </div>
                </div>
                <div className="col-6 my-2">
                    <div className="form-group">
                        <label>End</label>
                        <input type="time" disabled={!canEdit(shift?.endHour)} className="form-control" {...register(KeyWordLocalization.ShiftEntityEndHour, Validators({
                            required: true, greaterThan: watch(KeyWordLocalization.ShiftEntityInitHour),
                            minTime: canEdit(shift?.endHour) ? minEditabledDate() : undefined,
                        }))} />
                        <ErrorMessage as="aside" errors={errors} name={KeyWordLocalization.ShiftEntityEndHour} />
                    </div>
                </div>


                <div className="col-6 my-2">
                    <div onClick={_handleCancel} className="w-100 btn btn_light">{i18n(KeyWordLocalization.Cancel)}</div>
                </div>
                <div className="col-6 my-2">
                    <button type="submit" className="w-100 btn btn_primary">{i18n(KeyWordLocalization.Create)}</button>
                </div>
            </div>
        </form>
    </div>
}

export default CreateShiftFormComponent;