import './SearcherStyles.scss';
import { FC, useContext, useEffect, useRef, useState } from "react";
import SearhcerComponentProps from "./SearhcerComponentProps";
import LocalizationContext from "../../../domain/providers/localization/LocalizationContext";
import LocalizationContextType from "../../../domain/providers/localization/LocalizationContextType";
import { useForm } from "react-hook-form";
import KeyWordLocalization from "../../providers/localization/dictionaries/KeyWordLocalization";
import InfiniteScroll from "react-infinite-scroll-component";
import LoadingComponent from "../LoadingComponent/LoadingComponent";
import FilterSideBarComponent from './filterSideBar/FilterSideBarComponent';

const SearcherComponent: FC<SearhcerComponentProps> = ({ onSearch, render, filterOptions, title, itemsPerPage, floatingButtons, sortOptions, isInfinitive }) => {
    const { i18n } = useContext(LocalizationContext) as LocalizationContextType;
    const { register, handleSubmit, getValues, setValue, watch, formState: { errors } } = useForm();
    const [openFilter, setOpenFilter] = useState<boolean>(false);

    const [data, setData] = useState<any[]>([]);
    const [hasMore, setHasMore] = useState<boolean>(true);

    let _timerTap: any;

    const _handleChangeText = () => {
        setValue('page', 1);
        clearTimeout(_timerTap);
        _timerTap = setTimeout(() => onSubmit(getValues()), 2000);
    }

    const onSubmit = async (values: any) => {
        const response = await onSearch(values.search, values.page, itemsPerPage, values);
        setData(response);
        setHasMore(response.length >= itemsPerPage);
        clearTimeout(_timerTap);
    }

    const _onNextPage = async () => {
        const values = getValues();
        const currentPage = values.page;
        setValue('page', currentPage + 1);
        const response = await onSearch(values.search, currentPage + 1, itemsPerPage, values);
        setData([...data, ...response]);
        setHasMore(response.length >= itemsPerPage);
    }

    const _firstSearch = async () => {
        const response = await onSearch("", 1, itemsPerPage, []);
        setValue('page', 1);
        
        setData(response);
        setHasMore(response.length >= itemsPerPage);
    }

    const _handleOpenFilter = () => setOpenFilter(true);

    useEffect(() => {
        _firstSearch();
    }, []);

    return <form className="searcher_component" onSubmit={handleSubmit(onSubmit)}>
        <div className="container">
            <div className="row d-flex align-items-center">
                <div className='d-flex align-items-center'>
                    {title && <strong className="me-3">
                        {title}
                    </strong>}
                    <div className="flex-grow-1">
                        <input type="text" className="form-control flex-grow-1 me-2" onKeyUp={_handleChangeText} placeholder={i18n(KeyWordLocalization.Search)} {...register('search')} />
                    </div>
                </div>
                {
                    /**
                     * 
                     * <div className="col-2">
                    <button className="btn btn-light w-100" type='button' onClick={_handleOpenFilter}>
                        Filter
                    </button>
                </div>
                     */
                }
                
            </div>
            <div className="row mt-4" id='scrollable_container'>
                <div className="none_item"></div>
                {
                    isInfinitive === true && data ? 
                        <InfiniteScroll
                        dataLength={data.length}
                        next={_onNextPage}
                        hasMore={hasMore}
                        scrollableTarget="scrollable_container"
                        loader={<LoadingComponent />}
                        >
                            <div className="row d-flex align-items-stretch content_render">
                                {data?.map((item, index) => render(item, index))}
                            </div>
                        </InfiniteScroll>
                        :
                            data.length > 0 ? 
                            <div className="row d-flex align-items-stretch content_render">
                                {data?.map((item, index) => render(item, index))}
                            </div>
                            :
                            <LoadingComponent />
                        
                        
                }
                
            </div>
            {floatingButtons && <div className="d-flex justify-content-end my-2">
                {floatingButtons}
            </div>}
        </div>
        <FilterSideBarComponent
            sortOptions={sortOptions}
            filterOptions={filterOptions}
            open={openFilter}
            onClose={() => setOpenFilter(false)}
            errors={errors}
            register={register}
            watch={watch}
            onChange={setValue}
            onSubmit={handleSubmit(onSubmit)} />
    </form>
};

export default SearcherComponent;