import { injectable } from "inversify";
import RoleEntity from "../../entities/RoleEntity";
import ExceptionEntity from "../../entities/ExceptionEntity";
import { Either, isLeft, right } from "fp-ts/lib/Either";
import TrackingTimeRepository from "../../repositories/TrackingTimeRepository";
import TrackingTimeProvider from "../../providers/trackingTime/TrackingTimeProvider";
import GetTodayTrackingCalendarUseCase from "./GetTodayTrackingCalendarUseCase";
import { EventScheduleEntityStatus } from "../../entities/EventScheduleEntity";

interface props {
    trackingTimeRepository: TrackingTimeRepository;
    trackingTimeProvider: TrackingTimeProvider;
    getTodayTrackingCalendarUseCase: GetTodayTrackingCalendarUseCase;
}

@injectable()
export class EndEventClockUseCase {
    _trackingTimeRepository: TrackingTimeRepository;
    _trackingTimeProvider: TrackingTimeProvider;
    _getTodayTrackingCalendarUseCase: GetTodayTrackingCalendarUseCase;
    constructor(_:props) { 
        this._trackingTimeRepository = _.trackingTimeRepository;
        this._trackingTimeProvider = _.trackingTimeProvider;
        this._getTodayTrackingCalendarUseCase = _.getTodayTrackingCalendarUseCase;
    }
    public call = async (shiftId: string): Promise<Either<ExceptionEntity, void>> => {
        //end the current task
        const endTaskResponse = await this._trackingTimeRepository.endEvent(shiftId);
        if(isLeft(endTaskResponse)) return endTaskResponse;
        await this._getTodayTrackingCalendarUseCase.call();
        //check if exist a next event if not, end the day
        const _nextEvent = this._trackingTimeProvider.Actions.nextEvent;
        
        if(_nextEvent !== undefined){
            this._trackingTimeProvider.Actions.setCurrentEvent(_nextEvent);
            this._trackingTimeProvider.Actions.setStatus(EventScheduleEntityStatus.paused)
        }else{
            this._trackingTimeProvider.Actions.setCurrentEvent(undefined);
            this._trackingTimeProvider.Actions.setStatus(EventScheduleEntityStatus.ended)
        }
        return right(undefined);
    }
};

export const EndEventClockUseCaseName = "EndEventClockUseCaseName";