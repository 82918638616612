import { Either } from "fp-ts/lib/Either";
import ExceptionEntity from "../entities/ExceptionEntity";
import RoleEntity from "../entities/RoleEntity";
import ModuleEntity from "../entities/ModuleEntity";

export interface GetModuleWithAllRolesResponse {
    module: ModuleEntity,
    submodules: {
        name: string,
        roleWithPermisions: {
            name: string,
            canRead: boolean,
            canWrite: boolean,
            canDelete: boolean,
            canUpdate: boolean,
    }[]
}[]};

export default interface RoleRepository {
    getRoleById: (id: string) => Promise<Either<ExceptionEntity, RoleEntity>>;
    updateRoleInfo: (id: string, name: string, description: string) =>  Promise<Either<ExceptionEntity, string>>;
    updateRolePermissions: (id: string, bodyContent: any) => Promise<Either<ExceptionEntity, string>>;
    deleteRole: (id: string) => Promise<Either<ExceptionEntity, string>>;

    //modules
    getModuleWithAllRoles: (moduleId: string) => Promise<Either<ExceptionEntity, GetModuleWithAllRolesResponse>>;
    getAllRoles: () => Promise<Either<ExceptionEntity, RoleEntity[]>>
    getAllPermissions: () => Promise<Either<ExceptionEntity, any[]>>
    createRole: (name: string, description: string, permisions: any[]) => Promise<Either<ExceptionEntity, string>>;
}

export const RoleRepositoryName = "RoleRepository";