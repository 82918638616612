import { injectable } from "inversify";
import UserEntity, { UserEntityStatus } from "../../../domain/entities/UserEntity";
import AllUserRepository from "../../../domain/repositories/AllUsersRepository";
import SearchAllUsersApiImpl from "./api/impl/SearchAllUsersApiImpl";
import ExceptionEntity from "../../../domain/entities/ExceptionEntity";
import { Either } from "fp-ts/lib/Either";

@injectable()
class AllUserRepositoryImpl implements AllUserRepository {
    search = async (): Promise<Either<ExceptionEntity, UserEntity[]>> => SearchAllUsersApiImpl();
}

export default AllUserRepositoryImpl;