import { Either, right } from "fp-ts/lib/Either";
import ExceptionEntity from "../../../../../domain/entities/ExceptionEntity";
import EventScheduleEntity from "../../../../../domain/entities/EventScheduleEntity";
import GraphApi from "../../../../settings/GraphApi";
import UserEntity from "../../../../../domain/entities/UserEntity";
import DateParse from "../../../../../ui/utils/DateParse";

const CreateMultipleEventsForShiftApiImpl = async (events: EventScheduleEntity[], employees: UserEntity[], shiftGroupId: string): Promise<Either<ExceptionEntity, void>> => {
    let body: any[] = [];
    for (let i = 0; i < employees.length; i++) {
        const bodyEmployees = events.map(event => {
            return {
                activity_type_id: 7,
                end_time: DateParse.formatDate(event.dateEnd),
                start_time: DateParse.formatDate(event.dateStart),
                tag_type_id: event.type.id,
                shift_group: shiftGroupId,
                email: employees[i].email,
            }
        });
        body = body.concat(bodyEmployees);
    }
    const response = await GraphApi.mutation('createBulkShift_modifiers', { createShift_modifiersInput: body }, ['activity_type_id', 'end_time', 'shift_id', 'shift_modifier_id', 'start_time', 'tag_type_id']);
    return response;
}

export default CreateMultipleEventsForShiftApiImpl;