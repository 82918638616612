import { FC, useEffect, useRef, useState } from 'react';
import './UserManagementStyles.scss';

interface Info {
    subLabel: string;
    label: string;
}

interface SearchDropdownProps {
    data: Info[];
    id: string;
    onSelect: (user: Info, id: string) => void;
    selected: string;
    editable: boolean;
    needToBeFiltered?: boolean;
}

function capitalizeFirstLetterOfEachWord(name: string) {
    return name.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
}

const SearchDropdown: FC<SearchDropdownProps> = ({ data, onSelect, id, selected, editable, needToBeFiltered = true }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState(selected);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const [ filteredData, setFilteredData ] = useState([]);
    //const [ itemSelected, setItemSelected ] = useState("");

    const handleUserSelect = async (user: Info) => {
        setSearchTerm(`${capitalizeFirstLetterOfEachWord(user.subLabel ? user.subLabel : user.label)}`);
        //setItemSelected(`${capitalizeFirstLetterOfEachWord(user.subLabel ? user.subLabel : user.label)}`);
        setDropdownOpen(false);
        onSelect(user, id);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setDropdownOpen(false);
        }
    };

    const checkItem = (item: string) => {
        const isItemExist = data.filter(el => el.label === item);
        
        if (isItemExist.length === 0 && !editable ) {
            onSelect({label: '', subLabel: ''}, id);
            //setItemSelected('');
            if (!dropdownOpen) {
                
                setSearchTerm('');
            }
        } else {
            onSelect({label: item, subLabel: item}, id);
        }
        
    }

    useEffect(() => {
        if (needToBeFiltered) {
            console.log(data,  searchTerm);
            
            const filteredItems = data
                        ?.filter((user: Info) => user.label?.toLowerCase().includes(searchTerm?.toLowerCase()) || user.subLabel?.toLowerCase().includes(searchTerm?.toLowerCase()))
                        .map(item => item);
        setFilteredData(filteredItems as any);
        } else {
            const filteredItems = data
                        ?.map(item => item);
        setFilteredData(filteredItems as any);
        }
        
    }, [searchTerm])

    useEffect(() => {
        setSearchTerm(selected)
    }, [selected])

    useEffect(() => {
        if (dropdownOpen) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdownOpen]);

    useEffect(() => {
        setSearchTerm(selected);
    }, [])

    return (
        <>
        <div className="dropdown">
            <input
                type="text"
                className="dropdown-input"
                placeholder="Type and find an item"
                onChange={(e) => {
                    setSearchTerm(e.target.value);
                    setDropdownOpen(true);
                    //setItemSelected(e.target.value);
                }}
                onFocus={() => setDropdownOpen(true)}
                onBlur={(e) => checkItem(e.target.value)}
                value={!needToBeFiltered ? selected : searchTerm}
            />
            {dropdownOpen &&
                <div className="dropdown_user_menu_dropdown" ref={dropdownRef}>
                    {filteredData?.map((user: Info, index) => (
                            <div
                                key={index}
                                className="dropdown_user_item"
                                onClick={() => handleUserSelect(user)}
                            >
                                <strong>{capitalizeFirstLetterOfEachWord(user.label)}</strong>{user.subLabel ? ` (${user.subLabel})` : ``}
                            </div>
                        ))}

                    {data
                        ?.filter((user: Info) => user.label.toLowerCase().includes(searchTerm.toLowerCase()) || user.subLabel.toLowerCase().includes(searchTerm.toLowerCase())).length < 1 &&
                        <>No items found</>
                    }
                </div>
            }
        </div>

        </>
    );
};

export default SearchDropdown;
