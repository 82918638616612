import { injectable } from "inversify";
import AllUserRepository from "../../../domain/repositories/AllUsersRepository";
import SearchAllUsersApiImpl from "./api/impl/SearchAllUsersApiImpl";

@injectable()
class AllUserRepositoryFake implements AllUserRepository {
    search = async (): Promise<any> => {
        let response = await SearchAllUsersApiImpl();
        return response;
    }
}

export default AllUserRepositoryFake;