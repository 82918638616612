import './TrackingTimeOnHoldListComponentStyles.scss';

import { FC, useContext, useState } from "react";
import di from "../../../../../di/DependencyInjection";
import { PauseClockTrackingUseCase, PauseClockTrackingUseCaseName } from "../../../../../domain/use_cases/trackingTime/PauseClockTrackingUseCase";
import EventScheduleEntity from "../../../../../domain/entities/EventScheduleEntity";
import ModalsContext from "../../../../../domain/providers/modal/ModalsContext";
import ModalsContextType from "../../../../../domain/providers/modal/ModalsContextType";
import { isRight } from "fp-ts/lib/Either";
import TrackingTimeOnHoldComponent from '../trackingTimeOnHold/TrackingTimeOnHoldComponent';
import LocalizationContext from '../../../../../domain/providers/localization/LocalizationContext';
import LocalizationContextType from '../../../../../domain/providers/localization/LocalizationContextType';


interface HoldReasons {
    list: string[];
}

const TrackingTimeOnHoldListComponent: FC<{ holdReasons: HoldReasons, currentEvent: EventScheduleEntity | undefined }> = ({ holdReasons, currentEvent }) => {

    const { openModalCustom, closeModalCustom } = useContext(ModalsContext) as ModalsContextType;
    const { i18n } = useContext(LocalizationContext) as LocalizationContextType;
    const [loading, setLoading] = useState(false);

    const _handleSelection = async (reason: string) => {
        if(loading) return;
        setLoading(true);
        const response = await di.get<PauseClockTrackingUseCase>(PauseClockTrackingUseCaseName).call(reason, currentEvent!.shiftId!);
        setLoading(false);
        closeModalCustom();

        if (isRight(response)) {

        } else {

        }
    };

    return (
        <div>
            
            {
                holdReasons?.list.map((reason: string) => (
                    <div className="items_reason" key={reason} onClick={() => _handleSelection(reason)}>{i18n(reason)}</div>
                ))
            }
        </div>
    );
};

export default TrackingTimeOnHoldListComponent;
