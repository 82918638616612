import { Either, isLeft, right, left } from "fp-ts/lib/Either";
import ExceptionEntity from "../../../../../domain/entities/ExceptionEntity";
import EventScheduleEntity from "../../../../../domain/entities/EventScheduleEntity";
import TrackingClockInteractionEntity from "../../../../../domain/entities/TrackingClockInteractionEntity";
import GraphApi from "../../../../settings/GraphApi";
import DateParse from "../../../../../ui/utils/DateParse";
import EventScheduleEntityHostDto from "../../../../dto/impl/EventScheduleEntityHostDto";
import ShiftModifierHostDto from "../../../../dto/impl/ShiftModifierHostDto";
import { fillShiftDayWithWorkEvents } from "../../../calendar/api/impl/GetEmployeeTodayCalendarApiImpl";
import TrackingClockInteractionHostDto from "../../../../dto/impl/TrackingClockInteractionHostDto";


//TODO: implement
const GetDayPlannedVsRealApiImpl = async (employeeId: string, date: Date): Promise<Either<ExceptionEntity, { planned: EventScheduleEntity; real: TrackingClockInteractionEntity[]; }[]>> => {
    date.setHours(0, 0, 0, 0);
    date.setDate(date.getDate() + 1);
    const tomorrow = new Date(date);
    tomorrow.setDate(tomorrow.getDate() + 1);
    const response = await GraphApi.multipleQuery([
        {
            name: 'listShift_modifiersDates',
            params: { listShift_modifiersDatesInput: { employee_id: parseInt(employeeId), end_date: DateParse.formatDate(tomorrow), start_date: DateParse.formatDate(date) } },
            results: ['activity_type_id', 'end_time', 'shift_id', 'shift_modifier_id', 'start_time', 'tag_type_id']
        },
        {
            name: 'listShiftsDates',
            params: { listShiftsDatesInput: { employee_id: parseInt(employeeId), end_date: DateParse.formatDate(tomorrow), start_date: DateParse.formatDate(date) } },
            results: ['employee_id', 'end_time', 'is_active', 'shift_group', 'shift_id', 'start_time', '']
        },
        {
            name: 'listTime_trackingDates',
            params: { listTime_trackingDatesInput: { employee_id: parseInt(employeeId), end_date: DateParse.formatDate(tomorrow), start_date: DateParse.formatDate(date) } },
            results: ['activity_type_id', 'clock_in_time', 'clock_out_time', 'details', 'is_active', 'shift_id', 'tag_type_id', 'time_tracking_id', 'description']
        }

    ]);
    if (isLeft(response)) {
        return response;
    } else if (response.right.data.listShift_modifiersDates.length == 0 && response.right.data.listShiftsDates.length == 0) { //TODO habilitar response.right.data.listTime_trackingDates?.length == 0

        return left({ code: 'Not Found' });
    }

    const parsedModifiers: EventScheduleEntity[] = response.right.data.listShift_modifiersDates.map((item: any) => ShiftModifierHostDto.fromJson(item));

    let allEvents: EventScheduleEntity[] = [];

    response.right.data.listShiftsDates.forEach((item: any) => {
        const _startDate = DateParse.stringToDate(item.start_time);
        const _endDate = DateParse.stringToDate(item.end_time);
        const _shiftId = item.shift_id;
        const filtred_modifiers = parsedModifiers.filter((modifier) => modifier.shiftId == item.shift_id);
        const filledDay = fillShiftDayWithWorkEvents(_startDate, _endDate, _shiftId, filtred_modifiers);
        allEvents = [...allEvents, ...filledDay];
    });


    const allReal: TrackingClockInteractionEntity[] = response.right.data.listTime_trackingDates?.map((item: any) => TrackingClockInteractionHostDto.fromJson(item)) ?? [];

    const mapped = allEvents.map((event) => {
        return {
            planned: event,
            real: allReal.filter((real: TrackingClockInteractionEntity) => {
                if (real.details == event.id) return true;
                else if (real.details == "added_by_supervisor") {
                    if (real.clockOutTime == null) {
                        if(real.clockInTime > event.dateEnd) return false;
                        return true;
                    } else {
                        if(real.clockInTime >= event.dateEnd) return false;
                        else if(real.clockOutTime <= event.dateStart) return false;
                        else return true
                    }
                } else {
                    return false
                }
            })
        }
    });

    return right(mapped);

}

export default GetDayPlannedVsRealApiImpl;