import { injectable } from "inversify";
import AIRepository from "../../repositories/AIRepository";

interface props {
    aiRepository: AIRepository;
}

@injectable()
export class CreateAIMomentByIdUseCase {
    _aiRepository: AIRepository;

    constructor(_: props) {
        this._aiRepository = _.aiRepository;
    }

    public call = async (customer: string, type: string, description: string, weight: number): Promise<any> => {
        return await this._aiRepository.CreateAIMoment(customer, type, description, weight);
    }
}

export const CreateAIMomentByIdUseCaseName = "CreateAIMomentByIdUseCaseName";