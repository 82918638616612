import './SocialLoginComponentStyles.scss';
import { FC, useContext } from "react";
import Icons from "../../assets/Icons";
import { isRight } from "fp-ts/lib/Either";
import di from "../../../di/DependencyInjection";
import SignInWithActiveDirectoryUseCase, { SignInWithActiveDirectoryUseCaseName } from "../../../domain/use_cases/auth/SignInWithActiveDirectoryUseCase";
import KeyWordLocalization from "../../providers/localization/dictionaries/KeyWordLocalization";
import { routes } from "../../routes/RoutesComponent";
import { useNavigate } from "react-router-dom";
import LocalizationContext from "../../../domain/providers/localization/LocalizationContext";
import LocalizationContextType from "../../../domain/providers/localization/LocalizationContextType";
import ModalsContext from "../../../domain/providers/modal/ModalsContext";
import ModalsContextType from "../../../domain/providers/modal/ModalsContextType";

const SocialLoginComponent: FC<{}> = () => {
    const navigate = useNavigate();
    const { addToast } = useContext(ModalsContext) as ModalsContextType;

    const { i18n } = useContext(LocalizationContext) as LocalizationContextType;
    const _handleSignInWIthActiveDirectory = async () => {
        const response = await di.get<SignInWithActiveDirectoryUseCase>(SignInWithActiveDirectoryUseCaseName).call();
        if (isRight(response)) {
            if (!(response.right?.permissions?.employee != undefined)) {
                navigate(routes.rolesAndPermissions.relativePath)
            } else {
                navigate(routes.home.relativePath);
            }
        } else {
            if (response.left.code != undefined) addToast(i18n(response.left.code), 'error', null, true);
        }
    };

    return <div className="social_login_component">
        <div className="py-3 o_container text-center">
            <div>{i18n(KeyWordLocalization.SocialLoginComponentOr)}</div>
        </div>
        <div className="btn btn_light w-100" onClick={_handleSignInWIthActiveDirectory}>
            <Icons.MicrosoftShort />
            <span className='ms-2'>{i18n(KeyWordLocalization.SocialLoginComponentSignInWithMicrosoft)}</span>
        </div>
    </div>
}

export default SocialLoginComponent;