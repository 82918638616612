/********************************************************************************
 * File Header - AWS Send Confirm Code API Implementation
 * Created By: Marlon Alejandro Mendez Castañeda
 * Contact: +57 3234686680
 * Date: August 9, 2023
 *
 * Description:
 * Send the confirm code to the email.
 ********************************************************************************/

import { Auth } from "aws-amplify";
import { Either, left, right } from "fp-ts/lib/Either";
import ExceptionEntity from "../../../../../domain/entities/ExceptionEntity";
import KeyWordLocalization from "../../../../../ui/providers/localization/dictionaries/KeyWordLocalization";

const SendConfirmCodeApiImpl = async (email: string): Promise<Either<ExceptionEntity, void>> => {
    try {
        await Auth.resendSignUp(email, {username: email});
        return right(undefined);
    }
    catch (error: any) {
        if (error.code === 'LimitExceededException') return left({ code: KeyWordLocalization.AuthRepositoryErrorLimitResetExceeded, });
        return left({});
    }
}

export default SendConfirmCodeApiImpl;