import { injectable } from "inversify";
import RoleRepository from "../../repositories/RoleRepository";
import ExceptionEntity from "../../entities/ExceptionEntity";
import { Either } from "fp-ts/lib/Either";

interface props { roleRepository: RoleRepository }

@injectable()
export default class DeleteRoleByIdUseCase {
    
    _roleRepository: RoleRepository;

    constructor(_: props) {
        this._roleRepository = _.roleRepository;
    }

    public call = (id: string)  => new Promise<Either<ExceptionEntity, string>>(async (resolve, reject) => {
        try {
            const response = await this._roleRepository.deleteRole(id);
            
            return resolve(response);
        } catch (error) {
            return reject();
        }
    });
};

export const DeleteRoleByIdUseCaseName = "DeleteRoleByIdUseCaseName";